/**
 * Created by kimch on 2017-07-26.
 */
import * as Api from '../Api';
import {processHttpError} from './utils';
import {ActionTypes, Consts, Strings} from '../constants';

export function getDeckDetail(urlKey) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_DECK_DETAIL_REQUEST, urlKey});
        Api.getDeckDetails(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_DECK_DETAIL_SUCCESS, urlKey, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_DECK_DETAIL_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, urlKey});
        });
    };
}
/**
 * Created by kimchangduk on 2017-08-18.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts, Urls } from "../constants";
import { getCBT, finishCBT } from "../actions/cbt";
import Navigator, { LeftMenuTypes } from "../components/Navigator";
import CBTQuestionsForPC from "../components/CBT/CBTQuestionsForPC";
import Loader from "../components/Loader";
import OMR from "../components/CBT/OMR/OMR";
import update from "immutability-helper";
import RemainTime from "../components/RemainTime";
import DialogManager from "../dialogs/DialogManager";
import { isMobileDevice, hasChangedRequestToSuccess, hasChangedRequestToFailure } from "../utils";
import AppHistory from "../history";
import CBTQuestionsForMobile from "../components/CBT/CBTQuestionsForMobile";
import CBTFinishConfirmDialog from "../dialogs/CBTFinishConfirmDialog";
import FontScaleSelector from "../components/CBT/FontScaleSelector";
import UnmarkedQuestionListDialog from "../dialogs/UnmarkedQuestionListDialog";
import GA from "../GA";
import LoginRequiredDialog from "../dialogs/LoginRequiredDialog";
import CouponRegisterDialog from "../dialogs/CouponRegisterDialog";
import { notify } from "react-notify-toast";

class CBTPage extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
    finishRequest: PropTypes.string,
    isLogin: PropTypes.bool,
    request: PropTypes.string,
    errorMessage: PropTypes.string,
    dataSource: PropTypes.object,
    buyRequired: PropTypes.bool,
    finishDataSource: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    const urlKeyParam = ownProps.match.params.urlKey;
    return {
      finishRequest: state.cbtPaper.finish.state.request,
      finishDataSource: state.cbtPaper.finish.state.urlKey === urlKeyParam ? state.cbtPaper.finish.dataSource : null,
      request: state.cbt.state.request,
      buyRequired: state.cbt.state.buyRequired,
      dataSource: state.cbt.state.urlKey === urlKeyParam ? state.cbt.dataSource : null,
      errorMessage: state.cbt.state.urlKey === urlKeyParam ? state.cbt.state.errorMessage : null,
      isLogin: state.user.state.isLogin,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getCBT: (urlKey) => {
          if (urlKey === undefined) {
            urlKey = ownProps.match.params.urlKey;
          }
          dispatch(getCBT(urlKey));
        },
        finishCBT: (spendingSeconds, answerPaper) => {
          dispatch(finishCBT(ownProps.match.params.urlKey, spendingSeconds, answerPaper));
        },
      },
    };
  };

  state = {
    answers: [],
    dummyAnswersMap: [], // [{id:(number),marked:(boolean)}..]
    questionNavigation: { id: -1, requestTime: -1 },
    omrNavigation: { id: -1, requestTime: -1 },
    fontScale: 1,
  };

  componentDidMount() {
    GA.logPage();
    if (this.props.isLogin !== null) {
      this.props.actions.getCBT();
    }
  }

  componentWillReceiveProps(nextProps) {
    const prevUrlKey = this.props.match && this.props.match.params ? this.props.match.params.urlKey : null;
    const nextUrlKey = nextProps.match && nextProps.match.params ? nextProps.match.params.urlKey : null;
    if ((this.props.isLogin === null && nextProps.isLogin) || prevUrlKey !== nextUrlKey) {
      this.props.actions.getCBT(nextUrlKey);
    }

    if (hasChangedRequestToSuccess(this.props.request, nextProps.request)) {
      this.startTimer(nextProps.dataSource.minuteLimit * 60 * 1000);
      this.initAnswer(nextProps.dataSource);
      this.initDummyAnswersMap(nextProps.dataSource);
    }

    if (hasChangedRequestToSuccess(this.props.finishRequest, nextProps.finishRequest)) {
      const urlKey = this.props.match.params.urlKey;
      AppHistory.move(Urls.buildCBTResult(urlKey, nextProps.finishDataSource ? nextProps.finishDataSource.id : ""));
    }

    // CBT 가져오기 실패시
    if (hasChangedRequestToFailure(this.props.request, nextProps.request)) {
      if (!nextProps.isLogin) {
        DialogManager.push(LoginRequiredDialog);
      } else {
        if (nextProps.buyRequired) {
          DialogManager.push(CouponRegisterDialog, {
            onRequestClose: () => {
              DialogManager.clear();
              AppHistory.move(Urls.ROOT);
            },
          });
        } else {
          DialogManager.alert(nextProps.errorMessage, {
            onRequestClose: () => {},
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  getSortedQuestionList(dataSource) {
    if (dataSource === undefined) {
      dataSource = this.props.dataSource;
    }
    const result = [];
    const questions = dataSource.questions;

    if (dataSource.questionPage) {
      for (let page of dataSource.questionPage) {
        for (let questionId of page) {
          const question = questions.find((a) => a.id === questionId);
          if (question) {
            result.push(question);
          }
        }
      }
    }
    return questions;
  }

  testStartTime = -1;

  confirmFinishTest = () => {
    if (!this.props.isLogin) {
      notify.show(LOGIN_REQUIRED_USER_FINISH_ALERT);
      return;
    }
    DialogManager.push(CBTFinishConfirmDialog, {
      answers: this.state.answers,
      questions: this.getSortedQuestionList(),
      onRequestNaviation: this.onNavigateRequest,
      onRequestFinish: this.finishTest,
    });
  };

  popupRemainQuestions = () => {
    DialogManager.push(UnmarkedQuestionListDialog, {
      answers: this.state.answers,
      questions: this.getSortedQuestionList(),
      onRequestNaviation: this.onNavigateRequest,
    });
  };

  initAnswer = (dataSource) => {
    const answers = [];
    const questions = this.getSortedQuestionList(dataSource);
    for (let q of questions) {
      answers.push(null);
    }
    this.setState({ answers });
  };

  initDummyAnswersMap(dataSource) {
    const questions = this.getSortedQuestionList(dataSource);
    const dummyAnswersMap = [];
    for (let question of questions) {
      const dummyAnswer = [];
      for (let example of question.example) {
        dummyAnswer.push({ id: example.id, marked: false });
      }
      dummyAnswersMap.push(dummyAnswer);
    }
    this.setState({ dummyAnswersMap: dummyAnswersMap });
  }

  startTimer(testTime) {
    this.stopTimer();
    this.testStartTime = Date.now();
    this.testTimer = window.setTimeout(() => {
      this.finishTest(true);
    }, testTime);
  }

  stopTimer() {
    if (this.testTimer) {
      window.clearTimeout(this.testTimer);
    }
  }

  /**
   * 시험종료
   */
  finishTest = (withAlert = false) => {
    DialogManager.clear();
    if (withAlert) {
      alert("시험을 종료합니다");
    }

    this.stopTimer();
    const spendingSeconds = (Date.now() - this.testStartTime) / 1000;
    const questions = this.getSortedQuestionList();
    const examinationPapaer = [];
    for (let i = 0; i < questions.length; i++) {
      if (this.state.answers[i] !== null) examinationPapaer.push({ questionId: questions[i].id, answerId: this.state.answers[i] });
    }

    if (!this.props.isLogin) {
      notify.show(LOGIN_REQUIRED_USER_FINISH_ALERT);
      return;
    }

    this.props.actions.finishCBT(spendingSeconds, examinationPapaer);
  };

  onQuestionAnswerClick = (questionId, answerNumber) => {
    this.onAnswerClick(questionId, answerNumber);
    this.setState({ omrNavigation: { id: questionId, requestTime: Date.now() } });
  };

  onQuestionDummyAnswerClick = (questionId, answerNumber) => {
    if (!this.props.isLogin) {
      notify.show(LOGIN_REQUIRED_USER_MARKING_ALERT);
      return;
    }
    this.onDummyAnswerClick(questionId, answerNumber);
    this.setState({ omrNavigation: { id: questionId, requestTime: Date.now() } });
  };

  onAnswerClick = (questionId, answerId) => {
    if (!this.props.isLogin) {
      notify.show(LOGIN_REQUIRED_USER_MARKING_ALERT);
      return;
    }

    const questions = this.getSortedQuestionList();
    const questionIndex = questions.findIndex((a) => a.id === questionId);
    const newState = {};

    //<editor-fold desc="정답 데이터 추가">
    newState.answers = update(this.state.answers, {
      $splice: [[questionIndex, 1, this.state.answers[questionIndex] !== answerId ? answerId : null]],
    });
    //</editor-fold>

    //<editor-fold desc="가체점 정보 제거">
    const newDummyAnswers = [];
    for (let example of questions[questionIndex].example) {
      newDummyAnswers.push({ id: example.id, marked: false });
    }
    newState.dummyAnswersMap = update(this.state.dummyAnswersMap, {
      $splice: [[questionIndex, 1, newDummyAnswers]],
    });
    //</editor-fold>

    this.setState(newState);
  };

  onDummyAnswerClick = (questionId, answerId) => {
    const questions = this.getSortedQuestionList();
    const questionIndex = questions.findIndex((a) => a.id === questionId);
    const newState = {};

    //<editor-fold desc="기존 정답 제거">
    if (this.state.answers[questionIndex] !== null) {
      newState.answers = update(this.state.answers, {
        $splice: [[questionIndex, 1, null]],
      });
    }
    //</editor-fold>

    //<editor-fold desc="가체점 정보 추가">
    let newDummyAnswers = this.state.dummyAnswersMap[questionIndex];
    const exampleIndex = this.state.dummyAnswersMap[questionIndex].findIndex((a) => a.id === answerId);
    newDummyAnswers = update(newDummyAnswers, {
      $splice: [[exampleIndex, 1, { id: answerId, marked: !newDummyAnswers[exampleIndex].marked }]],
    });

    newState.dummyAnswersMap = update(this.state.dummyAnswersMap, {
      $splice: [[questionIndex, 1, newDummyAnswers]],
    });
    //</editor-fold>

    this.setState(newState);
  };

  onNavigateRequest = (questionId) => {
    if (typeof questionId === "string") {
      questionId = parseInt(questionId);
    }
    this.setState({ questionNavigation: { id: questionId, requestTime: Date.now() } });
  };

  getSubjectQuestionCount = (classSubject) => {
    const questions = this.getSortedQuestionList();
    return questions.filter((question) => question.classSubjectId === classSubject.id).length;
  };

  renderQuestions = () => {
    if (this.props.request !== Consts.REQUEST_SUCCESS) {
      return undefined;
    }

    return isMobileDevice() ? (
      <CBTQuestionsForMobile
        classSubjects={this.props.dataSource.classSubjects}
        classSubjectIndex={this.props.dataSource.classSubjectIndex}
        fontScale={this.state.fontScale}
        style={styles.questions.mobile}
        questions={this.getSortedQuestionList()}
        dataSource={this.props.dataSource}
        questionNavigation={this.state.questionNavigation}
        answers={this.state.answers}
        dummyAnswersMap={this.state.dummyAnswersMap}
        onAnswerClick={this.onQuestionAnswerClick}
        onDummyAnswerClick={this.onQuestionDummyAnswerClick}
        onFinishRequest={this.confirmFinishTest}
      />
    ) : (
      <CBTQuestionsForPC
        classSubjects={this.props.dataSource.classSubjects}
        classSubjectIndex={this.props.dataSource.classSubjectIndex}
        fontScale={this.state.fontScale}
        style={styles.questions.pc}
        questions={this.getSortedQuestionList()}
        questionPage={this.props.dataSource.questionPage}
        questionNavigation={this.state.questionNavigation}
        answers={this.state.answers}
        dummyAnswersMap={this.state.dummyAnswersMap}
        onAnswerClick={this.onQuestionAnswerClick}
        onDummyAnswerClick={this.onQuestionDummyAnswerClick}
        onRemainQuestionsRequest={this.popupRemainQuestions}
      />
    );
  };

  renderOMRCard = () => {
    if (this.props.request !== Consts.REQUEST_SUCCESS || isMobileDevice()) {
      return undefined;
    }

    // eslint-disable-next-line react/jsx-pascal-case
    return (
      <OMR
        questions={this.getSortedQuestionList()}
        answers={this.state.answers}
        dummyAnswersMap={this.state.dummyAnswersMap}
        onAnswerClick={this.onAnswerClick}
        answerNavigation={this.state.omrNavigation}
        onDummyAnswerClick={this.onDummyAnswerClick}
        onNavigateRequest={this.onNavigateRequest}
        onFinishRequest={this.confirmFinishTest}
        style={styles.omr}
      />
    );
  };

  renderTopPanel() {
    const dataSource = this.props.dataSource;
    const classSubjects = dataSource.classSubjects ? dataSource.classSubjects : [];
    const classSubjectIndex = dataSource.classSubjectIndex;

    const subjectSelectComponent = (
      <select
        style={styles.subjectSelect}
        onChange={(e) => {
          const questionId = e.target.value;
          this.onNavigateRequest(questionId);
          this.setState({ omrNavigation: { id: questionId, requestTime: Date.now() } });
        }}
      >
        {classSubjects.map((item, key) => {
          const subject = item.subject;
          return (
            <option value={classSubjectIndex[item.id]} key={key}>
              {`과목${key + 1}: ${subject.name} (${this.getSubjectQuestionCount(item)}문항)`}
            </option>
          );
        })}
      </select>
    );

    return (
      <div style={isMobileDevice() ? styles.topSection.mobile : styles.topSection.pc}>
        <RemainTime testTime={dataSource.minuteLimit * 60 * 1000} startTime={this.testStartTime} />
        {isMobileDevice() ? <div>{subjectSelectComponent}</div> : subjectSelectComponent}
        {!isMobileDevice() ? (
          <div>
            <FontScaleSelector
              scale={this.state.fontScale}
              onChangeScale={(scale) => {
                this.setState({ fontScale: scale });
              }}
            />
          </div>
        ) : undefined}
      </div>
    );
  }

  render() {
    const dataSource = this.props.dataSource;

    return (
      <div>
        <Navigator title={dataSource ? dataSource.name : undefined} leftMenuType={LeftMenuTypes.HOME} />
        <div style={styles.whiteBackground} />
        {this.props.request === Consts.REQUEST_WAITING ? <Loader /> : undefined}
        {dataSource ? (
          <div style={styles.container}>
            <div style={{ height: "100%", maxWidth: 1080, margin: "auto" }}>
              {this.renderTopPanel()}
              {this.renderQuestions()}
              {this.renderOMRCard()}
            </div>
          </div>
        ) : undefined}
      </div>
    );
  }
}

const borderRadius = 5;
const borderStyle = "1px solid rgba(0,0,0,0.5)";

const styles = {
  container: {
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 10,
    padding: 10,
    textAlign: "center",
    whiteSpace: "nowrap",
  },

  questions: {
    pc: {
      width: "calc(100% - 190px)",
      height: "calc(100% - 72px)",
      borderRadius: borderRadius,
      position: "relative",
      border: borderStyle,
    },
    mobile: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 75,
      bottom: 0,
      borderRadius: borderRadius,
    },
  },
  omr: {
    width: 185,
    height: "calc(100% - 72px)",
    verticalAlign: "top",
    marginLeft: 5,
    borderRadius: borderRadius,
    border: borderStyle,
  },
  topSection: {
    pc: {
      height: 70,
      backgroundColor: "#FFFFFF",
      marginBottom: 5,
      borderRadius: borderRadius,
      border: borderStyle,
      fontWeight: "bold",
      padding: 8,
      textAlign: "right",
    },
    mobile: {
      fontSize: 13,
      height: 57,
      backgroundColor: "#FFFFFF",
      marginBottom: 5,
      borderRadius: borderRadius,
      border: borderStyle,
      fontWeight: "bold",
      padding: 4,
      textAlign: "center",
    },
  },

  subjectSelect: {
    verticalAlign: "top",
    height: 28,
    marginTop: -3,
    marginLeft: 10,
  },
  whiteBackground: {
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#FFFFFF",
  },
};

const LOGIN_REQUIRED_USER_MARKING_ALERT = "로그인 시에만 마킹이 가능합니다.";
const LOGIN_REQUIRED_USER_FINISH_ALERT = "로그인 시에만 채점이 가능합니다.";

export default connect(CBTPage.StateToProps, CBTPage.DispatchToProps)(CBTPage);

/**
 * Created by kimchangduk on 2017-09-11.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Consts, Strings, Urls} from '../constants';
import {connect} from 'react-redux';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import AppHistory from "../history";
import {getBoardList, postBoardItem, getBoardItem, postBoardItemComment, editBoardItem, deleteBoardItem, deleteBoardItemComment, getBoardNoticeList} from '../actions/board';
import GA from '../GA';
import Pagination from "rc-pagination";
import Loader from "../components/Loader";

class BoardPage extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        dataSource: PropTypes.object,

        user: PropTypes.object,
        isLogin: PropTypes.bool,

        boardListLastPage: PropTypes.bool,
        boardListRequest: PropTypes.string,
        boardListPage: PropTypes.number,

        noticeList: PropTypes.array,

        boardItemData: PropTypes.object,
        boardItemRequest: PropTypes.string
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                getBoardList: (page = 0) => {
                    dispatch(getBoardList(page));
                },
                postBoardItem: (title, content) => {
                    dispatch(postBoardItem(title, content));
                },
                getBoardItem: (id) => {
                    dispatch(getBoardItem(id));
                },
                postBoardItemComment: (id, content) => {
                    dispatch(postBoardItemComment(id, content));
                },
                editBoardItem: (id, title, content) => {
                    dispatch(editBoardItem(id, title, content));
                },
                deleteBoardItem: (id) => {
                    dispatch(deleteBoardItem(id));
                },
                deleteBoardItemComment: (id, commentId) => {
                    dispatch(deleteBoardItemComment(id, commentId));
                },
                getNoticeList: () => {
                    dispatch(getBoardNoticeList());
                }
            }
        };
    };

    static StateToProps = (state) => {
        return {
            user: state.user,
            isLogin: state.user.state.isLogin,

            deleteBoardItemRequest: state.board.boardItem.delete.state.request,
            deleteCommentRequest: state.board.comment.delete.state.request,
            editRequest: state.board.boardItem.edit.state.request,
            postCommentRequest: state.board.comment.post.state.request,

            postRequest: state.board.boardItem.post.state.request,
            postData: state.board.boardItem.post.dataSource,

            boardListLastPage: state.board.boardList.get.state.last,
            boardListRequest: state.board.boardList.get.state.request,
            boardListPage: state.board.boardList.get.state.page,
            noticeList: state.board.notice.get.dataSource,
            dataSource: state.board.boardList.get.dataSource,

            boardItemData: state.board.boardItem.get.dataSource,
            boardItemRequest: state.board.boardItem.get.state.request
        };
    };

    componentDidMount() {
        GA.logPage();
        this.props.actions.getBoardList();
        this.props.actions.getNoticeList();
    }

    onWriteClick = () => {
        if (!this.props.isLogin) {
            AppHistory.moveLoginPage();
            return;
        }
        AppHistory.move(Urls.BOARD_WRITE);
    };

    goView = (id) => {
        AppHistory.move(Urls.buildBoardItem(id));
    };

    onPageChange = (pageNum) => {
        this.props.actions.getBoardList(pageNum - 1);
    };

    renderBoardListItem(item, key, isNotice = false) {
        return (<li
            className="membox-post-item"
            key={key}
            onClick={() => {
                this.goView(item.id);
            }}>
            <div className={`membox-postitem-title ${isNotice ? 'notice' : ''}`}>{isNotice ? <strong className="notice-icon">공지</strong> : undefined} {item.title}</div>
            <div className="membox-postitem-context">
                <span className="name">{item.user.nickname}</span>
                <span className="replies"><i className="glyphicon glyphicon-comment"/></span>
                <span className="registered_date">{Strings.buildDateString(item.registeredDate)}</span>
            </div>
        </li>);
    }

    render() {
        return (<div>
            {this.props.boardListRequest === Consts.REQUEST_WAITING ? <Loader/> : undefined}
            <Navigator leftMenuType={LeftMenuTypes.HOME} title="게시판"/>
            <div ref="container" style={styles.container}>
                <div style={{backgroundColor: 'white', maxWidth: 1000, margin: '10px auto', padding: 10, borderRadius: 5}}>
                    <div className="membox-post-list">
                        <div className="table" style={styles.overflowAuto}>
                            <ul style={styles.listContainer}>
                                {
                                    this.props.noticeList ? this.props.noticeList.map((item, key) => {
                                        return this.renderBoardListItem(item, key, true);
                                    }) : undefined
                                }
                                {
                                    this.props.dataSource && this.props.dataSource.content ? this.props.dataSource.content.map((item, key) => {
                                        return this.renderBoardListItem(item, key);
                                    }) : undefined
                                }
                            </ul>
                        </div>
                        <button className="flat-button full" style={styles.button} onClick={this.onWriteClick}>글쓰기</button>
                        {
                            this.props.dataSource ?
                                <div className="text-align-center">
                                    <Pagination pageSize={1} current={this.props.boardListPage + 1} onChange={this.onPageChange} total={this.props.dataSource.totalPages} style={styles.pagenation} locale={Consts.PAGENATION_LOCALE}/>
                                </div> : undefined
                        }
                    </div>
                </div>
            </div>
        </div>);
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: 48,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        padding: 10
    },
    pagenation: {
        marginTop: 10,
        display: 'inline-block'
    }
};

export default connect(BoardPage.StateToProps, BoardPage.DispatchToProps)(BoardPage);
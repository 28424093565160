/**
 * Created by kimchangduk on 2017-10-07.
 */

import React from "react";
import Radium from "radium";
import * as Api from "../Api";
import { connect } from "react-redux";
import { Consts } from "../constants";
import PropTypes from "prop-types";
import DefaultDialogView from "./DefaultDialogView";
import get from "get-js";
import { getMessageFromResponse, toPriceFormat } from "../utils";
import DialogManager from "./DialogManager";
import EmailVerificationRequiredDialog from "./EmailVerificationRequiredDialog";

class BuyDialog extends React.Component {
  static propTypes = {
    onRequestClose: PropTypes.func,
    item: PropTypes.object,
    user: PropTypes.object,
  };

  static StateToProps = (state) => {
    return {
      user: state.user.dataSource,
    };
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedPayMethod: payMethods[0],
      buyerName: props.user.name,
      buyerEmail: props.user.email,
      buyerTel: "",
      escrow: false,
      waiting: true,
    };
  }

  componentDidMount() {
    get(["https://code.jquery.com/jquery-1.12.4.min.js", "https://service.iamport.kr/js/iamport.payment-1.1.5.js"])
      .then(function () {
        window.IMP.init("imp70451013");
      })
      .catch(function () {
        DialogManager.alert("결제 라이브러리를 초기화하는 데 실패했습니다.");
        if (this.props.onRequestClose) {
          this.props.onRequestClose();
        }
      });
  }

  requestPay = (merchantUid, price) => {
    window.IMP.request_pay(
      {
        pg: this.state.selectedPayMethod.pg,
        pay_method: this.state.selectedPayMethod.pay_method,
        merchant_uid: merchantUid,
        name: this.props.item.name.slice(0, 16),
        amount: price,
        escrow: this.state.escrow,
        buyer_email: this.state.buyerEmail,
        buyer_name: this.state.buyerName,
        buyer_tel: this.state.buyerTel,
        m_redirect_url: window.location.protocol + "//" + window.location.host + "/payments/complete",
        app_scheme: "membox",
      },
      (rsp) => {
        if (rsp.success) {
          const impUid = rsp.imp_uid;
          const merchantUid = rsp.merchant_uid;
          const status = rsp.status;
          // const applyNum = rsp.apply_num;
          DialogManager.pop();
          this.callPurchaseAfter(impUid, merchantUid, status, 3);
        } else {
          let msg = "<p>결제에 실패하였습니다.</p>";
          msg += `<p>에러내용 : ${rsp.error_msg}</p>`;
          DialogManager.alert(msg);
          this.setState({ waiting: false });
        }
      }
    );
  };

  callPurchaseAfter = (impUid, merchantUid, status, failCount) => {
    if (failCount <= 0) {
      this.setState({ waiting: false });
      DialogManager.alert("<p>결제에 성공했으나 서버로 결과 전송에 실패했습니다.</p>" + "<p>고객센터로 연락주시길 바랍니다.</p>" + "<p>Tel.02-335-0932</p>");
      return;
    }
    Api.purchaseAfter(impUid, merchantUid, status)
      .then((response) => {
        DialogManager.alert("결제에 성공했습니다.", {
          onRequestClose: () => {
            DialogManager.pop();
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        this.callPurchaseAfter(impUid, failCount - 1);
      });
  };

  onPayMethodChange = (e) => {
    const selectedName = e.target.value;
    const selectedItem = payMethods.find((item) => item.name === selectedName);
    this.setState({ selectedPayMethod: selectedItem });
  };

  onBuyerInfoChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onPayButtonClick = () => {
    if (!this.state.buyerTel) {
      DialogManager.alert("전화번호를 입력하셔야 합니다.");
    } else {
      this.setState({ waiting: true });
      Api.purchasePre(this.props.item.type, this.props.item.urlKey)
        .then((response) => {
          const merchantUid = response.data.uid;
          this.requestPay(merchantUid, response.data.price);
        })
        .catch((error) => {
          this.setState({ waiting: false });
          if (error.response && error.response.data && error.response.data.id === Consts.RESPONSE_ID_EMAIL_VERIFICATION_REQUIRED) {
            DialogManager.push(EmailVerificationRequiredDialog);
          } else {
            this.setState({ errorMessage: getMessageFromResponse(error.response, "결제 요청에 실패했습니다.") });
          }
        });
    }
  };

  onEscrowChange = (e) => {
    this.setState({ escrow: e.target.check });
  };

  onRequestClose = () => {
    if (this.state.waiting) {
      return;
    }
    this.props.onRequestClose();
  };

  renderPrice = () => {
    if (this.props.item.discountPrice !== null && this.props.item.discountPrice !== undefined) {
      return <span>{toPriceFormat(this.props.item.discountPrice)}</span>;
    }
    return <span>{toPriceFormat(this.props.item.price)}</span>;
  };

  render() {
    const item = this.props.item;

    return (
      <DefaultDialogView
        header="결제"
        showHeader={true}
        showCloseButton={true}
        buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
        footerContent={
          <button style={this.state.buyerTel ? styles.activePayButton : undefined} className="primary" onClick={this.onPayButtonClick}>
            결제하기
          </button>
        }
        content={
          <div>
            <div>
              <p style={styles.sectionHeader}>상품정보</p>
              <div>
                <img src={item.coverImage} style={styles.coverImage} />
                <p style={styles.item}>{this.props.item.name}</p>
                <p style={styles.item}>{this.renderPrice()}</p>
              </div>
            </div>
            <div>
              <p style={styles.sectionHeader}>주문정보</p>
              <div>
                <input
                  style={styles.fullWidth}
                  type="text"
                  value={this.state.buyerName}
                  placeholder="이름"
                  name="buyerName"
                  onChange={this.onBuyerInfoChange}
                />
                <input
                  style={styles.fullWidth}
                  type="text"
                  value={this.state.buyerEmail}
                  placeholder="이메일"
                  name="buyerEmail"
                  onChange={this.onBuyerInfoChange}
                />
                <input
                  style={styles.fullWidth}
                  type="text"
                  value={this.state.buyerTel}
                  placeholder="전화번호(필수)"
                  name="buyerTel"
                  onChange={this.onBuyerInfoChange}
                />
              </div>
            </div>
            <div>
              <p style={styles.sectionHeader}>결제수단</p>
              <div>
                <select style={styles.fullWidth} onChange={this.onPayMethodChange} value={this.state.selectedPayMethod.name}>
                  {payMethods.map((item, key) => {
                    return (
                      <option key={key} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <p style={styles.escrowP}>
                  <input type="checkbox" id="escrow" onChange={this.onEscrowChange} checked={this.state.escrow} />
                  <label htmlFor="escrow" className="font-size-13">
                    에스크로 결제
                  </label>
                </p>
              </div>
            </div>
          </div>
        }
        onRequestClose={this.props.onRequestClose}
      />
    );
  }
}

const styles = {
  coverImage: {
    width: 100,
    height: "auto",
    display: "block",
    margin: "auto",
    verticalAlign: "top",
  },
  sectionHeader: {
    fontWeight: "bold",
    margin: "10px 0 5px",
  },
  item: {
    display: "block",
    wordBreak: "break-all",
    margin: "3px 0",
    textAlign: "center",
  },
  activePayButton: {
    backgroundColor: "#2962FF",
  },
  fullWidth: {
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
  },
  escrowP: { fontSize: 13 },
};

const payMethods = [
  { pg: "html5_inicis", pay_method: "card", name: "신용카드" },
  { pg: "html5_inicis", pay_method: "trans", name: "실시간 계좌이체" },
  { pg: "html5_inicis", pay_method: "vbank", name: "가상계좌" },
  { pg: "html5_inicis", pay_method: "phone", name: "휴대폰 소액결제" },
  { pg: "html5_inicis", pay_method: "samsung", name: "삼성페이" },
  { pg: "html5_inicis", pay_method: "kpay", name: "KPay" },
  { pg: "html5_inicis", pay_method: "cultureland", name: "문화상품권" },
  { pg: "html5_inicis", pay_method: "smartculture", name: "스마트문상" },
  { pg: "html5_inicis", pay_method: "happymoney", name: "해피머니" },
  { pg: "kakao", pay_method: "card", name: "카카오페이" },
];

export default connect(BuyDialog.StateToProps)(Radium(BuyDialog));

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Footer from "../components/Footer";
import GA from '../GA';

class SplashPage extends React.Component {
    static propTypes = {
        message: PropTypes.string
    };

    componentDidMount() {
        GA.logPage();
    }

    render() {
        return (
            <div className="splash">
                <div className="membox-logo"/>
                <div className="splash-message" dangerouslySetInnerHTML={{__html: this.props.message}}/>
                <Footer/>
            </div>);
    }
}

export default SplashPage;
/**
 * Created by kimchangduk on 2017-04-13.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";
import GA from "../GA";

const initialState = {
  state: {
    loginRequest: Consts.REQUEST_NONE,
    loginErrorMessage: "",
    logoutRequest: Consts.REQUEST_NONE,
    getRequest: Consts.REQUEST_NONE,
    getErrorMessage: "",
    editRequest: "",
    editErrorMessage: "",
    isLogin: undefined, // nullable boolean
    isAdmin: false,
    shortTermMemoryLevel: Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL,
  },
  dataSource: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.LOGIN_WITH_FACEBOOK_REQUEST:
      return update(state, {
        state: { loginRequest: { $set: Consts.REQUEST_WAITING } },
      });

    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.LOGIN_WITH_FACEBOOK_SUCCESS: {
      const data = action.data;
      const isAdmin = data && data.auth && data.auth.includes(Consts.ROLE_ADMIN);
      const shortTermMemoryLevel =
        data && data.userData && data.userData.shortTermMemoryLevel !== undefined && data.userData.shortTermMemoryLevel !== null
          ? data.userData.shortTermMemoryLevel
          : Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL;
      GA.setUserId(data.id);
      return update(state, {
        state: {
          loginRequest: { $set: Consts.REQUEST_SUCCESS },
          loginErrorMessage: { $set: "" },
          isLogin: { $set: true },
          isAdmin: { $set: isAdmin },
          shortTermMemoryLevel: { $set: shortTermMemoryLevel },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.LOGIN_WITH_FACEBOOK_FAILURE:
      return update(state, {
        state: {
          loginRequest: { $set: Consts.REQUEST_FAILURE },
          loginErrorMessage: { $set: action.errorMessage },
        },
      });

    case ActionTypes.LOGOUT_REQUEST:
      return update(state, {
        state: { logoutRequest: { $set: Consts.REQUEST_WAITING } },
      });

    case ActionTypes.LOGOUT_SUCCESS:
      GA.setUserId(null);
      return update(state, {
        state: {
          logoutRequest: {
            $set: Consts.REQUEST_SUCCESS,
          },
          isLogin: { $set: false },
          isAdmin: { $set: false },
          shortTermMemoryLevel: { $set: Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL },
        },
        dataSource: { $set: null },
      });

    case ActionTypes.LOGOUT_FAILURE:
      return update(state, {
        state: { logoutRequest: { $set: Consts.REQUEST_FAILURE } },
      });

    case ActionTypes.GET_USER_REQUEST:
      return update(state, {
        state: {
          getRequest: { $set: Consts.REQUEST_WAITING },
        },
      });

    case ActionTypes.GET_USER_SUCCESS: {
      const data = action.data;
      const isAdmin = data && data.auth && data.auth.includes(Consts.ROLE_ADMIN);
      const shortTermMemoryLevel =
        data && data.userData && data.userData.shortTermMemoryLevel !== undefined && data.userData.shortTermMemoryLevel !== null
          ? data.userData.shortTermMemoryLevel
          : Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL;
      GA.setUserId(data.id);
      return update(state, {
        state: {
          getRequest: { $set: Consts.REQUEST_SUCCESS },
          isLogin: { $set: true },
          isAdmin: { $set: isAdmin },
          getErrorMessage: { $set: "" },
          shortTermMemoryLevel: { $set: shortTermMemoryLevel },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.GET_USER_FAILURE:
      return update(state, {
        state: {
          getRequest: { $set: Consts.REQUEST_FAILURE },
          isLogin: { $set: false },
          isAdmin: { $set: false },
          getErrorMessage: { $set: action.errorMessage },
          shortTermMemoryLevel: { $set: Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL },
        },
        dataSource: { $set: null },
      });

    case ActionTypes.EDIT_USER_REQUEST:
      return update(state, {
        state: {
          editRequest: { $set: Consts.REQUEST_WAITING },
        },
      });

    case ActionTypes.EDIT_USER_SUCCESS: {
      const data = action.data;
      return update(state, {
        state: {
          editRequest: { $set: Consts.REQUEST_SUCCESS },
          editErrorMessage: { $set: "" },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.EDIT_USER_FAILURE:
      return update(state, {
        state: {
          editRequest: { $set: Consts.REQUEST_FAILURE },
          editErrorMessage: { $set: action.errorMessage },
        },
      });

    case ActionTypes.SET_SHORT_TERM_MEMORY_LEVEL:
      return update(state, {
        state: {
          shortTermMemoryLevel: { $set: action.shortTermMemoryLevel },
        },
      });

    default:
      return state;
  }
}

import React, { useRef, useEffect } from "react";
import "./AdFitCard.scss";

export default function AdFitVertical() {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let scr = document.createElement("script");
    scr.setAttribute("async", "true");
    scr.setAttribute("type", "text/javascript");
    scr.setAttribute("src", "//t1.daumcdn.net/kas/static/ba.min.js");
    if (containerRef?.current) {
      containerRef?.current?.append(scr);
    }
  }, []);

  return (
    <div className={`adfit-vertical-container`} ref={containerRef}>
      <div className={`kakao-vertical`}>
        <ins
          className="kakao_ad_area"
          style={{ display: "none" }}
          data-ad-unit={(window as any)["ADFIT_MENU_UNIT"]}
          data-ad-width={720}
          data-ad-height={100}
        ></ins>
      </div>
    </div>
  );
}

/**
 * Created by kimchangduk on 2018-02-04.
 */

import React from 'react';
import PropTypes from 'prop-types';

class LessonUnit extends React.Component {
    static propTypes = {
        package: PropTypes.object,
        body: PropTypes.object
    };

    state = {
        folded: false
    };

    toggleFold = () => {
        this.setState({folded: !this.state.folded});
    };

    render() {
        const _package = this.props.package;
        return <div className="unit">
            <p className="name" onClick={this.toggleFold}>{_package.name} {
                this.state.folded ?
                    <i className="fa fa-chevron-down folder" aria-hidden="true"/> :
                    <i className="fa fa-chevron-up folder" aria-hidden="true"/>
            }</p>
            {!this.state.folded ? this.props.body : undefined}
        </div>;
    }
}

export default LessonUnit;
/**
 * Created by kimchangduk on 2017-05-02.
 */

import React from 'react';
import {Urls,Consts} from '../constants';
import AppHistory from '../history';
import * as Api from '../Api';
import {hasChangedRequestToSuccess, hasChangedRequestToFailure, parseQueryString} from '../utils';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loginWithToken, loginWithFacebook, logout} from '../actions/user';
import DialogManager from "../dialogs/DialogManager";
import Cookies from "js-cookie";

class TokenLogin extends React.Component {
    static propTypes = {
        request: PropTypes.string,
        errorMessage: PropTypes.string,
        history: PropTypes.object,
        location: PropTypes.object,
        actions: PropTypes.object
    };

    static StateToProps = (state, ownProps) => {
        return {
            request: state.user.state.loginRequest,
            errorMessage: state.user.state.loginErrorMessage
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                loginWithToken: () => {
                    const {token, email} = parseQueryString(ownProps.location.search);
                    dispatch(loginWithToken(email, token));
                },
                loginWithFacebook: () => {
                    const {token} = parseQueryString(ownProps.location.search);
                    dispatch(loginWithFacebook(token));
                }
            }
        };
    };

    componentDidMount() {
        this.process();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.process();
        }

        if (hasChangedRequestToSuccess(prevProps.request, this.props.request)) {
            const {fcm, os} = parseQueryString(this.props.location.search);
            if (fcm) {
                Api.registerFcmToken(fcm, os).then(response => {
                    this.redirect();
                }).catch(error => {
                    this.redirect();
                });
            } else {
                this.redirect();
            }
        } else if (hasChangedRequestToFailure(prevProps.request, this.props.request)) {
            this.redirect();
            DialogManager.alert('로그인에 실패했습니다.');
        }
    }

    process = () => {
        const {type, os} = parseQueryString(this.props.location.search);
        switch (type ? type.toLowerCase() : '') {
            case TYPE_TOKEN:
                this.props.actions.loginWithToken();
                break;
            case TYPE_FACEBOOK:
                this.props.actions.loginWithFacebook();
                break;
            case TYPE_NONE:
            default:
                AppHistory.replace(Urls.ROOT);
                break;
        }

		if(os) {
			Cookies.set(Consts.OS, os, { expires: 99999 });
		}
    };

    redirect() {
        const {redirect} = parseQueryString(this.props.location.search);
        if (redirect) {
            window.location.href = redirect;
        } else {
            AppHistory.replace(Urls.ROOT);
        }
    }

    render() {
        return (<div/>);
    }
}

const TYPE_NONE = 'none';
const TYPE_FACEBOOK = 'facebook';
const TYPE_TOKEN = 'token';

export default connect(TokenLogin.StateToProps, TokenLogin.DispatchToProps)(TokenLogin);
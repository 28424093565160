/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";

class AnswerNumber extends React.Component {
  static propTypes = {
    number: PropTypes.number,
    onClick: PropTypes.func,
    onRightClick: PropTypes.func,
    selected: PropTypes.bool,
    marked: PropTypes.bool,
    dummyMarked: PropTypes.bool,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propKeys = ["number", "selected", "marked", "dummyMarked"];
    for (let key of propKeys) {
      if (this.props[key] !== nextProps[key]) {
        return true;
      }
    }

    return false;
  }

  render() {
    return (
      <span
        style={[styles.container, this.props.marked ? styles.marked : false, this.props.dummyMarked ? styles.dummyMarked : false]}
        onClick={() => {
          if (this.props.onClick) this.props.onClick(this.props.number);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          if (this.props.onRightClick) this.props.onRightClick(e);
        }}
      >
        {!this.props.marked && !this.props.dummyMarked ? this.props.number : undefined}
      </span>
    );
  }
}

const styles = {
  container: {
    position: "relative",
    borderRadius: "50%",
    border: "1px solid #000000",
    width: 23,
    height: 23,
    textAlign: "center",
    lineHeight: "20px",
    marginRight: 3,
    fontWeight: "bold",
    display: "inline-block",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  marked: {
    backgroundColor: "#000000",
  },
  dummyMarked: {
    backgroundColor: "#0000FF",
  },
};

export default Radium(AnswerNumber);

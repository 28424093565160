/**
 * Created by kimchangduk on 2017-08-08.
 */

import React from 'react';
import PropTypes from 'prop-types';
import AppHistory from '../history';
import {Consts} from '../constants';
import DialogManager from '../dialogs/DialogManager';
import * as Api from '../Api';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import GA from '../GA';

// https://test.membox.co.kr/password/reset/37/M9GLlNe3BMdPWCGgYV9SjbjCjUoZJV10
class PasswordResetPage extends React.Component {
    static propTypes = {
        match: PropTypes.object
    };

    state = {
        request: Consts.REQUEST_NONE,
        errorMessage: '',
        newPassword: '',
        newPasswordConfirm: ''
    };

    resetPassword = () => {
        if (this.state.newPassword !== this.state.newPasswordConfirm) {
            this.setState({errorMessage: '비밀번호가 일치하지 않습니다.'});
            return;
        }

        if (this.state.request === Consts.REQUEST_WAITING) {
            return;
        }

        const userId = this.props.match.params.userId;
        const token = this.props.match.params.token;
        this.setState({request: Consts.REQUEST_WAITING});
        Api.resetPassword(userId, token, this.state.newPassword).then(response => {
            AppHistory.move('/');
            DialogManager.alert('비밀번호가 초기화되었습니다.');
        }).catch(error => {
            this.setState({request: Consts.REQUEST_FAILURE});
            if (error && error.response && error.response.data && error.response.data.message) {
                DialogManager.alert(error.response.data.message);
            } else {
                DialogManager.alert('비밀번호 초기화에 실패했습니다.');
            }
        });
    };

    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value, errorMessage: ''});
    };

    render() {
        return (<div>
            <Navigator leftMenuType={LeftMenuTypes.HOME} title="비밀번호 초기화"/>
            <div style={styles.container}>
                <div>
                    <input type="password" placeholder="새로운 비밀번호" style={styles.inputBox} name="newPassword" onChange={this.onInputChange}/>
                    <input type="password" placeholder="비밀번호 확인" style={styles.inputBox} name="newPasswordConfirm" onChange={this.onInputChange}/>
                    <div className="control-invalid-message">{this.state.errorMessage}</div>
                    <button className="plumb-button plumb-button-full plumb-button-success" onClick={this.resetPassword}>
                        <i className="fa fa-key fa-fw"/>
                        비밀번호 초기화
                    </button>
                </div>
            </div>
        </div>);
    }
}

const styles = {
    container: {
        position: 'absolute',
        width: 228,
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        padding: 16,
        borderRadius: 16,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 64px 30px -64px rgba(0, 0, 0, 0.5)'
    },
    inputBox: {width: '100%', margin: '3px 0'}
};

export default PasswordResetPage;
/**
 * Created by kimchangduk on 2017-07-25.
 */

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AppHistory from '../history';
import {Urls} from '../constants';
import BuyTag from '../assets/images/buyTag.png';
import CouponRegisterDialog from "../dialogs/CouponRegisterDialog";
import DialogManager from "../dialogs/DialogManager";
import Dotdotdot from 'react-dotdotdot';
import {toPriceFormat} from "../utils";

class CBTItem extends React.Component {
    static propTypes = {
        test: PropTypes.object,
        isLogin: PropTypes.bool,
        mobile: PropTypes.bool
    };

    static defaultProps = {
        mobile: false
    };

    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin
        };
    };

    onClick = () => {
        if (!this.props.test) return;

        if (this.props.test.has || this.props.test.free) {
            AppHistory.move(Urls.buildCBT(this.props.test.urlKey));
        } else if (!this.props.isLogin) {
            AppHistory.moveLoginPage();
            return;
        } else {
            DialogManager.push(CouponRegisterDialog, {message: `<b>${this.props.test.name}</b> 교재를 학습하려면 구매 도서 인증이 필요합니다.`});
        }
    };

    renderForMobile() {
        const spaceRemovedName = this.props.test.name ? this.props.test.name.replace(/\s+/g, " ") : '';

        return (<div className="deck-item-mobile" onClick={this.onClick}>
            <div className="cover">
                <div className="cover-wrapper"><img src={this.props.test.coverImage} style={styles.img}/></div>
            </div>
            {/* this.props.test.has ? <img src={BuyTag} style={styles.buyTag}/> : undefined */undefined}
            <div className="info" style={{padding: 5, textAlign: 'left', lineHeight: '1.5em'}}>
                <div className="name" style={styles.name}>
                    <Dotdotdot clamp={2}>
                        <p>{spaceRemovedName}</p>
                    </Dotdotdot>
                </div>
                <div style={{fontSize: 13, color: '#689f38', fontWeight: 'bold', textAlign: 'left'}}>
                    {this.renderPrice()}
                </div>
            </div>
        </div>);
    }

    renderForPC() {
        return (<div className="deck-item" onClick={this.onClick}>
            <div className="cover">
                <img src={this.props.test.coverImage} style={styles.img}/>
            </div>
            {/*this.props.test.has ? <img src={BuyTag} style={styles.buyTag}/> : undefined*/undefined}
            <div style={{padding: 5, textAlign: 'left', lineHeight: '1.5em'}}>
                <div style={styles.name}>
                    <Dotdotdot clamp={2}>
                        <p>{this.props.test.name}</p>
                    </Dotdotdot>
                </div>
                <div style={{fontSize: 13, color: '#689f38', fontWeight: 'bold', textAlign: 'right'}}>
                    {this.renderPrice()}
                </div>
            </div>
        </div>);
    }

    renderPrice = () => {
        if (this.props.test.has) {
            return <span>구입한 항목</span>;
        }

        if (this.props.test.salesLabel) {
            return <span>{this.props.test.salesLabel}</span>;
        }
        if (this.props.test.discountPrice !== null && this.props.test.discountPrice !== undefined) {
            return <span><span className="text-line-through">{toPriceFormat(this.props.test.price)}</span> <span>{toPriceFormat(this.props.test.discountPrice)}</span></span>;
        }
        return <span>{toPriceFormat(this.props.test.price)}</span>;
    };

    render() {
        if (this.props.mobile) {
            return this.renderForMobile();
        } else {
            return this.renderForPC();
        }
    }
}

const styles = {
    buyTag: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 48,
        height: 48
    },
    img: {width: '100%', height: '100%'},
    name: {
        wordBreak: 'break-all',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: '#212121',
        fontSize: 14
    }
};

export default connect(CBTItem.StateToProps, null)(CBTItem);
export const termsOfServic =
  "제1조 목적\n" +
  "이 이용약관 (이하 ‘약관’)은 길벗R&D(이하 ‘회사’)가 제공하는 온라인상의 인터넷서비스(이하 ‘서비스’)에 회원으로 가입하고 이를 이용함에 있어 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n" +
  "\n" +
  "제 2조 정의\n" +
  "이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n" +
  "① ‘서비스’라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 웹사이트를 통해 제공하는 회사의 관련 제반 서비스를 의미합니다.\n" +
  "② ‘회원’이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다.\n" +
  "③ ‘아이디(ID)’라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 가입 신청자의 소유 여부를 확인한 전자우편주소(E-mail)를 의미합니다.\n" +
  "④ ‘비밀번호’라 함은 회원이 부여 받은 아이디 또는 전자우편주소와 일치되는 회원임을 확인하고, 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 의미합니다.\n" +
  "⑤ ‘유료서비스’라 함은 회사가 유료로 제공하는 각종 온라인디지털콘텐츠(각종 정보콘텐츠, VOD, 아이템, 응용프로그램, 기타 유료콘텐츠를 포함) 및 제반 서비스를 의미합니다.\n" +
  "⑥ ‘포인트’라 함은 서비스의 효율적 이용을 위해 회사가 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치가 없는 서비스 상의 가상 데이터를 의미합니다.\n" +
  "⑦ ‘게시물’이라 함은 회원이 서비스를 이용함에 있어 서비스 상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.\n" +
  "\n" +
  "제 3조 약관의명시와개정\n" +
  "① 회사는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 이메일 주소 등) 등을 누구나 알 수 있도록 서비스 초기화면 또는 연결화면을 통하여 게시합니다.\n" +
  "② 회사는 약관의 규제에 관한 법령, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법령, 방문판매 등에 관한 법령, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n" +
  "③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.\n" +
  "④ 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.\n" +
  "⑤ 회원이 명시적인 의사표현을 통해 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사가 이용계약을 해지할 수 있습니다.\n" +
  "⑥ 회사는 유료 서비스 및 개별 서비스에 대해서는 별도의 이용약관 및 정책 (이하 유료 서비스 약관 등)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 유료 서비스 약관 등이 우선하여 적용됩니다.\n" +
  "⑦ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법령, 약관의규제등에관한법령, 공정거래위원회가 정하는 전자상거래등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다. 제 2장 회원의 가입 및 관리\n" +
  "\n" +
  "제 4조 회원가입계약 체결\n" +
  "① 회원이 되고자 하는자(이하 가입신청자)는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관과 개인정보취급방침에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.\n" +
  "② 회사는 제1항과 같이 가입신청자가 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 1) 가입신청자가 이 약관 제6조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만 제6조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원재가입 승낙을 얻은 경우에는 예외로 합니다.\n" +
  "2) 등록 내용에 허위, 기재누락, 오기가 있는 경우\n" +
  "3) 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우\n" +
  "③ 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.\n" +
  "\n" +
  "제 5조 회원정보의변경\n" +
  "① 회원은 개인정보관리 화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스관리를 위해 필요한 전자우편주소, 연령 및 성별 정보는 수정할 수 없습니다.\n" +
  "② 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편, 기타방법으로 회사에게 그 변경사항을 알려야합니다.\n" +
  "③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.\n" +
  "\n" +
  "제 6조 회원탈퇴및자격상실등\n" +
  "① 회원은 회사에 언제든지 자신의 회원 등록을 말소(탈퇴)를 요청할 수 있으며 회사는 위 요청을 받은 즉시 해당 이용자회원의 회원등록말소를 위한절차를 밟습니다.\n" +
  "② 회원이 서비스 이용 계약을 해지하고자 할 때는 개인정보 관리화면에서 탈퇴하거나, 본인 확인이 가능하도록 이름, 아이디 또는 전자우편주소, 연락 가능한 전화번호를 기재하여 전자우편으로 해지신청을 해야 합니다.\n" +
  "③ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.\n" +
  "1) 가입 신청 시에 허위 내용을 등록한 경우\n" +
  "2) 서비스 이용과 관련하여 회원이 부담하는 대금 등의 채무를 기일에 지급하지 않는 경우\n" +
  "3) 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우\n" +
  "4) 광고, 욕설, 비방, 제3자의 개인정보, 저작권 등 법령의 제한을 받는 내용 등을 포함한 글을 게시하는 경우\n" +
  "5) 일반적인 이용 목적을 벗어나서 조회 수 상승 등의 이익을 위해 서비스를 이용하는 경우\n" +
  "6) 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우\n" +
  "④ 회사가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 않으면 회사는 회원자격을 상실시킬 수 있습니다.\n" +
  "⑤ 회원이 2년 동안 로그인하지 않으면 회사는 회원자격을 상실시킬 수 있습니다.\n" +
  "⑥ 회사가 회원자격을 상실시키는 경우에는 회원 탈퇴 처리합니다. 이 경우 회원에게 이를 통지하고, 처리 전에 소명할 기회를 부여합니다.\n" +
  "⑦ 회원 탈퇴 처리가 되면 이벤트 당첨에 따른 경품 제공 등 탈퇴 이전에 발생한 모든 혜택 및 서비스 제공은 소멸 또는 중단됩니다.\n" +
  "⑧ 회사는 본 서비스를 이용하는 회원에 대하여 회원의 연령, 서비스 이용내용, 시간 및 횟수 등에 따라 등급별로 세분하여 서비스 이용에 차등을 둘 수 있습니다.\n" +
  "\n" +
  "제 7조 회원에대한통지\n" +
  "① 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 전자우편 주소 또는 휴대전화로 할 수 있습니다.\n" +
  "② 회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 웹사이트에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대해서는 개별통지 합니다.\n" +
  "\n" +
  "제 8조 회원 ID 등의 관리책임\n" +
  "① 회원은 서비스 이용을 위한 회원 ID, 비밀번호의 관리에 대한 책임, 본인 ID의 제3자에 의한 부정사용 등 회원의 고의·과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다. 단, 이것이 회사의 고의·과실로 인하여 야기된 경우는 회사가 책임을 부담합니다.\n" +
  "② 회원은 회원 ID, 비밀번호 및 추가정보 등을 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 본인의 비밀번호를 수정하는 등의 조치를 취하여야 하며 즉시 이를 회사에 통보하여 회사의 안내를 따라야 합니다.\n" +
  "\n" +
  "제 9조 개인정보의 수집\n" +
  "① 회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터 필요한 개인정보를 수집하며, 수집항목에 대해서는 개인정보취급방침에 명시합니다.\n" +
  "제 3장 서비스의 이용\n" +
  "\n" +
  "제 10조 서비스의 제공 및 변경\n" +
  "① 회사는 회원에게 아래와 같은 서비스를 제공합니다.\n" +
  "1) 도서 및 관련 정보의 제공\n" +
  "2) 각종 온라인디지털콘텐츠 (각종 정보콘텐츠, VOD, 아이템, 응용프로그램 등)의 유/무료 제공 및 제반 서비스\n" +
  "3) 기타 회사가 추가하거나 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스\n" +
  "② 회사는 제공서비스의 품질 또는 기술적 사양에 대한 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스의 내용을 변경할 수 있습니다. 다만, 지속적인 서비스 이용에 중대한 영향을 미치는 변경이 있을 경우에는 서비스의 내용 및 제공일자를 명시하여 그 제공일자이전 7일부터 공지합니다.\n" +
  "\n" +
  "제 11조 서비스의이용및중단\n" +
  "① 질문에 대한 답변 등 회사 또는 회사가 지정한 사람이 회원의 요청에 응해야 하는 경우를 제외한 모든 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시중단은 사전에 공지합니다.\n" +
  "② 회사는 긴급한 시스템점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로 서비스를 중단할 수 있으며, 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스를 완전히 중단 할 수 있습니다.\n" +
  "③ 회사는 국가비상사태, 정전, 서비스설비의 장애 또는 서비스이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.\n" +
  "\n" +
  "제 12조 회원의게시물\n" +
  "① 회원이 게시하는 게시물로 인해 발생하는 손실이나 문제는 전적으로 회원의 책임이며, 회사의 고의가 아닌 한, 회사는 이에 대하여 책임지지 않습니다.\n" +
  "② 회원의 게시물이 정보통신망법 및 저작권법등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취해야 합니다.\n" +
  "③ 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당게시물에 대해 임시 또는 영구 조치를 취할 수 있습니다.\n" +
  "\n" +
  "제 13조 게시물에대한저작권등\n" +
  "① 회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에 귀속합니다.\n" +
  "② 회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하는 행위는 허용되지 않습니다.\n" +
  "③ 회원이 등록한 게시물에 대한 저작권은 해당 저작권자에게 귀속합니다.\n" +
  "④ 회원은 자신이 창작, 등록한 게시물에 대하여 회사가 서비스를 운영, 전시, 전송배포 또는 홍보하기 위한 목적으로 다음의 각호에 행위를 할 수 있는, 세계적이고 사용료 없는 비독점적 사용권을 회사에게 부여합니다. 1) 회사에서 제공하는 관련 서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 배포, 출판 및 2차 저작물과 편집 저작물작성\n" +
  "2) 회사의 제휴파트너에게 회원의 게시물내용을 제공, 사용하게 하는 것. 단, 이 경우 회사는 회원의 별도 동의 없이 회원의아이디이외 개인정보를 제공하지 않습니다.\n" +
  "⑤ 회원은 본 조 제4항의 사용권 부여가 회사가 서비스를 운영하는 동안 확정적으로 유효하며, 회원의 탈퇴 후에도 유효함에 동의합니다.\n" +
  "⑥ 회사는 본조 제4항 이외에 방법으로 회원의 게시물을 상업적으로 이용하고자 하는 경우, 전화, 팩스, 전자우편 등의 방법을 통해 사전에 회원의 동의를 얻어야 합니다. 단, 회원정보에 등록된 연락처가 사실과 다르거나 회원이 회사의 연락에 응하지 않아 사전에 동의를 구하지 못한 경우, 회사는 사후에 동의 절차를 구할 수 있습니다. 회사가 본 항에 따라 회원의 게시물을 상업적으로 이용할 경우 회사는 별도의 보상 제도를 운영할 수 있습니다.회원은 언제든지 고객센터 또는 서비스 내 기능을 통해 해당게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 요청할 수 있습니다.\n" +
  "\n" +
  "제 14조 (삭제)\n" +
  "\n" +
  "제 15조 정보의제공\n" +
  "① 회사는 회원의 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.\n" +
  "② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를 받아서 전송합니다. 다만, 회원의 거래 및 학습 서비스 관련 정보 및 고객문의 등에 대한 회신에 대해서는 제외됩니다.\n" +
  "③ 회사는 서비스의 운영과 관련하여 서비스화면, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을 할 수 있습니다.\n" +
  "\n" +
  "제 16조 회사의의무와책임\n" +
  "① 회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다. ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다. ③ 회사는 서비스이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.\n" +
  "④ 회사는 회원들의 게시물을 사전심사하거나 상시적으로 게시물의 내용을 확인 및 검토하지 않으며, 이에 대한 의무나 그 결과에 대한 책임을 지지 않습니다. ⑤ 회사는 서비스 이용과 관련하여 회사의 고의 또는 중과실이 없는 한 회원에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다.\n" +
  "\n" +
  "제 17조 회원의의무\n" +
  "① 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타회사의 업무에 방해되는 행위를 하여서는 안 됩니다.\n" +
  "② 회원은 다음 행위를 하여서는 안 됩니다.\n" +
  "1) 신청 또는 변경 시 허위내용의 등록\n" +
  "2) 타인의 정보도용\n" +
  "3) 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해\n" +
  "4) 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위\n" +
  "5) 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위\n" +
  "6) 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위\n" +
  "7) 기타 불법적이거나 부당한 행위\n" +
  "\n" +
  "제 18조 면책조항\n" +
  "① 회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스제공에 대한책임이 면제됩니다.\n" +
  "② 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.\n" +
  "③ 회사는 회원의 귀책사유(회원의 컴퓨터오류에 의해 손해가 발생한 경우 또는 회원이 신상정보 및 연락처 부실 기재 등)로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.\n" +
  "④ 회사는 회사의 고의 또는 중과실이 없는 한, 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 손해를 입은 것에 대해서 책임을 지지 않습니다.\n" +
  "⑤ 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.\n" +
  "⑥ 회사는 회원이 서비스에 게재한 각종정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.\n" +
  "⑦ 회사는 회원 상호간 및 회원과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.\n" +
  "\n" +
  "제 19조 재판권및준거법\n" +
  "① 회사와 회원 간 제기된 소송은 대한민국 법을 준거법으로 합니다.\n" +
  "② 회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원에 제소합니다.\n" +
  "③ 본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에 명시되지 않은 부분에 대하여는 관습에 의합니다.\n" +
  "\n";

export const privacyPolicy =
  "길벗R&D는 이용자(회원 및 온라인서비스를 이용하는 비회원)의 개인정보를 중요시하며, “정보통신망이용촉진및정보보호등에관한법률”을 준수하고 있습니다.\n" +
  "\n" +
  "회사는 개인정보취급방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.\n" +
  "\n" +
  "1. 수집하는 개인정보 항목\n" +
  "(1) 회사는 회원가입, 원활한 고객 상담, 각종 서비스 등 기본적인 서비스 제공을 위한 필수정보와 고객 맞춤 서비스 제공을 위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고 있습니다.\n" +
  "1) 필수항목: 전자우편주소, 생년월일, 성별, 이름, 소식지 수신여부\n" +
  "(2) 서비스 이용과정에서 아래와 같은 정보들이 수집될 수 있습니다.\n" +
  "1) 공통: IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록\n" +
  "2) 유료 서비스 이용 시: 주민등록번호 및 성명, 신용카드 정보, 은행계좌 정보, 결제기록\n" +
  "\n" +
  "2. 개인정보 수집방법\n" +
  "(1) 회원가입\n" +
  "(2) 서비스 이용\n" +
  "(3) 이벤트 응모\n" +
  "(4) 생성정보 수집 틀을 통한 수집\n" +
  "(5) 회원정보수정\n" +
  "(6) 팩스 및 전화\n" +
  "(7) 기타 유사 방법\n" +
  "\n" +
  "3. 개인정보의 수집 및 이용목적\n" +
  "(1) 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.\n" +
  "1) 회원 관리: 회원제 서비스 이용에 따른 본인확인, 개인 식별, 가입 의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달\n" +
  "2) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산: 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 등 발송\n" +
  "3) 마케팅 및 광고에 활용: 이벤트 등 광고성 정보 전달, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계\n" +
  "\n" +
  "4. 개인정보의 보유 및 이용기간\n" +
  "(1) 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.\n" +
  "(2) 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.\n" +
  "1) 보존 항목: 로그인 아이디 또는 아이디를 대체하는 전자우편주소, 탈퇴일, 탈퇴를 요청한 기기에서 사용한 IP Address, 탈퇴 사유\n" +
  "2) 보존 근거: 중복 가입 금지, 탈퇴 관련 민원처리\n" +
  "3) 보존 기간: 사이트가 폐쇄될 때까지\n" +
  "(3) 관련 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 해당 법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.\n" +
  "1) 서비스 이용기록, 접속 로그, 접속 IP 정보: 3개월(통신비밀보호법)\n" +
  "2) 표시/광고에 관한 기록: 6개월(전자상거래등에서의소비자보호에관한법률)\n" +
  "3) 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래등에서의소비자보호에관한법률)\n" +
  "4) 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래등에서의소비자보호에관한법률)\n" +
  "5) 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래등에서의소비자보호에관한법률)\n" +
  "\n" +
  "5. 개인정보의 파기절차 및 방법\n" +
  "(1) 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.\n" +
  "(2) 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.\n" +
  "1) 파기절차 - 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정 기간 저장된 후 파기됩니다.\n" +
  "- 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.\n" +
  "2) 파기방법 - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.\n" +
  "- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.\n" +
  "\n" +
  "6. 개인정보 제공\n" +
  "(1) 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 회사가 집니다. 다만, 다음의 경우에는 예외로 합니다.\n" +
  "1) 배송업무를 위해 배송업체에게 필요한 최소한의 이용자 정보(성명, 주소, 전화번호)를 알려주는 경우\n" +
  "2) 재화 등의 거래에 따른 대금정산을 위하여 필요한 경우\n" +
  "3) 도용방지를 위하여 본인확인에 필요한 경우\n" +
  "4) 법령의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우\n" +
  "\n" +
  "7. 이용자 및 법정대리인의 권리와 그 행사방법\n" +
  "(1) 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며,가입 해지(탈퇴)를 요청할 수도 있습니다.\n" +
  "(2) 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보변경' 등)을, 가입해지를 위해서는 \"회원탈퇴\"를 클릭하여 본인 확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다.\n" +
  "(3) 위의 방법이 불가능할 경우, 개인정보관리책임자에게 서면, 전화 또는 전자우편으로 연락하면 회사는 특별한 사유가 없는 한 지체 없이 조치합니다.\n" +
  "(4) 이용자가 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.\n" +
  '(5) 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보를 "4. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.\n' +
  "\n" +
  "8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항\n" +
  "(1) 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.\n" +
  "1) 쿠키의 사용 목적: 회사의 웹사이트들에 대한 방문 및 이용형태, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.\n" +
  "2) 쿠키의 설치/운영 및 거부\n" +
  "- 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.\n" +
  "- 다만, 쿠키의 저장을 거부할 경우에는 일부 서비스 이용에 어려움이 있을 수 있습니다.\n" +
  "① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.\n" +
  "② [개인정보 탭]을 클릭합니다.\n" +
  "③ [개인정보취급 수준]을 설정하시면 됩니다.\n" +
  "\n" +
  "9. 개인정보에 관한 민원서비스\n" +
  "(1) 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.\n" +
  "개인정보관리책임자 성명 : 김용갑\n" +
  "전화번호 : 02-335-0932\n" +
  "이메일 : help@membox.co.kr\n" +
  "귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.\n" +
  "기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.\n" +
  "1.개인분쟁조정위원회 (www.1336.or.kr/1336)\n" +
  "2.정보보호마크인증위원회 (www.eprivacy.or.kr/02-580-0533~4)\n" +
  "3.대검찰청 인터넷범죄수사센터 (http://icic.sppo.go.kr/02-3480-3600)\n" +
  "4.경찰청 사이버테러대응센터 (www.ctrc.go.kr/02-392-0330)\n" +
  "\n" +
  "10. Google Adsense(구글 애드센스)로 웹 광고 서비스\n" +
  "이용자의 사이트 이용 통계 분석을 위한 구글 분석(Google Analytics)과 웹 사이트 광고 출력을 위해 구글 애드센스(Google Adsense)에 의해 이용될 수 있습니다.\n" +
  '본 웹사이트에서 광고와(텍스트 광고, 배너 등)를 실행하는 미국 Google, Inc. (“구글”)의 웹 광고 서비스인 Google Adsense는 귀하의 브라우저에 구글이나 제삼자가 전송한 쿠키를 저장합니다. 쿠키에 저장된 정보는 구글뿐만 아니라 제삼자까지도 기록, 수집 및 평가할 수 있습니다. 또한, Google Adsense는 정보를 수집하기 위해 사용자에게 보이지 않는 웹 비컨(Web Beacons)도 사용합니다. 이를 통해 본 웹사이트의 사용자 트래픽과 같은 정보를 기록, 수집 및 평가할 수 있습니다. 쿠키나 웹 비컨을 통해 생성된 귀하의 웹 사이트 이용 정보는 미국 구글 서버로 전송되고, 그곳에 저장됩니다. 구글은 이렇게 수집한 정보를 이용하여 애드센스 광고와 관련된 귀하의 이용 행태를 분석합니다. 구글은 법적으로 규정된 경우나 구글의 위임을 받아 제삼자가 이 정보를 처리하는 경우, 이를 제삼자에게도 전달합니다. 물론 귀하는 컴퓨터 하드에 쿠키가 저장되거나 웹 비컨이 표시되는 것을 막을 수 있습니다. 그러려면 브라우저 설정에서 "쿠키 허용 안 함"을 선택하면 됩니다.';

/**
 * Created by kimchangduk on 2017-08-11.
 */

import React from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Consts, Strings} from '../constants/index';
import DialogManager from "./DialogManager";
import * as Api from "../Api";
import {changeProfile} from '../actions/user';
import {hasChangedRequestToSuccess, hasChangedRequestToFailure, getMessageFromResponse} from '../utils';
import DefaultDialogView from "./DefaultDialogView";
import DeleteUserDialog from "./DeleteUserDialog";

class ProfileDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func,
        user: PropTypes.object,
        editRequest: PropTypes.string,
        editErrorMessage: PropTypes.string,
        actions: PropTypes.object
    };

    static StateToProps = (state) => {
        return {
            user: state.user.dataSource,
            editRequest: state.user.state.editRequest,
            editErrorMessage: state.user.state.editErrorMessage
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                changeProfile: (params) => {
                    dispatch(changeProfile(params));
                }
            }
        };
    };

    constructor(props) {
        super(props);
        if (!this.props.user) {
            return;
        }
        const user = this.props.user;
        this.state = {
            name: user.name,
            nickname: user.nickname,
            email: user.email,
            birthday: user.birthday,
            sex: user.sex,
            agreeMail: user.agreeMail,
            notify: user.notify,
            oldPassword: '',
            newPassword: '',
            passwordConfirm: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (hasChangedRequestToSuccess(this.props.editRequest, nextProps.editRequest)) {
            DialogManager.alert('변경사항이 적용되었습니다.');
        } else if (hasChangedRequestToFailure(this.props.editRequest, nextProps.editRequest)) {
            DialogManager.alert(nextProps.editErrorMessage);
        }
    }

    checkValid = () => {
        if (this.state.birthday) {
            const birthday = new Date(this.state.birthday).toFormat('YYYY-MM-DD');
            if (!/\d\d\d\d-\d\d-\d\d/.test(birthday)) {
                DialogManager.alert(Strings.INVALID_DATE_FORMAT);
                return false;
            }
        }
        return true;
    };

    onLeave = (e) => {
        const thisDom = ReactDOM.findDOMNode(this);
        if (e.target !== thisDom) {
            return;
        }

        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.checkValid()) {
            return;
        }

        const changedData = this.getChangedData();

        if (changedData) {
            this.props.actions.changeProfile(changedData);
        } else {
            DialogManager.alert('변경사항이 없습니다.');
        }
    };

    onChangePassword = () => {
        if (this.state.newPassword !== this.state.passwordConfirm) {
            DialogManager.alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if (!this.state.oldPassword) {
            DialogManager.alert('기존 비밀번호를 입력해주세요.');
            return;
        }

        if (!this.state.newPassword) {
            DialogManager.alert('변경하실 비밀번호를 입력해주세요.');
            return;
        }

        Api.changePassword(this.state.oldPassword, this.state.newPassword).then(response => {
            DialogManager.alert('비밀번호가 변경되었습니다.');
            this.setState({oldPassword: '', newPassword: '', passwordConfirm: ''});
        }).catch(error => {
            DialogManager.alert(getMessageFromResponse(error.response, '비밀번호 변경에 실패했습니다.'));
        });
    };

    onDeleteUser = () => {
        DialogManager.push(DeleteUserDialog);
    };

    //<editor-fold desc="onInputChange">
    onInputChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    };

    onAgreeMailChange = (e) => {
        this.setState({agreeMail: !this.state.agreeMail});
    };

    onNotifyChange = (e) => {
        this.setState({notify: !this.state.notify});
    };

    onSexChange = (e) => {
        this.setState({sex: e.target.value});
    };

    onBirthdayChange = (e) => {
        this.setState({birthday: e.target.value});
    };

    resendVerifyEmail = () => {
        if (!this.props.user) {
            return;
        }

        Api.resendVerifyEmail(this.state.email).then(response => {
            DialogManager.alert(`<strong>${this.props.user.email}</strong>로 이메일 전송에 성공했습니다.`);
        }).catch(error => {
            DialogManager.alert(`<strong>${this.props.user.email}</strong>로 이메일 전송에 실패했습니다.`);
        });
    };

    getChangedData = () => {
        const user = this.props.user;
        if (!user) {
            return;
        }

        let changed = false;
        let params = {};
        let birthday = null;
        if (this.state.birthday) {
            birthday = new Date(this.state.birthday).toFormat('YYYY-MM-DD');
        }

        if (user.birthday !== birthday) {
            params.birthday = birthday;
            changed = true;
        }

        for (let key of ['name', 'nickname', 'email', 'sex', 'agreeMail', 'notify']) {
            if (this.state[key] !== user[key]) {
                params[key] = this.state[key];
                changed = true;
            }
        }

        if (changed) {
            return params;
        }

        return null;
    };

    //</editor-fold>

    render() {
        return (
            <DefaultDialogView
                buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
                footerStyle={{display: 'none'}}
                showHeader={true}
                modal={true}
                onRequestClose={this.props.onRequestClose}
                showCloseButton={true}
                header="계정"
                content={
                    <div>
                        <form onSubmit={this.onSubmit}>
                            <div className="stack-panel stack-panel-vertical" style={{margin: "4px 0px"}}>
                                <div className="stack-panel stack-panel-horizontal">
                                    <input value={this.state.name} type="text" name="name" className="editbox-full" placeholder="이름" data-trigger="focus" data-placement="bottom" required disabled={true}/>
                                </div>
                                <div className="stack-panel stack-panel-horizontal">
                                    <input onChange={this.onInputChange} value={this.state.nickname} type="text" name="nickname" className="editbox-full" placeholder="닉네임" data-trigger="focus" data-placement="bottom" required/>
                                </div>
                                <div className="stack-panel stack-panel-horizontal">
                                    <input onChange={this.onInputChange} value={this.state.email} type="email" name="email" className="editbox-full" placeholder="이메일" data-trigger="focus" data-placement="bottom" required/>
                                </div>
                                {
                                    this.props.user && !this.props.user.emailVerify && this.state.email === this.props.user.email ?
                                        <button type="button" className="flat-button" onClick={this.resendVerifyEmail} style={styles.saveButton}>인증 메일 보내기</button> : undefined
                                }
                                <div className="stack-panel stack-panel-horizontal">
                                    <div className="stack-panel stack-panel-horizontal" style={{width: "40%", paddingRight: 3}}>
                                        <label className="header" htmlFor="sex" style={{width: 28}}><i className="fa fa-venus-mars fa-fw"/></label>
                                        <select id="sex" className="full" name="sex" title="성별" value={this.state.sex} onChange={this.onSexChange} required>
                                            <option value={Consts.SEX_MALE}>남자</option>
                                            <option value={Consts.SEX_FEMALE}>여자</option>
                                        </select>
                                    </div>
                                    <div className="stack-panel stack-panel-horizontal" style={{width: "60%", paddingLeft: 3}}>
                                        <label className="header" htmlFor="birthday" style={{width: 28}}><i className="fa fa-birthday-cake fa-fw" aria-hidden="true"/></label>
                                        <input type="date" className="full" id="birthday" name="birthday" min="1900-01-01" max="2016-01-01" value={this.state.birthday ? this.state.birthday : ''} onChange={this.onBirthdayChange}/>
                                    </div>
                                </div>
                                <div className="stack-panel stack-panel-horizontal">
                                    <input type="checkbox" onChange={this.onAgreeMailChange} checked={this.state.agreeMail} id="agree-mail" name="agree_mail"/><label htmlFor="agree-mail"><span>소식지를 메일로 받는데 동의합니다.</span></label>
                                </div>
                                <div className="stack-panel stack-panel-horizontal">
                                    <input type="checkbox" onChange={this.onNotifyChange} checked={this.state.notify} id="notify" name="notify"/><label htmlFor="notify"><span>알림 수신에 동의합니다.</span></label>
                                </div>
                            </div>
                            <div className="stack-panel-horizontal">
                                <button type="submit" className="flat-button" style={styles.saveButton}>변경사항 적용</button>
                            </div>
                        </form>
                        <div style={styles.marginTop10}>
                            <div className="stack-panel stack-panel-horizontal">
                                <input onChange={this.onInputChange} value={this.state.oldPassword} type="password" name="oldPassword" className="editbox-full" placeholder="기존 비밀번호" data-trigger="focus" data-placement="bottom" required/>
                            </div>
                            <div className="stack-panel stack-panel-horizontal">
                                <input onChange={this.onInputChange} value={this.state.newPassword} type="password" name="newPassword" className="editbox-full" placeholder="새로운 비밀번호" data-trigger="focus" data-placement="bottom" required/>
                            </div>
                            <div className="stack-panel stack-panel-horizontal">
                                <input onChange={this.onInputChange} value={this.state.passwordConfirm} type="password" name="passwordConfirm" className="editbox-full" placeholder="비밀번호 확인" data-trigger="focus" data-placement="bottom" required/>
                            </div>
                            <button className="flat-button" onClick={this.onChangePassword} style={styles.saveButton}>비밀번호 변경</button>
                            <button className="flat-button" onClick={this.onDeleteUser} style={styles.deleteButton}>회원탈퇴</button>
                        </div>
                    </div>
                }
            />
        );
    }
}

const styles = {
    saveButton: {
        width: '100%'
    },
    header: {
        fontSize: 20,
        margin: 10
    },
    panel: {
        borderRadius: 10,
        background: '#FFFFFF'
    },
    marginTop10: {
        marginTop: 10
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 5,
        display: 'inline-block',
        textAlign: 'center',
        width: 48,
        height: 48,
        lineHeight: '48px',
        fontSize: 24,
        cursor: 'pointer'
    },
    deleteButton: {
        backgroundColor: '#F44336',
        width: '100%'
    }
};

export default connect(ProfileDialog.StateToProps, ProfileDialog.DispatchToProps)(ProfileDialog);
/**
 * Created by kimchangduk on 2017-08-27.
 */

import React from 'react';
import {Consts} from '../constants';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getCategory} from '../actions/category';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import Loader from "../components/Loader";
import DeckItem from "../components/DeckItem";
import CBTItem from "../components/CBTItem";
import GA from '../GA';
import MembershipItem from "../components/MembershipItem";
import { isMobileDevice } from "../utils";

class CategoryPage extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        match: PropTypes.object,
        request: PropTypes.string,
        errorMessage: PropTypes.string,
        dataSource: PropTypes.object
    };

    static StateToProps = (state, ownProps) => {
        const categoryStateId = state.category.category.state.id;
        const id = parseInt(ownProps.match.params.id);
        return {
            request: id === categoryStateId ? state.category.category.state.request : undefined,
            errorMessage: id === categoryStateId ? state.category.category.state.errorMessage : undefined,
            dataSource: id === categoryStateId ? state.category.category.dataSource : undefined
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getCategory: () => {
                    dispatch(getCategory(parseInt(ownProps.match.params.id)));
                }
            }
        };
    };

    state = {
        mobileMode: isMobileDevice()
    };

    componentDidMount() {
        GA.logPage();
        this.props.actions.getCategory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.actions.getCategory();
        }
    }

    /**
     * 현재 안씀 (구입여부 상관없이 그냥 보이게)
     * @param a
     * @param b
     * @returns {number}
     */
    categorySorter(a, b) {
        if (a.has && b.has && a.buyDate && b.buyDate && a.buyDate !== b.buyDate) {
            return b.buyDate - a.buyDate;
        }

        if (b.has !== a.has) {
            return b.has - a.has;
        }

        return b.id - a.id;
    }

    renderForMobile() {
        const dataSource = this.props.dataSource;
        return (<div>
            {this.props.request === Consts.REQUEST_WAITING ? <Loader/> : undefined}
            <Navigator leftMenuType={LeftMenuTypes.HOME} title={dataSource ? dataSource.name : ''}/>
            <div className="deck-list" style={{overflow: 'auto', bottom: 0}}>
                <div style={styles.mobile.list}>
                    <div>
                        {
                            this.props.dataSource && this.props.dataSource.membershipList ? this.props.dataSource.membershipList.map((item, key) => {
                                return (<MembershipItem key={key} data={item} mobile={this.state.mobileMode}/>);
                            }) : undefined
                        }
                        {
                            this.props.dataSource ? this.props.dataSource.deckList.map((item, key) => {
                                return (<DeckItem key={key} deck={item} mobile={this.state.mobileMode}/>);
                            }) : undefined
                        }
                    </div>
                    <div style={styles.marginTop10}>
                        {
                            this.props.dataSource ? this.props.dataSource.testList.map((item, key) => {
                                return (<CBTItem key={key} test={item} mobile={this.state.mobileMode}/>);
                            }) : undefined
                        }
                    </div>
                </div>
            </div>
        </div>);
    }

    renderForPC() {
        const dataSource = this.props.dataSource;
        return (<div>
            {this.props.request === Consts.REQUEST_WAITING ? <Loader/> : undefined}
            <Navigator leftMenuType={LeftMenuTypes.HOME} title={dataSource ? dataSource.name : ''}/>
            <div className="deck-list" style={{overflow: 'auto'}}>
                <div>
                    {
                        this.props.dataSource ? this.props.dataSource.membershipList.map((item, key) => {
                            return (<MembershipItem key={key} data={item}/>);
                        }) : undefined
                    }
                </div>
                <div>
                    {
                        this.props.dataSource ? this.props.dataSource.deckList.map((item, key) => {
                            return (<DeckItem key={key} deck={item}/>);
                        }) : undefined
                    }
                </div>
                <div>
                    {
                        this.props.dataSource ? this.props.dataSource.testList.map((item, key) => {
                            return (<CBTItem key={key} test={item}/>);
                        }) : undefined
                    }
                </div>

            </div>
        </div>);
    }

    render() {
        if (this.state.mobileMode) {
            return this.renderForMobile();
        } else {
            return this.renderForPC();
        }
    }
}

const styles = {
    mobile: {
        list: {
            margin: '10px 10px',
            backgroundColor: '#FFFFFF',
            borderRadius: 5
        }
    },
    marginTop10: {
        marginTop: 10
    }
};

export default connect(CategoryPage.StateToProps, CategoryPage.DispatchToProps)(CategoryPage);
/**
 * Created by kimchangduk on 2017-07-25.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { Strings } from "../constants";

import HourOn from "../assets/icons/hour_on.png";
import HourOff from "../assets/icons/hour_off.png";
import DayOn from "../assets/icons/day_on.png";
import DayOff from "../assets/icons/day_off.png";
import WeekOn from "../assets/icons/week_on.png";
import WeekOff from "../assets/icons/week_off.png";
import MonthOn from "../assets/icons/month_on.png";
import MonthOff from "../assets/icons/month_off.png";
import LongTermOn from "../assets/icons/long_term_on.png";
import LongTermOff from "../assets/icons/long_term_off.png";

class MemboxTabItem extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    dataSource: PropTypes.object,
    index: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: "",
  };

  render() {
    let caption = Strings.getMemboxLevelCaption(this.props.index);
    const membox = this.props.dataSource;
    const activeCount = membox && membox.READY ? membox.READY : 0;
    const waitCount = membox && membox.WAIT ? membox.WAIT : 0;
    const count = activeCount + waitCount;

    let iconImage = "";
    switch (this.props.index) {
      case "1":
        iconImage = count <= 0 ? HourOff : HourOn;
        break;
      case "2":
        iconImage = count <= 0 ? DayOff : DayOn;
        break;
      case "3":
        iconImage = count <= 0 ? WeekOff : WeekOn;
        break;
      case "4":
        iconImage = count <= 0 ? MonthOff : MonthOn;
        break;
      case "5":
        iconImage = count <= 0 ? LongTermOff : LongTermOn;
        break;
    }

    return (
      <div style={[styles.container, this.props.style]} onClick={this.props.onClick}>
        <img src={iconImage} style={styles.icon} />
        <div style={[styles.count, styles.activeCount, activeCount === 0 ? styles.displayNone : null]}>{activeCount}</div>
        <div style={[styles.count, activeCount > 0 || waitCount == 0 ? styles.displayNone : null]}>{waitCount}</div>
        <div style={styles.caption}>{caption}</div>
      </div>
    );
  }
}

const styles = {
  container: {
    width: "20%",
    top: 6,
    maxWidth: 70,
    height: 60,
    display: "inline-block",
    position: "relative",
    color: "#FFFFFF",
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    width: 23,
    height: 23,
    position: "absolute",
    top: 5,
    left: "50%",
    transform: "translateX(-50%)",
  },
  count: {
    position: "absolute",
    display: "inline-block",
    top: 0,
    right: 0,
    padding: "3px 6px",
    borderRadius: 9,
    fontSize: 12,
    lineHeight: 1,
    backgroundColor: "#333",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  },
  activeCount: {
    backgroundColor: "#ff4c4c",
  },
  displayNone: {
    display: "none",
  },
  caption: {
    display: "block",
    position: "absolute",
    bottom: 8,
    left: 0,
    right: 0,
    fontSize: 13,
    lineHeight: 1,
  },
};

export default Radium(MemboxTabItem);

/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import DialogManager from "./DialogManager";
import UnmarkedQuestions from "../components/CBT/UnmarkedQuestions";
import DefaultDialogView from "./DefaultDialogView";

class UnmarkedQuestionListDialog extends React.Component {
  static propTypes = {
    onRequestClose: PropTypes.func,
    onRequestNaviation: PropTypes.func,
    answers: PropTypes.array,
    questions: PropTypes.array,
  };

  onNavigationClick = (selectedIndex) => {
    const question = this.props.questions[selectedIndex];
    if (!question) {
      DialogManager.alert("이동할 문항을 선택해 주세요.");
    } else {
      if (this.props.onRequestNaviation) this.props.onRequestNaviation(question.id);
      if (this.props.onRequestClose) this.props.onRequestClose();
    }
  };

  render() {
    const answers = this.props.answers;
    const unmarkedQuestionIndexes = [];
    for (let key in answers) {
      if (answers[key] === null) {
        unmarkedQuestionIndexes.push(parseInt(key));
      }
    }

    return (
      <DefaultDialogView
        content={
          <div>
            <UnmarkedQuestions questionIndexes={unmarkedQuestionIndexes} onNavigationClick={this.onNavigationClick} />
          </div>
        }
        panelStyle={{ textAlign: "center" }}
        onRequestClose={this.props.onRequestClose}
      />
    );
  }
}

const borderColor = "rgba(0,0,0,0.2)";

const styles = {
  hr: {
    border: "none",
    margin: "15px 0",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
};

export default Radium(UnmarkedQuestionListDialog);

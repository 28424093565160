/**
 * Created by kimchangduk on 2017-08-29.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

class FontScaleSelector extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    scale: PropTypes.number,
    onChangeScale: PropTypes.func,
  };

  onScaleClick = (scale) => {
    if (this.props.onChangeScale && scale !== this.props.scale) {
      this.props.onChangeScale(scale);
    }
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]}>
        <button
          className={`plumb-button ${this.props.scale === 1 ? "plumb-button-success" : "plumb-button-default"}`}
          onClick={() => {
            this.onScaleClick(1);
          }}
        >
          100%
        </button>
        <button
          className={`plumb-button ${this.props.scale === 1.5 ? "plumb-button-success" : "plumb-button-default"}`}
          onClick={() => {
            this.onScaleClick(1.5);
          }}
        >
          150%
        </button>
        <button
          className={`plumb-button ${this.props.scale === 2 ? "plumb-button-success" : "plumb-button-default"}`}
          onClick={() => {
            this.onScaleClick(2);
          }}
        >
          200%
        </button>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "inline-block",
  },
  button: {},
  selectedButton: {},
};

export default Radium(FontScaleSelector);

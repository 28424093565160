/**
 * Created by kimch on 2017-08-31.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  finish: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      urlKey: null,
    },
    dataSource: null,
  },
  get: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      id: null,
    },
    dataSource: null,
  },
};

export default function cbtReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FINISH_CBT_REQUEST:
      return update(state, {
        finish: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            urlKey: { $set: action.urlKey },
          },
        },
      });

    case ActionTypes.FINISH_CBT_SUCCESS: {
      if (state.finish.state.urlKey !== action.urlKey) {
        return state;
      }

      const data = action.data;
      return update(state, {
        finish: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.FINISH_CBT_FAILURE:
      if (state.finish.state.urlKey !== action.urlKey) {
        return state;
      }
      return update(state, {
        finish: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });

    case ActionTypes.GET_CBT_PAPER_REQUEST:
      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
            id: { $set: action.id },
          },
        },
      });

    case ActionTypes.GET_CBT_PAPER_SUCCESS: {
      if (state.get.state.id !== action.id) {
        return state;
      }

      const data = action.data;
      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.GET_CBT_PAPER_FAILURE:
      if (state.get.state.id !== action.id) {
        return state;
      }
      return update(state, {
        get: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });

    default:
      return state;
  }
}

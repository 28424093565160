/**
 * Created by kimchangduk on 2017-07-23.
 */
import { Consts } from "./constants";
import Cookies from "js-cookie";
import * as Api from "./Api";
import DialogManager from "./dialogs/DialogManager";
import UnsupportedFcmDialog from "./dialogs/UnsupportedFcmDialog";
import update from "immutability-helper";
import queryString from "querystring";
import { store } from "./store";
import { setShortTermMemoryLevel } from "./actions/user";

let _isIosApp = false;
let firebase = null;
if (!isIE()) {
  firebase = require("firebase");
}

export const hasChangedRequestToSuccess = (before, after) => {
  if (typeof before === "object") {
    before = before.request;
  }
  if (typeof after === "object") {
    after = after.request;
  }
  return before === Consts.REQUEST_WAITING && after === Consts.REQUEST_SUCCESS;
};

export const hasChangedRequestToFailure = (before, after) => {
  if (typeof before === "object") {
    before = before.request;
  }
  if (typeof after === "object") {
    after = after.request;
  }
  return before === Consts.REQUEST_WAITING && after === Consts.REQUEST_FAILURE;
};

export function getMessageFromResponse(response, defaultMessage) {
  if (response && response.data && response.data.message !== undefined) {
    return response.data.message;
  }
  return defaultMessage;
}

export function validateEmail(email) {
  if (/[^@]+@[^@]+\.[^@]+/.test(email)) {
    return true;
  }
  return false;
}

export function parseQueryString(str) {
  if (str.charAt(0) === "?") {
    str = str.substring(1);
  }
  return queryString.parse(str);
}

export function requestFcmToken(withCheckPermission = true) {
  if (window.android) {
    return;
  } else if (_isIosApp) {
    callObjectiveCMethod("onCheckNotificationPermission");
  } else {
    if (firebase) {
      const messaging = firebase.messaging();
      const processGetToken = () => {
        messaging
          .getToken()
          .then((currentToken) => {
            deleteFcmToken();
            if (currentToken) registerFcmToken(currentToken);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
      };

      if (withCheckPermission) {
        if (isOpera() || isFirefox() || isChrome()) {
          // 알림 허용을 안한 상태이면 알림 안내 문구 팝업
          if (window.Notification && window.Notification.permission === "default") {
            DialogManager.alert("학습 알림을 위하여 알림 표시 권한을 허용으로 설정해주세요.");
            messaging.requestPermission().then(() => {
              processGetToken();
            });
          } else {
            processGetToken();
          }
        } else {
          DialogManager.push(UnsupportedFcmDialog);
        }
      } else {
        processGetToken();
      }
    } else {
      DialogManager.push(UnsupportedFcmDialog);
    }
  }
}

export function registerFcmToken(token) {
  if (Cookies.get(Consts.COOKIE_KEY_FCM_TOKEN) === token) {
    return;
  }
  Api.registerFcmToken(token).then((response) => {
    Cookies.set(Consts.COOKIE_KEY_FCM_TOKEN, token, { expires: 99999 });
  });
}

export function deleteFcmToken() {
  const fcmToken = Cookies.get(Consts.COOKIE_KEY_FCM_TOKEN);
  if (fcmToken) {
    Cookies.remove(Consts.COOKIE_KEY_FCM_TOKEN);
    Api.deleteFcmToken(fcmToken);
  }
}

export function arraysEqual(a, b) {
  if (!a || !b) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function combineStrings(strArr, seperator = undefined, maxConcatCount = undefined) {
  const loopCount = maxConcatCount !== undefined ? Math.min(strArr.length, maxConcatCount) : 0;
  let result = "";
  for (let i = 0; i < loopCount; i++) {
    if (seperator && i > 0) {
      result += seperator;
    }
    result += strArr[i];
  }
  return result;
}

export function shuffleArray(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
}

export function getShortTermMemoryLevel() {
  const result = store.getState().user.state.shortTermMemoryLevel;

  if (result === undefined || result === null) {
    return Consts.DEFAULT_SHORT_TERM_MEMORY_LEVEL;
  }
  return result;
}

export function increaseShortTermMemoryLevel() {
  const shortTermMemoryLevel = getShortTermMemoryLevel();
  if (shortTermMemoryLevel >= 50) {
    return;
  }
  setShortTermMemoryLevel(shortTermMemoryLevel + 1)(store.dispatch);
}

export function decreaseShortTermMemoryLevel() {
  const shortTermMemoryLevel = getShortTermMemoryLevel();
  if (shortTermMemoryLevel <= 10) {
    return;
  }
  setShortTermMemoryLevel(shortTermMemoryLevel - 1)(store.dispatch);
}

//<editor-fold desc="Check browser">

// Opera 8.0+
export function isOpera() {
  return (!!window.opr && !!window.opr.addons) || window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
}

// Firefox 1.0+
export function isFirefox() {
  return typeof window.InstallTrigger !== "undefined";
}

// Safari 3.0+ "[object HTMLElementConstructor]"
export function isSafari() {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || (typeof window.safari !== "undefined" && window.safari.pushNotification))
  );
}

// Internet Explorer 6-11
export function isIE() {
  return /*@cc_on!@*/ false || !!document.documentMode;
}

// Edge 20+
export function isEdge() {
  return !isIE() && !!window.StyleMedia;
}

// Chrome 1+
export function isChrome() {
  return !!window.chrome && !isEdge();
}

// Blink engine detection
export function isBlink() {
  return (isChrome() || isOpera()) && !!window.CSS;
}

//</editor-fold>

export function isMobileDevice() {
  const mobileKeyWords = ["Android", "iPhone", "iPod", "BlackBerry", "Windows CE", "SAMSUNG", "LG", "MOT", "SonyEricsson"];
  for (let info in mobileKeyWords) {
    if (navigator.userAgent.match(mobileKeyWords[info]) !== null) {
      return true;
    }
  }
  return false;
}

export function scrollIntoView(element, parent = undefined) {
  if (parent === undefined) {
    parent = element.parentElement;
  }

  // element가 상단에 보일때 스크롤탑
  const topFixedScrollTop = element.offsetTop - 5;
  const bottomFixedScrollTop = topFixedScrollTop - parent.clientHeight + element.clientHeight + 5 + 2;
  if (parent.scrollTop > topFixedScrollTop) {
    parent.scrollTop = topFixedScrollTop;
  } else if (parent.scrollTop < bottomFixedScrollTop) {
    parent.scrollTop = bottomFixedScrollTop;
  }
}

export function escapeMemboxCharacters(str) {
  if (str) {
    // 키보드 키
    if (/\[key:(.+?)\]/g.test(str)) {
      str = str.replace(/\[key:WINDOWS_LOGO\]/g, '<span class="keyboard-key"><i class="fa fa-windows" aria-hidden="true"></i></span>');
      str = str.replace(/\[key:(\\\]|[^\]]+?),(\\\]|[^\]]+?)\]/g, '<span class="keyboard-2char-key"><span>$1</span><span>$2</span></span>');
      str = str.replace(/\[key:(\\\]|[^\]]+?)\]/g, '<span class="keyboard-key">$1</span>');
    }

    if (/\\\\/g.test(str)) {
      str = str.replace("\\");
    }

    if (/\\</g.test(str)) {
      str = str.replace(/\\</g, "&lt;");
    }

    if (/\\>/g.test(str)) {
      str = str.replace(/\\>/g, "&gt;");
    }

    if (/<br\/>/g.test(str)) {
      str = str.replace(/<br\/>/g, "<br>");
    }

    str = str.replace(/\[youtubeLink:(.+?)\]/g, '<span class="youtubeLink" data="$1">영상보기</span>');

    const superscriptRegex = /\[superscript:([^[]+?)\]/g;
    const subscriptRegex = /\[subscript:([^[]+?)\]/g;
    while (superscriptRegex.test(str)) {
      str = str.replace(superscriptRegex, '<span class="superscript">$1</span>');
    }
    while (subscriptRegex.test(str)) {
      str = str.replace(subscriptRegex, '<span class="subscript">$1</span>');
    }
  }
  return str;
}

export function getFontScaledStyle(style, fontScale) {
  if (fontScale === 1 || style.fontSize === undefined) {
    return style;
  }

  return update(style, { fontSize: { $set: fontScale * style.fontSize } });
}

export function toDateFormat(date, format = "YYYY-MM-DD HH24:MI") {
  return date.toFormat(format);
}

export function toPriceFormat(price) {
  if (price === null || price === undefined) {
    return "쿠폰 전용";
  }

  if (price === 0) {
    return "무료";
  }

  return "₩" + numberWithCommas(price);
}

export function payMethodToKorean(pgProvider, payMethod) {
  if (pgProvider == "kakao") {
    return "카카오페이";
  }
  switch (payMethod) {
    case "card":
      return "신용카드";
    case "trans":
      return "실시간 계좌이체";
    case "vbank":
      return "가상계좌";
    case "phone":
      return "휴대폰 소액결제";
    case "samsung":
      return "삼성페이";
    case "kpay":
      return "KPay";
    case "cultureland":
      return "문화상품권";
    case "smartculture":
      return "스마트문상";
    case "happymoney":
      return "해피머니";
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function callLogoutInterface() {
  if (window.android && window.android.onLogout) {
    window.android.onLogout();
  } else if (_isIosApp) {
    callObjectiveCMethod("onLogout");
  }
}

function callObjectiveCMethod(method) {
  window.location = `notihub://${method}`;
}

window.ios = {
  checkIn: () => {
    _isIosApp = true;
  },
};

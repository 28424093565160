/**
 * Created by kimchangduk on 2017-07-27.
 */

import * as Api from '../Api';
import {processHttpError} from './utils';
import {ActionTypes, Consts, Strings} from '../constants';
import {getMessageFromResponse} from "../utils";

export function getDeckLessonCards(deckUrlKey, lessonId, shuffle = false) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_DECK_LESSON_CARDS_REQUEST, deckUrlKey, lessonId});
        Api.getDeckLessonCards(deckUrlKey, lessonId).then(response => {
            dispatch({type: ActionTypes.GET_DECK_LESSON_CARDS_SUCCESS, deckUrlKey, lessonId, data: response.data, shuffle});
        }).catch(error => {
            processHttpError(dispatch, error);
            let buyRequired = false;
            if (error.response && error.response.data) {
                buyRequired = error.response.data.id === Consts.RESPONSE_ID_BUY_REQUIRED;
            }
            dispatch({
                type: ActionTypes.GET_DECK_LESSON_CARDS_FAILURE,
                deckUrlKey,
                lessonId,
                errorMessage: getMessageFromResponse(error.response, Strings.FAILED_TO_LOAD_DATA),
                urlKey: deckUrlKey,
                buyRequired
            });
        });
    };
}
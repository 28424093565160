/**
 * Created by kimchangduk on 2017-07-24.
 */

import React from 'react';

class ResponseWaitingLayer extends React.Component {
    render() {
        return (<div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, backgroundColor: 'rgba(0, 0, 0,0.5)'}}/>);
    }
}

export default ResponseWaitingLayer;
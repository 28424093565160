/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Consts, Urls } from "../constants";
import Navigator, { LeftMenuTypes } from "../components/Navigator";
import Loader from "../components/Loader";
import { getCBTPaper, getCBT, getCBTSolution } from "../actions/cbt";
import { isMobileDevice } from "../utils";
import CBTSolutionsForPC from "../components/CBT/CBTSolutionsForPC";
import CBTSolutionsForMobile from "../components/CBT/CBTSolutionsForMobile";
import GA from "../GA";

class CBTResultSolution extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,

    resultId: PropTypes.number,
    resultRequest: PropTypes.string,
    resultErrorMessage: PropTypes.string,
    resultDataSource: PropTypes.object,

    cbtUrlKey: PropTypes.string,
    cbtRequest: PropTypes.string,
    cbtErrorMessage: PropTypes.string,
    cbtDataSource: PropTypes.object,

    cbtSolutionRequest: PropTypes.string,
    cbtSolutionErrorMessage: PropTypes.string,
    cbtSolutionDataSource: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {
      resultId: state.cbtPaper.get.state.id,
      resultRequest: state.cbtPaper.get.state.request,
      resultErrorMessage: state.cbtPaper.get.state.errorMessage,
      resultDataSource: state.cbtPaper.get.dataSource,
      cbtUrlKey: state.cbt.state.urlKey,
      cbtRequest: state.cbt.state.request,
      cbtErrorMessage: state.cbt.state.errorMessage,
      cbtDataSource: state.cbt.dataSource,
      cbtSolutionRequest: state.cbtSolution.state.request,
      cbtSolutionErrorMessage: state.cbtSolution.state.errorMessage,
      cbtSolutionDataSource: state.cbtSolution.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    const urlKey = ownProps.match.params.urlKey;
    const resultId = parseInt(ownProps.match.params.resultId);
    return {
      actions: {
        getCBTPaper: () => {
          dispatch(getCBTPaper(resultId));
        },
        getCBT: () => {
          dispatch(getCBT(urlKey));
        },
        getCBTSolution: () => {
          dispatch(getCBTSolution(urlKey));
        },
      },
    };
  };

  componentDidMount() {
    GA.logPage();
    const cbtUrlKey = parseInt(this.props.match.params.cbtUrlKey);
    const resultId = parseInt(this.props.match.params.resultId);
    if (
      cbtUrlKey !== this.props.cbtUrlKey ||
      resultId !== this.props.resultId ||
      this.props.cbtRequest !== Consts.REQUEST_SUCCESS ||
      this.props.resultRequest !== Consts.REQUEST_SUCCESS
    ) {
      this.props.actions.getCBTPaper();
      this.props.actions.getCBT();
    }
    this.props.actions.getCBTSolution();
  }

  renderQuestions = () => {
    if (
      this.props.resultRequest !== Consts.REQUEST_SUCCESS ||
      this.props.cbtRequest !== Consts.REQUEST_SUCCESS ||
      this.props.cbtSolutionRequest !== Consts.REQUEST_SUCCESS ||
      !this.props.resultDataSource ||
      !this.props.cbtDataSource ||
      !this.props.cbtSolutionDataSource
    ) {
      return undefined;
    }
    return isMobileDevice() ? (
      <CBTSolutionsForMobile
        resultDataSource={this.props.resultDataSource}
        cbtDataSource={this.props.cbtDataSource}
        cbtSolutionDataSource={this.props.cbtSolutionDataSource}
      />
    ) : (
      <CBTSolutionsForPC
        resultDataSource={this.props.resultDataSource}
        cbtDataSource={this.props.cbtDataSource}
        cbtSolutionDataSource={this.props.cbtSolutionDataSource}
        scrollParentElement={this.refs["container"]}
      />
    );
  };

  render() {
    return (
      <div>
        <Navigator
          title="시험 결과"
          leftMenuType={LeftMenuTypes.BACK}
          leftMenuUrl={Urls.buildCBTResult(this.props.match.params.urlKey, this.props.match.params.resultId)}
        />
        <div style={styles.container} ref="container">
          {this.props.resultRequest === Consts.REQUEST_WAITING || this.props.cbtRequest === Consts.REQUEST_WAITING ? <Loader /> : undefined}
          {this.renderQuestions()}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    overflowY: "auto",
    padding: "0 10px",
  },
};

export default connect(CBTResultSolution.StateToProps, CBTResultSolution.DispatchToProps)(Radium(CBTResultSolution));

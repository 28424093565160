/**
 * Created by kimchangduk on 2017-09-12.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Strings, Consts, Urls} from '../constants';
import {getBoardList, postBoardItem, getBoardItem, postBoardItemComment, editBoardItem, deleteBoardItem, deleteBoardItemComment, getBoardNoticeList, setBoardNoticeList, deleteBoardNoticeList} from '../actions/board';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import {hasChangedRequestToFailure, hasChangedRequestToSuccess} from "../utils";
import AppHistory from '../history';
import DialogManager from "../dialogs/DialogManager";
import GA from '../GA';

class BoardItem extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        actions: PropTypes.object,
        dataSource: PropTypes.object,
        onRequestClose: PropTypes.func,

        user: PropTypes.object,
        isLogin: PropTypes.bool,

        deleteBoardItemRequest: PropTypes.string,
        deleteCommentRequest: PropTypes.string,
        editRequest: PropTypes.string,
        postCommentRequest: PropTypes.string,

        postRequest: PropTypes.string,
        postData: PropTypes.object,

        setNoticeRequest: PropTypes.string,
        deleteNoticeRequest: PropTypes.string,
        setNoticeErrorMessage: PropTypes.string,
        deleteNoticeErrorMessage: PropTypes.string,

        boardListLastPage: PropTypes.bool,
        boardListRequest: PropTypes.string,
        boardListPage: PropTypes.number,

        boardItemData: PropTypes.object,
        boardItemRequest: PropTypes.string,

        noticeList: PropTypes.array
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                getNoticeList: () => {
                    dispatch(getBoardNoticeList());
                },
                getBoardList: (page = 0) => {
                    dispatch(getBoardList(page));
                },
                postBoardItem: (title, content) => {
                    dispatch(postBoardItem(title, content));
                },
                getBoardItem: (id) => {
                    dispatch(getBoardItem(id));
                },
                postBoardItemComment: (id, content) => {
                    dispatch(postBoardItemComment(id, content));
                },
                editBoardItem: (id, title, content) => {
                    dispatch(editBoardItem(id, title, content));
                },
                deleteBoardItem: (id) => {
                    dispatch(deleteBoardItem(id));
                },
                deleteBoardItemComment: (id, commentId) => {
                    dispatch(deleteBoardItemComment(id, commentId));
                },
                setNotice: (id) => {
                    dispatch(setBoardNoticeList(id));
                },
                deleteNotice: (id) => {
                    dispatch(deleteBoardNoticeList(id));
                }
            }
        };
    };

    static StateToProps = (state) => {
        return {
            user: state.user,
            isLogin: state.user.state.isLogin,

            deleteBoardItemRequest: state.board.boardItem.delete.state.request,
            deleteCommentRequest: state.board.comment.delete.state.request,
            editRequest: state.board.boardItem.edit.state.request,
            postCommentRequest: state.board.comment.post.state.request,
            postCommentErrorMessage: state.board.comment.post.state.request,

            postRequest: state.board.boardItem.post.state.request,
            postData: state.board.boardItem.post.dataSource,

            boardListLastPage: state.board.boardList.get.state.last,
            boardListRequest: state.board.boardList.get.state.request,
            boardListPage: state.board.boardList.get.state.page,
            dataSource: state.board.boardList.get.dataSource,

            noticeList: state.board.notice.get.dataSource,
            setNoticeRequest: state.board.notice.post.state.request,
            deleteNoticeRequest: state.board.notice.delete.state.request,
            setNoticeErrorMessage: state.board.notice.post.state.errorMessage,
            deleteNoticeErrorMessage: state.board.notice.delete.state.errorMessage,

            boardItemData: state.board.boardItem.get.dataSource,
            boardItemRequest: state.board.boardItem.get.state.request
        };
    };

    state = {
        commentContent: ''
    };

    componentDidMount() {
        const id = this.getBoardItemId();
        this.props.actions.getBoardItem(id);
        this.props.actions.getNoticeList();
        GA.logPage();
    }

    componentWillReceiveProps(nextProps) {
        if (hasChangedRequestToSuccess(this.props.deleteCommentRequest, nextProps.deleteCommentRequest)) {
            this.props.actions.getBoardItem(this.getBoardItemId());
        }

        if (hasChangedRequestToSuccess(this.props.postCommentRequest, nextProps.postCommentRequest)) {
            this.props.actions.getBoardItem(this.getBoardItemId());
        }

        if (hasChangedRequestToSuccess(this.props.deleteCommentRequest, nextProps.deleteCommentRequest)) {
            this.props.actions.getBoardItem(this.getBoardItemId());
        }

        if (hasChangedRequestToSuccess(this.props.deleteBoardItemRequest, nextProps.deleteBoardItemRequest)) {
            AppHistory.replace(Urls.BOARD);
        }

        if (hasChangedRequestToFailure(this.props.setNoticeRequest, nextProps.setNoticeRequest)) {
            DialogManager.alert(nextProps.setNoticeErrorMessage);
        }

        if (hasChangedRequestToFailure(this.props.deleteNoticeRequest, nextProps.deleteNoticeRequest)) {
            DialogManager.alert(nextProps.deleteNoticeErrorMessage);
        }
    }

    getBoardItemId = () => {
        return parseInt(this.props.match.params.id);
    };

    onEditClick = () => {
        AppHistory.move(Urls.buildBoardItemEdit(this.props.match.params.id));
    };

    //<editor-fold desc="Post comment">
    onCommentContentChange = (e) => {
        this.setState({commentContent: e.target.value});
    };

    onSubmitComment = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        if (this.props.postCommentRequest !== Consts.REQUEST_WAITING) {
            this.props.actions.postBoardItemComment(this.getBoardItemId(), this.state.commentContent);
        }
    };
    //</editor-fold>

    //<editor-fold desc="삭제관련">
    onDeleteBoardItem = () => {
        DialogManager.confirm(Strings.CONFIRM_DELETE_REALLY, () => {
            this.props.actions.deleteBoardItem(this.getBoardItemId());
        });
    };

    onDeleteCommentItem = (id) => {
        DialogManager.confirm(Strings.CONFIRM_DELETE_REALLY, () => {
            this.props.actions.deleteBoardItemComment(this.getBoardItemId(), id);
        });
    };

    //</editor-fold>

    isManager = () => {
        const user = this.props.user.dataSource;
        return user && user.authorities && (
            user.authorities.includes(Consts.Authorities.ROLE_SUPERUSER) ||
            user.authorities.includes(Consts.Authorities.ROLE_MANAGER));
    };

    isNotice = () => {
        return this.props.boardItemData && this.props.noticeList && this.props.noticeList.findIndex(a => a.id === this.props.boardItemData.id) >= 0;
    };

    onToggleNoticeClick = () => {
        if (this.isNotice()) {
            this.props.actions.deleteNotice(this.getBoardItemId());
        } else {
            this.props.actions.setNotice(this.getBoardItemId());
        }
    };

    render() {
        const user = this.props.user.dataSource;
        const viewItem = this.props.boardItemData;
        const editable = user && (
            viewItem && user.id === viewItem.user.id ||
            (
                user.authorities && (
                    user.authorities.includes(Consts.Authorities.ROLE_SUPERUSER) ||
                    user.authorities.includes(Consts.Authorities.ROLE_MANAGER)
                )
            )
        );

        return (<div>
            <Navigator leftMenuType={LeftMenuTypes.BACK} title="게시글" leftMenuUrl={Urls.BOARD}/>
            <div className="membox-board-contents">
                <div className="membox-post" style={styles.overflowAuto}>
                    {
                        this.props.boardItemRequest === Consts.REQUEST_SUCCESS && viewItem ?
                            <div className="wrapper" style={styles.listContainer}>
                                <div className="membox-post-header">
                                    <div className="title">{viewItem.title}</div>
                                    <div className="context">
                                        <span className="name">{this.props.boardItemData.user.nickname}</span>
                                        <span className="registered_date">{Strings.buildDateString(this.props.boardItemData.registeredDate)}</span>
                                    </div>
                                </div>
                                <div className="membox-post-content">
                                    <div className="content">
                                        <pre>{this.props.boardItemData.content}</pre>
                                    </div>
                                    {
                                        editable ?
                                            (<div className="flat-button-group button-length-2" style={styles.editButtonGroup}>
                                                <div className="flat-button" id="edit" onClick={this.onEditClick}>수정</div>
                                                <div className="flat-button" id="delete" onClick={this.onDeleteBoardItem}>삭제</div>
                                                <hr/>
                                            </div>) : undefined
                                    }
                                    {
                                        this.isManager() ?
                                            (<div className="flat-button" style={styles.editButtonGroup} onClick={this.onToggleNoticeClick}>{this.isNotice() ? '공지 해제' : '공지 설정'}</div>) : undefined
                                    }
                                    <div className="comment">
                                        <div className="comment-write">
                                            <form className="comment-form" onSubmit={this.onSubmitComment}>
                                                <div className="input-area">
                                                    <textarea name="content" placeholder="내용" value={this.state.commentContent} onChange={this.onCommentContentChange}/>
                                                </div>
                                                {
                                                    this.props.isLogin ?
                                                        <div className="comment-write-button">
                                                            <button className="flat-button full" id="reply" onClick={this.onSubmitComment} style={styles.commentWriteButton}>댓글 쓰기</button>
                                                        </div> : undefined
                                                }
                                            </form>
                                        </div>
                                        <ul className="comments">
                                            {
                                                this.props.boardItemData.comments ? this.props.boardItemData.comments.map((item, key) => {
                                                    const commentEditable = user && (
                                                        user.id === item.user.id ||
                                                        (
                                                            user.authorities &&
                                                            (user.authorities.includes(Consts.Authorities.ROLE_MANAGER) || user.authorities.includes(Consts.Authorities.ROLE_SUPERUSER))
                                                        )
                                                    );
                                                    return (<li className="comment-item" key={key}>
                                                        <div className="comment-content">
                                                            <pre>{item.content}</pre>
                                                        </div>
                                                        <div className="comment-context">
                                                            <span className="name">{item.user.nickname}</span>
                                                            {
                                                                commentEditable ?
                                                                    (<span className="remove" onClick={() => {
                                                                        this.onDeleteCommentItem(item.id);
                                                                    }}>삭제</span>) : undefined
                                                            }
                                                            <span className="date">{Strings.buildDateString(item.registeredDate)}</span>
                                                        </div>
                                                    </li>);
                                                }) : undefined
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div> : undefined
                    }
                </div>
            </div>
        </div>);
    }
}

const styles = {
    listContainer: {transitionTimingFunction: "cubic-bezier(0.1, 0.57, 0.1, 1)", transitionDuration: "0ms", transform: "translate(0px, 0px) translateZ(0px)"},
    overflowAuto: {overflow: 'auto'},
    commentWriteButton: {
        margin: 0
    },
    editButtonGroup: {
        margin: '0 6px'
    }
};

export default connect(BoardItem.StateToProps, BoardItem.DispatchToProps)(BoardItem);
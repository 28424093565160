/**
 * Created by kimchangduk on 2017-08-28.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DialogManager from '../dialogs/DialogManager';
import {isMobileDevice} from '../utils';
import YoutubeDialog from "../dialogs/YoutubeDialog";

class MemboxDiv extends React.Component {
    static propTypes = {
        dangerouslySetInnerHTML: PropTypes.object,
        style: PropTypes.object
    };

    static defaultProps = {
        style: {whiteSpace: 'pre-wrap'}
    };

    componentDidUpdate(prevProps) {
        if (prevProps.dangerouslySetInnerHTML !== this.props.dangerouslySetInnerHTML) {
            let youtubeLinks = ReactDOM.findDOMNode(this);
            youtubeLinks = youtubeLinks.getElementsByClassName('youtubeLink');
            for (let i = 0; i < youtubeLinks.length; i++) {
                const youtubeLink = youtubeLinks[i];
                youtubeLink.addEventListener('click', this.onYoutubeLinkClick);
                youtubeLink.addEventListener('mousedown', this.cancelEvent);
                youtubeLink.addEventListener('touchstart', this.cancelEvent);
            }
        }
    }

    cancelEvent = (e) => {
        e.stopPropagation();
    };

    onYoutubeLinkClick(e) {
        e.stopPropagation();
        DialogManager.push(YoutubeDialog, {hash: e.target.getAttribute('data')});
    }

    render() {
        return (<div {...this.props}/>);
    }
}

export default MemboxDiv;
/**
 * Created by kimchangduk on 2017-09-16.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';

class LessonItem extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        lessonItem: PropTypes.object,
        leftCount: PropTypes.number
    };

    render() {
        const lessonItem = this.props.lessonItem;
        const totalCount = lessonItem.cards.length;
        const leftCount = this.props.leftCount;
        let lessonItemClass;
        if (leftCount === totalCount) {
            lessonItemClass = DOM_CLASS_LESSON_ITEM_ICON_FULL;
        } else if (leftCount > (totalCount / 2)) {
            lessonItemClass = DOM_CLASS_LESSON_ITEM_ICON_OPEN;
        } else if (leftCount > 0) {
            lessonItemClass = DOM_CLASS_LESSON_ITEM_ICON_HALF;
        } else {
            lessonItemClass = DOM_CLASS_LESSON_ITEM_ICON_EMPTY;
        }

        let colorClass = '';
        let name = lessonItem.name;
        if (name.charAt(0) === "$") {
            const colorIndex = parseInt(name.charAt(1));
            if (!isNaN(colorIndex)) {
                colorClass = DOM_CLASS_LESSON_ITEM_ICON_COLOR[colorIndex];
            }
            name = name.substr(2);
        }

        return (<div className="lesson-item" onClick={this.props.onClick}>
            <div className={`lesson-item-icon ${lessonItemClass} ${colorClass}`}>
                <span className="lesson-item-state text-no-select">
                    {
                        leftCount === totalCount ?
                            `${totalCount}` :
                            `${leftCount}/${totalCount}`
                    }
                </span>
            </div>
            <Dotdotdot clamp={2}>
                <p style={styles.name}>{name}</p>
            </Dotdotdot>
        </div>);
    }
}

const DOM_CLASS_LESSON_ITEM_ICON_FULL = "full";
const DOM_CLASS_LESSON_ITEM_ICON_OPEN = "open";
const DOM_CLASS_LESSON_ITEM_ICON_HALF = "half";
const DOM_CLASS_LESSON_ITEM_ICON_EMPTY = "empty";
const DOM_CLASS_LESSON_ITEM_ICON_COLOR = ["red", "green", "skyblue", "purple", "yellow"];

const styles = {
    name: {
        padding: '0 5px',
        textAlign: 'center'
    }
};

export default LessonItem;
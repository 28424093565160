/**
 * Created by kimchangduk on 2017-08-07.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as Api from '../Api';
import DialogManager from '../dialogs/DialogManager';
import {validateEmail} from '../utils';

class ForgotPasswordDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func
    };

    state = {
        email: '',
        invalidMessage: ''
    };

    onLeave = (e) => {
        const thisDom = ReactDOM.findDOMNode(this);
        if (e.target !== thisDom) {
            return;
        }

        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    onSubmit = () => {
        if (!validateEmail(this.state.email)) {
            this.setState({invalidMessage: '이메일 형식이 아닙니다.'});
            return;
        } else {
            this.setState({invalidMessage: ''});
        }
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
        Api.forgotPassword(this.state.email).then(response => {
            DialogManager.alert('비밀번호 찾기 요청을 완료했습니다. 이메일을 확인 해 주십시오.');
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                DialogManager.alert(error.response.data.message);
            } else {
                DialogManager.alert('에러가 발생했습니다.');
            }
        });
    };

    onEmailChange = (e) => {
        this.setState({email: e.target.value, invalidMessage: ''});
    };

    render() {
        return (<div className="popup-view forgot-password-view popup" onClick={this.onLeave}>
            <div className="popup-panel default-panel">
                <div className="popup-header text-align-center">비밀번호 찾기</div>
                <div className="popup-content">
                    <p>가입한 이메일 계정을 입력하면 비밀번호를 재설정할 수 있는 이메일이 발송됩니다.</p>
                    <div className="control-panel">
                        <input type="text" className="bg editbox-full" name="email" placeholder="이메일" value={this.state.email} onChange={this.onEmailChange}/>
                        <div className="control-invalid-message">{this.state.invalidMessage}</div>
                    </div>
                </div>
                <div className="popup-footer button-length-2">
                    <button id="btnRequest" onClick={this.onSubmit}>요청</button>
                    <button id="btnCancel" onClick={this.props.onRequestClose}>취소</button>
                    <hr/>
                </div>
            </div>
        </div>);
    }
}

export default ForgotPasswordDialog;
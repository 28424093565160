/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  all: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  recent: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  popular: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
  my: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
};

function getUpdateKey(type) {
  switch (type) {
    case ActionTypes.GET_DECK_LIST_REQUEST:
    case ActionTypes.GET_DECK_LIST_SUCCESS:
    case ActionTypes.GET_DECK_LIST_FAILURE:
      return "all";

    case ActionTypes.GET_POPULAR_DECK_LIST_REQUEST:
    case ActionTypes.GET_POPULAR_DECK_LIST_SUCCESS:
    case ActionTypes.GET_POPULAR_DECK_LIST_FAILURE:
      return "popular";

    case ActionTypes.GET_RECENT_DECK_LIST_REQUEST:
    case ActionTypes.GET_RECENT_DECK_LIST_SUCCESS:
    case ActionTypes.GET_RECENT_DECK_LIST_FAILURE:
      return "recent";

    case ActionTypes.GET_MY_DECK_LIST_REQUEST:
    case ActionTypes.GET_MY_DECK_LIST_SUCCESS:
    case ActionTypes.GET_MY_DECK_LIST_FAILURE:
      return "my";
  }
}

export default function deckListReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DECK_LIST_REQUEST:
    case ActionTypes.GET_MY_DECK_LIST_REQUEST:
    case ActionTypes.GET_POPULAR_DECK_LIST_REQUEST:
    case ActionTypes.GET_RECENT_DECK_LIST_REQUEST: {
      const key = getUpdateKey(action.type);
      return update(state, {
        [key]: {
          state: { request: { $set: Consts.REQUEST_WAITING } },
        },
      });
    }

    case ActionTypes.GET_DECK_LIST_SUCCESS:
    case ActionTypes.GET_MY_DECK_LIST_SUCCESS:
    case ActionTypes.GET_POPULAR_DECK_LIST_SUCCESS:
    case ActionTypes.GET_RECENT_DECK_LIST_SUCCESS: {
      const key = getUpdateKey(action.type);
      const data = action.data;
      return update(state, {
        [key]: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: data },
        },
      });
    }

    case ActionTypes.GET_DECK_LIST_FAILURE:
    case ActionTypes.GET_MY_DECK_LIST_FAILURE:
    case ActionTypes.GET_POPULAR_DECK_LIST_FAILURE:
    case ActionTypes.GET_RECENT_DECK_LIST_FAILURE: {
      const key = getUpdateKey(action.type);
      return update(state, {
        [key]: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    }

    default:
      return state;
  }
}

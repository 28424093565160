/**
 * Created by kimchangduk on 2017-08-28.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialogView from "./DefaultDialogView";
import {isMobileDevice} from '../utils';

class YoutubeDialog extends React.Component {
    static propTypes = {
        hash: PropTypes.string,
        onRequestClose: PropTypes.func
    };

    render() {
        return (<DefaultDialogView
            onRequestClose={this.props.onRequestClose}
            panelStyle={styles.panel}
            content={
                <div>
                    <iframe width={isMobileDevice() ? 280 : 500} height={isMobileDevice() ? 230 : 360} src={`https://www.youtube.com/embed/${this.props.hash}?ecver=1`} frameBorder={0} allowFullScreen/>
                </div>
            }
        />);
    }
}

const styles = {
    panel: {
        textAlign: 'center',
        width: 'auto'
    }
};

export default YoutubeDialog;
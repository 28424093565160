/**
 * Created by kimchangduk on 2017-07-27.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";
import { shuffleArray } from "../utils";

const initialState = {
  //
  // total: 전체 기억상자
  //     - info: 상자의 갯수 정보
  //     - level: 레벨별 카드 id들
  // deck: deck별 기억상자
  //     - info: 상자의 갯수 정보
  //     - cards: 상자의 갯수 정보
  //     - level: 레벨별 카드 id들
  // memorize: 기억상자 담기
  // resetMembox: reset
  // card 정보들

  total: {
    info: {
      state: {
        request: Consts.REQUEST_NONE,
        errorMessage: "",
      },
      dataSource: null,
    },
    level: {
      state: {
        request: Consts.REQUEST_NONE,
        errorMessage: "",
        startCardId: -1,
        totalCount: -1,
        level: -1,
        last: false,
        currentPageLastCardId: null,
      },
      dataSource: null,
    },
  },
  deck: {
    info: {
      state: {
        request: Consts.REQUEST_NONE,
        urlKey: "",
        errorMessage: "",
      },
      dataSource: null,
    },
    cardIds: {
      state: {
        request: Consts.REQUEST_NONE,
        urlKey: "",
        errorMessage: "",
      },
      dataSource: null,
    },
    level: {
      state: {
        request: Consts.REQUEST_NONE,
        level: -1,
        urlKey: "",
        errorMessage: "",
      },
      dataSource: null,
    },
  },
  memorize: {
    state: {
      request: Consts.REQUEST_NONE,
      level: -1,
      cardId: "",
    },
  },
  resetMembox: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
  },
  cards: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      requestedIds: [],
    },
    dataSource: [],
  },
};

export default function memboxReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Get total membox info">
    case ActionTypes.GET_TOTAL_MEMBOX_INFO_REQUEST:
      return update(state, {
        total: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
            },
          },
        },
      });
    case ActionTypes.GET_TOTAL_MEMBOX_INFO_SUCCESS:
      return update(state, {
        total: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              errorMessage: { $set: "" },
            },
            dataSource: { $set: action.data },
          },
        },
      });
    case ActionTypes.GET_TOTAL_MEMBOX_INFO_FAILURE:
      return update(state, {
        total: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
            dataSource: { $set: null },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get total membox level cards">
    case ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_REQUEST: {
      const updateValue = {
        total: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
              level: { $set: action.level },
              startCardId: { $set: action.startCardId },
            },
          },
        },
      };

      return update(state, updateValue);
    }
    case ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_SUCCESS: {
      if (action.startCardId !== state.total.level.state.startCardId || action.level !== state.total.level.state.level) {
        return state;
      }
      const data = action.data;
      const updateValue = {
        total: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              errorMessage: { $set: "" },
              last: { $set: data.last },
              totalCount: { $set: data.totalElements },
            },
          },
        },
      };

      const content = data.content;
      updateValue.total.level.state.currentPageLastCardId = {
        $set:
          content && content[action.level] && content[action.level] && content[action.level][action.stateType]
            ? content[action.level][action.stateType][content[action.level][action.stateType].length - 1]
            : null,
      };
      if (action.shuffle && content && content[action.level] && content[action.level][action.stateType]) {
        shuffleArray(content[action.level][action.stateType]);
      }

      if (action.append) {
        //<editor-fold desc="뒤에 id가 push된 새로운 데이터 생성">
        let newDataSource = update(state.total.level.dataSource, {});
        if (!newDataSource) newDataSource = {};
        if (content) {
          const memorizeStates = [Consts.MEMORIZE_STATE_WAIT, Consts.MEMORIZE_STATE_READY];

          for (let newLevel of Object.keys(content)) {
            if (!newDataSource[newLevel]) newDataSource[newLevel] = {};
            for (let memorizeState of memorizeStates) {
              if (!content[newLevel][memorizeState] || content[newLevel][memorizeState].length <= 0) {
                continue;
              }
              if (!newDataSource[newLevel][memorizeState]) newDataSource[newLevel][memorizeState] = [];
              newDataSource[newLevel][memorizeState] = newDataSource[newLevel][memorizeState].concat(content[newLevel][memorizeState]);
            }
          }
        }
        //</editor-fold>

        updateValue.total.level.dataSource = { $set: newDataSource };
      } else {
        updateValue.total.level.dataSource = { $set: content };
      }

      return update(state, updateValue);
    }
    case ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_FAILURE: {
      if (action.startCardId !== state.total.level.state.startCardId || action.level !== state.total.level.state.level) {
        return state;
      }

      const updateValue = {
        total: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
          },
        },
      };

      if (!action.append) {
        updateValue.total.level.dataSource = { $set: null };
      }

      return update(state, updateValue);
    }
    //</editor-fold>

    //<editor-fold desc="Get deck membox info">
    case ActionTypes.GET_DECK_MEMBOX_INFO_REQUEST:
      return update(state, {
        deck: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
              urlKey: { $set: action.urlKey },
            },
          },
        },
      });
    case ActionTypes.GET_DECK_MEMBOX_INFO_SUCCESS:
      return update(state, {
        deck: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              errorMessage: { $set: "" },
            },
            dataSource: { $set: action.data },
          },
        },
      });
    case ActionTypes.GET_DECK_MEMBOX_INFO_FAILURE:
      return update(state, {
        deck: {
          info: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
            dataSource: { $set: null },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get deck membox cards">
    case ActionTypes.GET_DECK_MEMBOX_CARD_ID_REQUEST:
      return update(state, {
        deck: {
          cardIds: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
              urlKey: { $set: action.urlKey },
            },
          },
        },
      });
    case ActionTypes.GET_DECK_MEMBOX_CARD_ID_SUCCESS:
      return update(state, {
        deck: {
          cardIds: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              errorMessage: { $set: "" },
            },
            dataSource: { $set: action.data.content },
          },
        },
      });
    case ActionTypes.GET_DECK_MEMBOX_CARD_ID_FAILURE:
      return update(state, {
        deck: {
          cardIds: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
            dataSource: { $set: null },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Get deck membox level cards">
    case ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_REQUEST: {
      const updateValue = {
        deck: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_WAITING },
              urlKey: { $set: action.urlKey },
              level: { $set: action.level },
            },
            dataSource: { $set: null },
          },
        },
      };
      return update(state, updateValue);
    }
    case ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_SUCCESS: {
      if (state.deck.level.state.urlKey !== action.urlKey || state.deck.level.state.level !== action.level) {
        return state;
      }
      const data = action.data;
      if (action.shuffle && data && data.content) {
        const content = data.content;
        if (content) {
          for (let key of Object.keys(content)) {
            if (content[key][Consts.MEMORIZE_STATE_READY]) {
              shuffleArray(content[key][Consts.MEMORIZE_STATE_READY]);
            }
            if (content[key][Consts.MEMORIZE_STATE_WAIT]) {
              shuffleArray(content[key][Consts.MEMORIZE_STATE_WAIT]);
            }
          }
        }
      }

      const updateValue = {
        deck: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_SUCCESS },
              errorMessage: { $set: "" },
            },
            dataSource: { $set: action.data.content },
          },
        },
      };

      return update(state, updateValue);
    }
    case ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_FAILURE:
      if (state.deck.level.state.urlKey !== action.urlKey || state.deck.level.state.level !== action.level) {
        return state;
      }

      return update(state, {
        deck: {
          level: {
            state: {
              request: { $set: Consts.REQUEST_FAILURE },
              errorMessage: { $set: action.errorMessage },
            },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Memorize">
    case ActionTypes.MEMORIZE_CARD_REQUEST:
      return update(state, {
        memorize: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            level: { $set: action.level },
            cardId: { $set: action.cardId },
          },
        },
      });

    case ActionTypes.MEMORIZE_CARD_SUCCESS: {
      const newState = update(state, {
        memorize: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            level: { $set: action.level },
            cardId: { $set: action.cardId },
          },
        },
      });

      return newState;
    }

    case ActionTypes.MEMORIZE_CARD_FAILURE:
      return update(state, {
        memorize: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="Reset membox">
    case ActionTypes.RESET_MEMBOX_REQUEST:
      return update(state, {
        resetMembox: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.RESET_MEMBOX_SUCCESS:
      return update(state, {
        resetMembox: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
          },
        },
      });

    case ActionTypes.RESET_MEMBOX_FAILURE:
      return update(state, {
        resetMembox: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    //</editor-fold>

    //<editor-fold desc="기억상자 카드 데이터 가져오기">
    case ActionTypes.GET_MEMBOX_CARDS_REQUEST: {
      const updateValue = {
        cards: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
          },
        },
      };

      if (!action.append) {
        updateValue.cards.state.requestedIds = { $set: [] };
        updateValue.cards.dataSource = { $set: [] };
      } else {
        updateValue.cards.state.requestedIds = { $push: action.cardIds };
      }

      return update(state, updateValue);
    }

    case ActionTypes.GET_MEMBOX_CARDS_SUCCESS: {
      const updateValue = {
        cards: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $push: action.data },
        },
      };

      return update(state, updateValue);
    }

    case ActionTypes.GET_MEMBOX_CARDS_FAILURE: {
      const updateValue = {
        cards: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      };

      return update(state, updateValue);
    }
    //</editor-fold>

    default:
      return state;
  }
}

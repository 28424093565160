/**
 * Created by kimchangduk on 2017-07-31.
 */
import React from 'react';
import PropTypes from 'prop-types';

class CardQueueProgress extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        total: PropTypes.number
    };

    render() {
        const width = this.props.value / this.props.total * 100 + '%';
        return (<div>
            <div className="card-queue-progress">
                <div className="card-queue-progress-inner" style={{width}}/>
            </div>
            <div className="card-queue-count">{`${this.props.value} / ${this.props.total}`}</div>
        </div>);
    }
}

export default CardQueueProgress;
import {ActionTypes} from '../constants';
import {getMessageFromResponse} from "../utils";
import {processHttpError} from "./utils";
import * as Api from "../Api";

export function getPurchaseHistory(page = 0) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_PURCHASE_HISTORY_REQUEST, page});
        return Api.getPurchaseHistory(page).then(response => {
            dispatch({type: ActionTypes.GET_PURCHASE_HISTORY_SUCCESS, data: response.data, page});
        }).catch(error => {
            processHttpError(dispatch, error);
            let errorMessage = getMessageFromResponse(error.response, '결제 내역을 받아오는 데에 실패했습니다.');
            dispatch({type: ActionTypes.GET_PURCHASE_HISTORY_FAILURE, errorMessage: errorMessage, page});
        });
    };
}
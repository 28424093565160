/**
 * Created by kimchangduk on 2017-07-22.
 */

import React from "react";
import update from "immutability-helper";
import { Urls } from "../constants";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { openRightSideMenu, openCategorySideMenu } from "../actions/sideMenu";
import AppHistory from "../history";
import DialogManager from "../dialogs/DialogManager";
import HowToUseDialog from "../dialogs/HowToUseDialog";
import CategoryTable from "./CategoryTable/CategoryTable";
import Hamburger from "../assets/icons/svg_kit/menu.svg";
import GridImg from "../assets/icons/svg_kit/grid.svg";
import comboboxArrow from "../assets/icons/comboboxArrow.png";
import BackIcon from "../assets/icons/svg_kit/arrow-left.svg";
import LogoIcon from "../assets/icons/logo.png";
import { Router, Switch, Route } from "react-router-dom";
import SearchBox from "./Search/SearchBox";
import SearchButton from "./Search/SearchButton";
import { isMobileDevice } from "../utils";
import SearchBoxDialog from "./Search/SearchBoxDialog";

export const LeftMenuTypes = {
  HOME: "HOME",
  BACK: "BACK",
};

class Navigator extends React.Component {
  static defaultProps = {
    leftMenuType: LeftMenuTypes.HOME,
    leftMenuUrl: Urls.ROOT,
  };

  static propTypes = {
    leftMenuType: PropTypes.oneOf([LeftMenuTypes.BACK, LeftMenuTypes.HOME]),
    leftMenuUrl: PropTypes.string,
    leftMenuState: PropTypes.object,
    actions: PropTypes.object,
    title: PropTypes.string,
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        openRightSideMenu: () => {
          dispatch(openRightSideMenu());
        },
        openCategoryMenu: () => {
          dispatch(openCategorySideMenu());
        },
      },
    };
  };

  state = {
    visibleCategoryTable: false,
    visibleSearchDialog: false,
  };

  onLeftClick = () => {
    switch (this.props.leftMenuType) {
      case LeftMenuTypes.BACK:
        if (this.props.leftMenuUrl) {
          AppHistory.move(this.props.leftMenuUrl, this.props.leftMenuState);
        } else {
          AppHistory.goBack(this.props.leftMenuUrl);
        }
        break;
      case LeftMenuTypes.HOME: {
        const pathName = AppHistory.getPathName();
        if (!pathName && pathName === "/") {
          DialogManager.push(HowToUseDialog);
        } else {
          AppHistory.move("/");
        }
        break;
      }
    }
  };

  onRightClick = () => {
    this.props.actions.openRightSideMenu();
  };

  render() {
    let leftIconStyle = {
      cursor: "pointer",
      position: "absolute",
      left: 5,
      top: 0,
      width: 44,
      height: 48,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
    switch (this.props.leftMenuType) {
      case LeftMenuTypes.HOME:
        leftIconStyle.backgroundImage = `url(${LogoIcon})`;
        leftIconStyle.backgroundSize = `32px`;
        break;
      case LeftMenuTypes.BACK:
        leftIconStyle.backgroundImage = `url(${BackIcon})`;
        leftIconStyle.backgroundSize = `36px`;
        break;
    }

    const isMobile = isMobileDevice();

    return (
      <div style={styles.container}>
        <div style={leftIconStyle} onClick={this.onLeftClick} />
        <Switch>
          {!isMobile ? <Route path="/" exact component={SearchBox} /> : undefined}
          <Route
            component={() => (
              <div
                style={update(styles.title, {
                  right: { $set: isMobile ? 148 : 114 + 44 },
                  fontSize: { $set: isMobile ? 20 : 24 },
                })}
              >
                {this.props.title}
              </div>
            )}
          />
        </Switch>
        <div onClick={this.onRightClick} style={styles.hamburger} />
        {isMobile ? (
          <SearchButton
            onClick={() => {
              this.setState({ visibleSearchDialog: true });
            }}
          />
        ) : undefined}
        {isMobile ? (
          <div
            style={styles.gridImg}
            onClick={() => {
              this.setState({ visibleCategoryTable: true });
            }}
          />
        ) : (
          <div
            style={styles.combobox}
            onClick={() => {
              this.setState({ visibleCategoryTable: true });
            }}
          >
            카테고리
            <img style={styles.comboboxArrow} src={comboboxArrow} />
          </div>
        )}
        {this.state.visibleCategoryTable ? (
          <CategoryTable
            style={{ right: 44, top: 58 }}
            onBlur={() => {
              this.setState({ visibleCategoryTable: false });
            }}
          />
        ) : undefined}
        {this.state.visibleSearchDialog ? (
          <SearchBoxDialog
            onBlur={() => {
              this.setState({ visibleSearchDialog: false });
            }}
          />
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 48,
    zIndex: 1000,
    color: "#FFFFFF",
    background: "linear-gradient(to left, rgb(102, 107, 122), #444)",
  },
  title: {
    position: "absolute",
    left: 52,
    right: 114 + 44,
    height: 48,
    lineHeight: "48px",
    fontSize: "20px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    wordWrap: "normal",
    textOverflow: "ellipsis",
    overflow: "hidden",
    letterSpacing: "-0.5px",
  },
  hamburger: {
    float: "right",
    backgroundImage: `url(${Hamburger})`,
    backgroundPosition: "center",
    backgroundSize: 26,
    backgroundRepeat: "no-repeat",
    width: 44,
    height: "100%",
    cursor: "pointer",
  },
  combobox: {
    display: "inline-block",
    height: "100%",
    float: "right",
    fontSize: 18,
    padding: 12,
    cursor: "pointer",
    paddingRight: 40,
    position: "relative",
  },
  gridImg: {
    float: "right",
    backgroundImage: `url(${GridImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 44,
    height: "100%",
    cursor: "pointer",
  },
  comboboxArrow: {
    position: "absolute",
    right: 13,
    top: "calc(50% - 4px)",
  },
};

export default connect(undefined, Navigator.DispatchToProps)(Navigator);

import React from "react";
import PropTypes from "prop-types";
import { Consts } from "../constants";
import Navigator from "../components/Navigator";
import { connect } from "react-redux";
import { getDeckList, getPopularDeckList, getRecentDeckList, getMyDeckList } from "../actions/deckList";
import { getMemboxTotalInfo } from "../actions/membox";
import MemboxList from "../components/MemboxList";
import DeckItem from "../components/DeckItem";
import Radium from "radium";
import GA from "../GA";
import MembershipItem from "../components/MembershipItem";
import CBTItem from "../components/CBTItem";
import { isMobileDevice } from "../utils";

class DashboardPage extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    request: PropTypes.string,
    errorMessage: PropTypes.string,

    isLogin: PropTypes.bool,

    recentDataSource: PropTypes.array,
    popularDataSource: PropTypes.array,
    myDataSource: PropTypes.array,

    memboxTotalInfo: PropTypes.object,
  };

  static StateToProps = (state) => {
    return {
      request: state.deckList.all.state.request,
      errorMessage: state.deckList.all.state.errorMessage,

      isLogin: state.user.state.isLogin,

      recentDataSource: state.deckList.recent.dataSource,
      popularDataSource: state.deckList.popular.dataSource,
      myDataSource: state.deckList.my.dataSource,

      memboxTotalInfo: state.membox.total.info,
    };
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        getPopularDeckList: () => {
          dispatch(getPopularDeckList());
        },
        getRecentDeckList: () => {
          dispatch(getRecentDeckList());
        },
        getMyDeckList: () => {
          dispatch(getMyDeckList());
        },
        getMemboxTotalInfo: () => {
          dispatch(getMemboxTotalInfo());
        },
      },
    };
  };

  constructor(props) {
    super(props);
  }

  state = {
    mobileMode: isMobileDevice(),
  };

  componentDidMount() {
    GA.logPage();
    this.props.actions.getPopularDeckList();
    this.props.actions.getRecentDeckList();
    this.props.actions.getMyDeckList();
    this.props.actions.getMemboxTotalInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLogin && !nextProps.isLogin) {
      this.props.actions.getPopularDeckList();
      this.props.actions.getRecentDeckList();
      this.props.actions.getMyDeckList();
      this.props.actions.getMemboxTotalInfo();
    }
  }

  renderDecks = (dataSource) => {
    return dataSource.map((item, key) => {
      switch (item.type) {
        case Consts.CBT_TEST:
          return <CBTItem key={key} test={item} mobile={this.state.mobileMode} />;
        case Consts.MEMBERSHIP:
          return <MembershipItem key={key} data={item} mobile={this.state.mobileMode} />;
        case Consts.DECK:
        default:
          return <DeckItem key={key} deck={item} mobile={this.state.mobileMode} />;
      }
    });
  };

  deckBuyDateSortFunction = (a, b) => {
    if (a.has && b.has && a.buyDate && b.buyDate) {
      return b.buyDate - a.buyDate;
    }

    if (a.has === b.has) {
      return b.id - a.id;
    }

    return b.has - a.has;
  };

  renderForMobile() {
    const memboxInfoDataSource = this.props.memboxTotalInfo.dataSource;
    return (
      <div>
        <Navigator />
        <div style={styles.container}>
          <div style={{ margin: "auto", padding: 10, height: "calc(100% - 80px)", overflow: "auto" }}>
            <div style={styles.mobile.list}>
              {this.props.myDataSource ? (
                <div>
                  <p style={styles.mobile.blockTitle}>보유</p>
                  <div style={[{ whiteSpace: "initial" }]}>{this.renderDecks(this.props.myDataSource.sort(this.deckBuyDateSortFunction))}</div>
                </div>
              ) : undefined}
            </div>
            <div style={[styles.mobile.list, { marginTop: 0 }]}>
              {this.props.recentDataSource ? (
                <div>
                  <p style={styles.mobile.blockTitle}>최신</p>
                  <div>{this.renderDecks(this.props.recentDataSource)}</div>
                </div>
              ) : undefined}
            </div>
            <div style={styles.mobile.list}>
              {this.props.popularDataSource ? (
                <div>
                  <p style={styles.mobile.blockTitle}>인기</p>
                  <div>{this.renderDecks(this.props.popularDataSource)}</div>
                </div>
              ) : undefined}
            </div>
          </div>
          <MemboxList dataSource={memboxInfoDataSource} />
        </div>
      </div>
    );
  }

  renderForPC() {
    const memboxInfoDataSource = this.props.memboxTotalInfo.dataSource;
    return (
      <div>
        <Navigator />
        <div style={styles.container}>
          <div style={{ margin: "auto", padding: 10, height: "calc(100% - 80px)", overflow: "auto" }}>
            {this.props.myDataSource ? (
              <div style={[styles.section, { marginTop: 20 }]}>
                <p style={styles.blockTitle}>보유</p>
                <div style={[styles.deckList, { whiteSpace: "initial" }]}>{this.renderDecks(this.props.myDataSource)}</div>
              </div>
            ) : undefined}
            {this.props.recentDataSource ? (
              <div style={[styles.section, this.props.myDataSource ? { marginTop: 40 } : undefined]}>
                <p style={styles.blockTitle}>최신</p>
                <div style={styles.deckList}>{this.renderDecks(this.props.recentDataSource)}</div>
              </div>
            ) : undefined}
            {this.props.popularDataSource ? (
              <div style={styles.section}>
                <p style={styles.blockTitle}>인기</p>
                <div style={styles.deckList}>{this.renderDecks(this.props.popularDataSource)}</div>
              </div>
            ) : undefined}
          </div>
          <MemboxList dataSource={memboxInfoDataSource} />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.mobileMode) {
      return this.renderForMobile();
    } else {
      return this.renderForPC();
    }
  }
}

const styles = {
  container: {
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
  },
  blockTitle: {
    color: "#333",
    fontSize: 18,
    fontWeight: "bold",
    padding: "0 10px",
  },
  hr: {
    borderTop: "none",
    borderBottom: "1px solid #ffffff",
    borderLeft: "none",
    borderRight: "none",
    margin: "10px 0",
  },
  deckList: {
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    textAlign: "left",
  },
  section: {
    marginTop: 20,
  },
  mobile: {
    padding10: {
      padding: 10,
    },
    list: {
      marginTop: 10,
      backgroundColor: "#FFFFFF",
      borderRadius: 5,
    },
    blockTitle: {
      color: "#333",
      fontSize: 18,
      fontWeight: "bold",
      padding: "10px 10px",
    },
  },
};

export default connect(DashboardPage.StateToProps, DashboardPage.DispatchToProps)(Radium(DashboardPage));

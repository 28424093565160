/**
 * Created by kimchangduk on 2017-09-12.
 */

import React from 'react';
import AppHistory from '../history';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Strings, Consts, Urls} from '../constants';
import {getBoardList, postBoardItem, getBoardItem, postBoardItemComment, editBoardItem, deleteBoardItem, deleteBoardItemComment} from '../actions/board';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import {hasChangedRequestToSuccess} from '../utils';
import GA from '../GA';

class BoardItemEdit extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        actions: PropTypes.object,
        editType: PropTypes.string,

        user: PropTypes.object,

        deleteBoardItemRequest: PropTypes.string,
        deleteCommentRequest: PropTypes.string,
        editRequest: PropTypes.string,
        postCommentRequest: PropTypes.string,

        postRequest: PropTypes.string,
        postData: PropTypes.object,

        boardListLastPage: PropTypes.bool,
        boardListRequest: PropTypes.string,
        boardListPage: PropTypes.number,

        boardItemData: PropTypes.object,
        boardItemRequest: PropTypes.string
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                getBoardList: (page = 0) => {
                    dispatch(getBoardList(page));
                },
                postBoardItem: (title, content) => {
                    dispatch(postBoardItem(title, content));
                },
                getBoardItem: (id) => {
                    dispatch(getBoardItem(id));
                },
                postBoardItemComment: (id, content) => {
                    dispatch(postBoardItemComment(id, content));
                },
                editBoardItem: (id, title, content) => {
                    dispatch(editBoardItem(id, title, content));
                },
                deleteBoardItem: (id) => {
                    dispatch(deleteBoardItem(id));
                },
                deleteBoardItemComment: (id, commentId) => {
                    dispatch(deleteBoardItemComment(id, commentId));
                }
            }
        };
    };

    static StateToProps = (state) => {
        return {
            user: state.user,

            deleteBoardItemRequest: state.board.boardItem.delete.state.request,
            deleteCommentRequest: state.board.comment.delete.state.request,
            editRequest: state.board.boardItem.edit.state.request,
            postCommentRequest: state.board.comment.post.state.request,

            postRequest: state.board.boardItem.post.state.request,
            postData: state.board.boardItem.post.dataSource,

            boardListLastPage: state.board.boardList.get.state.last,
            boardListRequest: state.board.boardList.get.state.request,
            boardListPage: state.board.boardList.get.state.page,

            boardItemData: state.board.boardItem.get.dataSource,
            boardItemRequest: state.board.boardItem.get.state.request
        };
    };

    state = {
        editTitle: '',
        editContent: ''
    };

    componentDidMount() {
        GA.logPage();
        if (this.props.editType === EditTypes.WRITE) {
            this.initForWrite();
        } else if (this.props.editType === EditTypes.UPDATE) {
            this.initForUpdate();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.editType === EditTypes.UPDATE && hasChangedRequestToSuccess(this.props.boardItemRequest, nextProps.boardItemRequest)) {
            const boardItemData = nextProps.boardItemData;
            this.setState({
                editTitle: boardItemData.title, editContent: boardItemData.content
            });
        }

        if (hasChangedRequestToSuccess(this.props.postRequest, nextProps.postRequest)) {
            AppHistory.goBack(Urls.BOARD);
        }

        if (hasChangedRequestToSuccess(this.props.editRequest, nextProps.editRequest)) {
            AppHistory.goBack(Urls.buildBoardItem(this.props.match.params.id));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.editType !== prevProps.editType) {
            if (this.props.editType === EditTypes.WRITE) {
                this.initForWrite();
            } else if (this.props.editType === EditTypes.UPDATE) {
                this.initForUpdate();
            }
        }
    }

    initForUpdate = () => {
        const id = parseInt(this.props.match.params.id);
        this.props.actions.getBoardItem(id);
    };

    initForWrite = () => {
        this.setState({
            editTitle: '', editContent: ''
        });
    };

    onEditTitleChange = (e) => {
        this.setState({editTitle: e.target.value});
    };

    onEditContentChange = (e) => {
        this.setState({editContent: e.target.value});
    };

    onEditSubmit = (e) => {
        e.preventDefault();
        if (this.props.editType === EditTypes.WRITE && this.props.postRequest !== Consts.REQUEST_WAITING) {
            this.props.actions.postBoardItem(this.state.editTitle, this.state.editContent);
        } else if (this.props.editType === EditTypes.UPDATE && this.props.editRequest !== Consts.REQUEST_WAITING) {
            this.props.actions.editBoardItem(parseInt(this.props.match.params.id), this.state.editTitle, this.state.editContent);
        }
    };

    render() {
        const user = this.props.user.dataSource;
        const viewItem = this.props.boardItemData;
        const editable = user && viewItem && user.id === viewItem.user.id;

        return (<div>
            <Navigator leftMenuType={LeftMenuTypes.HOME} title={this.props.editType === EditTypes.WRITE ? '글작성' : '수정'}/>
            <div className="membox-board-contents">
                <div className="membox-post">
                    <form className="post-form" onSubmit={this.onEditSubmit}>
                        <input type="text" name="title" placeholder="제목" value={this.state.editTitle} required="" onChange={this.onEditTitleChange}/>
                        <div className="input-area">
                            <textarea name="content" placeholder="내용" required="" value={this.state.editContent} onChange={this.onEditContentChange}/>
                        </div>
                        <button className="flat-button full" type="submit">{this.props.editType === EditTypes.WRITE ? '보내기' : '수정'}</button>
                    </form>
                </div>
            </div>
        </div>);
    }
}

const styles = {
    listContainer: {transitionTimingFunction: "cubic-bezier(0.1, 0.57, 0.1, 1)", transitionDuration: "0ms", transform: "translate(0px, 0px) translateZ(0px)"},
    overflowAuto: {overflow: 'auto'}
};

export const EditTypes = {UPDATE: 'UPDATE', WRITE: 'WRITE'};

export default connect(BoardItemEdit.StateToProps, BoardItemEdit.DispatchToProps)(BoardItemEdit);
/**
 * Created by kimchangduk on 2017-07-19.
 */
import {combineReducers} from 'redux';
import userReducer from './user';
import registerReducer from './register';
import sideMenuReducer from './sideMenu';
import deckListReducer from './deckList';
import deckDetailReducer from './deckDetail';
import deckCardsReducer from './deckCards';
import deckLessonCardsReducer from './deckLessonCards';
import memboxReducer from './membox';
import boardReducer from './board';
import cbtReducer from './cbt';
import cbtListReducer from './cbtList';
import cbtSolutionReducer from './cbtSolution';
import cbtPaperReducer from './cbtPaper';
import categoryReducer from './category';
import purchaseReducer from './purchase';
import membershipReducer from './membership';

export default combineReducers({
    user: userReducer,
    register: registerReducer,
    sideMenu: sideMenuReducer,
    deckList: deckListReducer,
    deckDetail: deckDetailReducer,
    deckCards: deckCardsReducer,
    deckLessonCards: deckLessonCardsReducer,
    membox: memboxReducer,
    board: boardReducer,
    cbt: cbtReducer,
    cbtList: cbtListReducer,
    cbtSolution: cbtSolutionReducer,
    cbtPaper: cbtPaperReducer,
    category: categoryReducer,
    purchase: purchaseReducer,
    membership: membershipReducer
});

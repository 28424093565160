/**
 * Created by kimchangduk on 2017-07-27.
 */

import {ActionTypes, Strings, Consts} from '../constants/index';
import * as Api from '../Api';
import {processHttpError} from './utils';

/**
 * Dashboard 페이지에서 보여주는 간단한 카운트 정보
 */
export function getMemboxTotalInfo() {
    return dispatch => {
        dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_INFO_REQUEST});
        Api.getMemboxTotalInfo().then(response => {
            dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_INFO_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_INFO_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA});
        });
    };
}

export function getTotalDeckMemboxInfo(urlKey) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_DECK_MEMBOX_INFO_REQUEST, urlKey});
        Api.getMemboxDeckInfo(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_INFO_SUCCESS, data: response.data, urlKey});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_INFO_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, urlKey});
        });
    };
}

export function getDeckMemboxCardIds(urlKey) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_DECK_MEMBOX_CARD_ID_REQUEST, urlKey});
        Api.getMemboxDeckCardIds(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_CARD_ID_SUCCESS, data: response.data, urlKey});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_CARD_ID_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, urlKey});
        });
    };
}

export function getDeckMemboxLevelCardIds(urlKey, level, stateType = Consts.MEMORIZE_STATE_READY, shuffle = false) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_REQUEST, level, urlKey});
        Api.getMemboxDeckLevelCardIds(urlKey, level, stateType).then(response => {
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_SUCCESS, urlKey, data: response.data, level, shuffle});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_DECK_MEMBOX_LEVEL_CARD_ID_FAILURE, urlKey, errorMessage: Strings.FAILED_TO_LOAD_DATA, level});
        });
    };
}

export function getTotalMemboxLevelCardIds(level, stateType = Consts.MEMORIZE_STATE_READY, startCardId = null, append = false, shuffle = false) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_REQUEST, level, startCardId, append});
        Api.getMemboxLevelCardIds(level, stateType, startCardId).then(response => {
            dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_SUCCESS, data: response.data, stateType, level, startCardId, append, shuffle});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_TOTAL_MEMBOX_LEVEL_CARD_ID_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, level, startCardId, append});
        });
    };
}

export function getMemboxCards(cardIds, append = false) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_MEMBOX_CARDS_REQUEST, cardIds, append});
        Api.getMemboxCards(cardIds).then(response => {
            dispatch({type: ActionTypes.GET_MEMBOX_CARDS_SUCCESS, data: response.data, append});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_MEMBOX_CARDS_FAILURE, errorMessage: '기억상자 초기화에 실패했습니다.', append});
        });
    };
}

export function memorize(originLevel, level, cardId) {
    return dispatch => {
        dispatch({type: ActionTypes.MEMORIZE_CARD_REQUEST, level, originLevel, cardId});
        Api.memorize(level, cardId).then(response => {
            dispatch({type: ActionTypes.MEMORIZE_CARD_SUCCESS, data: response.data, level, originLevel, cardId});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.MEMORIZE_CARD_FAILURE, errorMessage: Strings.FAILED_TO_LOAD_DATA, level, originLevel, cardId});
        });
    };
}

export function resetMembox(urlKey) {
    return dispatch => {
        dispatch({type: ActionTypes.RESET_MEMBOX_REQUEST});
        Api.resetMembox(urlKey).then(response => {
            dispatch({type: ActionTypes.RESET_MEMBOX_SUCCESS});
        }).catch(error => {
            dispatch({type: ActionTypes.RESET_MEMBOX_FAILURE, errorMessage: '기억상자 초기화에 실패했습니다.'});
        });
    };
}
/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-19.
 */

import React from "react";
import ReactDOM from "react-dom";
import Radium from "radium";
import PropTypes from "prop-types";
import Question from "./Question";
import { getFontScaledStyle, scrollIntoView } from "../../utils";

class CBTQuestionsForPC extends React.Component {
  static defaultProps = {
    fontScale: 1,
  };

  static propTypes = {
    fontScale: PropTypes.number,
    style: PropTypes.object,
    questionPage: PropTypes.array,
    classSubjects: PropTypes.array,
    classSubjectIndex: PropTypes.object,
    questions: PropTypes.array,
    dummyAnswersMap: PropTypes.array,
    answers: PropTypes.array,
    onAnswerClick: PropTypes.func,
    onDummyAnswerClick: PropTypes.func,
    questionNavigation: PropTypes.object,
    onRemainQuestionsRequest: PropTypes.func,
  };

  state = {
    currentPage: 0,
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.questionNavigation !== nextProps.questionNavigation) {
      this.navigateToQuestion(nextProps.questionNavigation);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.questionNavigation !== this.props.questionNavigation && this.props.questionNavigation) {
      const scrollElement = this.refs[this.buildQuestionRef(this.props.questionNavigation.id)];
      if (scrollElement) {
        const domNode = ReactDOM.findDOMNode(scrollElement);
        const parentNode = this.refs["question-list"];
        scrollIntoView(domNode, parentNode);
      }
    }
  }

  buildQuestionRef = (id) => `question-${id}`;

  getRemainQuestionsCount = () => {
    const totalCounts = this.props.answers.length;
    return totalCounts - this.props.answers.filter((item) => item !== null).length;
  };

  onPrevButtonClick = () => {
    this.setState({ currentPage: Math.max(0, this.state.currentPage - 1) });
  };

  onNextButtonClick = () => {
    this.setState({ currentPage: Math.min(this.props.questionPage.length - 1, this.state.currentPage + 1) });
  };

  navigateToQuestion = ({ id }) => {
    for (let i in this.props.questionPage) {
      i = parseInt(i);
      if (this.props.questionPage[i].includes(id)) {
        this.setState({ currentPage: i });
        break;
      }
    }
  };

  renderQuestions = (page) => {
    if (!this.props.questionPage || !this.props.questionPage[page]) {
      return undefined;
    }

    const result = [];
    const questions = this.props.questions;

    for (let id of this.props.questionPage[page]) {
      const questionIndex = questions.findIndex((item) => item.id === id);
      if (questionIndex >= 0) {
        const question = questions[questionIndex];
        if (this.props.classSubjectIndex && this.props.classSubjectIndex[question.classSubjectId] === question.id) {
          const classSubject = this.props.classSubjects.find((a) => a.id === question.classSubjectId);
          const classSubjectIndex = this.props.classSubjects.findIndex((a) => a.id === question.classSubjectId);
          if (classSubject && classSubject.subject) {
            result.push(
              <div key={`subject-divider-${classSubject.id}`} style={styles.subjectDivider}>
                과목{classSubjectIndex + 1}: {classSubject.subject.name}
              </div>
            );
          }
        }

        result.push(
          <Question
            fontScale={this.props.fontScale}
            ref={this.buildQuestionRef(question.id)}
            key={id}
            question={question}
            answer={this.props.answers[questionIndex]}
            dummyAnswers={this.props.dummyAnswersMap[questionIndex]}
            onAnswerClick={this.props.onAnswerClick}
            onDummyAnswerClick={this.props.onDummyAnswerClick}
            questionIndex={questionIndex}
          />
        );
      }
    }
    return result;
  };

  render() {
    return (
      <div style={[styles.container, this.props.style]}>
        <div style={styles.questionPages} ref="question-list">
          <div style={{ margin: 10 }}>
            <div>{this.renderQuestions(this.state.currentPage)}</div>
          </div>
        </div>
        <div style={styles.pagenator}>
          <button className="plumb-button plumb-button-default" disabled={this.state.currentPage === 0} onClick={this.onPrevButtonClick}>
            이전 페이지
          </button>
          <span style={styles.currentPage}>
            {1 + this.state.currentPage} / {this.props.questionPage.length}
          </span>
          <button
            className="plumb-button plumb-button-default"
            onClick={this.onNextButtonClick}
            disabled={this.props.questionPage.length - 1 === this.state.currentPage}
          >
            다음 페이지
          </button>
          <div style={styles.remainCounts} onClick={this.props.onRemainQuestionsRequest}>
            남은 문항 수 : {this.getRemainQuestionsCount()}
          </div>
        </div>
      </div>
    );
  }
}

const tableBorderColor = "rgba(0,0,0,0.5)";

const styles = {
  container: {
    display: "inline-block",
    backgroundColor: "#FFFFFF",
    textAlign: "left",
    fontWeight: "bold",
  },
  page: {
    padding: 10,
    verticalAlign: "top",
    width: "50%",
    display: "table-cell",
  },
  pageLeftBorder: {
    borderLeft: "1px solid",
    borderLeftColor: tableBorderColor,
  },
  questionPages: {
    height: "calc(100% - 40px)",
    position: "relative",
    overflowY: "auto",
  },
  pagenator: {
    height: 40,
    paddingTop: 3,
    borderTop: "1px solid",
    borderTopColor: tableBorderColor,
    textAlign: "center",
    position: "relative",
  },
  remainCounts: {
    position: "absolute",
    padding: 8,
    top: 0,
    right: 10,
    fontWeight: "bold",
    textAlign: "center",
    cursor: "pointer",
  },
  currentPage: {
    marginLeft: 20,
    marginRight: 20,
  },
  subjectDivider: {
    textAlign: "center",
    padding: 5,
    background: "rgba(0,0,0,0.2)",
    border: "1px solid #000000",
    marginBottom: 10,
  },
};

export default Radium(CBTQuestionsForPC);

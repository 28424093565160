/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from "react";
import update from "immutability-helper";
import { Urls, Consts } from "../constants";
import PropTypes from "prop-types";
import AppHistory from "../history";
import { getDeckLessonCards } from "../actions/deckCards";
import { getDeckDetail } from "../actions/deckDetail";
import { getTotalDeckMemboxInfo, getDeckMemboxCardIds, memorize } from "../actions/membox";
import { connect } from "react-redux";
import Navigator, { LeftMenuTypes } from "../components/Navigator";
import PreviewStudyView from "../components/PreviewStudyView";
import DialogManager from "../dialogs/DialogManager";
import DefaultDialogView from "../dialogs/DefaultDialogView";
import Loader from "../components/Loader";
import GA from "../GA";
import { notify } from "react-notify-toast";
import LoginRequiredDialog from "../dialogs/LoginRequiredDialog";
import CouponRegisterDialog from "../dialogs/CouponRegisterDialog";
import { arraysEqual, decreaseShortTermMemoryLevel, getShortTermMemoryLevel, hasChangedRequestToSuccess, increaseShortTermMemoryLevel } from "../utils";

class MemorizePage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    actions: PropTypes.object,
    lesson: PropTypes.object,
    cards: PropTypes.array,
    cardIds: PropTypes.array,
    match: PropTypes.object,
    memboxInfoRequest: PropTypes.string,
    memboxInfoDataSource: PropTypes.object,
    memboxCardIdsRedux: PropTypes.object,
    deckCards: PropTypes.object,
    deckDetail: PropTypes.object,
    isLogin: PropTypes.bool,
    deckLessonCardsRequest: PropTypes.string,
    deckLessonCardsErrorMessage: PropTypes.string,
    deckLessonCardsBuyRequired: PropTypes.bool,
  };

  static defaultProps = {};

  static StateToProps = (state, ownProps) => {
    const deckUrlKey = ownProps.match.params.deckUrlKey;
    const lessonId = parseInt(ownProps.match.params.lessonId);

    const deckDetail = state.deckDetail.dataSource && state.deckDetail.dataSource.urlKey === deckUrlKey ? state.deckDetail.dataSource : undefined;
    let lesson = deckDetail ? MemorizePage.findLesson(deckDetail.element.packages, PackageOrLesson.PACKAGE, lessonId) : null;

    const memboxInfoRequest = state.membox.deck.info.state.request;
    const memboxInfoDataSource =
      state.membox.deck.info.state.urlKey === deckUrlKey && state.membox.deck.info.state.request === Consts.REQUEST_SUCCESS && state.membox.deck.info.dataSource
        ? state.membox.deck.info.dataSource
        : null;
    const memboxCardIdsRedux = state.membox.deck.cardIds;

    const cards =
      state.deckLessonCards.dataSource &&
      state.deckLessonCards.state.deckUrlKey === deckUrlKey &&
      state.deckLessonCards.state.lessonId === lessonId &&
      memboxInfoRequest !== Consts.REQUEST_WAITING &&
      memboxCardIdsRedux.state.request !== Consts.REQUEST_WAITING &&
      state.deckLessonCards.state.request === Consts.REQUEST_SUCCESS
        ? state.deckLessonCards.dataSource
        : undefined;

    let cardIds = cards ? cards.map((a) => a.id) : null; // 학습할 카드들의 id

    //<editor-fold desc="전체 lesson card id들 중에서 기억상자에 포함된 카드들은 제외하고 필터링한다.">
    if (
      cardIds &&
      cardIds.length > 0 &&
      memboxCardIdsRedux.state.request === Consts.REQUEST_SUCCESS &&
      memboxCardIdsRedux.state.urlKey === deckUrlKey &&
      memboxCardIdsRedux.dataSource
    ) {
      const memboxCardIds = memboxCardIdsRedux.dataSource;
      if (memboxCardIds) {
        for (let level of Object.keys(memboxCardIds)) {
          for (let state of [Consts.MEMORIZE_STATE_READY, Consts.MEMORIZE_STATE_WAIT]) {
            if (memboxCardIds[level] && memboxCardIds[level][state]) {
              // memboxCardIds에 cardIds의 요소가 포함되어있으면 제외시켜야한다.
              cardIds = cardIds.filter((a) => memboxCardIds[level][state].findIndex((b) => b === a) < 0);
            }
          }
        }
      }
    }
    //</editor-fold>

    return {
      memboxInfoRequest,
      memboxInfoDataSource,
      memboxCardIdsRedux,
      deckDetail: state.deckDetail,
      cards,
      cardIds,
      lesson,
      isLogin: state.user.state.isLogin,
      deckLessonCardsRequest: state.deckLessonCards.state.request,
      deckLessonCardsErrorMessage: state.deckLessonCards.state.errorMessage,
      deckLessonCardsBuyRequired: state.deckLessonCards.state.buyRequired,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getDeckMemboxInfo: () => {
          dispatch(getTotalDeckMemboxInfo(ownProps.match.params.deckUrlKey));
        },
        getDeckMemboxCardIds: () => {
          dispatch(getDeckMemboxCardIds(ownProps.match.params.deckUrlKey));
        },
        getDeckLessonCards: (shuffle = false) => {
          dispatch(getDeckLessonCards(ownProps.match.params.deckUrlKey, parseInt(ownProps.match.params.lessonId), shuffle));
        },
        getDeckDetail: () => {
          dispatch(getDeckDetail(ownProps.match.params.deckUrlKey));
        },
        memorize: (id) => {
          dispatch(memorize(0, targetLevel, id));
        },
      },
    };
  };

  static findLesson(root, packageOrLesson, lessonId) {
    if (!root) return null;

    if (packageOrLesson == PackageOrLesson.LESSON) {
      return root.find((a) => a.id === lessonId);
    } else {
      for (let i = 0; i < root.length; i++) {
        let lesson = this.findLesson(root[i].packages, PackageOrLesson.PACKAGE, lessonId);
        if (lesson) {
          return lesson;
        }
        lesson = this.findLesson(root[i].lessons, PackageOrLesson.LESSON, lessonId);
        if (lesson) {
          return lesson;
        }
      }
      return null;
    }
  }

  constructor(props) {
    super(props);
    if (props.cardIds) {
      this.state.cardIds = props.cardIds;
    }
  }

  state = {
    memorizedCardIds: [],
    cardIds: null, // 렌더링시 props.cardIds 대신 이걸로 사용한다. 뒤로 보내기 기능을 위함. 학습중 순서는 바뀌어도 갯수는 안변함,
    memboxInfo: null, // 렌더링시 props.memboxInfo 대신 이걸로 사용한다. 기억상자 이동후 카운팅을 위함.
  };

  componentDidMount() {
    GA.logPage();
    if (this.props.isLogin !== null) {
      this.requestCardData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLogin !== nextProps.isLogin) {
      this.requestCardData();
    }

    if (this.props.deckLessonCardsRequest === Consts.REQUEST_WAITING && nextProps.deckLessonCardsRequest === Consts.REQUEST_FAILURE) {
      if (nextProps.deckLessonCardsBuyRequired) {
        if (nextProps.isLogin === true) {
          DialogManager.push(CouponRegisterDialog, {
            onRequestClose: () => {
              DialogManager.clear();
              AppHistory.replace(Urls.buildLesson(nextProps.match.params.deckUrlKey));
            },
          });
        } else if (nextProps.isLogin === false) {
          DialogManager.push(LoginRequiredDialog);
        }
      } else {
        DialogManager.alert(nextProps.deckLessonCardsErrorMessage);
      }
    }

    // paging 기능이 생긴다면 page=0으로 새로 불러올때 초기화 해주도록 변경해줘야함
    if (hasChangedRequestToSuccess(this.props.memboxCardIdsRedux.state.request, nextProps.memboxCardIdsRedux.state.request)) {
      this.setState({
        memorizedCardIds: [],
      });
    }

    if (this.props.cardIds !== nextProps.cardIds && !arraysEqual(this.props.cardIds, nextProps.cardIds)) {
      if (nextProps.cardIds && nextProps.cardIds.length === 0) {
        const defaultRedirectPage = Urls.buildLesson(this.props.match.params.deckUrlKey);
        DialogManager.clear();
        DialogManager.alert("학습 할 내용이 없습니다.", {
          buttonText: "돌아가기",
          buttonType: Consts.DefaultDialogViewButtonTypes.ERROR,
          onClose: () => {
            // AppHistory.goBack(defaultRedirectPage); 뒤로가기
            AppHistory.move(defaultRedirectPage);
          },
        });
      }
      this.setState({
        cardIds: nextProps.cardIds,
      });
    }

    if (this.props.memboxInfoDataSource !== nextProps.memboxInfoDataSource) {
      this.setState({
        memboxInfo: nextProps.memboxInfoDataSource,
      });
    }

    if (hasChangedRequestToSuccess(this.props.deckDetail.state.request, nextProps.deckDetail.state.request)) {
      const shuffle = nextProps.deckDetail.dataSource && nextProps.deckDetail.dataSource.orderMode === Consts.OrderMode.RANDOM;
      this.props.actions.getDeckLessonCards(shuffle);
    }
  }

  movedBackCardIds = []; // 한번이라도 뒤로 넘긴 카드의 id가 들어감

  requestCardData() {
    this.props.actions.getDeckDetail();
    this.props.actions.getDeckMemboxInfo();
    this.props.actions.getDeckMemboxCardIds();
  }

  onCardMemorize = (cardId, targetLevel) => {
    if (!this.props.isLogin) {
      this.sendCardToBack(cardId);
      notify.show("로그인 시에만 학습 내용이 기록됩니다.");
      this.setState({
        memorizedCardIds: update(this.state.memorizedCardIds, { $push: [cardId] }),
      });
    } else {
      if (targetLevel !== null && targetLevel !== undefined) {
        this.props.actions.memorize(cardId);
        const updateState = {
          memorizedCardIds: update(this.state.memorizedCardIds, { $push: [cardId] }),
        };

        //<editor-fold desc="오른쪽 기억상자의 WAIT를 +1 시킴">
        if (this.state.memboxInfo) {
          const newMemboxInfo = update(this.state.memboxInfo, {});

          if (!newMemboxInfo[targetLevel]) {
            newMemboxInfo[targetLevel] = {
              [Consts.MEMORIZE_STATE_WAIT]: 0,
            };
          }

          if (newMemboxInfo[targetLevel][Consts.MEMORIZE_STATE_WAIT] === undefined) {
            newMemboxInfo[targetLevel][Consts.MEMORIZE_STATE_WAIT] = 0;
          }

          newMemboxInfo[targetLevel][Consts.MEMORIZE_STATE_WAIT]++;
          updateState.memboxInfo = newMemboxInfo;
        }
        //</editor-fold>

        this.setState(updateState);
        if (this.movedBackCardIds.findIndex((a) => a === cardId) >= 0) {
          increaseShortTermMemoryLevel();
        }
      } else {
        const shortTermMemoryLevel = getShortTermMemoryLevel();
        this.sendCardToBack(cardId, shortTermMemoryLevel);
        if (this.movedBackCardIds.findIndex((a) => a === cardId) >= 0) {
          decreaseShortTermMemoryLevel();
        } else {
          this.movedBackCardIds.push(cardId);
        }
      }
    }
  };

  sendCardToBack(cardId, shortTermMemoryLevel = undefined) {
    const targetIndex = this.state.cardIds.findIndex((a) => a === cardId);
    if (targetIndex >= 0) {
      const newCardIds = update(this.state.cardIds, { $splice: [[targetIndex, 1]] });
      if (shortTermMemoryLevel === undefined) {
        newCardIds.push(cardId);
      } else {
        if (targetIndex + shortTermMemoryLevel > newCardIds.length) {
          newCardIds.push(cardId);
        } else {
          newCardIds.splice(targetIndex + shortTermMemoryLevel, 0, cardId);
        }
      }
      this.setState({
        cardIds: newCardIds,
      });
    }
  }

  onMemorizeComplete = () => {
    const defaultRedirectPage = Urls.buildLesson(this.props.match.params.urlKey);
    DialogManager.clear();
    DialogManager.alert("모든 학습이 끝났습니다.", {
      buttonType: Consts.DefaultDialogViewButtonTypes.SUCCESS,
      onClose: () => {
        AppHistory.goBack(defaultRedirectPage);
      },
    });
  };

  render() {
    let title = this.props.lesson ? this.props.lesson.name : "";
    if (title && title.charAt(0) === "$") {
      title = title.substr(2);
    }

    return (
      <div id="memorize-page" className="page">
        <Navigator
          leftMenuType={LeftMenuTypes.BACK}
          title={title}
          leftMenuUrl={Urls.buildLesson(this.props.match.params.deckUrlKey)}
          leftMenuState={this.props.history.location ? this.props.history.location.state : undefined}
        />
        {this.props.isLogin === null ||
        this.props.memboxInfoRequest === Consts.REQUEST_WAITING ||
        this.props.memboxCardIdsRedux.state.request === Consts.REQUEST_WAITING ||
        this.props.deckLessonCardsRequest === Consts.REQUEST_WAITING ? (
          <Loader />
        ) : this.props.cards && this.state.cardIds ? (
          <PreviewStudyView
            lessonName={title}
            rightMemboxLevel={this.props.isLogin ? "1" : undefined}
            onMemorize={this.onCardMemorize}
            cardIds={this.state.cardIds}
            memorizedCardIds={this.state.memorizedCardIds}
            cardDataSource={this.props.cards}
            totalCardCount={this.props.cardIds.length}
            memboxInfo={this.state.memboxInfo}
            onComplete={this.onMemorizeComplete}
          />
        ) : undefined}
      </div>
    );
  }
}

const targetLevel = 1;
const PackageOrLesson = { PACKAGE: 0, LESSON: 1 };

export default connect(MemorizePage.StateToProps, MemorizePage.DispatchToProps)(MemorizePage);

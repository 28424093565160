import "core-js";
import React from "react";
import { Consts } from "./constants";
import "date-utils";
import { render } from "react-dom";
import routes from "./routes";
import { store } from "./store";
import * as Api from "./Api";

import "./styles/membox.scss";
import "font-awesome/css/font-awesome.css";

import { Provider } from "react-redux";
import axios from "axios";
import querystring from "querystring";
import { requestFcmToken, isIE } from "./utils";
import GA from "./GA";

let firebase = null;
if (!isIE()) {
  firebase = require("firebase");
}

//////////////////////////////////////////////////////////////////////////////////
// AJAX Setting                                                                 //
//////////////////////////////////////////////////////////////////////////////////
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
axios.defaults.headers.put["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
axios.defaults.headers.patch["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";

axios.defaults.transformRequest = [
  function (data, config) {
    if (data instanceof FormData) return data;
    if (config && config["Content-Type"] === "application/json") {
      if (typeof data === "object") return JSON.stringify(data);
      return data;
    }
    return querystring.stringify(data);
  },
];

//<editor-fold desc="Firebase">
var firebaseConfig = {
  apiKey: "AIzaSyC8s5_nT26xgwuFAs4mnJ2Chf3el7FdLT8",
  authDomain: "brain-memory-learning.firebaseapp.com",
  databaseURL: "https://brain-memory-learning.firebaseio.com",
  projectId: "brain-memory-learning",
  storageBucket: "brain-memory-learning.appspot.com",
  messagingSenderId: "948348010847",
  appId: "1:948348010847:web:2a06c91d854a34057936ea",
};

if (firebase) {
  firebase.initializeApp(firebaseConfig);
  const messaging = firebase.messaging();
  messaging.onTokenRefresh(function () {
    requestFcmToken();
  });

  messaging.onMessage((payload) => {
    // TODO: 메세지 수신처리
    // eslint-disable-next-line no-console
    console.log("메세지 수신", payload);
  });
}
//</editor-fold>

GA.initialize();

render(<Provider store={store}>{routes}</Provider>, document.getElementById("root"));

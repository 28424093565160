/**
 * Created by kimchangduk on 2017-08-01.
 */

import React from "react";
import PropTypes from "prop-types";
const Modernizr = window.Modernizr;

class Swipable extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    style: PropTypes.object,
    onSwipeLeft: PropTypes.func,
    onSwipeRight: PropTypes.func,
    onClick: PropTypes.func,
    swipeCheckDistance: PropTypes.number,
  };

  static defaultProps = {
    swipeCheckDistance: 25,
  };

  startX = null;
  startY = null;

  //<editor-fold desc="Touch Events">
  onTouchStart = (e) => {
    if (!Modernizr.touchevents) {
      return;
    }

    if (e.touches) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    }
  };

  onTouchEnd = (e) => {
    if (!Modernizr.touchevents) {
      return;
    }

    if (this.startX !== null && e.changedTouches && e.changedTouches.length > 0) {
      this.onHorizontalSwipeActionEnd(this.startX, e.changedTouches[0].clientX, this.startY, e.changedTouches[0].clientY);
    }
    this.startX = null;
    this.startY = null;
  };
  //</editor-fold>

  //<editor-fold desc="Mouse Events">
  onMouseDown = (e) => {
    if (Modernizr.touchevents) {
      return;
    }
    this.startX = e.clientX;
    this.startY = e.clientY;
  };

  onMouseUp = (e) => {
    if (Modernizr.touchevents) {
      return;
    }
    if (this.startX) {
      this.onHorizontalSwipeActionEnd(this.startX, e.clientX, this.startY, e.clientY);
    }
    this.startX = null;
    this.startY = null;
  };

  onMouseLeave = () => {
    this.startX = null;
  };
  //</editor-fold>

  onHorizontalSwipeActionEnd = (startX, endX, startY, endY) => {
    if (Math.abs((endY - startY) / (endX - startX)) > Math.sqrt(3)) {
      return;
    }
    if (startX - endX > this.props.swipeCheckDistance && this.props.onSwipeLeft) {
      this.props.onSwipeLeft();
    } else if (endX - startX > this.props.swipeCheckDistance && this.props.onSwipeRight) {
      this.props.onSwipeRight();
    }
  };

  render() {
    return (
      <div
        className={this.props.className}
        style={this.props.style}
        onClick={this.props.onClick}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Swipable;

/* eslint-disable react/no-danger */

/**
 * Created by kimchangduk on 2017-11-29.
 */

import React from 'react';
import PropTypes from 'prop-types';
import WordSpeaker from '../../../assets/icons/word_speaker.jpg';
import {Consts} from '../../../constants';

class Template11 extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        onVoiceClick: PropTypes.func,
        data: PropTypes.object
    };

    boldOnlyEnglish = (lines) => {
        return lines.split(Consts.BR_REGEX).map((item) => {
            return item.replace(new RegExp("([a-zA-Z]+)", "g"), "<strong>$1</strong>");
        }).join('<br/>');
    };

    insertBullet = (lines) => {
        return `<ul>${
            lines.split(Consts.BR_REGEX).map(item => {
                return `<li>${this.boldOnlyEnglish(item)}</li>`;
            }).join("\n")}</ul>`;
    };

    render() {
        const data = this.props.data;
        return (<div className={this.props.className} onClick={this.props.onClick}>
            <div className="card-container">
                <div className="card-front">
                    <article>
                        <span className="word fit fit-to-width" dangerouslySetInnerHTML={{__html: data.front}}/>
                        <div className="voicePlay position-bottom-left" data-property="voice" style={!data["voice"] ? styles.displayNone : undefined} onClick={this.props.onVoiceClick}>
                            <img className="word-speaker" src={WordSpeaker}/>
                        </div>
                    </article>
                </div>
                <div className="card-back">
                    <article>
                        <span className="back-word fit" dangerouslySetInnerHTML={{__html: data.front}}/>
                        <span className="mean fit" dangerouslySetInnerHTML={{__html: data.back}}/>
                        <div className="examples">
                            {
                                data.synonyms ?
                                    <div className="row">
                                        <div className="key box">유</div>
                                        <div className="value" dangerouslySetInnerHTML={{__html: this.boldOnlyEnglish(data.synonyms)}}/>
                                    </div> : undefined
                            }
                            {
                                data.antonyms ?
                                    <div className="row">
                                        <div className="key box">반</div>
                                        <div className="value" dangerouslySetInnerHTML={{__html: this.boldOnlyEnglish(data.antonyms)}}/>
                                    </div> : undefined
                            }
                            {
                                data.derivatives ?
                                    <div className="row">
                                        <div className="key box">파</div>
                                        <div className="value" dangerouslySetInnerHTML={{__html: this.boldOnlyEnglish(data.derivatives)}}/>
                                    </div> : undefined
                            }

                            {
                                data.freq_word ?
                                    <div className="row margin-10">
                                        <span className="key long">빈출 어휘</span>
                                        <div className="freq-word" dangerouslySetInnerHTML={{__html: this.insertBullet(data.freq_word)}}/>
                                    </div> : undefined
                            }
                        </div>
                    </article>
                    <div className="voicePlay position-bottom-left" data-property="voice" style={!data["voice"] ? styles.displayNone : undefined} onClick={this.props.onVoiceClick}>
                        <img className="word-speaker" src={WordSpeaker}/>
                    </div>
                </div>
            </div>
        </div>);
    }
}

const styles = {displayNone: {display: 'none'}};

export default Template11;
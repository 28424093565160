/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hasChangedRequestToSuccess } from "../utils";
import { Consts, Urls } from "../constants";
import Navigator, { LeftMenuTypes } from "../components/Navigator";
import Loader from "../components/Loader";
import AppHistory from "../history";
import { getCBTPaper, getCBT } from "../actions/cbt";
import GA from "../GA";

class CBTResult extends React.Component {
  static propTypes = {
    match: PropTypes.object,
    actions: PropTypes.object,
    resultId: PropTypes.number,
    resultRequest: PropTypes.string,
    resultErrorMessage: PropTypes.string,
    resultDataSource: PropTypes.object,
    cbtUrlKey: PropTypes.string,
    cbtRequest: PropTypes.string,
    cbtErrorMessage: PropTypes.string,
  };

  static StateToProps = (state, ownProps) => {
    return {
      resultId: state.cbtPaper.get.state.id,
      resultRequest: state.cbtPaper.get.state.request,
      resultErrorMessage: state.cbtPaper.get.state.errorMessage,
      resultDataSource: state.cbtPaper.get.dataSource,
      cbtUrlKey: state.cbt.state.urlKey,
      cbtRequest: state.cbt.state.request,
      cbtErrorMessage: state.cbt.state.errorMessage,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    const cbtUrlKey = ownProps.match.params.urlKey;
    const resultId = parseInt(ownProps.match.params.resultId);
    return {
      actions: {
        getCBTPaper: () => {
          dispatch(getCBTPaper(resultId));
        },
        getCBT: () => {
          dispatch(getCBT(cbtUrlKey));
        },
      },
    };
  };

  state = {
    selectedQuestionId: -1,
  };

  componentDidMount() {
    GA.logPage();
    this.props.actions.getCBTPaper();
    this.props.actions.getCBT();
  }

  renderResult = () => {
    const cbtUrlKey = this.props.match.params.urlKey;
    const resultId = parseInt(this.props.match.params.resultId);
    if (
      this.props.resultId !== resultId ||
      this.props.resultRequest !== Consts.REQUEST_SUCCESS ||
      this.props.cbtUrlKey !== cbtUrlKey ||
      this.props.cbtRequest !== Consts.REQUEST_SUCCESS
    ) {
      return undefined;
    }

    const resultDataSource = this.props.resultDataSource;
    const classSubjects = resultDataSource.classSubjects;
    return (
      <div style={styles.resultContainer}>
        <p style={styles.title}>시험 결과</p>
        <div style={styles.table}>
          <div style={styles.tableBottomBorder}>
            <div style={[styles.column, styles.column1, styles.rowHeader]}>과목</div>
            <div style={[styles.column, styles.column2, styles.rowHeader]}>점수</div>
            <div style={[styles.column, styles.column3, styles.rowHeader]}>결과</div>
          </div>
          {resultDataSource.passGrade ? (
            resultDataSource.passGrade.classSubjects.map((item, key) => {
              const classSubjectObj = classSubjects.find((a) => a.id === item.classSubjectId);
              return (
                <div key={key} style={key !== resultDataSource.passGrade.classSubjects.length - 1 ? styles.tableBottomBorder : undefined}>
                  <div style={[styles.column, styles.column1]}>{classSubjectObj && classSubjectObj.subject ? classSubjectObj.subject.name : ""}</div>
                  <div style={[styles.column, styles.column2]}>{item.score}</div>
                  <div style={[styles.column, styles.column3, item.pass ? styles.passColor : styles.notPassColor]}>{item.pass ? "합격" : "불합격"}</div>
                </div>
              );
            })
          ) : (
            <p>합격 기준 설정이 필요합니다.</p>
          )}
        </div>
        {resultDataSource.passGrade ? (
          <div style={[styles.passResult, resultDataSource.passGrade.pass ? styles.passColor : styles.notPassColor]}>
            {resultDataSource.passGrade.pass ? "합격" : "불합격"}
          </div>
        ) : undefined}

        <button
          onClick={() => {
            const cbtUrlKey = this.props.match.params.urlKey;
            const resultId = parseInt(this.props.match.params.resultId);
            AppHistory.move(Urls.buildCBTResultSolution(cbtUrlKey, resultId));
          }}
          className="plumb-button plumb-button-full plumb-button-success"
          style={styles.solutionButton}
        >
          해설 보기
        </button>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Navigator title="시험 결과" leftMenuType={LeftMenuTypes.BACK} leftMenuUrl={Urls.buildCBT(this.props.match.params.urlKey)} />
        <div style={styles.container}>
          {this.props.resultRequest === Consts.REQUEST_WAITING || this.props.cbtRequest === Consts.REQUEST_WAITING ? <Loader /> : undefined}
          {this.renderResult()}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 10,
    padding: 10,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  resultContainer: {
    backgroundColor: "#FFFFFF",
    maxWidth: 300,
    margin: "10px auto",
    borderRadius: 10,
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    padding: 10,
  },
  table: {
    border: "1px solid #ccc",
    fontSize: 14,
  },
  tableBottomBorder: {
    borderBottom: "1px solid #ccc",
  },
  rowHeader: {
    fontWeight: "bold",
    backgroundColor: "#EEEEEE",
  },
  column: {
    display: "inline-block",
    padding: 5,
  },
  column1: { width: "50%", fontWeight: "bold" },
  column2: { width: "25%" },
  column3: { width: "25%" },
  passResult: {
    padding: 10,
    fontSize: 18,
    fontWeight: "bold",
  },
  passColor: { color: "#2196F3" },
  notPassColor: { color: "#F44336" },
  solutionButton: {},
};

export default connect(CBTResult.StateToProps, CBTResult.DispatchToProps)(Radium(CBTResult));

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-04.
 */
import React from "react";
import Radium from "radium";
import ReactDOM from "react-dom";
import ExitIcon from "../assets/icons/exit.png";
import ExitBlackIcon from "../assets/icons/exit_black.png";
import PropTypes from "prop-types";
import { Consts } from "../constants";

class DefaultDialogView extends React.Component {
  static propTypes = {
    popupContentStyle: PropTypes.object,
    parentClassName: PropTypes.string,
    showCloseButton: PropTypes.bool,
    modal: PropTypes.bool,
    header: PropTypes.string,
    headerStyle: PropTypes.object,
    showHeader: PropTypes.bool,
    content: PropTypes.node,
    buttonText: PropTypes.string,
    buttonType: PropTypes.string,
    onRequestClose: PropTypes.func,
    onClose: PropTypes.func,
    panelClassName: PropTypes.string,
    panelStyle: PropTypes.object,
    footerClassName: PropTypes.string,
    footerContent: PropTypes.node,
    footerStyle: PropTypes.object,
  };

  componentDidMount() {
    window.addEventListener("click", this.onLeave);

    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur();
    }
    if (this.refs.container) {
      this.refs.container.focus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onLeave);
  }

  onLeave = (e) => {
    if (this.props.modal) {
      return;
    }
    const panelNode = ReactDOM.findDOMNode(this);
    if (panelNode && e.target === panelNode) {
      this.requestClose();
    }
  };

  requestClose = () => {
    if (this.props.onRequestClose) {
      this.props.onRequestClose();
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    let buttonClassName = "";
    let buttonContent = "닫기";

    switch (this.props.buttonType) {
      case Consts.DefaultDialogViewButtonTypes.SUCCESS:
        buttonClassName = "primary";
        buttonContent = "확인";
        break;
      case Consts.DefaultDialogViewButtonTypes.ERROR:
        buttonClassName = "secondary";
        buttonContent = "확인";
        break;
    }

    if (this.props.buttonText !== undefined) {
      buttonContent = this.props.buttonText;
    }

    return (
      <div className={`popup-view popup ${this.props.parentClassName ? this.props.parentClassName : ""}`} ref="container">
        <div className={`popup-panel default-panel ${this.props.panelClassName}`} style={this.props.panelStyle} onClick={this.onLeave}>
          {this.props.showHeader ? (
            <div className="popup-header" style={[{ textAlign: "center" }, this.props.headerStyle]}>
              {this.props.header}
              {this.props.showCloseButton ? <img src={ExitIcon} style={styles.exitIcon} onClick={this.props.onRequestClose} /> : undefined}
            </div>
          ) : this.props.showCloseButton ? (
            <img src={ExitBlackIcon} style={styles.exitBlackIcon} onClick={this.props.onRequestClose} />
          ) : undefined}
          {typeof this.props.content === "string" ? (
            <div className="popup-content" style={this.props.popupContentStyle} dangerouslySetInnerHTML={{ __html: this.props.content }} />
          ) : (
            <div className="popup-content" style={this.props.popupContentStyle}>
              {this.props.content}
            </div>
          )}
          <div className={`popup-footer ${this.props.footerClassName}`} style={this.props.footerStyle}>
            {this.props.buttonType === Consts.DefaultDialogViewButtonTypes.CUSTOM ? (
              this.props.footerContent
            ) : (
              <button className={buttonClassName} onClick={this.requestClose}>
                {buttonContent}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  exitIcon: {
    position: "absolute",
    right: 13,
    top: 13,
    cursor: "pointer",
  },
  exitBlackIcon: {
    position: "absolute",
    right: 10,
    top: 10,
    width: 15,
    cursor: "pointer",
    zIndex: 1,
  },
};

export default Radium(DefaultDialogView);

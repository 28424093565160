/**
 * Created by kimchangduk on 2017-09-05.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

class UnmarkedQuestions extends React.Component {
  static propTypes = {
    questionIndexes: PropTypes.array,
    onNavigationClick: PropTypes.func,
  };

  state = { selectedQuestionIndex: -1 };

  onNavigationClick = () => {
    if (this.props.onNavigationClick) {
      this.props.onNavigationClick(this.state.selectedQuestionIndex);
    }
  };

  render() {
    const questionIndexes = this.props.questionIndexes;
    return (
      <div>
        <strong>안 푼 문제가 있습니다.</strong>
        <div>
          <ul style={styles.unsolvedUl}>
            {questionIndexes.map((item, key) => {
              return (
                <li
                  style={[styles.unsolvedLi, this.state.selectedQuestionIndex === item ? styles.unsolvedLiSelected : false]}
                  key={key}
                  onClick={() => {
                    this.setState({ selectedQuestionIndex: item });
                  }}
                >
                  {item + 1}번 문항
                </li>
              );
            })}
          </ul>
          <button className="plumb-button plumb-button-default" onClick={this.onNavigationClick}>
            문항으로 이동
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  unsolvedUl: {
    width: 150,
    margin: "5px auto 3px auto",
    border: "1px solid rgba(0,0,0,0.4)",
    height: 160,
    overflowY: "auto",
    textAlign: "left",
  },
  unsolvedLi: {
    cursor: "pointer",
    height: 25,
    padding: 4,
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
  unsolvedLiSelected: {
    backgroundColor: "rgba(0,0,120,0.2)",
    ":hover": {
      backgroundColor: "rgba(0,0,120,0.2)",
    },
  },
};

export default Radium(UnmarkedQuestions);

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-11-03.
 */

import React from 'react';
import PropTypes from 'prop-types';
import MemboxDiv from "../../MemboxDiv";
import {escapeMemboxCharacters} from "../../../utils";
import OImage from "../../../assets/images/o_icon.png";
import XImage from "../../../assets/images/x_icon.png";
import OorXImage from "../../../assets/images/o_or_x_image.png";

class Template8 extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        openSolution: false
    };

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyPress);
    }

    onKeyPress = (e) => {
        switch (e.keyCode) {
            // ↑
            case 38:
                this.openSolution();
                break;

            // ↓
            case 40:
                this.closeSolution();
                break;
        }
    };

    computeLargeSizeAnswerStyle = (answer) => {
        if (!answer) return undefined;
        return {
            fontSize: Math.max(Math.min(150 / answer.length, 50), 20)
        };
    };

    renderOX(ox) {
        const charactor = ox;
        switch (charactor) {
            case "o":
            case "O":
            case "○":
            case "TRUE":
            case "true":
                return <img src={OImage}/>;
            default:
                return <img src={XImage}/>;
        }
    }

    openSolution() {
        if (this.refs['card'].classList.contains('flip'))
            this.refs['solutionFolder'].classList.add('open');
    }

    closeSolution() {
        if (this.refs['card'].classList.contains('flip'))
            this.refs['solutionFolder'].classList.remove('open');
    }

    onSolutionTouch = (e) => {
        e.stopPropagation();
        this.setState({openSolution: !this.state.openSolution});
    };

    render() {
        const data = this.props.data;
        const hasSolution = data.solution || data.solutionPassage;


        return (<div className={this.props.className} onClick={this.props.onClick} ref="card">
            <div className="card-container">
                <div className="card-front">
                    <article>
                        <div className="right_title" dangerouslySetInnerHTML={{__html: data.rightTitle}}/>
                        <div className="content" style={data.oxQuiz ? {bottom: 50} : undefined}>
                            {
                                data.history ? <div className="history">
                                    <span className="keyboard-key" style={styles.historyTitle}>출제</span> {data.history}
                                </div> : undefined
                            }
                            <MemboxDiv className={`question ${data.oxQuiz ? 'ox' : ''}`}
                                       dangerouslySetInnerHTML={{__html: escapeMemboxCharacters(data.question)}}/>
                            {
                                data.questionPassage ?
                                    <MemboxDiv className="question-passage"
                                               dangerouslySetInnerHTML={{__html: escapeMemboxCharacters(data.questionPassage)}}/> : undefined
                            }
                        </div>
                        {
                            data.oxQuiz ? <img className="o-or-x" style={styles.oOrXImage} src={OorXImage}/> : undefined
                        }
                    </article>
                </div>
                <div className="card-back">
                    <article>
                        {
                            data.oxQuiz ?
                                <div
                                    className={`answer ${data.oxQuiz ? 'ox' : ''}`}
                                    style={this.computeLargeSizeAnswerStyle(data.answer)}>
                                    {this.renderOX(data.answer)}
                                </div> :
                                <div
                                    className={`answer ${data.oxQuiz ? 'ox' : ''}`}
                                    style={this.computeLargeSizeAnswerStyle(data.answer)}
                                    dangerouslySetInnerHTML={{__html: escapeMemboxCharacters(data.answer)}}/>
                        }
                        {
                            data.solution || data.solutionPassage ?
                                <div className={`solution-folder ${this.state.openSolution ? 'open' : ''}`} ref="solutionFolder">
                                    <span className="solution-toggle">{this.state.openSolution ? '정답' : '해설'}</span>
                                    <div className="solution-toggle-button-area" onClick={this.onSolutionTouch}/>
                                    <div className="solution-content">
                                        {
                                            data.solution ?
                                                <MemboxDiv className="solution" dangerouslySetInnerHTML={{__html: escapeMemboxCharacters(data.solution)}}/> : undefined
                                        }
                                        {
                                            data.solutionPassage ?
                                                <MemboxDiv className="solution-passage" dangerouslySetInnerHTML={{__html: escapeMemboxCharacters(data.solutionPassage)}}/> : undefined
                                        }
                                    </div>
                                </div> : undefined
                        }
                    </article>
                </div>
            </div>
            <div className="card-posteffect"/>
        </div>);
    }
}

const styles = {
    displayNone: {display: 'none'}
};

export default Template8;
/**
 * Created by kimchangduk on 2017-08-05.
 */

import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import HowToUseSwiper from "../components/HowToUseSwiper";

class HowToUseDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func
    };

    state = {
        leaveAreaMouseDowned: false,
        carouselIndex: 0
    };

    componentDidMount() {
        window.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('mouseup', this.onMouseUp);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    onMouseDown = (e) => {
        this.setState({leaveAreaMouseDowned: e.target === this.getThisElement()});
    };

    onMouseUp = (e) => {
        if (e.target === this.getThisElement() && this.state.leaveAreaMouseDowned) {
            this.onCloseClick();
        }
        this.setState({leaveAreaMouseDowned: false});
    };

    onCloseClick = () => {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    getThisElement() {
        return ReactDOM.findDOMNode(this);
    }

    render() {
        return (<div className="popup-view slider-popup-view popup">
                <div className="popup-panel">
                    <HowToUseSwiper/>
                    <button className="slide-popup-close-button page-actor" onClick={this.onCloseClick}><i className="fa fa-times fa-fw"/></button>
                </div>
            </div>
        );
    }
}

const styles = {
    width320: {width: 320},
    displayNone: {display: 'none'}
};

export default HowToUseDialog;
/**
 * Created by kimchangduk on 2017-07-25.
 */
import {ActionTypes} from '../constants';
import * as Api from '../Api';

export function getCategoryList() {
    return dispatch => {
        dispatch({type: ActionTypes.GET_CATEGORY_LIST_REQUEST});
        Api.getCategoryList().then(response => {
            dispatch({type: ActionTypes.GET_CATEGORY_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_CATEGORY_LIST_FAILURE, errorMessage: '카테고리 정보를 가져올 수 없습니다.'});
        });
    };
}

export function getCategory(id) {
    return dispatch => {
        dispatch({type: ActionTypes.GET_CATEGORY_REQUEST, id});
        Api.getCategory(id).then(response => {
            dispatch({type: ActionTypes.GET_CATEGORY_SUCCESS, data: response.data, id});
        }).catch(error => {
            dispatch({type: ActionTypes.GET_CATEGORY_FAILURE, errorMessage: '카테고리 정보를 가져올 수 없습니다.', id});
        });
    };
}
/**
 * Created by kimchangduk on 2017-08-04.
 */

import React from 'react';
import PropTypes from 'prop-types';
import DefaultDialogView from "./DefaultDialogView";
import EbbinghausImage from '../assets/images/about_ebbinghaus.png';
import GraphImage from '../assets/images/about_graph.png';

class AboutMemboxDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func
    };

    render() {
        const content = (<div>
            <div className="scroll-view-container" style={{overflow: 'auto', transitionTimingFunction: 'cubic-bezier(0.1, 0.57, 0.1, 1)', transitionDuration: '0ms', transform: 'translate(0px, 0px) translateZ(0px)'}}>
                <article>
                    <h1>기억상자란?</h1>
                    <p>아무리 깜지를 쓰고, 달달 말해보고, 별 짓을 해도 암기가 되지 않는다?</p>
                    <p>기억상자는 에빙하우스의 망각곡선의 이론에 입거한 최적의 암기효율을 유도하는 프로그램입니다. 각 학습상자에 들어있는 항목들을 Alarming Program을 통해 특정한 시간대에 지속적으로 학습을 유도하여 모든 학습 항목들을 장기기억까지 유도, 활용하는데 그 목적이 있습니다.</p>
                    <p>본 프로그램의 이론적 근거는 아래와 같습니다.</p>
                    <p style={{float: 'left', margin: 10}}><img src={EbbinghausImage} alt="(인물사진)Ebbinghaus, 1855~1909"/><br/><span style={{fontSize: '0.8em'}}>Ebbinghaus, 1855~1909</span></p>
                    <p>인간의 기억을 연구했던 독일의 심리학자 헤르만 에빙하우스(Ebbinghaus)에 따르면 기억은 학습 후 10분 후부터 망각이 시작되며 1시간이 지나면 약 45%, 하루가 지나면 약 35%의 기억만 남는다고 합니다. 이렇게 시간에 비례해 망각되는 학습 내용을 장기기억 영역에 영구히 보존하여 평생 사용하기 위해서는 적절한 반복 학습이 필요합니다.</p>
                    <p style={styles.textAlignCenter}><img src={GraphImage} alt="망각곡선 그래프" style={{width: '92%'}}/></p>
                    <p><span style={styles.letterSpacingLeft1Percent}>예를 들어, 학습을 마치면 그 내용들이 ‘1시간’ 기억상자로 들어오는데, 프로그램이 ‘1시간’ 기억상자에 들어온 시간을 기억하고 있다가 1시간 후에 자동으로 반복 학습을 유도합니다. ‘1시간’ 기억상자에 저장된 내용 중 알고 있는 내용은 ‘하루’ 기억상자로 이동하고 모르는 내용은 ‘1시간’ 기억상자에 그대로 남아 다시 1시간 후에 복습할 수 있도록 합니다. </span>하루 후에 마찬가지로 ‘하루’ 기억상자에 저장된 내용을 학습하여, 알고 있는 내용은 ‘일주일’ 기억상자로 이동하고 모르는 내용은 ‘1시간’ 기억상자로 돌아가 다시 1시간 후에 복습할 수 있도록 합니다.</p>
                    <p><span style={styles.letterSpacingLeft1Percent}>이런 방식으로 ‘하루’ -&gt; ‘일주일’ -&gt; ‘한 달’ 기억상자를 거쳐 ‘장기기억’ 기억상자에 도달하면 6개월에 한 번씩 반복 학습을 유도하므로 기억이 강화되어 평생 활용할 수 있는 장기기억 영</span>역에 쌓이게 됩니다.</p>
                    <p>저희 프로그램은 이러한 효율적인 암기뿐만 아니라, 학습 중에도 알고리즘을 통해 쉽게 암기되지 않는 아이템을 점진적으로 출력 빈도를 올림으로써 장기기억을 활용함에 신기원을 이루었습니다. </p>
                    <p>시험뿐만이 아니라 시사, 상식 등의 모든 분야의 암기에서 고통 받는 이들을 구원해 줄 「기억상자」 많은 이용 부탁드립니다. :)</p>
                    <p/>
                </article>
            </div>
        </div>);

        return (<DefaultDialogView onRequestClose={this.props.onRequestClose} parentClassName="page-popup-view" content={content} popupContentStyle={styles.popupContentStyle}/>);
    }
}

const styles = {
    letterSpacingLeft1Percent: {letterSpacing: '-1%'},
    textAlignCenter: {textAlign: 'center'},
    popupContentStyle: {
        overflow: 'auto'
    }
};

export default AboutMemboxDialog;
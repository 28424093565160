import React, { useRef, useEffect } from "react";
import "./AdFitCard.scss";

export default function AdFitHorizontal({ mobile = true }: { mobile: boolean }) {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let scr = document.createElement("script");
    scr.setAttribute("async", "true");
    scr.setAttribute("type", "text/javascript");
    scr.setAttribute("src", "//t1.daumcdn.net/kas/static/ba.min.js");
    if (containerRef?.current) {
      containerRef?.current?.append(scr);
    }
  }, []);
  const config = mobile
    ? {
        apiKeyKey: "ADFIT_MOBILE_STUDY_PAGE_UNIT",
        width: "320",
        height: "50",
      }
    : {
        apiKeyKey: "ADFIT_STUDY_PAGE_UNIT",
        width: "728",
        height: "90",
      };
  return (
    <div className={`adfit-horizontal-container`} ref={containerRef}>
      <div className={`kakao-horizontal`}>
        <ins
          className="kakao_ad_area"
          style={{ display: "none" }}
          data-ad-unit={(window as any)[config.apiKeyKey]}
          data-ad-width={config.width}
          data-ad-height={config.height}
        ></ins>
      </div>
    </div>
  );
}

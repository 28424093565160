/**
 * Created by kimchangduk on 2017-07-23.
 */

import * as Api from "../Api";
import { processHttpError } from "../actions/utils";
import { ActionTypes, Strings, Consts } from "../constants";
import { getMessageFromResponse, requestFcmToken, deleteFcmToken, callLogoutInterface } from "../utils";

export function getUser(action) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_USER_REQUEST });
    Api.getUser()
      .then((response) => {
        dispatch({ type: ActionTypes.GET_USER_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        dispatch({ type: ActionTypes.GET_USER_FAILURE });
      });
  };
}

export function login(email, password) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_WITH_FACEBOOK_REQUEST });
    Api.login(email, password)
      .then((response) => {
        requestFcmToken();
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, Strings.FAILED_TO_LOGIN);
        dispatch({ type: ActionTypes.LOGIN_WITH_FACEBOOK_FAILURE, errorMessage });
      });
  };
}

export function loginWithFacebook(token) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_REQUEST });
    Api.loginWithFacebook(token)
      .then((response) => {
        requestFcmToken();
        dispatch({ type: ActionTypes.LOGIN_WITH_FACEBOOK_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, Strings.FAILED_TO_LOGIN);
        dispatch({ type: ActionTypes.LOGIN_FAILURE, errorMessage });
      });
  };
}

export function loginWithToken(email, token) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_REQUEST });
    return Api.loginWithToken(email, token)
      .then((response) => {
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, "로그인에 실패했습니다.");
        dispatch({ type: ActionTypes.LOGIN_FAILURE, errorMessage: errorMessage });
      });
  };
}

export function logout() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGOUT_REQUEST });
    Api.logout()
      .then((response) => {
        deleteFcmToken();
        callLogoutInterface();
        dispatch({ type: ActionTypes.LOGOUT_SUCCESS });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        callLogoutInterface();
        dispatch({ type: ActionTypes.LOGOUT_FAILURE });
      });
  };
}

export function changeProfile(params) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.EDIT_USER_REQUEST });
    return Api.changeProfile(params)
      .then((response) => {
        dispatch({ type: ActionTypes.EDIT_USER_SUCCESS, data: response.data });
      })
      .catch((error) => {
        processHttpError(dispatch, error);
        let errorMessage = getMessageFromResponse(error.response, "변경사항 적용에 실패했습니다.");
        dispatch({ type: ActionTypes.EDIT_USER_FAILURE, errorMessage: errorMessage });
      });
  };
}

export function setShortTermMemoryLevel(shortTermMemoryLevel) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_SHORT_TERM_MEMORY_LEVEL, shortTermMemoryLevel });
    Api.editUserData(shortTermMemoryLevel);
  };
}

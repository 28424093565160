/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-11-28.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {combineStrings, isMobileDevice} from "../../utils";

class FoldableDiv extends React.Component {
    static propTypes = {
        defaultFold: PropTypes.bool,
        content: PropTypes.string
    };

    static defaultProps = {
        defaultFold: true,
        content: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            fold: !!this.props.defaultFold
        };
    }

    toggleFoldState = () => {
        this.setState({fold: !this.state.fold});
    };

    render() {
        const pTagReplaceRegex = new RegExp("</p>");
        const splitedStr = this.props.content !== null && this.props.content !== undefined ? this.props.content.replace(pTagReplaceRegex, "</p><br/>").split('<br/>') : null;
        const maxLineCount = 1;
        const isOverflow = splitedStr ? splitedStr.length > maxLineCount : false;
        let content;
        if (this.state.fold && isOverflow) {
            content = combineStrings(splitedStr, '<br>', maxLineCount).trim();
            if (content.endsWith("</p>")) {
                content = content.substr(0, content.length - "</p>".length) + "...</p>";
            } else {
                content += '...';
            }
        } else {
            content = this.props.content;
        }

        return (<div className={`foldable-div ${isOverflow ? 'overflow' : ''}`} onClick={isOverflow ? this.toggleFoldState : undefined}>
            <div dangerouslySetInnerHTML={{__html: content}}/>
            {
                isOverflow ?
                    (
                        this.state.fold ?
                            <i className="fa fa-angle-down fold-toggle-icon" aria-hidden="true"/> :
                            <i className="fa fa-angle-up fold-toggle-icon" aria-hidden="true"/>
                    )
                    : undefined
            }
        </div>);
    }
}

export default FoldableDiv;
import { ActionTypes, Consts, Strings } from "../constants";
import update from "immutability-helper";

const initialState = {
  history: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
      page: -1,
      last: false,
    },
    dataSource: null,
  },
};

export default function purchaseReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_PURCHASE_HISTORY_REQUEST: {
      const updateObj = {
        history: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
        },
      };
      if (action.page === 0) {
        updateObj.history.dataSource = { $set: null };
      }
      return update(state, updateObj);
    }
    case ActionTypes.GET_PURCHASE_HISTORY_SUCCESS:
      return update(state, {
        history: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
            errorMessage: { $set: "" },
            page: { $set: action.page },
            last: { $set: action.data.last },
          },
          dataSource: action.page === 0 || state.history.dataSource === null ? { $set: action.data.content } : { $push: action.data.content },
        },
      });

    case ActionTypes.GET_PURCHASE_HISTORY_FAILURE:
      return update(state, {
        history: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: null },
        },
      });
  }
  return state;
}

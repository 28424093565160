/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-23.
 */

import React from "react";
import update from "immutability-helper";
import Radium from "radium";
import PropTypes from "prop-types";
import "./CBTQuestions.scss";
import { isMobileDevice, escapeMemboxCharacters, getFontScaledStyle } from "../../utils";
import MemboxDiv from "../MemboxDiv";

class Question extends React.Component {
  static defaultProps = {
    fontScale: 1,
    showRightSign: false,
  };

  static propTypes = {
    fontScale: PropTypes.number,
    style: PropTypes.object,
    question: PropTypes.object,
    answer: PropTypes.number,
    rightAnswer: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))]),
    dummyAnswers: PropTypes.array,
    onAnswerClick: PropTypes.func,
    onDummyAnswerClick: PropTypes.func,
    questionIndex: PropTypes.number,
    showRightAnswer: PropTypes.bool,
    showRightSign: PropTypes.bool,
    showWrongSign: PropTypes.bool,
    solution: PropTypes.string,
    advice: PropTypes.string,
    joinedStudying: PropTypes.string,
    showSolution: PropTypes.bool,
  };

  state = {
    visibleSolution: true,
    showRightSign: false,
    showWrongSign: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const propKeys = [
      "fontScale",
      "style",
      "question",
      "answer",
      "rightAnswer",
      "dummyAnswers",
      "onAnswerClick",
      "onDummyAnswerClick",
      "questionIndex",
      "showRightAnswer",
      "showRightSign",
      "solution",
      "showSolution",
    ];
    for (let key of propKeys) {
      if (this.props[key] !== nextProps[key]) {
        return true;
      }
    }

    return false;
  }

  getExampleNumberCharacter = (n, marked) => {
    if (typeof n === "string") n = parseInt(n);
    if (marked) {
      return "●";
    }

    switch (n) {
      case 1:
        return "①";
      case 2:
        return "②";
      case 3:
        return "③";
      case 4:
        return "④";
      case 5:
        return "⑤";
      case 6:
        return "⑥";
      case 7:
        return "⑦";
      case 8:
        return "⑧";
      case 9:
        return "⑨";
      case 10:
        return "⑩";
      case 11:
        return "⑪";
      case 12:
        return "⑫";
      case 13:
        return "⑬";
      case 14:
        return "⑭";
      case 15:
        return "⑮";
    }
  };

  checkRightAnswer(exampleId, answer) {
    if (Array.isArray(answer)) {
      return answer.findIndex((a) => a.includes(exampleId)) >= 0;
    } else {
      return exampleId === answer;
    }
  }

  getAnswerCharactors() {
    // this.getExampleNumberCharacter(question.example.findIndex(a => a.id === this.props.rightAnswer) + 1)
    const answers = [];
    if (Array.isArray(this.props.rightAnswer)) {
      for (let item1 of this.props.rightAnswer) {
        for (let item2 of item1) {
          const answerIndex = this.props.question.example ? this.props.question.example.findIndex((a) => a.id === item2) : null;
          if (answerIndex + 1 > 0 && !answers.includes(answerIndex + 1)) answers.push(answerIndex + 1);
        }
      }
    } else if (this.props.rightAnswer !== null && this.props.rightAnswer !== undefined) {
      answers.push(this.props.question.example.findIndex((a) => a.id === this.props.rightAnswer) + 1);
    }

    let result = "";
    for (let answer of answers) {
      if (result) result += ", ";
      result += this.getExampleNumberCharacter(answer);
    }
    return result;
  }

  solutionAutoPadding = (str) => {
    if (!str) {
      return str;
    }
    const splited = str.split("<br/>");
    let result = "";
    for (let i in splited) {
      let line = splited[i];
      const trimed = this.trimStart(line);
      switch (trimed.charAt(0)) {
        case "ㆍ":
          line = '<span class="indent-2">' + trimed + "</span>";
          break;
        case "-":
          line = '<span class="indent-4">' + trimed + "</span>";
          break;
        case "▶":
          line = '<span class="indent-6">' + trimed + "</span>";
          break;
        case "①":
        case "②":
        case "③":
        case "④":
        case "⑤":
        case "⑥":
        case "⑦":
        case "⑧":
        case "⑨":
        case "⑩":
        case "⑪":
        case "⑫":
        case "⑬":
        case "⑭":
        case "⑮":
        case "⑯":
        case "⑰":
        case "⑱":
        case "⑲":
        case "⑳":
          line = '<span class="indent-2">' + trimed + "</span>";
          break;
      }

      result += `<div style="margin-bottom: 5px;">${line}</div>`;
    }
    return result;
  };

  trimStart = (string, character = " ") => {
    let startIndex = 0;
    while (string[startIndex] === character) {
      startIndex++;
    }

    return string.substr(startIndex);
  };

  render() {
    const question = this.props.question;
    const questionIndex = this.props.questionIndex;
    const isMobile = isMobileDevice();
    let exampleContentStyle = getFontScaledStyle(isMobile ? styles.exampleContent.mobile : styles.exampleContent.pc, this.props.fontScale);
    exampleContentStyle = update(exampleContentStyle, {
      maxWidth: { $set: `calc(100% - ${exampleContentStyle.fontSize + 13}px)` },
    });

    return (
      <div style={[getFontScaledStyle(isMobile ? styles.question.mobile : styles.question.pc, this.props.fontScale), this.props.style]} className="question">
        <div>
          <span style={getFontScaledStyle(isMobile ? styles.questionNumber.mobile : styles.questionNumber.pc, this.props.fontScale)}>{questionIndex + 1}.</span>
          <div style={getFontScaledStyle(isMobile ? styles.questionContent.mobile : styles.questionContent.pc)}>
            <div dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(question.content) }} />
            {question.fingerprint ? (
              <div>
                <div className="border-block" dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(question.fingerprint) }} />
              </div>
            ) : undefined}
          </div>
          {this.props.showRightSign ? <div style={styles.rightSign} /> : undefined}
          {this.props.showWrongSign ? <div style={styles.wrongSign} /> : undefined}
        </div>

        {
          /* 보기 출력 */
          question.example.map((item, key) => {
            const dummyMarked = this.props.dummyAnswers && this.props.dummyAnswers.find((a) => a.id === item.id).marked;
            const rightAnswerMarked = this.props.showRightAnswer && this.checkRightAnswer(item.id, this.props.rightAnswer);
            const userAnswerMarked = this.props.answer === item.id;
            const marked = dummyMarked || rightAnswerMarked || userAnswerMarked;

            let color = null;
            if (dummyMarked) {
              color = "#0000FF";
            } else if (userAnswerMarked) {
              color = "#000000";
            } else if (rightAnswerMarked) {
              color = "#FF0000";
            }

            return (
              <div key={key} style={[getFontScaledStyle(isMobile ? styles.example.mobile : styles.example.pc, this.props.fontScale)]}>
                <div
                  style={{ display: "inline" }}
                  onClick={() => {
                    if (this.props.onAnswerClick) {
                      this.props.onAnswerClick(question.id, item.id);
                    }
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    if (this.props.onDummyAnswerClick) this.props.onDummyAnswerClick(question.id, item.id);
                  }}
                >
                  <span style={[getFontScaledStyle(isMobile ? styles.exampleNumber.mobile : styles.exampleNumber.pc, this.props.fontScale), { color }]}>
                    {this.getExampleNumberCharacter(key + 1, marked)}
                  </span>
                  <div style={exampleContentStyle} dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(item.content) }} />
                </div>
              </div>
            );
          })
        }
        {this.props.showRightAnswer ? <p style={styles.answer}>정답: {this.getAnswerCharactors()}</p> : undefined}
        {this.props.showSolution ? (
          <div>
            <span style={styles.solutionButton}>해설 {this.state.visibleSolution ? "▼" : "▶"}</span>
            {this.state.visibleSolution ? (
              <div className="solution" style={styles.solution}>
                <MemboxDiv
                  style={styles.margin10}
                  dangerouslySetInnerHTML={{ __html: this.solutionAutoPadding(escapeMemboxCharacters(this.props.solution)) }}
                />
                <MemboxDiv style={styles.margin10} dangerouslySetInnerHTML={{ __html: this.solutionAutoPadding(escapeMemboxCharacters(this.props.advice)) }} />
                <MemboxDiv
                  style={styles.margin10}
                  dangerouslySetInnerHTML={{ __html: this.solutionAutoPadding(escapeMemboxCharacters(this.props.joinedStudying)) }}
                />
              </div>
            ) : undefined}
          </div>
        ) : undefined}
      </div>
    );
  }
}

const styles = {
  question: {
    pc: {
      fontSize: 16,
      marginBottom: 20,
      textAlign: "left",
      position: "relative",
    },
    mobile: {
      fontSize: 17,
      marginBottom: 20,
      textAlign: "left",
      position: "relative",
    },
  },
  questionContent: {
    pc: {
      display: "inline-block",
      width: "calc(100% - 34px)",
      verticalAlign: "top",
      marginBottom: 5,
      whiteSpace: "normal",
    },
    mobile: {
      display: "inline-block",
      width: "calc(100% - 40px)",
      verticalAlign: "top",
      marginBottom: 10,
      whiteSpace: "normal",
    },
  },
  questionNumber: {
    pc: {
      width: 34,
    },
    mobile: {
      width: 34,
    },
  },
  answer: {
    textAlign: "center",
    padding: 10,
    fontWeight: "bold",
    fontSize: 19,
    color: "#FF0000",
  },
  example: {
    pc: {
      paddingLeft: 15,
      whiteSpace: "normal",
      marginBottom: 3,
      lineHeight: "1em",
    },
    mobile: {
      paddingLeft: 15,
      whiteSpace: "normal",
      marginBottom: 5,
      lineHeight: "1em",
    },
  },
  exampleNumber: {
    pc: {
      cursor: "pointer",
      position: "relative",
      verticalAlign: "top",
      width: 17,
      fontSize: 16,
      marginTop: 2,
      display: "inline-block",
    },
    mobile: {
      cursor: "pointer",
      position: "relative",
      verticalAlign: "top",
      width: 20,
      fontSize: 18,
      marginTop: 2,
      display: "inline-block",
    },
  },
  exampleContent: {
    pc: {
      display: "inline-block",
      maxWidth: "calc(100% - 20px)",
      marginLeft: 3,
      fontSize: 16,
      fontWeight: 300,
      cursor: "pointer",
      overflow: "hidden",
      paddingTop: 1,
    },
    mobile: {
      display: "inline-block",
      maxWidth: "calc(100% - 22px)",
      marginLeft: 3,
      fontSize: 18,
      fontWeight: 300,
      cursor: "pointer",
      overflow: "hidden",
      lineHeight: "1.3em",
    },
  },
  exampleMarked: {
    display: "inline-block",
    position: "absolute",
    left: 0,
    top: "calc(50% - 3px)",
    backgroundColor: "#FFFFFF",
    width: 4,
    height: 6,
  },
  rightSign: {
    border: "2px solid #FF0000",
    borderRadius: "50%",
    width: 50,
    height: 50,
    position: "absolute",
    left: -18,
    top: -15,
  },
  wrongSign: {
    backgroundColor: "#FF0000",
    width: 2,
    height: 50,
    position: "absolute",
    left: 3,
    top: -15,
    transform: "rotate(30deg)",
  },
  solutionButton: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  solution: {
    marginTop: 5,
    whiteSpace: "normal",
    fontWeight: 300,
  },
  margin10: {
    marginBottom: 10,
  },
};

export default Radium(Question);

/**
 * Created by kimchangduk on 2017-04-24.
 */
/* eslint-disable no-useless-escape */

export const OK = '확인';
export const ADD = '추가';
export const DELETE = '삭제';
export const REPORT = '신고';
export const CLEAR = '지우기';
export const SAVE = '저장';
export const COPY = '프로젝트 복사';
export const EDIT = '수정';
export const CLOSE = '닫기';
export const WRITE = '작성';
export const CANCEL = '취소';
export const SHARE = '공유';
export const PASSWORD_CHANGE = '비밀번호 변경';
export const CONFIRM_DELETE = '삭제하시겠습니까?';
export const CONFIRM_DELETE_REALLY = '정말로 삭제하시겠습니까?';
export const SIGN_UP = '회원가입';
export const SIGN_IN = '로그인';
export const INVALID_DATE_FORMAT = "날짜는 1900-01-01 형식으로 하셔야 합니다.";
export const FAILED_TO_CONVERT_DATE = "날짜 변환에 실패했습니다.";

//<editor-fold desc="Profile, Sign Up, Sign In">
export const PROFILE = '프로필';
export const USERNAME = '아이디';
export const EMAIL = '이메일';
export const PASSWORD = '비밀번호';
export const CURRENT_PASSWORD = '현재 비밀번호';
export const NEW_PASSWORD = '새 비밀번호';
export const VERIFY_PASSWORD = '새 비밀번호 확인';

export const PASSWORD_IS_EMPTY = '현재 비밀번호를 입력하십시오.';
export const NEW_PASSWORD_IS_EMPTY = '변경할 비밀번호를 입력하십시오.';
export const EMAIL_IS_EMPTY = 'Email을 입력해주십시오.';
export const USERNAME_IS_EMPTY = 'Username을 입력해주십시오.';

export const NOT_EQUALS = '일치하지 않습니다.';
export const NOT_EQUALS_NEW_PASSWORD = '새로운 비밀번호가 일치하지 않습니다.';

export const NOT_CHANGED = '변경사항이 없습니다.';

export const FAILED_TO_SAVE_USER = '사용자 정보 수정에 실패했습니다.';
export const FAILED_TO_LOAD_DATA = '데이터를 불러오는 데 실패했습니다.';
export const FAILED_TO_EDIT_DATA = '데이터를 수정하는 데 실패했습니다.';
export const FAILED_TO_ADD_DATA = '데이터를 추가하는 데 실패했습니다.';
export const FAILED_TO_SUBSCRIBE = '구독에 실패했습니다.';
export const FAILED_TO_UNSUBSCRIBE = '구독 취소에 실패했습니다.';
export const FAILED_TO_DELETE = '삭제에 실패했습니다.';
export const FAILED_TO_SIGN_UP = '회원가입에 실패했습니다.';
export const FAILED_TO_LOAD_USER = '사용자 데이터를 불러오는 데에 실패했습니다.';
export const FAILED_TO_LOGIN = '로그인에 실패했습니다.';
export const FAILED_TO_DELETE_ACCOUNT = '회원탈퇴에 실패했습니다.';

export const SUCCESS_TO_UPDATED = '수정 성공';
export const SUCCESS_TO_PASSWORD_EDIT = '비밀번호 수정 성공';
export const FAILED_TO_PASSWORD_EDIT = '비밀번호 수정 실패';
export const SUCCESS_TO_DELETE = '삭제에 성공했습니다.';
//</editor-fold>

export const getMemboxLevelCaption = (level) => {
    switch (level.toString()) {
        case "1":
            return "1시간";
        case "2":
            return "하루";
        case "3":
            return "일주일";
        case "4":
            return "한달";
        case "5":
            return "장기기억";
    }
    return undefined;
};

export const buildDateString = (time) => {
    const dateTime = new Date(time);
    const hours = dateTime.getHours();
    return `${dateTime.toFormat('YYYY-MM-DD')} ${hours >= 12 ? '오후' : '오전'} ${dateTime.toFormat('HH24:MI:SS')}`;
};

export const NO_RESULTS = "검색 결과가 없습니다.";
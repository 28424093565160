/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from "react";
import update from "immutability-helper";
import { Urls, Consts, Strings } from "../constants";
import PropTypes from "prop-types";
import AppHistory from "../history";
import { getTotalDeckMemboxInfo, getTotalMemboxLevelCardIds, getMemboxTotalInfo, getDeckMemboxLevelCardIds, memorize, getMemboxCards } from "../actions/membox";
import { connect } from "react-redux";
import Navigator, { LeftMenuTypes } from "../components/Navigator";
import PreviewStudyView from "../components/PreviewStudyView";
import GA from "../GA";
import Loader from "../components/Loader";
import { decreaseShortTermMemoryLevel, getShortTermMemoryLevel, hasChangedRequestToSuccess, increaseShortTermMemoryLevel } from "../utils";
import DialogManager from "../dialogs/DialogManager";

class RemindPage extends React.Component {
  static propTypes = {
    shuffle: PropTypes.bool,
    history: PropTypes.object,
    actions: PropTypes.object,
    cards: PropTypes.array,
    match: PropTypes.object,
    memboxInfoRedux: PropTypes.object,
    levelCardIdsRedux: PropTypes.object,
    levelCardIds: PropTypes.array,
    cardIdsLast: PropTypes.bool,
    getCardRequest: PropTypes.string,
    learnMemboxState: PropTypes.string, // WAIT 일경우 preview 모드
    debugMode: PropTypes.bool,
    currentPageLastCardId: PropTypes.number,
  };

  static StateToProps = (state, ownProps) => {
    const deckUrlKey = ownProps.match.params.deckUrlKey;
    const level = parseInt(ownProps.match.params.level);

    const memboxInfoRedux = !deckUrlKey ? state.membox.total.info : state.membox.deck.info;
    const levelCardIdsRedux = !deckUrlKey ? state.membox.total.level : state.membox.deck.level;
    let levelCardIds = levelCardIdsRedux.state.level === level ? levelCardIdsRedux.dataSource : null;
    if (levelCardIds) {
      if (levelCardIds[level]) {
        levelCardIds = levelCardIds[level][ownProps.learnMemboxState];
      } else {
        levelCardIds = null;
      }
    }
    return {
      memboxInfoRedux: memboxInfoRedux,
      levelCardIdsRedux,
      levelCardIds,
      cardIdsLast: !deckUrlKey
        ? state.membox.total.level.state.last
        : state.membox.deck.level.state.level === level && state.membox.deck.level.state.request === Consts.REQUEST_SUCCESS,
      cards: state.membox.cards.dataSource,
      currentPageLastCardId: !deckUrlKey ? state.membox.total.level.state.currentPageLastCardId : null, // 전체 기억상자 복습 페이지 학습때에만 사용
      getCardRequest: state.membox.cards.state.request,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    const deckUrlKey = ownProps.match.params.deckUrlKey;
    const level = parseInt(ownProps.match.params.level);

    return {
      actions: {
        getInfo: () => {
          if (deckUrlKey) {
            dispatch(getTotalDeckMemboxInfo(deckUrlKey));
          } else {
            dispatch(getMemboxTotalInfo());
          }
        },
        getCardIds: (startCardId, append) => {
          if (deckUrlKey) {
            dispatch(getDeckMemboxLevelCardIds(deckUrlKey, level, ownProps.learnMemboxState, ownProps.shuffle));
          } else {
            dispatch(getTotalMemboxLevelCardIds(level, ownProps.learnMemboxState, startCardId, append, ownProps.shuffle));
          }
        },
        memorize: (targetLevel, id) => {
          dispatch(memorize(level, targetLevel, id));
        },
        getCards: (cardIds, append) => {
          dispatch(getMemboxCards(cardIds, append));
        },
      },
    };
  };

  state = {
    memboxInfo: null,
    memorizedCardIds: [],
    levelCardIds: [],
    enableTimeOver: true,
  };

  componentDidMount() {
    this.reservedMoveBackCardIds = [];
    GA.logPage();
    this.props.actions.getInfo();
    this.props.actions.getCardIds();
  }

  componentWillReceiveProps(nextProps) {
    if (hasChangedRequestToSuccess(this.props.memboxInfoRedux.state.request, nextProps.memboxInfoRedux.state.request)) {
      this.setState({
        memboxInfo: this.props.learnMemboxState === Consts.MEMORIZE_STATE_READY ? JSON.parse(JSON.stringify(nextProps.memboxInfoRedux.dataSource)) : {}, // deep copy
      });
    }

    if (hasChangedRequestToSuccess(this.props.levelCardIdsRedux.state.request, nextProps.levelCardIdsRedux.state.request)) {
      const deckUrlKey = this.props.match.params.deckUrlKey;

      //<editor-fold desc="현재 기억상자가 비었는지 체크">
      if (!nextProps.levelCardIds || nextProps.levelCardIds.length <= 0) {
        const urlKey = this.props.match.params.deckUrlKey;
        const defaultRedirectPage = urlKey ? Urls.buildLesson(urlKey) : Urls.ROOT;
        DialogManager.alert(`${this.props.learnMemboxState === Consts.MEMORIZE_STATE_WAIT ? "확인" : "복습"}할 내용이 없습니다.`, {
          buttonText: "돌아가기",
          buttonType: Consts.DefaultDialogViewButtonTypes.ERROR,
          onClose: () => {
            AppHistory.goBack(defaultRedirectPage);
          },
        });
        this.setState({ enableTimeOver: false });
      }
      //</editor-fold>

      //<editor-fold desc="level Card Id 정보를 받아오면 Card 데이터를 받아올지 체크">
      if (deckUrlKey || nextProps.levelCardIdsRedux.state.startCardId === null || nextProps.levelCardIdsRedux.state.startCardId === undefined) {
        this.props.actions.getCards(
          nextProps.levelCardIds.filter((item, index) => index < cardLoadLeastCount),
          false
        );
      } else {
        this.checkLoadMoreCards(undefined, nextProps.cards, this.state.levelCardIds, nextProps.getCardRequest);
      }
      //</editor-fold>

      //<editor-fold desc="Level Card Id 받아온 데이터를 state에 반영">
      const updateValue = {};
      let newLevelCardIds = null;
      if (deckUrlKey || nextProps.levelCardIdsRedux.state.startCardId === null || nextProps.levelCardIdsRedux.state.startCardId === undefined) {
        this.reservedMoveBackCardIds = [];
        newLevelCardIds = update(nextProps.levelCardIds, {});
      } else if (this.props.levelCardIds && nextProps.levelCardIds && nextProps.levelCardIds.length > this.props.levelCardIds.length) {
        const addedIds = nextProps.levelCardIds.slice(this.props.levelCardIds.length);
        newLevelCardIds = update(this.state.levelCardIds, { $push: addedIds });
      }
      //</editor-fold>

      if (newLevelCardIds) {
        this.reservedMoveBackCardIds = this.reservedMoveBackCardIds.sort((a, b) => a - b);
        for (let index in this.reservedMoveBackCardIds) {
          const moveBackCardId = this.reservedMoveBackCardIds[index];
          if (moveBackCardId.index > newLevelCardIds.length) {
            break;
          }
          newLevelCardIds.splice(moveBackCardId.index - 1, 0, moveBackCardId.cardId);
        }
        this.reservedMoveBackCardIds = this.reservedMoveBackCardIds.filter((a) => a.index > newLevelCardIds.length);
      }

      // 만약 last page면 reservedMoveId들을 그냥 맨뒤로 보내기
      if (
        nextProps.levelCardIdsRedux.state.startCardId !== null &&
        nextProps.levelCardIdsRedux.state.startCardId !== undefined &&
        !this.props.levelCardIdsRedux.state.last &&
        nextProps.levelCardIdsRedux.state.last
      ) {
        newLevelCardIds = update(newLevelCardIds ? newLevelCardIds : this.state.levelCardIds, { $push: this.reservedMoveBackCardIds.map((a) => a.cardId) });
        this.reservedMoveBackCardIds = [];
      }

      if (newLevelCardIds) {
        this.setState({
          levelCardIds: newLevelCardIds,
        });
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    // 기억상자로 이동이 되면 id와 카드를 더불러올지 체크
    if (this.state.memorizedCardIds !== nextState.memorizedCardIds || this.state.levelCardIds.length > nextState.levelCardIds.length) {
      this.checkLoadMoreCardIds(
        nextState.memorizedCardIds,
        nextState.levelCardIds,
        nextProps.levelCardIdsRedux,
        nextProps.cardIdsLast,
        nextProps.currentPageLastCardId
      );
      this.checkLoadMoreCards(nextState.memorizedCardIds, nextProps.cards, nextState.levelCardIds, nextProps.getCardRequest);
    }
  }

  reservedMoveBackCardIds = []; // /remind 페이지에서는 쓰이지 않는다.

  movedBackCardIds = []; // 한번이라도 뒤로 넘긴 카드의 id가 들어감

  // 기억상자 이동할때마다 체크
  checkLoadMoreCardIds = (nextMemorizedCardIds, nextCardIdsState, nextLevelCardIdsRedux, lastPage, currentPageLastCardId) => {
    if (nextLevelCardIdsRedux.state.request === Consts.REQUEST_WAITING) {
      return;
    }

    if (lastPage) {
      return;
    }
    if (!nextMemorizedCardIds || !nextCardIdsState) {
      return;
    }

    if (nextCardIdsState.length - nextMemorizedCardIds.length - this.reservedMoveBackCardIds.length < cardIdLoadLeastCount) {
      const nextLevelCardDataSource = nextLevelCardIdsRedux.dataSource;
      const level = nextLevelCardIdsRedux.state.level;
      if (
        nextLevelCardDataSource &&
        nextLevelCardDataSource[level] &&
        nextLevelCardDataSource[level][this.props.learnMemboxState] &&
        nextLevelCardDataSource[level][this.props.learnMemboxState].length > 0
      ) {
        this.props.actions.getCardIds(currentPageLastCardId, true);
      }
    }
  };

  checkLoadMoreCards = (nextMemorizedCardIds, nextCardsProp, nextLevelCardIds, nextGetCardRequest) => {
    if (nextMemorizedCardIds === undefined) {
      nextMemorizedCardIds = this.state.memorizedCardIds;
    }

    if (nextCardsProp === undefined) {
      nextCardsProp = this.props.cards;
    }

    if (nextLevelCardIds === undefined) {
      nextLevelCardIds = this.state.levelCardIds;
    }

    if (!nextMemorizedCardIds || !nextLevelCardIds) {
      return;
    }

    const nextShowCards = this.state.levelCardIds
      .filter((a) => nextMemorizedCardIds.findIndex((b) => a === b) < 0)
      .filter((v, index) => index < cardIdLoadLeastCount);
    if (nextShowCards.filter((a) => nextCardsProp.findIndex((b) => b.id === a) < 0).length > 0) {
      let unloadedCards = nextLevelCardIds.filter((a) => (nextCardsProp ? nextCardsProp.findIndex((b) => b.id === a) < 0 : true));
      unloadedCards = unloadedCards.splice(0, Math.min(cardLoadLeastCount, unloadedCards.length));
      if (unloadedCards.length > 0 && nextGetCardRequest !== Consts.REQUEST_WAITING) {
        this.props.actions.getCards(unloadedCards, true);
      }
    }
  };

  onCardMemorize = (cardId, level) => {
    if (typeof level === "string") {
      level = parseInt(level);
    }

    const updateValue = {};

    const isPreviewMode = this.props.learnMemboxState === Consts.MEMORIZE_STATE_WAIT;
    if (level !== null && !isPreviewMode) {
      this.props.actions.memorize(level, cardId);
    }

    if (level !== null && level !== undefined) {
      updateValue.memorizedCardIds = update(this.state.memorizedCardIds, { $push: [cardId] });
      if (this.movedBackCardIds.findIndex((a) => a === cardId) >= 0) {
        increaseShortTermMemoryLevel();
      }
    } else {
      // Preview Mode
      // 뒤로 넘기기 때문에 기존 reservedMoveBackCardId 인덱스를 -- 해준다.
      for (let reservedMoveBackCardId of this.reservedMoveBackCardIds) {
        reservedMoveBackCardId.index--;
      }

      const newLevelCardIds = update(this.state.levelCardIds, {});
      const removeIndex = newLevelCardIds.findIndex((a) => a === cardId);
      if (removeIndex >= 0) {
        newLevelCardIds.splice(removeIndex, 1);
      }

      const pushBackLength = getShortTermMemoryLevel(); // TODO: pushBackIndex를 가져온다
      const newInsertIndex = removeIndex + pushBackLength;

      if (newLevelCardIds.length >= newInsertIndex) {
        // 현재 들고있는 levelCardIds에 삽입 가능할경우 바로 삽입
        newLevelCardIds.splice(newInsertIndex, 0, cardId);
      } else {
        if (this.props.cardIdsLast) {
          // levelCardIds를 모두 load한 경우 그냥 맨뒤로 보내기
          newLevelCardIds.push(cardId);
        } else {
          this.reservedMoveBackCardIds.push({ cardId, index: newInsertIndex - 1 });
        }
      }

      updateValue.levelCardIds = newLevelCardIds;

      // 처음본 카드이면 movedBackCardIds에 등록, 아니면 shortTermMemoryLevel decrease
      if (this.movedBackCardIds.findIndex((a) => a === cardId) >= 0) {
        decreaseShortTermMemoryLevel();
      } else {
        this.movedBackCardIds.push(cardId);
      }
    }

    //<editor-fold desc="새로운 membox info 계산">
    const currentLevel = parseInt(this.props.match.params.level);
    const newMemboxInfo = this.state.memboxInfo ? JSON.parse(JSON.stringify(this.state.memboxInfo)) : {};

    if (!isPreviewMode) {
      if (level === currentLevel) {
        newMemboxInfo[currentLevel][Consts.MEMORIZE_STATE_READY]--;
        newMemboxInfo[currentLevel][Consts.MEMORIZE_STATE_WAIT]++;
      } else {
        if (!newMemboxInfo[level]) {
          newMemboxInfo[level] = {};
        }
        if (!newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT]) {
          newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT] = 0;
        }
        newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT]++;
        newMemboxInfo[currentLevel][Consts.MEMORIZE_STATE_READY]--;
      }
    } else {
      if (level) {
        if (!newMemboxInfo[level]) newMemboxInfo[level] = {};
        if (!newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT]) newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT] = 0;
        newMemboxInfo[level][Consts.MEMORIZE_STATE_WAIT]++;
      }
      // newMemboxInfo[level]
    }
    updateValue.memboxInfo = newMemboxInfo;
    //</editor-fold>

    this.setState(updateValue);
  };

  renderDebugMode = () => {
    return (
      <div style={{ position: "absolute", top: 100, left: 20, right: 20, wordBreak: "break-all" }}>
        <strong>DEBUG MODE</strong>
        <p style={{ color: "red" }}>
          levelCardIds({this.state.levelCardIds.length}): {JSON.stringify(this.state.levelCardIds)}
        </p>
        <p style={{ color: "blue" }}>
          memorizedCardIds({this.state.memorizedCardIds.length}): {JSON.stringify(this.state.memorizedCardIds)}
        </p>
        <p style={{ color: "green" }}>
          reservedMoveBackCardIds({this.reservedMoveBackCardIds.length}): {JSON.stringify(this.reservedMoveBackCardIds)}
        </p>
        <p style={{ color: "red" }}>
          props levelCardIds({this.props.levelCardIds ? this.props.levelCardIds.length : 0}): {JSON.stringify(this.props.levelCardIds)}
        </p>
        <p style={{ color: "blue" }}>
          props cards({this.props.cards ? this.props.cards.length : 0}): {JSON.stringify(this.props.cards.map((a) => a.id))}
        </p>
      </div>
    );
  };

  onComplete = () => {
    const urlKey = this.props.match.params.deckUrlKey;
    const defaultRedirectPage = urlKey ? Urls.buildLesson(urlKey) : Urls.ROOT;
    DialogManager.alert(this.props.learnMemboxState === Consts.MEMORIZE_STATE_WAIT ? "모든 카드의 확인이 끝났습니다." : "모든 복습이 끝났습니다.", {
      buttonType: Consts.DefaultDialogViewButtonTypes.SUCCESS,
      onClose: () => {
        AppHistory.goBack(defaultRedirectPage);
      },
    });
    this.setState({ enableTimeOver: false });
  };

  render() {
    const level = this.props.match.params.level;

    // 만약 다음 preview 모드면 다음 기억상자레벨을 현재 레벨로 설정
    const nextLevel = this.props.learnMemboxState === Consts.MEMORIZE_STATE_WAIT ? level : Math.min(5, parseInt(level) + 1).toString();

    let totalCount = 0;
    if (
      this.props.memboxInfoRedux.dataSource &&
      this.props.memboxInfoRedux.dataSource[level] &&
      this.props.memboxInfoRedux.dataSource[level][this.props.learnMemboxState]
    ) {
      totalCount = this.props.memboxInfoRedux.dataSource[level][this.props.learnMemboxState];
    }

    const deckUrlKey = this.props.match.params.deckUrlKey;
    return (
      <div id="remind-page" className="page">
        {deckUrlKey ? (
          <Navigator
            leftMenuType={LeftMenuTypes.BACK}
            title={`${Strings.getMemboxLevelCaption(level)} 기억상자`}
            leftMenuUrl={Urls.buildLesson(deckUrlKey)}
            leftMenuState={this.props.history.location ? this.props.history.location.state : undefined}
          />
        ) : (
          <Navigator leftMenuType={LeftMenuTypes.HOME} title={`${Strings.getMemboxLevelCaption(level)} 기억상자`} />
        )}
        {this.props.memboxInfoRedux.state.request === Consts.REQUEST_WAITING ? (
          <Loader />
        ) : this.props.levelCardIds && this.props.cards && this.props.cards.length > 0 ? (
          <PreviewStudyView
            enableTimeOver={this.state.enableTimeOver}
            onMemorize={this.onCardMemorize}
            cardIds={this.state.levelCardIds}
            cardDataSource={this.props.cards}
            totalCardCount={totalCount}
            memorizedCardIds={this.state.memorizedCardIds}
            leftMemboxLevel={this.props.learnMemboxState === Consts.MEMORIZE_STATE_READY ? "1" : null}
            rightMemboxLevel={nextLevel}
            memboxLevel={level}
            memboxInfo={this.state.memboxInfo}
            onComplete={this.onComplete}
          />
        ) : undefined}
        {this.props.debugMode ? this.renderDebugMode() : undefined}
      </div>
    );
  }
}

const cardLoadLeastCount = 10;
const cardIdLoadLeastCount = 6;

const connectedRemindPage = connect(RemindPage.StateToProps, RemindPage.DispatchToProps)(RemindPage);
connectedRemindPage.defaultProps = {
  learnMemboxState: Consts.MEMORIZE_STATE_READY,
  shuffle: true,
  debugMode: false,
};
export default connectedRemindPage;

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-30.
 */

import React from "react";
import Radium from "radium";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { escapeMemboxCharacters } from "../../../utils";
import MemboxDiv from "../../MemboxDiv";

class Template9 extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    data: PropTypes.object,
  };

  state = {
    selectedAnswers: [],
    openSolution: false,
  };

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyPress);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.className && this.props.className.includes("push-back") && (!nextProps.className || !nextProps.className.includes("push-back"))) {
      this.setState({
        selectedAnswers: [],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyPress);
  }

  onKeyPress = (e) => {
    switch (e.keyCode) {
      // ↑
      case 38:
        this.openSolution();
        break;

      // ↓
      case 40:
        this.closeSolution();
        break;
    }
  };

  onCancelTouchTap = (e) => {
    e.stopPropagation();
  };

  toggleAnswerIndex = (index) => {
    const answerIndex = this.state.selectedAnswers.findIndex((a) => a === index);
    if (answerIndex < 0) {
      this.setState({
        selectedAnswers: update(this.state.selectedAnswers, { $push: [index] }),
      });
    } else {
      this.setState({
        selectedAnswers: update(this.state.selectedAnswers, { $splice: [[answerIndex, 1]] }),
      });
    }
  };

  markAnswerCandidate = (text) => {
    const firstChar = text.charAt(0);
    if ((firstChar >= "ⓐ" && firstChar <= "ⓩ") || (firstChar >= "①" && firstChar <= "⑮")) {
      return "●" + text.substring(1, text.length);
    }
    return text;
  };

  openSolution() {
    if (this.refs["card"].classList.contains("flip")) this.refs["solutionFolder"].classList.add("open");
  }

  closeSolution() {
    if (this.refs["card"].classList.contains("flip")) this.refs["solutionFolder"].classList.remove("open");
  }

  onSolutionTouch = (e) => {
    e.stopPropagation();
    this.setState({ openSolution: !this.state.openSolution });
  };

  render() {
    const data = this.props.data;
    return (
      <div className={this.props.className} onClick={this.props.onClick} ref="card">
        <div className="card-container">
          <div className="card-front">
            <article>
              <div className="right_title" dangerouslySetInnerHTML={{ __html: data.rightTitle }} />
              <div className="content">
                <div className="history">
                  <span className="keyboard-key">출제</span> {data.history}
                </div>
                <MemboxDiv className={`question`} dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(data.question) }} />
                {data.questionPassage ? (
                  <div className="question-passage" onClick={this.onCancelTouchTap}>
                    {data.questionPassage
                      ? data.questionPassage.map((item, key) => {
                          const answerCandidate = escapeMemboxCharacters(item);
                          const marked = this.state.selectedAnswers.includes(key);
                          const content = marked ? this.markAnswerCandidate(answerCandidate) : answerCandidate;
                          return (
                            <div
                              key={key}
                              className={marked ? "selected" : ""}
                              onClick={(e) => {
                                this.toggleAnswerIndex(key);
                              }}
                              dangerouslySetInnerHTML={{ __html: content }}
                            />
                          );
                        })
                      : undefined}
                  </div>
                ) : undefined}
              </div>
            </article>
          </div>
          <div className="card-back">
            <article>
              <div style={[styles.answerContainer, data.solution || data.solutionPassage ? { maxHeight: "calc(100% - 30px)" } : undefined]}>
                {data.questionPassage ? (
                  <div className="question-passage">
                    {data.questionPassage
                      ? data.questionPassage.map((item, key) => {
                          const answerCandidate = escapeMemboxCharacters(item);
                          const marked = this.state.selectedAnswers.includes(key);
                          const content = marked ? this.markAnswerCandidate(answerCandidate) : answerCandidate;
                          return (
                            <div key={key} className={marked ? "selected" : ""}>
                              <span
                                className={
                                  data.answer && data.answer.map((item) => (typeof item === "string" ? parseInt(item) : item)).includes(parseInt(key) + 1)
                                    ? "answer-underline"
                                    : ""
                                }
                                dangerouslySetInnerHTML={{ __html: content }}
                              />
                            </div>
                          );
                        })
                      : undefined}
                  </div>
                ) : undefined}
              </div>
              {data.solution || data.solutionPassage ? (
                <div className={`solution-folder ${this.state.openSolution ? "open" : ""}`} ref="solutionFolder">
                  <span className="solution-toggle">{this.state.openSolution ? "정답" : "해설"}</span>
                  <div className="solution-toggle-button-area" onClick={this.onSolutionTouch} />
                  <div className="solution-content">
                    {data.solution ? <MemboxDiv className="solution" dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(data.solution) }} /> : undefined}
                    {data.solutionPassage ? (
                      <MemboxDiv className="solution-passage" dangerouslySetInnerHTML={{ __html: escapeMemboxCharacters(data.solutionPassage) }} />
                    ) : undefined}
                  </div>
                </div>
              ) : undefined}
            </article>
          </div>
        </div>
        <div className="card-posteffect" />
      </div>
    );
  }
}

const styles = {
  answerContainer: {
    overflow: "auto",
    maxHeight: "100%",
  },
};

export default Radium(Template9);

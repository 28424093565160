/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from 'react';
import {Consts} from '../constants';
import {connect} from 'react-redux';
import RemindPage from "./RemindPage";

class PreviewPage extends React.Component {
    render() {
        return <RemindPage {...this.props} learnMemboxState={Consts.MEMORIZE_STATE_WAIT} shuffle={false}/>;
    }
}

export default connect(PreviewPage.StateToProps, PreviewPage.DispatchToProps)(PreviewPage);
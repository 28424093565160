/**
 * Created by kimchangduk on 2017-07-23.
 */
import { Urls } from "../constants";
import { logout } from "./user";

export function processHttpError(dispatch, error) {
  console.error(error);
  if (error && error.response && error.response.status === 401) {
    logout(Urls.LOGIN)(dispatch);
  }
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === "development") console.error(error);
}

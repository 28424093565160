/**
 * Created by kimch on 2017-04-21.
 */

import React from "react";
import { Router, Switch, Route } from "react-router-dom";

import App from "./containers/App";
import LoginPage from "./containers/LoginPage";
import SignUpPage from "./containers/SignUpPage";
import SplashPage from "./containers/SplashPage";
import RemindPage from "./containers/RemindPage";
import DashboardPage from "./containers/DashboardPage";
import LessonPage from "./containers/LessonPage";
import MemorizePage from "./containers/MemorizePage";
import { appHistoryObject } from "./history";
import PreviewPage from "./containers/PreviewPage";
import PasswordResetPage from "./containers/PasswordResetPage";
import EmailValidatePage from "./containers/EmailValidatePage";
import CBTPage from "./containers/CBTPage";
import CategoryPage from "./containers/CategoryPage";
import CBTResult from "./containers/CBTResult";
import CBTResultSolution from "./containers/CBTResultSolution";
import BoardPage from "./containers/BoardPage";
import BoardItem from "./containers/BoardItem";
import BoardItemEdit, { EditTypes } from "./containers/BoardItemEdit";
import TokenLogin from "./containers/TokenLogin";
import PurchaseHistoryPage from "./containers/PurchaseHistoryPage";
import PurchaseCallbackPage from "./containers/PurchaseCallbackPage";
import MembershipPage from "./containers/MembershipPage";
import HashtagResultPage from "./containers/HashtagResultPage";
import TermsPage from "./containers/TermsPage";
import { privacyPolicy, termsOfServic } from "./terms";

export default (
  <Router history={appHistoryObject}>
    <Switch>
      <Route path="/token_login" exact={true} component={TokenLogin} />
      <Route path="/payments/complete" exact={true} component={PurchaseCallbackPage} />
      <Route
        component={(props) => {
          return (
            <App {...props}>
              <Switch>
                <Route path="/login" exact={true} component={LoginPage} />
                <Route path="/signup" exact={true} component={SignUpPage} />
                <Route path="/tag/:name" exact={true} component={HashtagResultPage} />
                <Route path="/purchaseHistory" exact={true} component={PurchaseHistoryPage} />
                <Route path="/password/reset/:userId/:token" exact={true} component={PasswordResetPage} />
                <Route path="/user/:userId/validate/:token" exact={true} component={EmailValidatePage} />
                <Route path="/splash" exact={true} component={SplashPage} />
                <Route path="/remind/:level/:deckUrlKey?" component={RemindPage} />
                <Route path="/preview/:level/:deckUrlKey?" component={PreviewPage} />
                <Route path="/board" exact={true} component={BoardPage} />
                <Route path="/board/write" exact={true} component={(props) => <BoardItemEdit {...props} editType={EditTypes.WRITE} />} />
                <Route path="/board/:id/edit" exact={true} component={(props) => <BoardItemEdit {...props} editType={EditTypes.UPDATE} />} />
                <Route path="/board/:id" exact={true} component={BoardItem} />
                <Route path="/membership/:urlKey" exact={true} component={MembershipPage} />
                <Route path="/lesson/:urlKey" exact={true} component={LessonPage} />
                <Route path="/memorize/:deckUrlKey/:lessonId" exact={true} component={MemorizePage} />
                <Route path="/cbt/:urlKey" exact={true} component={CBTPage} />
                <Route path="/cbt/:urlKey/result/:resultId" exact={true} component={CBTResult} />
                <Route path="/cbt/:urlKey/result/:resultId/solution" exact={true} component={CBTResultSolution} />
                <Route path="/category/:id" exact={true} component={CategoryPage} />
                <Route path="/privacy-policy" exact={true} component={() => <TermsPage terms={privacyPolicy} />} />
                <Route path="/terms-of-service" exact={true} component={() => <TermsPage terms={termsOfServic} />} />
                <Route path="/" exact={true} component={DashboardPage} />
              </Switch>
            </App>
          );
        }}
      />
    </Switch>
  </Router>
);

/**
 * Created by kimchangduk on 2017-09-03.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";

class FloatingQuestionNavigator extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    questions: PropTypes.array,
    gradePaper: PropTypes.array,
    onChange: PropTypes.func,
  };

  state = {
    selectedValue: -1,
  };

  onChange = (e) => {
    const newVal = parseInt(e.target.value);
    this.setState({ selectedValue: newVal });
    if (this.props.onChange) {
      this.props.onChange(newVal);
    }
  };

  render() {
    const questions = this.props.questions;
    return (
      <div style={[styles.container, this.props.style]}>
        <div style={styles.title}>문제 이동</div>
        <div style={styles.selectWrapper}>
          <select value={this.state.selectedValue} onChange={this.onChange}>
            {questions
              ? questions.map((item, key) => {
                  const right = this.props.gradePaper.find((a) => a.questionId === item.id).right;
                  return (
                    <option key={item.id} value={item.id}>
                      {parseInt(key) + 1}번 문항 {right ? "O" : "X"}
                    </option>
                  );
                })
              : undefined}
          </select>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    minWidth: 120,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    textAlign: "center",
    textAlignLast: "center",
    fontWeight: "bold",
    border: "1px solid #ccc",
    fontSize: 13,
  },
  title: {
    padding: 5,
    borderBottom: "1px solid #ccc",
    backgroundColor: "#eee",
  },
  selectWrapper: {
    padding: 5,
  },
};

export default Radium(FloatingQuestionNavigator);

/**
 * Created by kimchangduk on 2017-07-24.
 */
import cardFlip from './assets/sounds/cardFlip.mp3';
import clickButton from './assets/sounds/clickButton.mp3';
import toggleButton from './assets/sounds/toggleButton.mp3';

class SoundManager {
    enable = true;
    soundMap = {};

    /**
     * 사운드 목록을 추가한다.
     */
    add(list) {
        for (let key in list) {
            let filename = list[key];
            let fullPath = filename;

            let audioObject = new Audio(fullPath);
            audioObject.load();

            this.soundMap[key] = audioObject;
        }
    }

    /**
     * 등록된 사운드를 재생한다.
     * @param {type} name
     */
    play(name) {
        if (!this.enable) return;

        if (!this.soundMap[name]) return;

        try {
            let sound = this.soundMap[name];
            if (this.currentSound && sound !== this.currentSound) this.currentSound.pause();

            if (!isNaN(sound.duration)) sound.currentTime = 0;
            sound.play();

            this.currentSound = sound;
        } catch (e) {
            if (process.NODE_ENV === 'development') {
// eslint-disable-next-line no-console
                console.error(e);
            }
        }
    }

    playUrl(url) {
        this.stop();
        this.currentSound = new Audio(url);
        this.currentSound.play();
    }

    playTTS(word) {
        this.stop();
        window.responsiveVoice.speak(word, 'US English Female');
    }

    stop() {
        if (!this.currentSound) return;
        this.currentSound.pause();
    }

    /**
     * 사운드 관리자를 활성화시키거나 비활성화한다.
     * @param {type} enable
     */
    setEnable(enable) {
        this.enable = enable;
    }
}

const singletonObj = new SoundManager();
singletonObj.add({
    "click": clickButton,
    "toggle": toggleButton,
    "flip": cardFlip
});

export default singletonObj;
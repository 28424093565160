/**
 * Created by kimchangduk on 2017-08-14.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import AnswerNumber from "./AnswerNumber";
import { scrollIntoView } from "../../../utils";

class OMR extends React.Component {
  static propTypes = {
    style: PropTypes.object,
    answerNavigation: PropTypes.object,
    questions: PropTypes.array,
    answers: PropTypes.array,
    dummyAnswersMap: PropTypes.array,
    onAnswerClick: PropTypes.func,
    onDummyAnswerClick: PropTypes.func,
    onNavigateRequest: PropTypes.func,
    onFinishRequest: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    if (this.props.answerNavigation !== prevProps.answerNavigation) {
      this.navigateToAnswer(this.props.answerNavigation.id);
    }
  }

  navigateToAnswer = (questionId) => {
    const component = this.refs[this.buildAnswerRef(questionId)];
    if (component) scrollIntoView(component);
  };

  getQuestionNumber = (n) => {
    let result = n.toString();
    if (n < 10) {
      result = "0" + result;
    }
    result += ".";
    return result;
  };

  buildAnswerRef = (id) => `answer-${id}`;

  render() {
    const answerComponents = [];
    for (let i = 0; i < this.props.questions.length; i++) {
      const question = this.props.questions[i];
      answerComponents.push(
        <div key={i} ref={this.buildAnswerRef(question.id)} style={styles.answers}>
          <span
            style={styles.questionNumber}
            onClick={() => {
              if (this.props.onNavigateRequest) this.props.onNavigateRequest(question.id);
            }}
          >
            {this.getQuestionNumber(i + 1)}
          </span>
          {question.example.map((item, key) => {
            return (
              <AnswerNumber
                onClick={() => {
                  if (this.props.onAnswerClick) this.props.onAnswerClick(question.id, item.id);
                  if (this.props.onNavigateRequest) this.props.onNavigateRequest(question.id);
                }}
                onRightClick={() => {
                  if (this.props.onDummyAnswerClick) this.props.onDummyAnswerClick(question.id, item.id);
                  if (this.props.onNavigateRequest) this.props.onNavigateRequest(question.id);
                }}
                key={key}
                number={key + 1}
                marked={this.props.answers && item.id === this.props.answers[i]}
                dummyMarked={this.props.dummyAnswersMap && this.props.dummyAnswersMap[i] && this.props.dummyAnswersMap[i].find((a) => a.id === item.id).marked}
              />
            );
          })}
        </div>
      );
    }

    return (
      <div style={[styles.container, this.props.style]}>
        <div style={styles.title}>답안지</div>
        <div style={styles.questions}>{answerComponents}</div>
        <div style={styles.bottomSection}>
          <button className="plumb-button plumb-button-full plumb-button-default " onClick={this.props.onFinishRequest}>
            시험 종료
          </button>
        </div>
      </div>
    );
  }
}

const borderColor = "rgba(0,0,0,0.5)";

const styles = {
  container: {
    display: "inline-block",
    backgroundColor: "#FFFFFF",
    textAlign: "left",
  },
  title: {
    textAlign: "center",
    padding: 3,
    fontWeight: "bold",
    borderBottom: `1px solid ${borderColor}`,
    height: 26,
  },
  questionNumber: {
    verticalAlign: "middle",
    marginRight: 3,
    fontSize: 18,
    cursor: "pointer",
  },
  questions: {
    height: "calc(100% - 66px)",
    padding: 5,
    overflowY: "auto",
    position: "relative",
  },
  answers: {
    marginBottom: 2,
  },
  bottomSection: {
    height: 40,
    borderTop: `1px solid ${borderColor}`,
    padding: 3,
    fontWeight: "bold",
    textAlign: "center",
  },
};

export default Radium(OMR);

/**
 * Created by kimchangduk on 2017-07-25.
 */

import React from "react";
import AppHistory from "../history";
import { connect } from "react-redux";
import { logout } from "../actions/user";
import { closeSideMenu } from "../actions/sideMenu";
import PropTypes from "prop-types";
import AboutMemboxDialog from "../dialogs/AboutMemboxDialog";
import DialogManager from "../dialogs/DialogManager";
import HowToUseDialog from "../dialogs/HowToUseDialog";
import CouponRegisterDialog from "../dialogs/CouponRegisterDialog";
import ProfileDialog from "../dialogs/ProfileDialog";
import { Urls, Consts } from "../constants";
import ExitIcon from "../assets/icons/svg_kit/close.svg";
import MemboxResetDialog from "../dialogs/MemboxResetDialog";
import AdFitVertical from "./AdFit/AdFitVertical";
import Cookies from "js-cookie";

class SideMenu extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    visible: PropTypes.bool,
    isLogin: PropTypes.bool,
  };

  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        logout: () => {
          dispatch(logout());
        },
        closeSideMenu: () => {
          dispatch(closeSideMenu());
        },
      },
    };
  };

  static StateToProps = (state) => {
    return {
      isLogin: state.user.state.isLogin,
    };
  };

  closeSideMenu = () => {
    this.props.actions.closeSideMenu();
  };

  onPanelTouchTap = (e) => {
    e.stopPropagation();
  };

  onLogoutClick = () => {
    this.props.actions.logout();
    this.props.actions.closeSideMenu();
  };

  onLoginClick = () => {
    this.props.actions.closeSideMenu();
    AppHistory.moveLoginPage();
  };

  onSignUpClick = () => {
    this.props.actions.closeSideMenu();
    AppHistory.moveSignUpPage();
  };

  onAboutClick = () => {
    DialogManager.push(AboutMemboxDialog);
    this.closeSideMenu();
  };

  onMemboxResetClick = () => {
    DialogManager.push(MemboxResetDialog);
    this.closeSideMenu();
  };

  onHowToUseClick = () => {
    DialogManager.push(HowToUseDialog);
    this.closeSideMenu();
  };

  onCouponClick = () => {
    DialogManager.push(CouponRegisterDialog);
    this.closeSideMenu();
  };

  onBoardClick = () => {
    AppHistory.move(Urls.BOARD);
    this.closeSideMenu();
  };

  onEmailClick = () => {
    window.location = `mailto:${Consts.HELP_MAIL}`;
  };

  onProfileClick = () => {
    DialogManager.push(ProfileDialog);
    this.closeSideMenu();
  };

  onPayHistoryClick = () => {
    AppHistory.move(Urls.PURCHASE_HISTORY);
    this.closeSideMenu();
  };

  render() {
    const isLogin = this.props.isLogin;
	const visibleBoard = Cookies.get(Consts.OS) !== Consts.OS_ANDROID;
	
    return (
      <div className={`sidemenu-view right-side ${this.props.visible ? "show" : ""}`} onClick={this.closeSideMenu}>
        <div className="sidemenu-panel" onClick={this.onPanelTouchTap}>
          <img src={ExitIcon} onClick={this.closeSideMenu} style={{ cursor: "pointer", float: "right", zIndex: 1, position: "relative" }} />
          <ul className="sidemenu-list">
            <li className="sidemenu-item" onClick={this.onAboutClick}>
              <div className="sidemenu-icon question-icon" />
              <span>기억상자란?</span>
            </li>
            <li className="sidemenu-item" onClick={this.onHowToUseClick}>
              <div className="sidemenu-icon information-icon" />
              <span>사용법</span>
            </li>
            {isLogin ? (
              <div>
                <hr style={styles.hr} />
                <li className="sidemenu-item primary" onClick={this.onProfileClick}>
                  <div className="sidemenu-icon member-icon" />
                  <span>계정</span>
                </li>
                <li className="sidemenu-item primary" onClick={this.onCouponClick}>
                  <div className="sidemenu-icon coupon-icon" />
                  <span>구매 도서 인증</span>
                </li>
                <li className="sidemenu-item" onClick={this.onPayHistoryClick}>
                  <div className="sidemenu-icon pay-history" />
                  <span>결제 내역</span>
                </li>
              </div>
            ) : undefined}
            {isLogin ? (
              <li className="sidemenu-item secondary" onClick={this.onMemboxResetClick}>
                <div className="sidemenu-icon reset-icon" />
                <span>기억상자 초기화</span>
              </li>
            ) : undefined}
            <hr style={styles.hr} />
            {
				visibleBoard ? <li className="sidemenu-item" onClick={this.onBoardClick}>
					<div className="sidemenu-icon board-icon"/>
					<span>게시판</span>
				</li> : undefined
			}
            <li className="sidemenu-item" onClick={this.onEmailClick}>
              <div className="sidemenu-icon email-icon" />
              <span>이메일 문의</span>
            </li>
            {isLogin ? (
              <li className="sidemenu-item" onClick={this.onLogoutClick}>
                <div className="sidemenu-icon logout-icon" />
                <span>로그아웃</span>
              </li>
            ) : (
              [
                <hr style={styles.hr} key={0} />,
                <li className="sidemenu-item" onClick={this.onLoginClick} key={1}>
                  <div className="sidemenu-icon login-icon" />
                  <span>로그인</span>
                </li>,
                <li className="sidemenu-item" onClick={this.onSignUpClick} key={2}>
                  <div className="sidemenu-icon signup" />
                  <span>회원가입</span>
                </li>,
              ]
            )}
          </ul>
          <AdFitVertical />
        </div>
      </div>
    );
  }
}

const styles = {
  hr: {
    margin: 0,
    padding: 0,
    border: "1px solid #ededed",
    borderTop: "none",
  },
};

export default connect(SideMenu.StateToProps, SideMenu.DispatchToProps)(SideMenu);

/**
 * Created by kimchangduk on 2017-07-20.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppHistory from "../history";
import { signUp } from "../actions/register";
import { hasChangedRequestToSuccess, hasChangedRequestToFailure } from "../utils";
import ResponseWaitingLayer from "../components/ResponseWaitingLayer";
import { Consts, Strings } from "../constants";
import DialogManager from "../dialogs/DialogManager";
import GA from "../GA";
import HowToUseSwiper from "../components/HowToUseSwiper";
import { termsOfServic, privacyPolicy } from "../terms";

class SignUpPage extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    signUpRequest: PropTypes.string,
    signUpErrorMessage: PropTypes.string,
  };

  static StateToProps = (state) => {
    return {
      signUpRequest: state.register.state.request,
      signUpErrorMessage: state.register.state.errorMessage,
    };
  };
  static DispatchToProps = (dispatch) => {
    return {
      actions: {
        signUp: (name, nickname, email, password, sex, birthday, agreeMail) => {
          dispatch(signUp(name, nickname, email, password, sex, birthday, agreeMail));
        },
      },
    };
  };

  state = {
    signupComplete: false,
    termsChecked: false,
    privacyPolicyChecked: false,
    name: "",
    nickname: "",
    email: "",
    password: "",
    passwordConfirm: "",
    birthday: undefined,
    sex: Consts.SEX_MALE,
    agreeMail: true,
  };

  componentDidMount() {
    GA.logPage();
  }

  componentWillReceiveProps(nextProps) {
    if (hasChangedRequestToSuccess(this.props.signUpRequest, nextProps.signUpRequest)) {
      this.setState({ signupComplete: true });
    } else if (hasChangedRequestToFailure(this.props.signUpRequest, nextProps.signUpRequest)) {
      DialogManager.alert(nextProps.signUpErrorMessage);
    }
  }

  onTermsAgreeClick = () => {
    this.setState({
      termsChecked: true,
    });
  };

  onPrivacyPolicyAgreeClick = () => {
    this.setState({
      privacyPolicyChecked: true,
    });
  };

  goBack = () => {
    AppHistory.goBack();
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.password !== this.state.passwordConfirm) {
      DialogManager.alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    let birthday = undefined;
    if (this.state.birthday) {
      birthday = new Date(this.state.birthday).toFormat("YYYY-MM-DD");
      if (!/\d\d\d\d-\d\d-\d\d/.test(birthday)) {
        DialogManager.alert(Strings.INVALID_DATE_FORMAT);
        return;
      }
    }
    this.props.actions.signUp(this.state.name, this.state.nickname, this.state.email, this.state.password, this.state.sex, birthday, this.state.agreeMail);
  };

  //<editor-fold desc="onInputChange">
  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onAgreeMailChange = () => {
    this.setState({ agreeMail: !this.state.agreeMail });
  };

  onSexChange = (e) => {
    this.setState({ sex: e.target.value });
  };

  onBirthdayChange = (e) => {
    this.setState({ birthday: e.target.value });
  };
  //</editor-fold>

  goMainPage = () => {
    AppHistory.move("/");
  };

  renderComplete() {
    return (
      <div className="popup-view page-popup-view popup">
        <div className="popup-panel default-panel">
          <div className="popup-content" style={{ maxHeight: "none", overflow: "auto", margin: 0 }}>
            <div className="scroll-view-container">
              <article style={{ padding: 0 }}>
                <div className="text-align-center">
                  <HowToUseSwiper imageStyle={{ width: 290 }} />
                </div>
                <div style={{ padding: 10 }}>
                  <p>안녕하세요. {this.state.name} 회원님</p>
                  <p>기억상자 회원으로 가입해 주셔서 감사합니다.</p>
                  <p>{this.state.email} 의 메일을 확인하여 인증하시면 기억상자를 이용하실 수 있습니다.</p>
                </div>
              </article>
            </div>
          </div>
          <div className="popup-footer button">
            <button onClick={this.goMainPage}>닫기</button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.signupComplete) {
      return this.renderComplete();
    }

    return (
      <div>
        {!this.state.termsChecked ? (
          <section className="popup-view popup" data-no="1">
            <div className="popup-panel default-panel">
              <div className="popup-content">
                <h1 className="text-align-center">이용약관</h1>
                <div style={styles.scrollView}>
                  <pre id="terms" className="long-text">
                    {termsOfServic}
                  </pre>
                </div>
                <div className="stack-panel-horizontal">
                  <button
                    onClick={this.onTermsAgreeClick}
                    type="button"
                    className="button-agree plumb-button plumb-button-half plumb-button-success left-side-block"
                  >
                    동의함
                  </button>
                  <button onClick={this.goBack} type="button" className="button-back plumb-button plumb-button-half plumb-button-cancel right-side-block">
                    동의하지 않음
                  </button>
                </div>
              </div>
            </div>
          </section>
        ) : undefined}
        {this.state.termsChecked && !this.state.privacyPolicyChecked ? (
          <section className="popup-view popup" data-no="2">
            <div className="popup-panel default-panel">
              <div className="popup-content">
                <h1 className="text-align-center">개인정보취급방침</h1>
                <div style={styles.scrollView}>
                  <pre id="privacy" className="long-text">
                    {privacyPolicy}
                  </pre>
                </div>
                <div className="stack-panel-horizontal">
                  <button onClick={this.onPrivacyPolicyAgreeClick} className="button-agree plumb-button plumb-button-half plumb-button-success left-side-block">
                    동의함
                  </button>
                  <button onClick={this.goBack} className="button-back plumb-button plumb-button-half plumb-button-cancel right-side-block">
                    동의하지 않음
                  </button>
                </div>
              </div>
            </div>
          </section>
        ) : undefined}
        {this.state.termsChecked && this.state.privacyPolicyChecked ? (
          <section className="popup-view popup" data-no="3">
            <div className="popup-panel default-panel">
              <div className="popup-content">
                <h1 className="text-align-center">회원가입</h1>
                <form onSubmit={this.onSubmit}>
                  <div className="stack-panel stack-panel-vertical" style={{ margin: "4px 0px" }}>
                    <div className="stack-panel stack-panel-horizontal">
                      <input
                        onChange={this.onInputChange}
                        value={this.state.name}
                        type="text"
                        name="name"
                        className="editbox-full"
                        placeholder="이름"
                        data-trigger="focus"
                        data-placement="bottom"
                        required
                      />
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <input
                        onChange={this.onInputChange}
                        value={this.state.nickname}
                        type="text"
                        name="nickname"
                        className="editbox-full"
                        placeholder="닉네임"
                        data-trigger="focus"
                        data-placement="bottom"
                        required
                      />
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <input
                        onChange={this.onInputChange}
                        value={this.state.email}
                        type="email"
                        name="email"
                        className="editbox-full"
                        placeholder="이메일"
                        data-trigger="focus"
                        data-placement="bottom"
                        required
                      />
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <input
                        onChange={this.onInputChange}
                        value={this.state.password}
                        type="password"
                        name="password"
                        className="editbox-full"
                        placeholder="비밀번호"
                        data-trigger="focus"
                        data-placement="bottom"
                        required
                      />
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <input
                        onChange={this.onInputChange}
                        value={this.state.passwordConfirm}
                        type="password"
                        name="passwordConfirm"
                        className="editbox-full"
                        placeholder="비밀번호 확인"
                        data-trigger="focus"
                        data-placement="bottom"
                        required
                      />
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <div className="stack-panel stack-panel-horizontal" style={{ width: "40%", paddingRight: 3 }}>
                        <label className="header" htmlFor="sex" style={{ width: 28 }}>
                          <i className="fa fa-venus-mars fa-fw" />
                        </label>
                        <select id="sex" className="full" name="sex" title="성별" value={this.state.sex} onChange={this.onSexChange} required>
                          <option value={Consts.SEX_MALE}>남자</option>
                          <option value={Consts.SEX_FEMALE}>여자</option>
                        </select>
                      </div>
                      <div className="stack-panel stack-panel-horizontal" style={{ width: "60%", paddingLeft: 3 }}>
                        <label className="header" htmlFor="birthday" style={{ width: 28 }}>
                          <i className="fa fa-birthday-cake fa-fw" aria-hidden="true" />
                        </label>
                        <input
                          type="date"
                          className="full"
                          id="birthday"
                          name="birthday"
                          min="1900-01-01"
                          max="2016-01-01"
                          value={this.state.birthday}
                          onChange={this.onBirthdayChange}
                        />
                      </div>
                    </div>
                    <div className="stack-panel stack-panel-horizontal">
                      <input type="checkbox" onChange={this.onAgreeMailChange} checked={this.state.agreeMail} id="agree-mail" name="agree_mail" />
                      <label htmlFor="agree-mail">
                        <span>소식지를 메일로 받는데 동의합니다.</span>
                      </label>
                    </div>
                  </div>
                  <div className="stack-panel-horizontal">
                    <button type="submit" className="button-submit plumb-button plumb-button-half plumb-button-success left-side-block">
                      가입하기
                    </button>
                    <button type="button" onClick={this.goBack} className="button-back plumb-button plumb-button-half plumb-button-cancel right-side-block">
                      취소
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        ) : undefined}
        {this.props.signUpRequest === Consts.REQUEST_WAITING ? <ResponseWaitingLayer /> : undefined}
      </div>
    );
  }
}

const styles = {
  scrollView: { width: "100%", height: 320, margin: "4px 0px", padding: 4, border: "1px solid #cdcdcd", overflow: "auto", color: "#333" },
};

export default connect(SignUpPage.StateToProps, SignUpPage.DispatchToProps)(SignUpPage);

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-07-31.
 */

import React from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import SoundManager from "../../soundManager";

import FrontSoundIcon from "../../assets/icons/front_sound.png";
import BackSoundIcon from "../../assets/icons/back_sound.png";
import WordSpeaker from "../../assets/icons/word_speaker.jpg";
import ExampleSpeaker from "../../assets/icons/example_speaker.jpg";

import Template9 from "./templates/Template9";
import Template8 from "./templates/Template8";
import Template10 from "./templates/Template10";
import Template11 from "./templates/Template11";
import AdFitCard from "../AdFit/AdFitCard";

class CardView extends React.Component {
  static propTypes = {
    lessonName: PropTypes.string,
    dataSource: PropTypes.object,
    disabled: PropTypes.bool,
    /**
     * shakeTime 이 변경될때마다 카드를 흔드는 효과를 발생시킨다
     */
    shakeTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    flyToNext: PropTypes.bool,
    flyToPrev: PropTypes.bool,
    fadeOutToLeft: PropTypes.bool,
    fadeOutToRight: PropTypes.bool,
    fliped: PropTypes.bool,
    onFlip: PropTypes.func,
    active: PropTypes.bool,
    cardTestMode: PropTypes.bool, // true일경우 id만 표시해준다
    onAdSkipClick: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    flyToNext: false,
    flyToPrev: false,
    active: false,
    cardTestMode: false,
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.shakeTime !== nextProps.shakeTime) {
      const elem = findDOMNode(this);
      elem.classList.remove("card-shake");
      if (nextProps.shakeTime) {
        setTimeout(() => {
          elem.classList.add("card-shake");
        }, 33);
      }
    }
  };

  onTap = () => {
    if (this.props.disabled) {
      return;
    }

    if (this.props.onFlip) {
      this.props.onFlip();
    }
  };

  onVoiceClick = (e) => {
    if (this.props.disabled) {
      return;
    }
    e.stopPropagation();

    const data = this.props.dataSource;
    let element = e.target;
    if (!element.classList.contains("voicePlay")) {
      element = element.parentElement;
    }
    const propertyName = element.getAttribute("data-property");
    if (data.data[propertyName]) {
      const propertyValue = data.data[propertyName];
      if (/.*\.mp3/gi.test(propertyValue)) {
        SoundManager.playUrl(propertyValue);
      } else {
        SoundManager.playTTS(propertyValue);
      }
    }
  };

  onExpandTap = (e) => {
    e.stopPropagation();
    const target = e.target;
    target.classList.toggle("expand");
    const expanded = target.classList.contains("expand");
    const expandClassName = target.getAttribute("data-expand");
    const expandElements = target.parentElement.getElementsByClassName(expandClassName);
    for (let i = 0; i < expandElements.length; i++) {
      const element = expandElements[i];
      element.style.display = expanded ? null : "block";
    }
  };

  renderCardTest = (cardClassNames) => {
    return (
      <div className={cardClassNames} onClick={this.onTap}>
        <div className="card-container">
          <div className="card-front">
            <article>
              <p>FRONT</p>
              <p>{JSON.stringify(this.props.dataSource)}</p>
            </article>
          </div>
          <div className="card-back">
            <article>
              <p>BACK</p>
              <p>{JSON.stringify(this.props.dataSource)}</p>
            </article>
          </div>
        </div>
        <div className="card-posteffect" />
      </div>
    );
  };

  render() {
    const data = this.props.dataSource ? this.props.dataSource.data : null;
    let cardClassNames = "card";
    if (this.props.fliped) {
      cardClassNames += " flip";
    }
    if (this.props.flyToNext) {
      cardClassNames += " push-next";
    }
    if (this.props.flyToPrev) {
      cardClassNames += " push-prev";
    }
    if (this.props.fadeOutToLeft) {
      cardClassNames += " push-back";
    }
    if (this.props.fadeOutToRight) {
      cardClassNames += " push-front";
    }
    if (this.props.dataSource?.templateId === -1) {
      cardClassNames += " adfit";
    }

    if (this.props.cardTestMode) {
      return this.renderCardTest(cardClassNames);
    }

    if (!data && this.props.dataSource?.templateId !== -1) {
      return <div className={cardClassNames} />;
    }

    switch (this.props.dataSource.templateId) {
      case -1: // 광고카드
        return (
          <div className={cardClassNames}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <AdFitCard onSkip={this.props.onAdSkipClick} />
                </article>
              </div>
            </div>
          </div>
        );
      case 1:
        cardClassNames += " english_word";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit fit-to-width">{data.front}</span>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="back"
                    style={!data["back"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <span className="mean fit">{data.back}</span>
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="frontTTS"
                    style={!data["frontTTS"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="back"
                    style={!data["back"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 2:
        cardClassNames += " general_knowledge";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="gk_question sizefit fit fit-to-height" dangerouslySetInnerHTML={{ __html: data.question }} />
                  <div className="gk_example scrollable card-scroll">
                    <div className="scroll-wrapper" dangerouslySetInnerHTML={{ __html: data.example }} />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <span className="gk_answer sizefit fit" dangerouslySetInnerHTML={{ __html: data.answer }} />
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 3:
        cardClassNames += " speak_1min";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <div
                    className="voicePlay position-bottom-right"
                    style={!data["back"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                    data-property="back"
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <span className="mean fit" dangerouslySetInnerHTML={{ __html: data.back }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="frontTTS"
                    onClick={this.onVoiceClick}
                    style={!data["frontTTS"] ? styles.displayNone : undefined}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="back"
                    onClick={this.onVoiceClick}
                    style={!data["back"] ? styles.displayNone : undefined}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 4:
        cardClassNames += " english_word";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit fit-to-width" dangerouslySetInnerHTML={{ __html: data.front }} />
                </article>
              </div>
              <div className="card-back">
                <article>
                  <div className="back-word">{data.front}</div>
                  <div className="mean" dangerouslySetInnerHTML={{ __html: data.back }} />
                  <div className="ex expand" data-expand="ex_kor">
                    {data.ex}
                  </div>
                  <div className="ex_kor">{data.ex_kor}</div>
                  <div className="voice_button voicePlay" style={!data["ex"] ? styles.displayNone : undefined} onClick={this.onVoiceClick} data-property="ex">
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 5:
        cardClassNames += " speak_1min";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="frontVoice"
                    style={!data["frontVoice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="back"
                    style={!data["back"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <span className="mean fit" dangerouslySetInnerHTML={{ __html: data.back }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="frontVoice"
                    style={!data["frontVoice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="back"
                    style={!data["back"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <i className="glyphicon glyphicon-volume-up" />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 6:
        // 토익_파트2
        cardClassNames += " speak_1min";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="pop_voice"
                    style={!data["pop_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="word-speaker" src={WordSpeaker} />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <div className="back-word" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <span className="mean fit" dangerouslySetInnerHTML={{ __html: data.back }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="pop_voice"
                    style={!data["pop_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="word-speaker" src={WordSpeaker} />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );
      case 7:
        // 토익_파트1
        // 토익_파트2
        cardClassNames += " english_word";
        return (
          <div className={cardClassNames} onClick={this.onTap}>
            <div className="card-container">
              <div className="card-front">
                <article>
                  <span className="word fit fit-to-width" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="pop_voice"
                    style={!data["pop_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="word-speaker" src={WordSpeaker} />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="flip_voice"
                    style={!data["flip_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="example-speaker" src={ExampleSpeaker} />
                  </div>
                </article>
              </div>
              <div className="card-back">
                <article>
                  <div className="back-word" dangerouslySetInnerHTML={{ __html: data.front }} />
                  <div className="mean" dangerouslySetInnerHTML={{ __html: data.back }} />
                  <div className="ex expand" data-expand="ex_kor" onClick={this.onExpandTap}>
                    {data.ex}
                  </div>
                  <div className="ex_kor">{data.ex_kor}</div>
                  <div
                    className="voicePlay position-bottom-left"
                    data-property="pop_voice"
                    style={!data["pop_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="word-speaker" src={WordSpeaker} />
                  </div>
                  <div
                    className="voicePlay position-bottom-right"
                    data-property="flip_voice"
                    style={!data["flip_voice"] ? styles.displayNone : undefined}
                    onClick={this.onVoiceClick}
                  >
                    <img className="example-speaker" src={ExampleSpeaker} />
                  </div>
                </article>
              </div>
            </div>
            <div className="card-posteffect" />
          </div>
        );

      case 8: {
        // it수험서
        cardClassNames += " card-template-8";
        return <Template8 active={this.props.active} className={cardClassNames} onClick={this.onTap} data={data} />;
      }

      case 9: {
        // 객관식 문제
        cardClassNames += " card-template-9";
        return <Template9 active={this.props.active} className={cardClassNames} onClick={this.onTap} data={data} />;
      }
      case 10:
        // 듣기 문제들
        cardClassNames += " card-template-10";
        return <Template10 active={this.props.active} className={cardClassNames} lessonName={this.props.lessonName} onClick={this.onTap} data={data} />;
      case 11:
        // 영단어 (유반파빈)
        cardClassNames += " card-template-11";
        return <Template11 active={this.props.active} className={cardClassNames} onClick={this.onTap} data={data} onVoiceClick={this.onVoiceClick} />;
      default:
        break;
    }

    return <div />;
  }
}

const styles = {
  displayNone: { display: "none" },
};

export default CardView;

/**
 * Created by kimchangduk on 2017-12-30.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import * as Api from '../../Api';
import AppHistory from '../../history';
import {Consts, Urls} from '../../constants';

class SearchResult extends React.Component {
    static propTypes = {
        mobile: PropTypes.bool,
        style: PropTypes.object,
        searchString: PropTypes.string,
        onSelect: PropTypes.func,
        onRequestClose: PropTypes.func
    };

    static defaultProps = {
        mobile: false
    };

    state = {
        searchResults: null,
        isWaiting: false,
        cursorIndex: 0
    };

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
        if (this.props.searchString) {
            this.search(this.props.searchString);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.searchString !== nextProps.searchString) {
            if (this.searchTimeout) {
                window.clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = window.setTimeout(() => {
                this.search(this.props.searchString);
            }, TIMEOUT, this.searchTimeout);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
        if (this.searchTimeout) {
            window.clearTimeout(this.searchTimeout);
        }
    }

    searchTimeout = null;

    onKeyDown = (e) => {
        switch (e.keyCode) {
            // ↑
            case 38:
                this.cursorMove(this.state.cursorIndex - 1);
                break;

            // ↓
            case 40:
                this.cursorMove(this.state.cursorIndex + 1);
                break;

            // Enter
            case 13: {
                if (this.state.cursorIndex < 0 || !this.state.searchResults) {
                    return;
                }
                let count = 0;
                for (let itemKey of resultKeyOrder) {
                    if (!this.state.searchResults[itemKey]) {
                        return;
                    }

                    if ((count + this.state.searchResults[itemKey].length) > this.state.cursorIndex) {
                        const selectedItem = this.state.searchResults[itemKey][this.state.cursorIndex - count];
                        if (selectedItem) {
                            switch (itemKey) {
                                case "hash":
                                    AppHistory.move(Urls.buildHashtag(selectedItem.tag));
                                    break;
                                case "membership":
                                    AppHistory.move(Urls.buildMembership(selectedItem.urlKey));
                                    break;
                                case "deck":
                                    AppHistory.move(Urls.buildLesson(selectedItem.urlKey));
                                    break;
                                case "cbt":
                                    AppHistory.move(Urls.buildCBT(selectedItem.urlKey));
                                    break;
                            }
                            if (this.props.onRequestClose) {
                                this.props.onRequestClose();
                            }
                        }
                        return;
                    }
                    count += this.state.searchResults[itemKey].length;
                }
                break;
            }
        }
    };

    search = (searchStr) => {
        if (searchStr) {
            this.setState({isWaiting: true});
            Api.search(searchStr).then(response => {
                this.setState({searchResults: response.data, isWaiting: false});
            }).catch(error => {
                this.setState({searchResults: null, isWaiting: false});
            });
        } else {
            this.setState({searchResults: null});
        }
    };

    renderResults(searchResults) {
        // deck, cbt, hash, membership
        const results = [];
        if (searchResults) {
            let count = 0;
            for (let dataKey of resultKeyOrder) {
                if (searchResults[dataKey] && searchResults[dataKey].length > 0) {
                    switch (dataKey) {
                        case "hash": {
                            results.push(<div key="hash">
                                {
                                    searchResults.hash.map((item, key) => {
                                        return <div ref={RESULT_ITEM_REF_PREFIX + (count + key)} key={key} className={`result-item hashtag ${(count + key) === this.state.cursorIndex ? 'selected' : ''}`} onClick={() => {
                                            AppHistory.move(Urls.buildHashtag(item.tag));
                                            if (this.props.onRequestClose) {
                                                this.props.onRequestClose();
                                            }
                                        }}># {item.tag}</div>;
                                    })
                                }
                            </div>);
                            break;
                        }
                        case "membership": {
                            results.push(<div key="membership">
                                {
                                    searchResults.membership.map((item, key) => {
                                        return <div ref={RESULT_ITEM_REF_PREFIX + (count + key)} key={key} className={`result-item ${(count + key) === this.state.cursorIndex ? 'selected' : ''}`} onClick={() => {
                                            AppHistory.move(Urls.buildMembership(item.urlKey));
                                            if (this.props.onRequestClose) {
                                                this.props.onRequestClose();
                                            }
                                        }}>
                                            <img src={item.coverImage}/>
                                            {item.name}
                                        </div>;
                                    })
                                }
                            </div>);
                            break;
                        }
                        case "deck": {
                            results.push(<div key="deck">
                                {
                                    searchResults.deck.map((item, key) => {
                                        return <div ref={RESULT_ITEM_REF_PREFIX + (count + key)} key={key} className={`result-item ${(count + key) === this.state.cursorIndex ? 'selected' : ''}`} onClick={() => {
                                            AppHistory.move(Urls.buildLesson(item.urlKey));
                                            if (this.props.onRequestClose) {
                                                this.props.onRequestClose();
                                            }
                                        }}>
                                            <img src={item.coverImage}/>
                                            {item.name}
                                        </div>;
                                    })
                                }
                            </div>);
                            break;
                        }
                        case "cbt": {
                            results.push(<div key="CBT">
                                {
                                    searchResults.cbt.map((item, key) => {
                                        return <div ref={RESULT_ITEM_REF_PREFIX + (count + key)} key={key} className={`result-item ${(count + key) === this.state.cursorIndex ? 'selected' : ''}`} onClick={() => {
                                            AppHistory.move(Urls.buildCBT(item.urlKey));
                                            if (this.props.onRequestClose) {
                                                this.props.onRequestClose();
                                            }
                                        }}>
                                            <img src={item.coverImage}/>
                                            {item.name}
                                        </div>;
                                    })
                                }
                            </div>);
                            break;
                        }
                    }
                    count += searchResults[dataKey].length;
                }
            }
        }
        if (results.length > 0) {
            return results;
        }
        return <div className="no-results">검색결과가 없습니다.</div>;
    }

    cursorMove(number) {
        let totalItemCount = 0;
        for (let itemKey of resultKeyOrder) {
            if (this.state.searchResults && this.state.searchResults[itemKey]) {
                totalItemCount += this.state.searchResults[itemKey].length;
            }
        }

        number = Math.max(0, number);
        number = Math.min(number, totalItemCount - 1);
        this.setState({cursorIndex: number});

        const parentElement = ReactDOM.findDOMNode(this);
        const selectedElement = this.refs[RESULT_ITEM_REF_PREFIX + number];
        if (selectedElement && parentElement) {
            if (parentElement.scrollTop > selectedElement.offsetTop) {
                parentElement.scrollTop = selectedElement.offsetTop;
            } else if ((parentElement.clientHeight + parentElement.scrollTop) < (selectedElement.offsetTop + selectedElement.clientHeight)) {
                parentElement.scrollTop = selectedElement.offsetTop + selectedElement.clientHeight - parentElement.clientHeight;
            }
        }

    }

    render() {
        if (!this.props.searchString) {
            return null;
        }

        return (<div className={`search-result-list ${this.props.mobile ? 'mobile' : ''}`} style={this.props.style}>
            {
                this.state.isWaiting ?
                    <div className="searching">검색중</div> : this.renderResults(this.state.searchResults)
            }
        </div>);
    }
}

const TIMEOUT = 300;
const resultKeyOrder = ['hash', 'membership', 'deck', 'cbt'];
const RESULT_ITEM_REF_PREFIX = 'result-item-';

export default SearchResult;
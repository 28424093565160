/**
 * Created by kimchangduk on 2017-07-25.
 */

import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../actions/user';
import {closeSideMenu} from '../actions/sideMenu';
import {getCategoryList, getCategory} from '../actions/category';
import {Urls} from '../constants';
import AppHistory from '../history';
import PropTypes from 'prop-types';
import SoundManager from '../soundManager';

class CategorySideMenu extends React.Component {
    static propTypes = {
        categoryListState: PropTypes.object,
        categoryListDataSource: PropTypes.array,
        categoryState: PropTypes.object,
        categoryDataSource: PropTypes.object,
        actions: PropTypes.object,
        visible: PropTypes.bool
    };

    static StateToProps = (state) => {
        return {
            categoryListState: state.category.categoryList.state,
            categoryListDataSource: state.category.categoryList.dataSource,
            categoryState: state.category.category.state,
            categoryDataSource: state.category.category.dataSource,
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                logout: () => {
                    dispatch(logout());
                },
                closeSideMenu: () => {
                    dispatch(closeSideMenu());
                },
                getCategoryList: () => {
                    dispatch(getCategoryList());
                },
                getCategory: (id) => {
                    dispatch(getCategory(id));
                }
            }
        };
    };

    componentDidMount() {
        this.props.actions.getCategoryList();
    }

    closeSideMenu = () => {
        this.props.actions.closeSideMenu();
    };

    onPanelTouchTap = (e) => {
        e.stopPropagation();
    };

    onCategoryClick = (e) => {
        // 페이지 이동
        const categoryId = parseInt(e.target.getAttribute('data'));
        if (!isNaN(categoryId)) {
            AppHistory.move(Urls.buildCategory(categoryId));
        }
        this.closeSideMenu();
    };

    travelCategorys(categories, depth = 0, array = undefined) {
        if (!array) {
            array = [];
        }

        if (!categories) {
            return array;
        }

        const paddingLeft = 5 + depth * 15;
        for (let category of categories) {
            const existsSubcategories = category.subCategories && category.subCategories.length > 0;
            array.push(<li
                key={category.id}
                className="sidemenu-item"
                style={[paddingLeft, existsSubcategories ? styles.nonClickable : undefined]}
                data={category.id}
                onClick={existsSubcategories ? undefined : this.onCategoryClick}>
                <i className="fa fa-archive fa-fw"/>{category.name}
            </li>);
            this.travelCategorys(category.subCategories, depth + 1, array);

            // 구분선 추가
            // if (depth === 0) {
            //     array.push(<hr key={`${category.id}-hr`} style={styles.hr}/>)
            // }
        }
        return array;
    }

    render() {
        const categoryComponents = this.travelCategorys(this.props.categoryListDataSource);
        return (<div className={`sidemenu-view left-side ${this.props.visible ? 'show' : ''}`} onClick={this.closeSideMenu}>
            <div className="sidemenu-panel" onClick={this.onPanelTouchTap}>
                <ul className="sidemenu-list">
                    {categoryComponents}
                </ul>
            </div>
        </div>);
    }
}

const styles = {
    hr: {
        margin: 0,
        padding: 0,
        border: '1px solid #FFFFFF',
        borderTop: 'none'
    },
    nonClickable: {
        cursor: 'arrow'
    }
};

export default connect(CategorySideMenu.StateToProps, CategorySideMenu.DispatchToProps)(CategorySideMenu);
/**
 * Created by kimchangduk on 2017-10-18.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { getMessageFromResponse, parseQueryString } from "../utils";
import AppHistory from "../history";
import * as Api from "../Api";
import DialogManager from "../dialogs/DialogManager";

class PurchaseCallbackPage extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  componentDidMount() {
    const queryObj = parseQueryString(window.location.search);
    if (queryObj && queryObj.imp_success === "true") {
      Api.purchaseAfter(queryObj.imp_uid, queryObj.merchant_uid, queryObj.status)
        .then((response) => {
          let msg = "<p>결제에 성공하였습니다.</p>";
          DialogManager.alert(msg);
          AppHistory.move("/");
        })
        .catch((error) => {
          DialogManager.alert(
            "<p>결제에 성공했으나 서버로 결과 전송에 실패했습니다.</p>" + "<p>고객센터로 연락주시길 바랍니다.</p>" + "<p>Tel.02-335-0932</p>"
          );
          AppHistory.move("/");
        });
    } else {
      if (queryObj.error_msg) {
        let msg = "<p>결제에 실패하였습니다.</p>";
        msg += `<p>에러내용 : ${queryObj.error_msg}</p>`;
        DialogManager.alert(msg);
      }
      AppHistory.move("/");
    }
  }

  render() {
    return <Loader />;
  }
}

export default connect(PurchaseCallbackPage.StateToProps, PurchaseCallbackPage.DispatchToProps)(PurchaseCallbackPage);

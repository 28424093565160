/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppHistory from "../history";
import {
  hasChangedRequestToSuccess,
  hasChangedRequestToFailure
} from "../utils";
import { Urls, Consts } from "../constants";
import { login, loginWithFacebook } from "../actions/user";
import DialogManager from "../dialogs/DialogManager";
import ForgotPasswordDialog from "../dialogs/ForgotPasswordDialog";
import Footer from "../components/Footer";
import GA from "../GA";

class LoginPage extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    actions: PropTypes.object,
    location: PropTypes.object,
    loginRequest: PropTypes.string,
    loginErrorMessage: PropTypes.string,
    isLogin: PropTypes.bool
  };

  static StateToProps = state => {
    return {
      isLogin: state.user.state.isLogin,
      loginRequest: state.user.state.loginRequest,
      loginErrorMessage: state.user.state.loginErrorMessage
    };
  };

  static DispatchToProps = dispatch => {
    return {
      actions: {
        login: (email, password) => {
          dispatch(login(email, password));
        },
        loginWithFacebook: token => {
          dispatch(loginWithFacebook(token));
        }
      }
    };
  };

  state = {
    email: "",
    password: ""
  };

  componentDidMount() {
    GA.logPage();
  }

  componentDidUpdate(prevProps) {
    if (
      hasChangedRequestToSuccess(
        prevProps.loginRequest,
        this.props.loginRequest
      )
    ) {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.redirectionPathname
      ) {
        AppHistory.replace(this.props.location.state.redirectionPathname);
      } else {
        AppHistory.replace(Urls.ROOT);
      }
    } else if (
      hasChangedRequestToFailure(
        prevProps.loginRequest,
        this.props.loginRequest
      )
    ) {
      DialogManager.alert(this.props.loginErrorMessage);
    }
  }

  onTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSignUpClick = () => {
    AppHistory.moveSignUpPage();
  };

  onSubmit = e => {
    e.preventDefault();
    for (let ref of ["email-input", "password-input", "submit-button"]) {
      ReactDOM.findDOMNode(this.refs[ref]).blur();
    }
    this.props.actions.login(this.state.email, this.state.password);
  };

  onFacebookClick = e => {
    e.preventDefault();
    window.FB.getLoginStatus(response => {
      switch (response.status) {
        case "connected":
          this.props.actions.loginWithFacebook(
            response.authResponse.accessToken
          );
          break;
        case "not_authorized":
        case "unknown":
          window.FB.login(response2 => {
            if (response2.status === "connected") {
              this.props.actions.loginWithFacebook(
                response2.authResponse.accessToken
              );
            }
          });
          break;
      }
    });
  };

  onForgotPasswordClick = e => {
    DialogManager.push(ForgotPasswordDialog);
  };

  render() {
    return (
      <div id="login-page">
        <div className="anchor-center">
          <div className="logo">
            <div className="logo-icon" />
          </div>
          <form className="login-form" onSubmit={this.onSubmit}>
            <div>
              <input
                ref="email-input"
                type="text"
                name="email"
                placeholder="이메일"
                onChange={this.onTextChange}
                value={this.state.email}
                required
              />
              <input
                ref="password-input"
                type="password"
                name="password"
                placeholder="비밀번호"
                onChange={this.onTextChange}
                value={this.state.password}
                required
              />
              <button
                ref="submit-button"
                type="submit"
                className="login-button"
                onClick={this.onLoginClick}
              >
                <i className="fa fa-envelope fa-fw" /> 이메일 로그인
              </button>
              <hr/>
              <button
                className="facebook-button"
                onClick={this.onFacebookClick}
              >
                <i className="fa fa-facebook-square fa-fw" /> 페이스북 로그인
              </button>
              <div className="button-wrap">
              <span
                  id="btnSignup"
                  className="signup-button"
                  onClick={this.onSignUpClick}
                >회원가입</span>
                <span
                  id="btnForgot"
                  className="forgot-password-button"
                  onClick={this.onForgotPasswordClick}
                >비밀번호 찾기</span>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

const styles = {};

export default connect(
  LoginPage.StateToProps,
  LoginPage.DispatchToProps
)(LoginPage);

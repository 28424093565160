/**
 * Created by kimch on 2017-07-25.
 */
import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  categoryList: {
    state: {
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: [],
  },
  category: {
    state: {
      id: -1,
      request: Consts.REQUEST_NONE,
      errorMessage: "",
    },
    dataSource: null,
  },
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    //<editor-fold desc="Category List">
    case ActionTypes.GET_CATEGORY_LIST_REQUEST: {
      return update(state, {
        categoryList: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            errorMessage: { $set: "" },
          },
          dataSource: { $set: [] },
        },
      });
    }

    case ActionTypes.GET_CATEGORY_LIST_SUCCESS: {
      return update(state, {
        categoryList: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CATEGORY_LIST_FAILURE: {
      return update(state, {
        categoryList: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
        },
      });
    }
    //</editor-fold>
    //<editor-fold desc="Category">
    case ActionTypes.GET_CATEGORY_REQUEST: {
      return update(state, {
        category: {
          state: {
            request: { $set: Consts.REQUEST_WAITING },
            id: { $set: action.id },
            errorMessage: { $set: "" },
          },
        },
      });
    }

    case ActionTypes.GET_CATEGORY_SUCCESS: {
      if (state.category.state.id !== action.id) {
        return state;
      }

      return update(state, {
        category: {
          state: {
            request: { $set: Consts.REQUEST_SUCCESS },
          },
          dataSource: { $set: action.data },
        },
      });
    }

    case ActionTypes.GET_CATEGORY_FAILURE: {
      if (state.category.state.id !== action.id) {
        return state;
      }

      return update(state, {
        category: {
          state: {
            request: { $set: Consts.REQUEST_FAILURE },
            errorMessage: { $set: action.errorMessage },
          },
          dataSource: { $set: null },
        },
      });
    }
    //</editor-fold>
    default:
      return state;
  }
}

/**
 * Created by kimchangduk on 2017-10-15.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import { Consts, Strings } from "../constants";
import { connect } from "react-redux";
import { resetMembox } from "../actions/membox";
import Loader from "../components/Loader";
import DefaultDialogView from "./DefaultDialogView";

import { hasChangedRequestToSuccess, hasChangedRequestToFailure } from "../utils";
import DialogManager from "./DialogManager";
import AppHistory from "../history";
import * as Api from "../Api";
import { logout } from "../actions/user";

class DeleteUserDialog extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    email: PropTypes.string,
    onRequestClose: PropTypes.func,
    logoutRequest: PropTypes.string,
  };

  static StateToProps = (state, ownProps) => {
    return {
      email: state.user.dataSource ? state.user.dataSource.email : "",
      logoutRequest: state.user.state.logoutRequest,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        logout: () => {
          dispatch(logout());
        },
      },
    };
  };

  state = {
    isLoading: false,
    passwordInput: "",
    reasonInput: "",
    waiting: false,
  };

  componentWillReceiveProps(nextProps) {
    if (hasChangedRequestToSuccess(this.props.logoutRequest, nextProps.logoutRequest)) {
      window.location.href = "/";
    }
  }

  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDeleteButtonClick = () => {
    if (!this.state.passwordInput) {
      DialogManager.alert(Strings.PASSWORD_IS_EMPTY);
    } else {
      this.setState({ waiting: true });
      Api.deleteAccount(this.state.passwordInput, this.state.reasonInput)
        .then((response) => {
          this.props.actions.logout();
        })
        .catch((error) => {
          this.setState({ waiting: false });
          DialogManager.alert(Strings.FAILED_TO_DELETE_ACCOUNT);
        });
    }
  };

  render() {
    return (
      <div>
        <DefaultDialogView
          showHeader={true}
          header="기억상자 회원탈퇴"
          panelClassName="width-400"
          onRequestClose={this.props.onRequestClose}
          content={
            <div>
              <div style={styles.row}>
                <span style={styles.key}>이메일</span>
                <span style={styles.value}>{this.props.email}</span>
              </div>
              <div style={styles.row}>
                <span style={styles.key}>비밀번호</span>
                <input
                  name="passwordInput"
                  type="password"
                  style={[styles.input, styles.value]}
                  onChange={this.onInputChange}
                  value={this.state.passwordInput}
                />
              </div>
              <div style={[styles.row, styles.reasonRow]}>
                <span style={styles.key}>사유</span>
                <textarea
                  className="input"
                  rows={5}
                  style={[styles.value, styles.textarea]}
                  name="reasonInput"
                  value={this.state.reasonInput}
                  onChange={this.onInputChange}
                />
              </div>
            </div>
          }
          buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
          footerClassName="button-length-2"
          footerContent={
            <div className="height-100percent">
              <button style={[this.state.passwordInput ? styles.initializeButton : undefined]} onClick={this.onDeleteButtonClick}>
                회원탈퇴
              </button>
              <button style={[styles.footerButton]} onClick={this.props.onRequestClose}>
                취소
              </button>
              {this.state.passwordInput ? undefined : <hr />}
            </div>
          }
        />
        {this.state.waiting ? <Loader /> : undefined}
      </div>
    );
  }
}

const styles = {
  pTag: {
    margin: "10px 0",
    lineHeight: "1.2em",
  },
  input: {
    width: "100%",
    margin: "5px 0",
  },
  initializeButton: {
    backgroundColor: "#F44336",
  },
  textarea: {
    height: "auto",
    margin: "5px 0",
  },
  row: {
    display: "flex",
    paddingTop: 3,
    marginTop: 5,
    alignItems: "center",
  },
  key: {
    flex: "0 0 70px",
  },
  value: {
    width: "100%",
  },
  reasonRow: {
    alignItems: "baseline",
  },
  footerButton: {
    height: "100%",
  },
};

export default connect(DeleteUserDialog.StateToProps, DeleteUserDialog.DispatchToProps)(Radium(DeleteUserDialog));

/**
 * Created by kimchangduk on 2017-08-10.
 */

import React from 'react';
import PropTypes from 'prop-types';

class Loader extends React.Component {
    render() {
        return (<div className="loader-view shade-70">
            <div className="loader-view-icon loading anchor-center"/>
            <div className="text-align-center anchor-center"><span className="loader-view-message text-no-select"/></div>
        </div>);
    }
}

export default Loader;
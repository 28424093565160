/**
 * Created by kimchangduk on 2017-07-23.
 */

import axios from "axios";
import { Consts } from "./constants";
import querystring from "querystring";

const jsonContentTypeHeaderConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

//<editor-fold desc="Account controller /v1/account ">
export function getUser() {
  return axios.get("/v1/account");
}

export function login(email, password) {
  return axios.post("/v1/account/login", { email, password });
}

export function loginWithFacebook(token) {
  return axios.get("/v1/account/facebook/login", { params: { token } });
}

export function loginWithToken(email, token) {
  return axios.post("/v1/account/token/login", { email, token });
}

export function logout() {
  return axios.post("/api/logout");
}

export function signUp(name, nickname, email, password, sex, birthday, agreeMail) {
  const params = { name, nickname, email, password, sex, birthday, agreeMail };
  return axios.post("/v1/account/", params);
}

export function forgotPassword(email) {
  return axios.post("/v1/account/forget/password", { email });
}

export function resetPassword(userId, token, newPassword) {
  return axios.put("/v1/account/password/reset", { userId, token, newPassword });
}

export function validateEmail(id, token) {
  return axios.post("/v1/account/email/verify", { id, token });
}

export function registerFcmToken(token, os) {
  if (os === undefined) {
    os = Consts.OS_WEB;
  }
  return axios.post("/v1/account/fcm", { token, os });
}

export function deleteFcmToken(token) {
  return axios.delete("/v1/account/fcm", { params: { token } });
}

export function changeProfile(params) {
  return axios.put("/v1/account/", params);
}

export function deleteAccount(password, reason) {
  return axios.delete("/v1/account/", {
    params: {
      password,
      reason,
    },
  });
}

//</editor-fold>

//<editor-fold desc="Deck controller /v1/deck">
export function getDeckList() {
  return axios.get("/v1/deck/list");
}

export function getPopularDeckList() {
  return axios.get("/v1/dashboard/popularity");
}

export function getRecentDeckList() {
  return axios.get("/v1/dashboard/recent");
}

export function getMyDeckList() {
  return axios.get("/v1/deck/my");
}

export function getDeckDetails(urlKey) {
  return axios.get(`/v1/deck/${urlKey}`);
}

export function resendVerifyEmail() {
  return axios.get("/v1/account/email/verify/resend");
}

export function getDeckLessonCards(urlKey, lessonId) {
  return axios.get(`/v1/deck/${urlKey}/${lessonId}`);
}

export function changePassword(beforePassword, newPassword) {
  return axios.put("/v1/account/password", { beforePassword, newPassword });
}

//</editor-fold>

//<editor-fold desc="Membox controller /v1/membox">
export function getMemboxTotalInfo() {
  return axios.get("/v1/membox");
}

export function getMemboxDeckInfo(urlKey) {
  return axios.get(`/v1/membox/deck/${urlKey}`);
}

export function getMemboxDeckCardIds(urlKey) {
  return axios.get(`/v1/membox/deck/${urlKey}/cards/id`);
}

export function getMemboxDeckLevelCardIds(urlKey, level, stateType = Consts.MEMORIZE_STATE_READY) {
  return axios.get(`/v1/membox/deck/${urlKey}/level/${level}/cards/id`, { params: { stateType } });
}

export function getMemboxLevelCardIds(level, stateType = Consts.MEMORIZE_STATE_READY, startCardId = null) {
  const parameter = { stateType };
  if (startCardId !== null && startCardId !== undefined) {
    parameter.startCardId = startCardId;
  }
  return axios.get(`/v1/membox/level/${level}/cards/id`, { params: parameter });
}

export function memorize(level, cardId) {
  return axios.post(`/v1/membox/level/${level}/`, { cardId });
}

export function getMemboxCards(cardIds) {
  return axios.get(`/v1/membox/cards?${querystring.stringify({ cardIds: cardIds })}`);
}

export function resetMembox(urlKey = undefined) {
  if (urlKey) {
    return axios.delete(`/v1/membox/deck/${urlKey}`);
  } else {
    return axios.delete("/v1/membox");
  }
}

//</editor-fold>

//<editor-fold desc="Board">
export function getBoardList(page) {
  return axios.get("/v1/board/list", { params: { page } });
}

export function postBoardItem(title, content) {
  return axios.post("/v1/board", { title, content });
}

export function deleteBoardItem(id) {
  return axios.delete(`/v1/board/${id}`);
}

export function getBoardItem(id) {
  return axios.get(`/v1/board/${id}`);
}

export function editBoardItem(id, title, content) {
  const param = {};

  if (title !== undefined) {
    param.title = title;
  }

  if (content !== undefined) {
    param.content = content;
  }

  return axios.put(`/v1/board/${id}`, param);
}

export function postBoardItemComment(id, content) {
  return axios.post(`/v1/board/${id}/comments`, { content });
}

export function deleteBoardItemComment(id, commentsId) {
  return axios.delete(`/v1/board/${id}/comments/${commentsId}`);
}

export function getBoardNoticeList() {
  return axios.get("/v1/board/list/notice");
}

export function setBoardNotice(id) {
  return axios.post(`/v1/manage/board/notice`, { id });
}

export function deleteBoardNotice(id) {
  return axios.delete(`/v1/manage/board/notice/${id}`);
}

//</editor-fold>

//<editor-fold desc="CBT">
export function getCBT(urlKey) {
  return axios.get(`/v1/cbt/${urlKey}`);
}

export function getCBTSolution(urlKey) {
  return axios.get(`/v1/cbt/${urlKey}/solution`);
}

export function getCBTList() {
  return axios.get(`/v1/cbt/list`);
}

export function finishCBT(urlKey, spendingSeconds, examinationPaper) {
  spendingSeconds = Math.floor(spendingSeconds);
  return axios.post(`/v1/cbt/${urlKey}/examinationpaper?spendingSeconds=${spendingSeconds}`, examinationPaper, jsonContentTypeHeaderConfig);
}

export function getCBTPaper(urlKey) {
  return axios.get(`/v1/cbt/examinationpaper/${urlKey}`);
}

//</editor-fold>

export function getMembership(urlKey) {
  return axios.get(`/v1/membership/${urlKey}`);
}

//<editor-fold desc="Category">
export function getCategoryList() {
  return axios.get("/v1/category");
}

export function getCategory(id) {
  return axios.get(`/v1/category/${id}`);
}

//</editor-fold>

//<editor-fold desc="Payment">
export function purchasePre(type, deckUrlKey) {
  if (!type) {
    type = Consts.DECK;
  }

  return axios.post(`/v1/purchase/imp/${type}/${deckUrlKey}/pre`);
}

export function purchaseAfter(imp_uid, merchant_uid, status) {
  const param = {
    imp_uid,
    merchant_uid,
  };

  if (status === null || status === undefined) {
    param.status = status;
  }

  return axios.post(`/v1/purchase/imp`, param);
}

export function registerCoupon(couponCode) {
  return axios.post("/v1/purchase/coupon", { couponCode });
}

export function getPurchaseHistory(page) {
  return axios.get("/v1/purchase/history", { params: { page } });
}

export function getPaymentState() {
  return axios.get("/v1/status/payment");
}

//</editor-fold>

export function editUserData(newValue) {
  return axios.put("/v1/account/data", { shortTermMemoryLevel: newValue });
}

export function search(searchStr) {
  return axios.get(`/v1/search`, { params: { name: searchStr } });
}

export function hashtag(name) {
  return axios.get(`/v1/hashtag/${name}`);
}

export function getPopupList() {
  return axios.get("/v1/dashboard/popup");
}

/**
 * Created by kimchangduk on 2017-08-03.
 */

import React from 'react';
import PropTypes from 'prop-types';

class Timer extends React.Component {
    static propTypes = {
        run: PropTypes.bool,
        startTime: PropTypes.number,
        timeout: PropTypes.number,
        fps: PropTypes.number,
        className: PropTypes.string,
        onTimeout: PropTypes.func,

    };

    static defaultProps = {
        timeout: 30000,
        fps: 60
    };

    state = {
        percent: 0
    };

    componentDidMount() {
        if (this.props.startTime && this.props.run) {
            this.startTimer();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.startTime !== nextProps.startTime && nextProps.startTime && nextProps.run) {
            this.startTimer();
        }

        if (this.props.run !== nextProps.run) {
            if (nextProps.run) {
                this.startTimer();
            } else {
                this.stopTimer();
            }
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            window.clearTimeout(this.timer);
        }
    }

    timer = null;

    startTimer = () => {
        this.stopTimer();
        this.timer = window.setTimeout(this.onTick, this.calculateTickTime());
    };

    stopTimer = () => {
        window.clearTimeout(this.timer);
    };

    onTick = () => {
        const currentTime = Date.now();
        const percent = Math.min(100, (currentTime - this.props.startTime) * 100 / this.props.timeout);
        this.setState({percent});

        if (percent >= 100) {
            if (this.props.run && this.props.onTimeout) {
                this.props.onTimeout();
            }
            this.stopTimer();
        } else {
            this.timer = window.setTimeout(this.onTick, this.calculateTickTime());
        }
    };

    calculateTickTime = () => (1000 / this.props.fps);

    render() {
        const style = {
            width: this.state.percent + '%'
        };
        return (<div className={`study-timer ${this.props.run ? 'working' : ''} ${this.props.className ? this.props.className : ''}`}>
            <div className="timer-icon"><i className="membox-icon timer"/></div>
            <div className="timer-progress">
                <div className="timer-progress-inner" style={style}/>
            </div>
        </div>);
    }
}

export default Timer;
import React from "react";
import "./TermsPage.scss";

export default function PrivacyPolicy({ terms }: { terms: string }) {
  return (
    <div className="terms-page">
      <pre>{terms}</pre>
    </div>
  );
}

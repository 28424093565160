/**
 * Created by kimchangduk on 2017-08-04.
 */
import React from "react";
import {Consts} from "../constants";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ExitIcon from '../assets/icons/exit.png';
import ExitBlackIcon from '../assets/icons/exit_black.png';

class ConfirmDialogView extends React.Component {
    static propTypes = {
        parentClassName: PropTypes.string,
        header: PropTypes.string,
        headerStyle: PropTypes.object,
        showHeader: PropTypes.bool,
        buttonType: PropTypes.string,
        content: PropTypes.node,
        onRequestClose: PropTypes.func,
        onPrimaryButtonClick: PropTypes.func,
        onSecondaryButtonClick: PropTypes.func,
        contentStyle: PropTypes.object,
        footerStyle: PropTypes.object,
        showCloseButton: PropTypes.bool
    };

    static defaultProps = {
        buttonType: Consts.ConfirmDialogViewButtonTypes.YES_OR_NO,
        showCloseButton: false
    };

    componentDidMount() {
        window.addEventListener('click', this.onLeave);
        if (document.activeElement && document.activeElement.blur) {
            document.activeElement.blur();
        }
        if (this.refs.container) {
            this.refs.container.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onLeave);
    }

    onLeave = (e) => {
        if (!this.props.onRequestClose) {
            return;
        }
        const panelNode = ReactDOM.findDOMNode(this);
        if (panelNode && e.target === panelNode) {
            this.onSecondaryButtonClick();
        }
    };

    onPrimaryButtonClick = () => {
        if (this.props.onPrimaryButtonClick) {
            const eventArg = {closeDialog: true};
            this.props.onPrimaryButtonClick(eventArg);
            if (eventArg.closeDialog) {
                this.requestClose();
            }
        } else {
            this.requestClose();
        }
    };

    onSecondaryButtonClick = () => {
        if (this.props.onSecondaryButtonClick) {
            const eventArg = {closeDialog: true};
            this.props.onSecondaryButtonClick(eventArg);
            if (eventArg.closeDialog) {
                this.requestClose();
            }
        } else {
            this.requestClose();
        }
    };

    requestClose = () => {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    };

    render() {
        let primaryButtonText;
        let secondaryButtonText;
        switch (this.props.buttonType) {
            case Consts.ConfirmDialogViewButtonTypes.CONFIRM_OR_CANCEL:
                primaryButtonText = '확인';
                secondaryButtonText = '취소';
                break;
            case Consts.ConfirmDialogViewButtonTypes.YES_OR_NO:
            default:
                primaryButtonText = '예';
                secondaryButtonText = '아니오';
                break;
        }

        return (
            <div ref="container" className={`popup-view popup ${this.props.parentClassName ? this.props.parentClassName : ''}`}>
                <div className="popup-panel default-panel" onClick={this.onLeave}>
                    {
                        this.props.showHeader ?
                            <div className="popup-header" style={this.props.headerStyle}>
                                {this.props.header}
                                {
                                    this.props.showCloseButton ?
                                        <img src={ExitIcon} style={styles.exitIcon} onClick={this.props.onRequestClose}/> : undefined
                                }
                            </div> : undefined
                    }
                    <div className="popup-content" style={this.props.contentStyle}>{this.props.content}</div>
                    <div className="popup-footer button-length-2" style={this.props.footerStyle}>
                        <button onClick={this.onSecondaryButtonClick}>{secondaryButtonText}</button>
                        <button onClick={this.onPrimaryButtonClick}>{primaryButtonText}</button>
                        <hr/>
                    </div>
                </div>
            </div>);
    }
}

const styles = {
    showCloseButton: false,
    exitIcon: {
        position: 'absolute',
        right: 13,
        top: 13,
        cursor: 'pointer'
    }
};

export default ConfirmDialogView;
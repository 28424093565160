/**
 * Created by kimchangduk on 2017-11-24.
 */

import React from 'react';
import PropTypes from 'prop-types';
import PlayButton from '../../../assets/icons/av/ic_play_arrow_black_24px.svg';
import PauseButton from '../../../assets/icons/av/ic_pause_black_24px.svg';
import RepeatIcon1 from '../../../assets/icons/repeat_icon/repeat_1.png';
import RepeatIcon2 from '../../../assets/icons/repeat_icon/repeat_2.png';
import RepeatIcon3 from '../../../assets/icons/repeat_icon/repeat_3.png';
import RepeatIcon4 from '../../../assets/icons/repeat_icon/repeat_4.png';
import RepeatIcon5 from '../../../assets/icons/repeat_icon/repeat_5.png';

class Template10 extends React.Component {
    static propTypes = {
        lessonName: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func,
        data: PropTypes.object,
        autoNextPlay: PropTypes.bool
    };

    static defaultProps = {
        autoNextPlay: true
    };

    state = {
        audio: null,
        playState: PlayStates.Ended,
        playingUrl: '',
        index: 0,
        repeatCount: 2
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.index !== this.state.index) {
            const audioDOM = this.refs["audio-" + this.state.index];
            const fileListDOM = this.refs["file-list"];

            if (audioDOM && fileListDOM &&
                ((fileListDOM.scrollTop + fileListDOM.clientHeight - audioDOM.offsetTop) < audioDOM.clientHeight ||
                    (fileListDOM.scrollTop + fileListDOM.clientHeight - audioDOM.offsetTop) > fileListDOM.clientHeight
                )) {
                fileListDOM.scrollTop = audioDOM.offsetTop;
            }
        }
    }

    componentWillUnmount() {
        const audio = this.state.audio;
        if (audio) {
            this.unbindAudioEvents(audio);
            audio.pause();
        }
    }

    currentRepeat = 0;

    onClickFile = (index) => {
        if (index === this.state.index) {
            if (this.state.playState === PlayStates.Playing) {
                if (this.state.audio) {
                    const audio = this.state.audio;
                    audio.currentTime = 0;
                }
            } else {
                this.onClickPlayButton();
            }
            return;
        }

        const audio = this.state.audio;
        if (audio) {
            this.unbindAudioEvents(audio);
            audio.pause();
        }

        const newAudio = this.buildAudioObject(index);
        this.setState({audio: newAudio, index: index});
        this.forceUpdate();
        newAudio.play();
    };

    onClickPlayButton = () => {
        let audio;
        if (this.state.audio) {
            audio = this.state.audio;

        } else {
            audio = this.buildAudioObject(this.state.index);
        }
        this.setState({
            audio: audio
        });
        this.forceUpdate();
        audio.play();
    };

    onClickPause = () => {
        if (this.state.audio) {
            this.state.audio.pause();
            this.setState({playState: PlayStates.Paused});
            this.forceUpdate();
        }
    };

    /*

    onClickStop = () => {
        const audio = this.state.audio;
        if (audio) {
            audio.removeEventListener('pause', this.onAudioPaused);
            this.setState({audio: null, playState: PlayStates.Ended, index: 0});
            audio.pause();
        }
    };

    onPrevClick = () => {
        if (this.state.audio) {
            const audio = this.state.audio;
            this.unbindAudioEvents(audio);
            audio.pause();
            this.setState({playState: PlayStates.Ended, audio: null});
            this.forceUpdate();
        }
        const newIndex = this.state.index - 1;
        if (newIndex < 0 || newIndex >= this.props.data.audios.length) {
            return;
        }
        const newAudio = this.buildAudioObject(newIndex);
        this.setState({index: newIndex, audio: newAudio});
        this.forceUpdate();
        newAudio.play();
    };

    onNextClick = () => {
        if (this.state.audio) {
            const audio = this.state.audio;
            this.unbindAudioEvents(audio);
            audio.pause();
            this.setState({playState: PlayStates.Ended, audio: null});
            this.forceUpdate();
        }
        const newIndex = this.state.index + 1;
        if (newIndex < 0 || newIndex >= this.props.data.audios.length) {
            return;
        }
        const newAudio = this.buildAudioObject(newIndex);
        this.setState({index: newIndex, audio: newAudio});
        this.forceUpdate();
        newAudio.play();
    };

    */

    onAudioPlayed = (e) => {
        if (e.target === this.state.audio) {
            this.setState({
                playState: PlayStates.Playing
            });
            this.forceUpdate();
        }
    };

    onAudioPaused = (e) => {
        if (e.target === this.state.audio) {
            if (e.target.ended) {
                this.currentRepeat++;
                if (this.currentRepeat < this.state.repeatCount) {
                    e.target.currentTime = 0;
                    e.target.play();
                    return;
                }
            }

            this.setState({
                playState: e.target.ended ? PlayStates.Ended : PlayStates.Paused
            });
            this.forceUpdate();
            if (e.target.ended && this.props.autoNextPlay) {
                let newIndex = this.state.index + 1;
                if (newIndex < this.props.data.audios.length) {
                    const audio = this.state.audio;
                    audio.src = this.props.data.audios[newIndex];
                    audio.currentTime = 0;
                    this.currentRepeat = 0;
                    this.setState({
                        index: newIndex, audio: audio
                    });
                    this.forceUpdate();
                    audio.play();
                }
            }
            this.forceUpdate();
        }
    };

    buildAudioObject = (index) => {
        const audio = new Audio(this.props.data.audios[index]);
        audio.addEventListener('pause', this.onAudioPaused);
        audio.addEventListener('play', this.onAudioPlayed);
        this.currentRepeat = 0;
        return audio;
    };

    unbindAudioEvents = (audio) => {
        audio.removeEventListener('pause', this.onAudioPaused);
        audio.removeEventListener('play', this.onAudioPlayed);
    };

    parseAudioFileName(str) {
        const result = (/\/([^/]+)\.mp3/ig).exec(str);
        if (result && result[1]) {
            return result[1];
        }
        return '';
    }

    renderRepeatButton = () => {
        let image = null;
        switch (this.state.repeatCount) {
            case 1:
                image = RepeatIcon1;
                break;
            case 2:
                image = RepeatIcon2;
                break;
            case 3:
                image = RepeatIcon3;
                break;
            case 4:
                image = RepeatIcon4;
                break;
            case 5:
                image = RepeatIcon5;
                break;
        }

        return <img src={image} style={styles.repeatIcon} onClick={() => {
            if (this.state.repeatCount >= 5) {
                this.setState({repeatCount: 1});
            } else {
                this.setState({repeatCount: this.state.repeatCount + 1});
            }
        }}/>;
    };

    render() {
        return (<div className={this.props.className}>
            <div className="card-container">
                <div className="card-front">
                    <p className="lesson-name">{this.props.lessonName}</p>
                    <div className="player-buttons">
                        {/*<img className="button" src={PrevButton} onClick={this.onPrevClick}/>*/}
                        {this.renderRepeatButton()}
                        {
                            this.state.playState === PlayStates.Playing ?
                                <img className="button" style={styles.playButton} src={PauseButton} onClick={this.onClickPause}/> :
                                <img className="button" style={styles.playButton} src={PlayButton} onClick={this.onClickPlayButton}/>
                        }
                        {/*<img className="button" src={StopButton} onClick={this.onClickStop}/>*/}
                        {/*<img className="button" src={NextButton} onClick={this.onNextClick}/>*/}
                    </div>
                    <div className="file-list" ref="file-list">
                        {
                            this.props.data.audios ?
                                this.props.data.audios.map((item, key) => {
                                    return <div
                                        ref={"audio-" + key}
                                        onClick={() => {
                                            this.onClickFile(key);
                                        }}
                                        key={key}
                                        className={`file ${key == this.state.index ? 'current' : ''} ${key === this.state.index && this.state.playState === PlayStates.Playing ? 'playing' : ''}`}>
                                        {key + 1}. {this.parseAudioFileName(item)}
                                    </div>;
                                }) : undefined
                        }
                    </div>
                </div>
                <div className="card-back"/>
            </div>
        </div>);
    }
}

const PlayStates = {
    Playing: 'playing',
    Paused: 'paused',
    Ended: 'ended',
};

const styles = {
    repeatIcon: {
        height: 41, position: 'relative', top: 3, marginRight: 25, cursor: 'pointer'
    },
    playButton: {marginLeft: 25}
};

export default Template10;
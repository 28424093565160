/**
 * Created by kimchangduk on 2017-08-06.
 */

import * as Api from '../Api';
import {ActionTypes} from '../constants';
import {processHttpError} from '../actions/utils';
import {getMessageFromResponse} from "../utils";

export function getBoardList(page = 0) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_BOARD_LIST_REQUEST, page});
        Api.getBoardList(page).then(response => {
            dispatch({type: ActionTypes.GET_BOARD_LIST_SUCCESS, page, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_BOARD_LIST_FAILURE, errorMessage: '게시판을 불러올 수 없습니다.'});
        });
    };
}

export function getBoardItem(id) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_BOARD_ITEM_VIEW_REQUEST, id});
        Api.getBoardItem(id).then(response => {
            dispatch({type: ActionTypes.GET_BOARD_ITEM_VIEW_SUCCESS, data: response.data, id});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_BOARD_ITEM_VIEW_FAILURE, errorMessage: '게시물을 불러올 수 없습니다.', id});
        });
    };
}

export function postBoardItem(title, content) {
    return (dispatch) => {
        dispatch({type: ActionTypes.POST_BOARD_ITEM_REQUEST});
        Api.postBoardItem(title, content).then(response => {
            dispatch({type: ActionTypes.POST_BOARD_ITEM_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.POST_BOARD_ITEM_FAILURE, errorMessage: '게시물을 등록할 수 없습니다.'});
        });
    };
}

export function deleteBoardItem(id) {
    return (dispatch) => {
        dispatch({type: ActionTypes.DELETE_BOARD_ITEM_REQUEST});
        Api.deleteBoardItem(id).then(response => {
            dispatch({type: ActionTypes.DELETE_BOARD_ITEM_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.DELETE_BOARD_ITEM_FAILURE, errorMessage: '게시물을 삭제할 수 없습니다.'});
        });
    };
}

export function editBoardItem(id, title, content) {
    return (dispatch) => {
        dispatch({type: ActionTypes.EDIT_BOARD_ITEM_REQUEST, id});
        Api.editBoardItem(id, title, content).then(response => {
            dispatch({type: ActionTypes.EDIT_BOARD_ITEM_SUCCESS, data: response.data, id});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.EDIT_BOARD_ITEM_FAILURE, errorMessage: '게시물을 수정할 수 없습니다.', id});
        });
    };
}

export function postBoardItemComment(id, content) {
    return (dispatch) => {
        dispatch({type: ActionTypes.POST_BOARD_ITEM_COMMENT_REQUEST, id});
        Api.postBoardItemComment(id, content).then(response => {
            dispatch({type: ActionTypes.POST_BOARD_ITEM_COMMENT_SUCCESS, data: response.data, id});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.POST_BOARD_ITEM_COMMENT_FAILURE, errorMessage: '댓글을 등록할 수 없습니다.', id});
        });
    };
}

export function deleteBoardItemComment(id, commentId) {
    return (dispatch) => {
        dispatch({type: ActionTypes.DELETE_BOARD_ITEM_COMMENT_REQUEST, id, commentId});
        Api.deleteBoardItemComment(id, commentId).then(response => {
            dispatch({type: ActionTypes.DELETE_BOARD_ITEM_COMMENT_SUCCESS, data: response.data, id, commentId});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.DELETE_BOARD_ITEM_COMMENT_FAILURE, errorMessage: '댓글을 삭제할 수 없습니다.', id, commentId});
        });
    };
}

export function getBoardNoticeList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_BOARD_NOTICE_LIST_REQUEST});
        Api.getBoardNoticeList().then(response => {
            dispatch({type: ActionTypes.GET_BOARD_NOTICE_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.GET_BOARD_NOTICE_LIST_FAILURE, errorMessage: getMessageFromResponse(error.response, '공지 불러오기에 실패했습니다.')});
        });
    };
}

export function setBoardNoticeList(id) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SET_BOARD_NOTICE_LIST_REQUEST});
        Api.setBoardNotice(id).then(response => {
            dispatch({type: ActionTypes.SET_BOARD_NOTICE_LIST_SUCCESS, id, data: response.data});
            getBoardNoticeList()(dispatch);
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.SET_BOARD_NOTICE_LIST_FAILURE, id, errorMessage: getMessageFromResponse(error.response, '공지 설정에 실패했습니다.')});
        });
    };
}

export function deleteBoardNoticeList(id) {
    return (dispatch) => {
        dispatch({type: ActionTypes.DELETE_BOARD_NOTICE_LIST_REQUEST, id});
        Api.deleteBoardNotice(id).then(response => {
            dispatch({type: ActionTypes.DELETE_BOARD_NOTICE_LIST_SUCCESS, id, data: response.data});
            getBoardNoticeList()(dispatch);
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({type: ActionTypes.DELETE_BOARD_NOTICE_LIST_FAILURE, id, errorMessage: getMessageFromResponse(error.response, '공지 해제에 실패했습니다.')});
        });
    };
}
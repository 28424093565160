/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
    urlKey: "",
    errorMessage: "",
    buyRequired: false,
  },
  dataSource: null,
};

export default function deckCardsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DECK_CARDS_REQUEST:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          urlKey: { $set: action.urlKey },
          buyRequired: { $set: false },
        },
      });

    case ActionTypes.GET_DECK_CARDS_SUCCESS: {
      if (state.state.urlKey !== action.urlKey) {
        return state;
      }
      const data = action.data;
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
          errorMessage: { $set: "" },
          buyRequired: { $set: false },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.GET_DECK_CARDS_FAILURE:
      if (state.state.urlKey !== action.urlKey) {
        return state;
      }

      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          errorMessage: { $set: action.errorMessage },
          buyRequired: { $set: action.buyRequired },
        },
      });

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import SearchResult from "./SearchResult";

class SearchBox extends React.Component {
    static propTypes = {
        style: PropTypes.object
    };

    static defaultProps = {
        style: {
            position: 'absolute',
            left: 48,
            right: 114 + 48,
            height: 48,
            lineHeight: '48px',
            fontSize: '1.4em',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            wordWrap: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    };

    state = {
        searchStr: '',
        showResult: false
    };

    componentWillUnmount() {
        if (this.blurTimeout) {
            window.clearTimeout(this.blurTimeout);
        }
    }

    onInputFocus = () => {
        this.setState({showResult: true});
    };

    onInputBlur = () => {
        this.blurTimeout = window.setTimeout(() => {
            this.setState({showResult: false});
        }, 300);
    };

    render() {
        return (<div style={this.props.style}>
            <input
                className="search-box"
                type="text"
                placeholder="검색어"
                value={this.state.searchStr}
                onChange={(e) => this.setState({searchStr: e.target.value})}
                onFocus={this.onInputFocus} onBlur={this.onInputBlur}
            />
            {
                this.state.showResult ?
                    <SearchResult style={styles.searchResult} searchString={this.state.searchStr}/> : undefined
            }
        </div>);
    }
}

const styles = {
    searchResult: {
        position: 'fixed',
        width: '100%',
        top: 40,
        maxWidth: 300,
        left: 58
    }
};

export default SearchBox;
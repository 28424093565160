/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-07-19.
 */

import React from "react";
import {Consts, Urls} from "../constants";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getDeckDetail} from '../actions/deckDetail';
import {getTotalDeckMemboxInfo, getDeckMemboxCardIds} from '../actions/membox';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import MemboxList from "../components/MemboxList";
import DialogManager from "../dialogs/DialogManager";
import GA from '../GA';
import LessonItem from "../components/LessonItem";
import CouponRegisterDialog from "../dialogs/CouponRegisterDialog";
import SelectBuyMethodDialog from "../dialogs/SelectBuyMethodDialog";
import AppHistory from '../history';
import DefaultDialogView from "../dialogs/DefaultDialogView";
import LoginRequiredDialog from "../dialogs/LoginRequiredDialog";
import Loader from "../components/Loader";
import FoldableDiv from "../components/FoldableDiv/FoldableDiv";
import DescriptionDiv from "../components/FoldableDiv/DescriptionDiv";
import LessonUnit from "../components/LessonUnit";

class LessonPage extends React.Component {
    static propTypes = {
        history: PropTypes.object,
        actions: PropTypes.object,
        match: PropTypes.object,
        request: PropTypes.string,
        dataSource: PropTypes.object,
        memboxInfo: PropTypes.object,
        memboxCardIds: PropTypes.object,
        isLogin: PropTypes.bool
    };

    static StateToProps = (state) => {
        return {
            request: state.deckDetail.state.request,
            dataSource: state.deckDetail.dataSource,
            memboxInfo: state.membox.deck.info,
            memboxCardIds: state.membox.deck.cardIds,
            isLogin: state.user.state.isLogin
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getDeckDetail: (urlKey) => {
                    if (urlKey === undefined) {
                        urlKey = ownProps.match.params.urlKey;
                    }
                    dispatch(getDeckDetail(urlKey));
                },
                getDeckMemboxInfo: (urlKey) => {
                    if (urlKey === undefined) {
                        urlKey = ownProps.match.params.urlKey;
                    }
                    dispatch(getTotalDeckMemboxInfo(urlKey));
                },
                getDeckMemboxCardIds: (urlKey) => {
                    if (urlKey === undefined) {
                        urlKey = ownProps.match.params.urlKey;
                    }
                    dispatch(getDeckMemboxCardIds(urlKey));
                },
            }
        };
    };

    constructor(props) {
        super(props);
        if (props.history && props.history.location.state && props.history.location.state.tabPackageIndex !== undefined && props.history.location.state.tabPackageIndex !== null) {
            this.state.selectedPackageIndex = props.history.location.state.tabPackageIndex;
        }
    }

    state = {
        selectedPackageIndex: 0
    };

    componentDidMount() {
        GA.logPage();
        this.props.actions.getDeckDetail();
        this.props.actions.getDeckMemboxInfo();
        this.props.actions.getDeckMemboxCardIds();
    }

    componentWillReceiveProps(nextProps) {
        const prevUrlKey = this.props.match && this.props.match.params ? this.props.match.params.urlKey : null;
        const nextUrlKey = nextProps.match && nextProps.match.params ? nextProps.match.params.urlKey : null;
        if (this.props.isLogin && !nextProps.isLogin || prevUrlKey !== nextUrlKey) {
            this.props.actions.getDeckDetail(nextUrlKey);
            this.props.actions.getDeckMemboxInfo(nextUrlKey);
            this.props.actions.getDeckMemboxCardIds(nextUrlKey);
        }
    }

    onPackageClick = (index) => {
        this.setState({selectedPackageIndex: index});
    };

    onLessonClick = (lessonItem) => {
        const deck = this.props.dataSource;

        if (!deck) return;

        if (deck.has || deck.price === 0 || deck.discountPrice === 0 || lessonItem.free) {
            const memorizedCardCount = this.getMemorizedCardCount(lessonItem);
            const totalCount = lessonItem.cards.length;
            if (memorizedCardCount === totalCount) {
                DialogManager.alert('더 이상 학습 할 내용이 없습니다.', {buttonType: Consts.DefaultDialogViewButtonTypes.SUCCESS});
            } else {
                AppHistory.move(Urls.buildMemorize(this.props.match.params.urlKey, lessonItem.id), {tabPackageIndex: this.state.selectedPackageIndex});
            }
        }
        else {
            this.onBuyClick();
        }
    };

    getMemorizedCardCount = (lessonItem) => {
        let memorizedCardCount = 0;
        const urlKey = this.props.match.params.urlKey;
        const cardIds = this.props.memboxCardIds && this.props.memboxCardIds.state.urlKey === urlKey && this.props.memboxCardIds.state.request === Consts.REQUEST_SUCCESS ?
            this.props.memboxCardIds.dataSource : null;

        if (cardIds) {
            const levelKeys = Object.keys(cardIds);
            for (let cardId of lessonItem.cards) {
                for (let level of levelKeys) {
                    const levelCards = cardIds[level];
                    if (levelCards) {
                        if (levelCards[Consts.MEMORIZE_STATE_READY] && levelCards[Consts.MEMORIZE_STATE_READY].includes(cardId)) {
                            memorizedCardCount += 1;
                        }
                        else if (levelCards[Consts.MEMORIZE_STATE_WAIT] && levelCards[Consts.MEMORIZE_STATE_WAIT].includes(cardId)) {
                            memorizedCardCount += 1;
                        }
                    }
                }
            }
        }

        return memorizedCardCount;
    };

    onBuyClick = () => {
        const deck = this.props.dataSource;
        if (!deck) {
            return;
        }

        // 판매시: 구입하기, 추폰등록창+구입하기창
        // 비매품: 쿠폰 등록창
        if (!this.props.isLogin) {
            DialogManager.push(LoginRequiredDialog, {showCloseButton: true});
            return;
        }

        if (deck.price === null) {
            DialogManager.push(CouponRegisterDialog, {message: `<b>${deck.name}</b> 교재를 학습하려면 구매 도서 인증이 필요합니다.`});
        } else {
            DialogManager.push(SelectBuyMethodDialog, {item: deck});
        }
    };

    renderLessons = (_package) => {
        return (<div>
            {
                _package.lessons ? _package.lessons.map((lessonItem, lessonKey) => {
                    return this.renderLessonItem(lessonItem, lessonKey);
                }) : undefined
            }
            {
                _package.packages ? _package.packages.filter(a => a.type === PackageTypes.UNIT).map((_subPackage, _subPackageKey) => {
                    return <LessonUnit key={_subPackageKey} package={_subPackage} body={this.renderLessons(_subPackage)}/>;
                }) : undefined
            }
        </div>);
    };

    renderLessonItem = (lesson, key) => {
        const memorizedCardCount = this.getMemorizedCardCount(lesson);
        const totalCount = lesson.cards.length;
        const leftCount = totalCount - memorizedCardCount;

        return (<LessonItem key={key} lessonItem={lesson} leftCount={leftCount} onClick={() => {
            this.onLessonClick(lesson);
        }}/>);
    };

    renderDescription = () => {
        if (!this.props.dataSource) {
            return;
        }

        const couponBuy = this.props.dataSource.price === null;
        const has = this.props.dataSource.has;
        const description = this.props.dataSource.description ? this.props.dataSource.description : '';
        const free = this.props.dataSource.price === 0 || this.props.dataSource.discountPrice === 0;
        const showBuyButton = !has && !couponBuy && !free;

        if (showBuyButton || description) {
            return <DescriptionDiv
                data={description}
                showBuyButton={showBuyButton}
                price={this.props.dataSource.discountPrice ? this.props.dataSource.discountPrice : this.props.dataSource.price}
                onBuyClick={this.onBuyClick}/>;
        }
    };

    renderPackageList() {
        const urlKey = this.props.match.params.urlKey;

        let rootPackages = this.props.dataSource && this.props.dataSource.element ? this.props.dataSource.element.packages : null;
        if (rootPackages) {
            rootPackages = rootPackages.filter(a => a.type === PackageTypes.TAB);
        }

        const rootPackagesTab = rootPackages ? (<div className={`package-selector ${!rootPackages || rootPackages.length <= 1 ? 'hidden' : ''}`}>
            {
                rootPackages.map((item, key) => {
                    return (<div
                        key={key}
                        onClick={() => {
                            this.onPackageClick(key);
                        }}
                        className={`package-selector-item ${key === this.state.selectedPackageIndex ? 'selected' : ''}`}>
                        {item.name}
                    </div>);
                })
            }
        </div>) : undefined;

        const lessons = rootPackagesTab ? (<div className="lesson-container">
            {
                rootPackages.map((packageItem, packageKey) => {
                    return <div key={packageKey} className={`lesson-list ${this.state.selectedPackageIndex !== packageKey ? 'invisible' : ''}`}>
                        {this.renderLessons(packageItem)}
                    </div>;
                })
            }
        </div>) : undefined;

        return <div>
            <div className="package-list">
                {this.renderDescription()}
                {rootPackagesTab}
                {lessons}
            </div>
            <MemboxList dataSource={this.props.memboxInfo.dataSource} lessonTabIndex={this.state.selectedPackageIndex} deckUrlKey={urlKey}/>
        </div>;
    }

    render() {
        let content = null;
        if (this.props.memboxInfo.state.request === Consts.REQUEST_WAITING || this.props.request === Consts.REQUEST_WAITING || this.props.memboxCardIds.state.request === Consts.REQUEST_WAITING) {
            content = <Loader/>;
        } else {
            content = this.renderPackageList();
        }

        return (<div id="lesson-page">
            <Navigator leftMenuType={LeftMenuTypes.HOME} title={this.props.dataSource ? this.props.dataSource.name : ''}/>
            {content}
        </div>);
    }
}

const PackageTypes = {UNIT: 'UNIT', TAB: 'TAB'};

export default connect(LessonPage.StateToProps, LessonPage.DispatchToProps)(LessonPage);
/**
 * Created by kimchangduk on 2017-07-29.
 */

import React from "react";
import { connect } from "react-redux";
import { Strings, Urls } from "../constants";
import PropTypes from "prop-types";
import AppHistory from "../history";
import DialogManager from "../dialogs/DialogManager";
import MemboxTabItem from "./MemboxTabItem";

class MemboxList extends React.Component {
  static propTypes = {
    lessonTabIndex: PropTypes.number,
    dataSource: PropTypes.object,
    deckUrlKey: PropTypes.string,
    isLogin: PropTypes.bool
  };

  static StateToProps = state => {
    return {
      isLogin: state.user.state.isLogin
    };
  };

  onMemboxClick = level => {
    if (!this.props.isLogin) {
      AppHistory.moveLoginPage();
      return;
    }

    if (!this.props.dataSource) {
      return;
    }

    const membox = this.props.dataSource[level];
    const caption = Strings.getMemboxLevelCaption(level);
    const readyCount = membox && membox.READY ? membox.READY : 0;
    const waitCount = membox && membox.WAIT ? membox.WAIT : 0;
    if (readyCount > 0) {
      AppHistory.move(Urls.buildRemind(level, this.props.deckUrlKey), {
        tabPackageIndex: this.props.lessonTabIndex
      });
    } else if (waitCount > 0) {
      DialogManager.confirm(
        `${caption} 기억상자는 아직 학습할 준비가 안됐습니다. 기억상자에 있는 항목들을 훑어보겠습니까?`,
        () => {
          AppHistory.move(Urls.buildPreview(level, this.props.deckUrlKey), {
            tabPackageIndex: this.props.lessonTabIndex
          });
        }
      );
    } else {
      DialogManager.alert(`${caption} 기억상자는 비어있습니다.`);
    }
  };

  render() {
    return (
      <div style={styles.container}>
        {["1", "2", "3", "4", "5"].map(item => {
          return (
            <MemboxTabItem
              index={item}
              key={item}
              dataSource={
                this.props.dataSource ? this.props.dataSource[item] : null
              }
              onClick={() => {
                this.onMemboxClick(item);
              }}
            />
          );
        })}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    textAlign: "center",
    height: 72,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "0 auto",
    background: "linear-gradient(to left, rgb(102, 107, 122), #444)",
    borderTop: "1px solid #7f7f7f",
    overflow: "hidden"
  }
};

export default connect(MemboxList.StateToProps)(MemboxList);

/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-08.
 */

import React from 'react';
import PropTypes from 'prop-types';
import * as Api from '../Api';
import AppHistory from '../history';
import DialogManager from "../dialogs/DialogManager";
import GA from '../GA';

class EmailValidatePage extends React.Component {
    static propTypes = {
        match: PropTypes.object
    };

    state = {
        message: ''
    };

    componentDidMount() {
        GA.logPage();
        const id = this.props.match.params.userId;
        const token = this.props.match.params.token;
        Api.validateEmail(id, token).then(response => {
            this.setState({message: '인증되었습니다.<br/>3초뒤 기억상자로 이동합니다.'});
            setTimeout(() => {
                AppHistory.move('/');
            }, 3000);
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message) {
                this.setState({message: error.response.data.message});
            } else {
                this.setState({message: '메일 인증에 실패했습니다.'});
            }
        });
    }

    render() {
        return (<div style={styles.container} dangerouslySetInnerHTML={{__html: this.state.message}}/>);
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        padding: 20,
        background: '#FFFFFF',
        borderRadius: 16,
        textAlign: 'center',
        lineHeight: '23px'
    }
};

export default EmailValidatePage;
/**
 * Created by kimchangduk on 2017-07-25.
 */
import {ActionTypes} from '../constants';

export function openRightSideMenu() {
    return dispatch => {
        dispatch({type: ActionTypes.RIGHT_SIDE_MENU_OPEN});
    };
}

export function openCategorySideMenu() {
    return dispatch => {
        dispatch({type: ActionTypes.CATEGORY_SIDE_MENU_OPEN});
    };
}

export function closeSideMenu() {
    return dispatch => {
        dispatch({type: ActionTypes.SIDE_MENU_CLOSE});
    };
}
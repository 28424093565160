/**
 * Created by kimchangduk on 2017-09-04.
 */

import React from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import FloatingQuestionNavigator from "./FloatingQuestionNavigator";
import Question from "./Question";

class SolutionsForPC extends React.Component {
    static propTypes = {
        cbtSolutionDataSource: PropTypes.object,
        cbtDataSource: PropTypes.object,
        resultDataSource: PropTypes.object,
        scrollParentElement: PropTypes.object
    };

    getSortedQuestionList(dataSource) {
        if (dataSource === undefined) {
            dataSource = this.props.cbtDataSource;
        }
        const result = [];
        const questions = dataSource.questions;
        for (let page of dataSource.questionPage) {
            for (let questionId of page) {
                const question = questions.find(a => a.id === questionId);
                if (question) {
                    result.push(question);
                }
            }
        }
        return questions;
    }

    render() {
        const questions = this.getSortedQuestionList();
        return <div style={styles.questions}>
            {
                questions.map((item, key) => {
                    const userSubmit = this.props.resultDataSource.gradePaper.find(a => a.questionId === item.id);
                    const question = this.props.cbtSolutionDataSource.questions.find(a => a.id === item.id);
                    if (question) {
                        return <div style={styles.question} key={item.id} ref={`question-${item.id}`}>
                            <Question
                                question={item}
                                questionIndex={questions.findIndex(a => a.id === item.id)}
                                rightAnswer={question.answerIds ? question.answerIds : question.answerId}
                                answer={userSubmit.userAnswerId}
                                solution={question.solution}
                                advice={question.advice}
                                joinedStudying={question.joinedStudying}
                                showSolution={!!(question.solution || question.solutionPassage || question.advice)}
                                showRightAnswer
                                showRightSign={userSubmit.right}
                                showWrongSign={!userSubmit.right}
                            />
                        </div>;
                    }
                    return undefined;
                })
            }
            <FloatingQuestionNavigator
                style={styles.floatingQuestionNavigator}
                questions={questions}
                gradePaper={this.props.resultDataSource.gradePaper}
                onChange={(id) => {
                    const element = ReactDOM.findDOMNode(this.refs[`question-${id}`]);
                    if (this.props.scrollParentElement) {
                        this.props.scrollParentElement.scrollTop = element.offsetTop - 10;
                    }
                }}/>
        </div>;
    }
}

const styles = {
    questions: {
        maxWidth: 600,
        margin: '20px auto'
    },
    question: {
        backgroundColor: '#FFFFFF',
        padding: 20,
        marginBottom: 10,
        borderRadius: 10,
        fontWeight: 'bold'
    },
    floatingQuestionNavigator: {
        position: 'fixed',
        right: 20,
        bottom: 10
    }
};

export default SolutionsForPC;
/**
 * Created by kimchangduk on 2017-08-21.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {isMobileDevice} from '../utils';

class RemainTime extends React.Component {
    static propTypes = {
        startTime: PropTypes.number,
        testTime: PropTypes.number
    };

    state = {
        currentTime: Date.now()
    };

    componentDidMount() {
        this.clockTick();
    }

    componentWillUnmount() {
        if (this.clockTimer) {
            window.clearTimeout(this.clockTimer);
        }
    }

    clockTick = () => {
        this.setState({currentTime: Date.now()});
        this.clockTimer = window.setTimeout(this.clockTick, 200);
    };

    buildRemainTimeString() {
        let remainTimeSec = this.props.testTime - this.state.currentTime + this.props.startTime;
        if (remainTimeSec <= 0) {
            return '0:00:00';
        }

        const hour = Math.floor(remainTimeSec / (3600 * 1000));
        remainTimeSec %= (3600 * 1000);
        const min = Math.floor(remainTimeSec / (60 * 1000));
        remainTimeSec %= (60 * 1000);
        const sec = Math.floor(remainTimeSec / 1000);
        return `${hour}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
    }

    render() {
        return (<div style={isMobileDevice() ? styles.container.mobile : styles.container.pc}>남은 시간 {this.buildRemainTimeString()}</div>);
    }
}

const styles = {
    container: {
        pc: {
            display: 'inline-block',
            fontSize: 17
        },
        mobile: {
            fontSize: 15,
            marginBottom: 5
        }
    }
};

export default RemainTime;
/**
 * Created by kimchangduk on 2017-10-24.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DefaultDialogView from "./DefaultDialogView";
import {Consts} from "../constants";
import DialogManager from "./DialogManager";
import * as Api from "../Api";

class EmailVerificationRequiredDialog extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        onRequestClose: PropTypes.func,
        user: PropTypes.object
    };

    static StateToProps = (state, ownProps) => {
        return {
            user: state.user.dataSource,
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {}
        };
    };

    onResendClick = () => {
        if (!this.props.user) {
            return;
        }

        Api.resendVerifyEmail(this.props.user.email).then(response => {
            DialogManager.pop();
            DialogManager.alert(`<strong>${this.props.user.email}</strong>로 이메일 전송에 성공했습니다.`);
        }).catch(error => {
            DialogManager.alert(`<strong>${this.props.user.email}</strong>로 이메일 전송에 실패했습니다.`);
        });
    };

    render() {
        return (<DefaultDialogView
            onRequestClose={this.props.onRequestClose}
            content={
                <div>
                    이메일 인증이 필요합니다.
                    인증 메일을 재전송하시겠습니까?
                </div>
            }
            buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
            footerClassName="button-length-2"
            footerContent={<div style={styles.fullHeight}>
                <button onClick={this.props.onRequestClose}>닫기</button>
                <button onClick={this.onResendClick}>이메일 재전송</button>
                <hr/>
            </div>}
        />);
    }
}

const styles = {
    fullHeight: {height: '100%'}
};

export default connect(EmailVerificationRequiredDialog.StateToProps, EmailVerificationRequiredDialog.DispatchToProps)(EmailVerificationRequiredDialog);
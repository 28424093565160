/**
 * Created by kimchangduk on 2017-04-23.
 */

// User
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGIN_WITH_FACEBOOK_REQUEST = 'LOGIN_WITH_FACEBOOK_REQUEST';
export const LOGIN_WITH_FACEBOOK_SUCCESS = 'LOGIN_WITH_FACEBOOK_SUCCESS';
export const LOGIN_WITH_FACEBOOK_FAILURE = 'LOGIN_WITH_FACEBOOK_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const RIGHT_SIDE_MENU_OPEN = 'RIGHT_SIDE_MENU_OPEN';
export const CATEGORY_SIDE_MENU_OPEN = 'CATEGORY_SIDE_MENU_OPEN';
export const SIDE_MENU_CLOSE = 'SIDE_MENU_CLOSE';

export const GET_DECK_LIST_REQUEST = 'GET_DECK_LIST_REQUEST';
export const GET_DECK_LIST_SUCCESS = 'GET_DECK_LIST_SUCCESS';
export const GET_DECK_LIST_FAILURE = 'GET_DECK_LIST_FAILURE';

export const GET_RECENT_DECK_LIST_REQUEST = 'GET_RECENT_DECK_LIST_REQUEST';
export const GET_RECENT_DECK_LIST_SUCCESS = 'GET_RECENT_DECK_LIST_SUCCESS';
export const GET_RECENT_DECK_LIST_FAILURE = 'GET_RECENT_DECK_LIST_FAILURE';

export const GET_POPULAR_DECK_LIST_REQUEST = 'GET_POPULAR_DECK_LIST_REQUEST';
export const GET_POPULAR_DECK_LIST_SUCCESS = 'GET_POPULAR_DECK_LIST_SUCCESS';
export const GET_POPULAR_DECK_LIST_FAILURE = 'GET_POPULAR_DECK_LIST_FAILURE';

export const GET_MY_DECK_LIST_REQUEST = 'GET_MY_DECK_LIST_REQUEST';
export const GET_MY_DECK_LIST_SUCCESS = 'GET_MY_DECK_LIST_SUCCESS';
export const GET_MY_DECK_LIST_FAILURE = 'GET_MY_DECK_LIST_FAILURE';

export const GET_DECK_DETAIL_REQUEST = 'GET_DECK_DETAIL_REQUEST';
export const GET_DECK_DETAIL_SUCCESS = 'GET_DECK_DETAIL_SUCCESS';
export const GET_DECK_DETAIL_FAILURE = 'GET_DECK_DETAIL_FAILURE';

export const GET_DECK_CARDS_REQUEST = 'GET_DECK_CARDS_REQUEST';
export const GET_DECK_CARDS_SUCCESS = 'GET_DECK_CARDS_SUCCESS';
export const GET_DECK_CARDS_FAILURE = 'GET_DECK_CARDS_FAILURE';

export const GET_DECK_LESSON_CARDS_REQUEST = 'GET_DECK_LESSON_CARDS_REQUEST';
export const GET_DECK_LESSON_CARDS_SUCCESS = 'GET_DECK_LESSON_CARDS_SUCCESS';
export const GET_DECK_LESSON_CARDS_FAILURE = 'GET_DECK_LESSON_CARDS_FAILURE';

//<editor-fold desc="Membox">
export const GET_TOTAL_MEMBOX_INFO_REQUEST = "GET_TOTAL_MEMBOX_INFO_REQUEST";
export const GET_TOTAL_MEMBOX_INFO_SUCCESS = "GET_TOTAL_MEMBOX_INFO_SUCCESS";
export const GET_TOTAL_MEMBOX_INFO_FAILURE = "GET_TOTAL_MEMBOX_INFO_FAILURE";

export const GET_TOTAL_MEMBOX_CARD_REQUEST = "GET_TOTAL_MEMBOX_CARD_REQUEST";
export const GET_TOTAL_MEMBOX_CARD_SUCCESS = "GET_TOTAL_MEMBOX_CARD_SUCCESS";
export const GET_TOTAL_MEMBOX_CARD_FAILURE = "GET_TOTAL_MEMBOX_CARD_FAILURE";

export const GET_DECK_MEMBOX_INFO_REQUEST = "GET_DECK_MEMBOX_INFO_REQUEST";
export const GET_DECK_MEMBOX_INFO_SUCCESS = "GET_DECK_MEMBOX_INFO_SUCCESS";
export const GET_DECK_MEMBOX_INFO_FAILURE = "GET_DECK_MEMBOX_INFO_FAILURE";

export const GET_DECK_MEMBOX_CARD_ID_REQUEST = "GET_DECK_MEMBOX_CARD_ID_REQUEST";
export const GET_DECK_MEMBOX_CARD_ID_SUCCESS = "GET_DECK_MEMBOX_CARD_ID_SUCCESS";
export const GET_DECK_MEMBOX_CARD_ID_FAILURE = "GET_DECK_MEMBOX_CARD_ID_FAILURE";

export const GET_DECK_MEMBOX_LEVEL_CARD_ID_REQUEST = "GET_DECK_MEMBOX_LEVEL_CARD_ID_REQUEST";
export const GET_DECK_MEMBOX_LEVEL_CARD_ID_SUCCESS = "GET_DECK_MEMBOX_LEVEL_CARD_ID_SUCCESS";
export const GET_DECK_MEMBOX_LEVEL_CARD_ID_FAILURE = "GET_DECK_MEMBOX_LEVEL_CARD_ID_FAILURE";

export const GET_TOTAL_MEMBOX_LEVEL_CARD_ID_REQUEST = "GET_TOTAL_MEMBOX_LEVEL_CARD_ID_REQUEST";
export const GET_TOTAL_MEMBOX_LEVEL_CARD_ID_SUCCESS = "GET_TOTAL_MEMBOX_LEVEL_CARD_ID_SUCCESS";
export const GET_TOTAL_MEMBOX_LEVEL_CARD_ID_FAILURE = "GET_TOTAL_MEMBOX_LEVEL_CARD_ID_FAILURE";

export const GET_MEMBOX_CARDS_REQUEST = "GET_MEMBOX_CARDS_REQUEST";
export const GET_MEMBOX_CARDS_SUCCESS = "GET_MEMBOX_CARDS_SUCCESS";
export const GET_MEMBOX_CARDS_FAILURE = "GET_MEMBOX_CARDS_FAILURE";

export const MEMORIZE_CARD_REQUEST = "MEMORIZE_CARD_REQUEST";
export const MEMORIZE_CARD_SUCCESS = "MEMORIZE_CARD_SUCCESS";
export const MEMORIZE_CARD_FAILURE = "MEMORIZE_CARD_FAILURE";

export const RESET_MEMBOX_REQUEST = "RESET_MEMBOX_REQUEST";
export const RESET_MEMBOX_SUCCESS = "RESET_MEMBOX_SUCCESS";
export const RESET_MEMBOX_FAILURE = "RESET_MEMBOX_FAILURE";
//</editor-fold>

//<editor-fold desc="Board">
export const GET_BOARD_NOTICE_LIST_REQUEST = "GET_BOARD_NOTICE_LIST_REQUEST";
export const GET_BOARD_NOTICE_LIST_SUCCESS = "GET_BOARD_NOTICE_LIST_SUCCESS";
export const GET_BOARD_NOTICE_LIST_FAILURE = "GET_BOARD_NOTICE_LIST_FAILURE";

export const SET_BOARD_NOTICE_LIST_REQUEST = "SET_BOARD_NOTICE_LIST_REQUEST";
export const SET_BOARD_NOTICE_LIST_SUCCESS = "SET_BOARD_NOTICE_LIST_SUCCESS";
export const SET_BOARD_NOTICE_LIST_FAILURE = "SET_BOARD_NOTICE_LIST_FAILURE";

export const DELETE_BOARD_NOTICE_LIST_REQUEST = "DELETE_BOARD_NOTICE_LIST_REQUEST";
export const DELETE_BOARD_NOTICE_LIST_SUCCESS = "DELETE_BOARD_NOTICE_LIST_SUCCESS";
export const DELETE_BOARD_NOTICE_LIST_FAILURE = "DELETE_BOARD_NOTICE_LIST_FAILURE";

export const GET_BOARD_LIST_REQUEST = "GET_BOARD_LIST_REQUEST";
export const GET_BOARD_LIST_SUCCESS = "GET_BOARD_LIST_SUCCESS";
export const GET_BOARD_LIST_FAILURE = "GET_BOARD_LIST_FAILURE";

export const GET_BOARD_ITEM_VIEW_REQUEST = "GET_BOARD_ITEM_VIEW_REQUEST";
export const GET_BOARD_ITEM_VIEW_SUCCESS = "GET_BOARD_ITEM_VIEW_SUCCESS";
export const GET_BOARD_ITEM_VIEW_FAILURE = "GET_BOARD_ITEM_VIEW_FAILURE";

export const POST_BOARD_ITEM_REQUEST = "POST_BOARD_ITEM_REQUEST";
export const POST_BOARD_ITEM_SUCCESS = "POST_BOARD_ITEM_SUCCESS";
export const POST_BOARD_ITEM_FAILURE = "POST_BOARD_ITEM_FAILURE";

export const EDIT_BOARD_ITEM_REQUEST = "EDIT_BOARD_ITEM_REQUEST";
export const EDIT_BOARD_ITEM_SUCCESS = "EDIT_BOARD_ITEM_SUCCESS";
export const EDIT_BOARD_ITEM_FAILURE = "EDIT_BOARD_ITEM_FAILURE";

export const DELETE_BOARD_ITEM_REQUEST = "DELETE_BOARD_ITEM_REQUEST";
export const DELETE_BOARD_ITEM_SUCCESS = "DELETE_BOARD_ITEM_SUCCESS";
export const DELETE_BOARD_ITEM_FAILURE = "DELETE_BOARD_ITEM_FAILURE";

export const POST_BOARD_ITEM_COMMENT_REQUEST = "POST_BOARD_ITEM_COMMENT_REQUEST";
export const POST_BOARD_ITEM_COMMENT_SUCCESS = "POST_BOARD_ITEM_COMMENT_SUCCESS";
export const POST_BOARD_ITEM_COMMENT_FAILURE = "POST_BOARD_ITEM_COMMENT_FAILURE";

export const DELETE_BOARD_ITEM_COMMENT_REQUEST = "DELETE_BOARD_ITEM_COMMENT_REQUEST";
export const DELETE_BOARD_ITEM_COMMENT_SUCCESS = "DELETE_BOARD_ITEM_COMMENT_SUCCESS";
export const DELETE_BOARD_ITEM_COMMENT_FAILURE = "DELETE_BOARD_ITEM_COMMENT_FAILURE";
//</editor-fold>

//<editor-fold desc="CBT">
export const GET_CBT_LIST_REQUEST = "GET_CBT_LIST_REQUEST";
export const GET_CBT_LIST_SUCCESS = "GET_CBT_LIST_SUCCESS";
export const GET_CBT_LIST_FAILURE = "GET_CBT_LIST_FAILURE";

export const GET_CBT_REQUEST = "GET_CBT_REQUEST";
export const GET_CBT_SUCCESS = "GET_CBT_SUCCESS";
export const GET_CBT_FAILURE = "GET_CBT_FAILURE";

export const GET_CBT_SOLUTION_REQUEST = "GET_CBT_SOLUTION_REQUEST";
export const GET_CBT_SOLUTION_SUCCESS = "GET_CBT_SOLUTION_SUCCESS";
export const GET_CBT_SOLUTION_FAILURE = "GET_CBT_SOLUTION_FAILURE";

export const FINISH_CBT_REQUEST = "FINISH_CBT_REQUEST";
export const FINISH_CBT_SUCCESS = "FINISH_CBT_SUCCESS";
export const FINISH_CBT_FAILURE = "FINISH_CBT_FAILURE";

export const GET_CBT_PAPER_REQUEST = "GET_CBT_PAPER_REQUEST";
export const GET_CBT_PAPER_SUCCESS = "GET_CBT_PAPER_SUCCESS";
export const GET_CBT_PAPER_FAILURE = "GET_CBT_PAPER_FAILURE";
//</editor-fold>

//<editor-fold desc="Membership 가져오기">
export const GET_MEMBERSHIP_REQUEST = 'GET_MEMBERSHIP_REQUEST';
export const GET_MEMBERSHIP_SUCCESS = 'GET_MEMBERSHIP_SUCCESS';
export const GET_MEMBERSHIP_FAILURE = 'GET_MEMBERSHIP_FAILURE';
//</editor-fold>

//<editor-fold desc="Category">
export const GET_CATEGORY_LIST_REQUEST = "GET_CATEGORY_LIST_REQUEST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILURE = "GET_CATEGORY_LIST_FAILURE";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";
//</editor-fold>

//<editor-fold desc="Purchase">
export const GET_PURCHASE_HISTORY_REQUEST = "GET_PURCHASE_HISTORY_REQUEST ";
export const GET_PURCHASE_HISTORY_SUCCESS = "GET_PURCHASE_HISTORY_SUCCESS ";
export const GET_PURCHASE_HISTORY_FAILURE = "GET_PURCHASE_HISTORY_FAILURE ";
//</editor-fold>

export const SET_SHORT_TERM_MEMORY_LEVEL = "SET_SHORT_TERM_MEMORY_LEVEL";
import {ActionTypes, Consts} from '../constants';
import * as Api from '../Api';
import {getMessageFromResponse} from '../utils';
import {processHttpError} from '../actions/utils';

export function getCBT(urlKey) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_REQUEST, urlKey: urlKey});
        Api.getCBT(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_CBT_SUCCESS, urlKey: urlKey, data: response.data});
        }).catch(error => {
            let buyRequired = false;
            if (error.response && error.response.data) {
                buyRequired = error.response.data.id === Consts.RESPONSE_ID_BUY_REQUIRED;
            }
            processHttpError(dispatch, error);
            dispatch({
                type: ActionTypes.GET_CBT_FAILURE,
                errorMessage: getMessageFromResponse(error.response, 'CBT 목록을 읽어올 수 없습니다.'),
                urlKey: urlKey,
                buyRequired
            });
        });
    };
}

export function getCBTList() {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_LIST_REQUEST,});
        Api.getCBTList().then(response => {
            dispatch({type: ActionTypes.GET_CBT_LIST_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({
                type: ActionTypes.GET_CBT_LIST_REQUEST,
                errorMessage: getMessageFromResponse(error.response, 'CBT 목록을 읽어올 수 없습니다.')
            });
        });
    };
}

export function getCBTSolution(urlKey) {
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_SOLUTION_REQUEST});
        Api.getCBTSolution(urlKey).then(response => {
            dispatch({type: ActionTypes.GET_CBT_SOLUTION_SUCCESS, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({
                type: ActionTypes.GET_CBT_SOLUTION_FAILURE,
                errorMessage: getMessageFromResponse(error.response, '해설을 읽어올 수 없습니다.')
            });
        });
    };
}


export function getCBTPaper(id) {
    if (typeof(id) === 'string') {
        id = parseInt(id);
    }
    return (dispatch) => {
        dispatch({type: ActionTypes.GET_CBT_PAPER_REQUEST, id: id});
        Api.getCBTPaper(id).then(response => {
            dispatch({type: ActionTypes.GET_CBT_PAPER_SUCCESS, id: id, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({
                type: ActionTypes.GET_CBT_PAPER_FAILURE,
                id: id,
                errorMessage: getMessageFromResponse(error.response, '답안지를 가져올 수 없습니다.')
            });
        });
    };
}


export function finishCBT(urlKey, spendingSeconds, examinationPaper) {
    return (dispatch) => {
        dispatch({type: ActionTypes.FINISH_CBT_REQUEST, urlKey: urlKey});
        Api.finishCBT(urlKey, spendingSeconds, examinationPaper).then(response => {
            dispatch({type: ActionTypes.FINISH_CBT_SUCCESS, urlKey: urlKey, data: response.data});
        }).catch(error => {
            processHttpError(dispatch, error);
            dispatch({
                type: ActionTypes.FINISH_CBT_FAILURE,
                urlKey: urlKey,
                errorMessage: getMessageFromResponse(error.response, '답안지를 제출할 수 없습니다.')
            });
        });
    };
}
/**
 * Created by kimchangduk on 2017-04-23.
 */

import {ActionTypes, Strings} from '../constants';
import {getUser} from '../actions/user';
import * as Api from '../Api';
import {processHttpError} from '../actions/utils';
import {getMessageFromResponse} from '../utils';

export function signUp(name, nickname, email, password, sex, birthday, agreeMail) {
    return (dispatch) => {
        dispatch({type: ActionTypes.SIGNUP_REQUEST});
        return Api.signUp(name, nickname, email, password, sex, birthday, agreeMail).then(response => {
            dispatch({type: ActionTypes.SIGNUP_SUCCESS});
            getUser()(dispatch);
        }).catch(error => {
            processHttpError(dispatch, error);
            let errorMessage = getMessageFromResponse(error.response, Strings.FAILED_TO_SIGN_UP);
            dispatch({type: ActionTypes.SIGNUP_FAILURE, errorMessage: errorMessage});
        });
    };
}
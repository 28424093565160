/**
 * Created by kimchangduk on 2017-9-15.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";
import { shuffleArray } from "../utils";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
    deckUrlKey: "",
    lessonId: -1,
    errorMessage: "",
    buyRequired: false,
  },
  dataSource: null,
};

export default function deckLessonCardsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_DECK_LESSON_CARDS_REQUEST:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          deckUrlKey: { $set: action.deckUrlKey },
          lessonId: { $set: action.lessonId },
          buyRequired: { $set: false },
        },
      });

    case ActionTypes.GET_DECK_LESSON_CARDS_SUCCESS: {
      if (state.state.deckUrlKey !== action.deckUrlKey || state.state.lessonId !== action.lessonId) {
        return state;
      }
      const data = action.data;
      if (action.shuffle) {
        shuffleArray(data);
      }

      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
          errorMessage: { $set: "" },
          buyRequired: { $set: false },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.GET_DECK_LESSON_CARDS_FAILURE:
      if (state.state.deckUrlKey !== action.deckUrlKey || state.state.lessonId !== action.lessonId) {
        return state;
      }

      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          errorMessage: { $set: action.errorMessage },
          buyRequired: { $set: action.buyRequired },
        },
      });

    default:
      return state;
  }
}

/**
 * Created by kimch on 2017-07-25.
 */
import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  rightSideMenuVisible: false,
  categorySideMenuVisible: false,
};

export default function sideMenuReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RIGHT_SIDE_MENU_OPEN: {
      return update(state, {
        rightSideMenuVisible: { $set: true },
      });
    }

    case ActionTypes.CATEGORY_SIDE_MENU_OPEN: {
      return update(state, {
        categorySideMenuVisible: { $set: true },
      });
    }

    case ActionTypes.SIDE_MENU_CLOSE: {
      return update(state, {
        rightSideMenuVisible: { $set: false },
        categorySideMenuVisible: { $set: false },
      });
    }

    default:
      return state;
  }
}

/**
 * Created by kimchangduk on 2017-10-15.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Consts} from '../constants';
import {connect} from 'react-redux';
import {resetMembox} from '../actions/membox';
import Loader from "../components/Loader";
import DefaultDialogView from "./DefaultDialogView";
import {hasChangedRequestToSuccess, hasChangedRequestToFailure} from '../utils';
import DialogManager from "./DialogManager";
import AppHistory from "../history";
import * as Urls from "../constants/Urls";
import {getDeckDetail} from "../actions/deckDetail";
import {REQUEST_WAITING} from "../constants/Consts";

class MemboxResetDialog extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        nickname: PropTypes.string,
        onRequestClose: PropTypes.func,
        resetMemboxRequest: PropTypes.string,
        resetMemboxErrorMessage: PropTypes.string,
        deckDataSource: PropTypes.object,
        deckRequest: PropTypes.string
    };

    static StateToProps = (state, ownProps) => {
        return {
            nickname: state.user.dataSource ? state.user.dataSource.nickname : '',
            resetMemboxRequest: state.membox.resetMembox.state.request,
            resetMemboxErrorMessage: state.membox.resetMembox.state.errorMessage,
            deckDataSource: state.deckDetail.dataSource,
            deckRequest: state.deckDetail.state.request,
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                resetMembox: (urlKey) => {
                    dispatch(resetMembox(urlKey));
                },
                getDeckDetail: (urlKey) => {
                    dispatch(getDeckDetail(urlKey));
                }
            }
        };
    };

    state = {
        isLoading: false,
        nicknameInput: ''
    };

    componentDidMount() {
        const deckUrlKey = this.getDeckUrlKey();
        if (deckUrlKey && !this.isDeckPage() && this.props.deckRequest !== REQUEST_WAITING) {
            this.props.actions.getDeckDetail(deckUrlKey);
        }
    }

    componentWillReceiveProps = (nextProps) => {
        if (hasChangedRequestToSuccess(this.props.resetMemboxRequest, nextProps.resetMemboxRequest)) {
            /* 초기화후 리프레쉬 X
            DialogManager.pop();
            DialogManager.alert('기억상자 정보가 초기화되었습니다.');
            */
            window.location.reload();
        }
        if (hasChangedRequestToFailure(this.props.resetMemboxRequest, nextProps.resetMemboxRequest)) {
            DialogManager.alert(nextProps.resetMemboxErrorMessage);
        }
    };

    onNicknameInputChange = (e) => {
        this.setState({
            nicknameInput: e.target.value
        });
    };

    onDeleteButtonClick = () => {
        this.props.actions.resetMembox(this.getDeckUrlKey());
    };

    isDeckPage = () => {
        if (!this.props.deckDataSource) {
            return false;
        }

        const deckUrlKey = this.getDeckUrlKey();
        return deckUrlKey && deckUrlKey === this.props.deckDataSource.urlKey;
    };

    getDeckUrlKey = () => {
        const pathName = AppHistory.getPathName();
        for (let prefix of [Urls.LESSON, Urls.MEMORIZE]) {
            const regex = new RegExp(prefix + "/([^/]+)");
            if (regex.test(pathName)) {
                return regex.exec(pathName)[1];
            }
        }
        for (let prefix of [Urls.REMIND, Urls.PREVIEW]) {
            const regex = new RegExp(prefix + "/\\d+/([^/]+)");
            if (regex.test(pathName)) {
                return regex.exec(pathName)[1];
            }
        }
    };

    render() {
        return (<div>
            {this.props.deckRequest === Consts.REQUEST_WAITING ? <div/> :
                <DefaultDialogView
                    panelClassName="width-400"
                    onRequestClose={this.props.onRequestClose}
                    content={<div>
                        {
                            this.isDeckPage() ?
                                <p style={styles.pTag}>{`'${this.props.deckDataSource.name}' 기억상자 학습 데이터를 모두 초기화합니다.`}</p>
                                : <p style={styles.pTag}>기억상자 학습 데이터를 모두 초기화합니다.</p>
                        }
                        <p style={styles.pTag}>아래에 사용자의 닉네임을 입력하시기 바랍니다.</p>
                        <input type="text" style={styles.fullWidth} onChange={this.onNicknameInputChange} value={this.state.nicknameInput} placeholder={this.props.nickname}/>
                    </div>}
                    buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
                    footerContent={<button
                        style={this.props.nickname === this.state.nicknameInput ? styles.initializeButton : undefined}
                        onClick={this.props.nickname === this.state.nicknameInput ? this.onDeleteButtonClick : undefined}
                    >
                        기억상자 초기화
                    </button>}
                />
            }
            {
                this.props.deckRequest === Consts.REQUEST_WAITING || this.props.resetMemboxRequest === Consts.REQUEST_WAITING ?
                    <Loader/> : undefined
            }
        </div>);
    }
}

const styles = {
    pTag: {
        margin: '10px 0',
        lineHeight: '1.2em'
    },
    fullWidth: {
        width: '100%',
        margin: '5px 0'
    },
    initializeButton: {
        backgroundColor: '#F44336'
    }
};

export default connect(MemboxResetDialog.StateToProps, MemboxResetDialog.DispatchToProps)(MemboxResetDialog);
/**
 * Created by kimchangduk on 2018-02-23.
 */
/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";
import * as Api from "../Api";
import "./NoticePopupList.scss";
import update from "immutability-helper";
import CloseButton from "../assets/icons/popup_close_button.svg";
import { Consts } from "../constants";
import { isMobileDevice } from "../utils";
import history from "../history";

class NoticePopupList extends React.Component {
  state = {
    closedPopups: [],
    popups: [],
  };

  componentDidMount() {
    if (window.location.pathname === "/") this.fetchPopups();
  }

  fetchPopups = () => {
    Api.getPopupList()
      .then((response) => {
        let closedPopups = [];
        for (let item of response.data) {
          if (this.getCookie(cookieNamePrefix + item.id) === cookieValue) {
            closedPopups.push(item.id);
          }
        }
        this.setState({ popups: response.data, closedPopups });
      })
      .catch((error) => {});
  };

  onClose = (id) => {
    this.setState({ closedPopups: update(this.state.closedPopups, { $push: [id] }) });
  };

  onCloseDay = (id) => {
    this.addClosedPopupCookie(cookieNamePrefix + id);
    this.setState({ closedPopups: update(this.state.closedPopups, { $push: [id] }) });
  };

  closeAllPopups = () => {
    if (this.state.popups) this.setState({ closedPopups: this.state.popups.map((item) => item.id) });
  };

  addClosedPopupCookie(cname) {
    const todayValue = new Date();
    // 오늘 날짜를 변수에 저장

    todayValue.setDate(todayValue.getDate() + 1);
    document.cookie = cname + "=" + encodeURI(cookieValue) + "; expires=" + todayValue.toGMTString() + "; path=/;";
  }

  getCookie(name) {
    const cookieName = name + "=";
    let x = 0;
    let lastChrCookie;
    while (x <= document.cookie.length) {
      const y = x + cookieName.length;
      if (document.cookie.substring(x, y) === cookieName) {
        if ((lastChrCookie = document.cookie.indexOf(";", y)) < 0) lastChrCookie = document.cookie.length;
        return decodeURI(document.cookie.substring(y, lastChrCookie));
      }
      x = document.cookie.indexOf(" ", x) + 1;
      if (x === 0) break;
    }
    return "";
  }

  onPopupNodeCreate = (node) => {
    if (!node) {
      return;
    }

    const aTags = node.getElementsByTagName("a");
    if (aTags) {
      for (let aTag of aTags) {
        aTag.addEventListener("click", (e) => {
          e.preventDefault();
          const href = aTag.getAttribute("href");
          if (href) {
            this.closeAllPopups();
            history.move(href, { descriptionOpen: true });
          }
        });
      }
    }
  };

  getShowingPopups = () => {
    return this.state.popups.filter((a) => this.state.closedPopups.findIndex((b) => a.id === b) < 0);
  };

  renderPopupForPC = (item, key) => {
    return (
      <div key={key} className="notice-popup">
        <div
          ref={(instance) => {
            this.onPopupNodeCreate(instance);
          }}
          dangerouslySetInnerHTML={{ __html: item.content }}
          className="content"
        />
        <div className="button-list">
          <a
            className="button"
            onClick={() => {
              this.onCloseDay(item.id);
            }}
          >
            하루동안 열지 않기
          </a>
          <a
            className="button"
            onClick={() => {
              this.onClose(item.id);
            }}
          >
            닫기
          </a>
        </div>
      </div>
    );
  };

  renderPopupForMobile = (item, key) => {
    return (
      <div>
        <div key={key} className="notice-popup-mobile">
          <div
            ref={(instance) => {
              this.onPopupNodeCreate(instance);
            }}
            dangerouslySetInnerHTML={{ __html: item.content }}
            className="content"
          />
        </div>
        <div className="button-list-mobile">
          <a
            className="button"
            onClick={() => {
              this.onCloseDay(item.id);
            }}
          >
            하루동안 열지 않기
          </a>
          <a
            className="button"
            onClick={() => {
              this.onClose(item.id);
            }}
          >
            닫기
          </a>
        </div>
      </div>
    );
  };

  render() {
    const isMobile = isMobileDevice();
    const showingPopups = this.getShowingPopups();
    return (
      <div className="notice-popup-list">
        {showingPopups && showingPopups.length > 0 ? <div className="background" /> : undefined}
        {showingPopups && showingPopups.length > 0
          ? isMobile
            ? this.renderPopupForMobile(showingPopups[0])
            : this.renderPopupForPC(showingPopups[0])
          : undefined}
      </div>
    );
  }
}

const cookieNamePrefix = "closed-popup";
const cookieValue = "closed";

export default NoticePopupList;

/**
 * Created by kimchangduk on 2017-07-23.
 */
import createBrowserHistory from "history/createBrowserHistory";
import { Urls } from "./constants";

const appHistory = createBrowserHistory();
export const appHistoryObject = appHistory;

export default {
  move: (url, state) => {
    appHistory.push(url, state);
  },
  replace: (url, state) => {
    appHistory.replace(url, state);
  },
  goBack: (url) => {
    if (appHistory.length > 1) {
      appHistory.goBack();
    } else {
      appHistory.push(url);
    }
  },
  moveSignUpPage: (redirectionPathname, redirectionState) => {
    // TODO: 모바일앱 처리
    if (appHistory) {
      appHistory.push(Urls.SIGNUP, {
        redirectionPathname: redirectionPathname === undefined ? appHistory.location.pathname : redirectionPathname,
        redirectionState: redirectionState === undefined ? appHistory.location.state : redirectionState,
      });
    } else {
      window.location.ref = Urls.LOGIN;
    }
  },
  moveLoginPage: () => {
    // TODO: 모바일앱 처리
    if (window.android && window.android.onLogin) {
      window.android.onLogin();
    } else {
      if (appHistory) {
        appHistory.push(Urls.LOGIN, {
          redirectionPathname: appHistory.location.pathname,
          redirectionState: appHistory.location.state,
        });
      } else {
        window.location.ref = Urls.LOGIN;
      }
    }
  },
  replaceLoginPage: () => {
    // TODO: 모바일앱 처리
    if (window.android && window.android.onLogin) {
      window.android.onLogin();
    } else {
      if (appHistory) {
        appHistory.replace(Urls.LOGIN, {
          redirectionPathname: appHistory.location.pathname,
          redirectionState: appHistory.location.state,
        });
      } else {
        window.location.ref = Urls.LOGIN;
      }
    }
  },
  getPathName: () => {
    return appHistory.location.pathname;
  },
};

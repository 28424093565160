/**
 * Created by kimchangduk on 2017-09-04.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Question from "./Question";
import Swipable from "../Swipable";
import ReactDOM from "react-dom";

class SolutionsForPC extends React.Component {
    static propTypes = {
        cbtDataSource: PropTypes.object,
        cbtSolutionDataSource: PropTypes.object,
        resultDataSource: PropTypes.object
    };

    constructor(props) {
        super(props);
        const questions = this.getSortedQuestionList();
        if (questions && questions.length > 0) {
            this.state.currentQuestionId = questions[0].id;
        }
    }

    state = {currentQuestionId: null};

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state.currentQuestionId) {
            const scrollable = this.refs['questions'];
            scrollable.scrollTop = 0;
        }
    }

    getSortedQuestionList(dataSource) {
        if (dataSource === undefined) {
            dataSource = this.props.cbtDataSource;
        }
        const result = [];
        const questions = dataSource.questions;
        for (let page of dataSource.questionPage) {
            for (let questionId of page) {
                const question = questions.find(a => a.id === questionId);
                if (question) {
                    result.push(question);
                }
            }
        }
        return questions;
    }

    onCurrentQuestionIdChange = (e) => {
        const newVal = parseInt(e.target.value);
        this.setState({currentQuestionId: newVal});
    };

    render() {
        const questions = this.getSortedQuestionList();
        const questionIndex = questions.findIndex(a => a.id === this.state.currentQuestionId);
        const item = questions[questionIndex]; // CBTSolutionsForPC에서 쓰이는 item과 동일
        const userSubmit = this.props.resultDataSource.gradePaper.find(a => a.questionId === item.id);
        const question = this.props.cbtSolutionDataSource.questions.find(a => a.id === this.state.currentQuestionId);

        const prevButtonDisabled = !questions || !questions[0] || questions[0].id === this.state.currentQuestionId;
        const nextButtonDisabled = !questions || !questions[questions.length - 1] || questions[questions.length - 1].id === this.state.currentQuestionId;

        return <div style={styles.container}>
            <div style={styles.question} ref="questions">
                {
                    item && question ? <Swipable>
                        <Question
                            question={item}
                            questionIndex={questions.findIndex(a => a.id === item.id)}
                            rightAnswer={question.answerIds ? question.answerIds : question.answerId}
                            answer={userSubmit.userAnswerId}
                            solution={question.solution}
                            advice={question.advice}
                            joinedStudying={question.joinedStudying}
                            showSolution={!!(question.solution || question.solutionPassage || question.advice)}
                            showRightAnswer
                            showRightSign={userSubmit.right}
                            showWrongSign={!userSubmit.right}
                        />
                    </Swipable> : undefined
                }
            </div>
            <div style={styles.navigator}>
                <button style={styles.navigatorInput} className="plumb-button plumb-button-default" disabled={prevButtonDisabled} onClick={() => {
                    this.setState({currentQuestionId: questions[questionIndex - 1].id});
                }}>
                    이전
                </button>
                <select style={styles.navigatorInput} value={this.state.currentQuestionId} onChange={this.onCurrentQuestionIdChange}>
                    {questions.map((item, key) => (<option key={item.id} value={item.id}>{parseInt(key) + 1}번 문항</option>))}
                </select>
                <button style={styles.navigatorInput} className="plumb-button plumb-button-default" disabled={nextButtonDisabled} onClick={() => {
                    this.setState({currentQuestionId: questions[questionIndex + 1].id});
                }}>
                    다음
                </button>
            </div>
        </div>;
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#FFFFFF'
    },
    question: {
        height: 'calc(100% - 43px)',
        borderBottom: '1px solid #ccc',
        overflow: 'auto',
        padding: 20
    },
    navigator: {
        height: 43,
        textAlign: 'center',
        padding: 5
    },
    navigatorInput: {
        marginTop: 2
    }
};

export default SolutionsForPC;
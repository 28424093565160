import React, { useRef, useEffect } from "react";
import { Consts } from "../../constants";
import "./AdFitCard.scss";

export default function AdFitVertical({ onSkip, hide }) {
  const containerRef = useRef();

  useEffect(() => {
    if (!hide) {
      let scr = document.createElement("script");
      scr.setAttribute("async", "true");
      scr.setAttribute("type", "text/javascript");
      scr.setAttribute("src", "//t1.daumcdn.net/kas/static/ba.min.js");
      containerRef.current.append(scr);
    }
  }, [hide]);

  return (
    <div className={`adfit-card-container`} ref={containerRef}>
      <div className={`kakao-card${hide ? " hide" : ""}`}>
        <ins className="kakao_ad_area" style={{ display: "none" }} data-ad-unit={window.ADFIT_CARD_UNIT} data-ad-width="250" data-ad-height="250"></ins>
      </div>
      <button
        className="skip-btn"
        onClick={() => {
          if (onSkip && !hide) onSkip();
        }}
      >
        SKIP
      </button>
    </div>
  );
}

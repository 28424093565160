/**
 * Created by kimchangduk on 2017-10-16.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Consts} from '../constants';
import {connect} from 'react-redux';
import {getPurchaseHistory} from '../actions/purchase';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import Loader from "../components/Loader";
import {toDateFormat, payMethodToKorean, toPriceFormat, hasChangedRequestToFailure} from '../utils';
import '../styles/purchase-history.scss';
import DialogManager from "../dialogs/DialogManager";
import PurchaseHistoryDetailDialog from "../dialogs/PurchaseHistoryDetailDialog";
import GA from '../GA';

class PurchaseHistoryPage extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        purchaseRequest: PropTypes.string,
        purchaseErrorMessage: PropTypes.string,
        purchasePage: PropTypes.number,
        purchaseLast: PropTypes.bool,
        purchaseDataSource: PropTypes.array
    };

    static StateToProps = (state, ownProps) => {
        return {
            purchaseRequest: state.purchase.history.state.request,
            purchaseErrorMessage: state.purchase.history.state.errorMessage,
            purchasePage: state.purchase.history.state.page,
            purchaseLast: state.purchase.history.state.last,
            purchaseDataSource: state.purchase.history.dataSource
        };
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {
                getPurchaseHistory: (page = 0) => {
                    dispatch(getPurchaseHistory(page));
                }
            }
        };
    };

    componentDidMount() {
        GA.logPage();
        this.props.actions.getPurchaseHistory();
    }

    componentWillReceiveProps(nextProps) {
        if (hasChangedRequestToFailure(this.props.purchaseErrorMessage, nextProps.purchaseErrorMessage)) {
            return DialogManager.alert(nextProps.purchaseErrorMessage);
        }
    }

    renderPurchaseHistoryItem(item, key) {
        const imp = item.imp;
        const items = item.items;
        let itemText = '';
        for (let i = 0; i < items.length; i++) {
            if (i > 0) itemText += ', ';
            itemText += items[i].name;
        }

        return <div key={key} style={styles.purchaseItem} onClick={() => {
            this.onPurchaseHistoryItemClick(item);
        }}>
            <div className="purchase-history-item">
                <div>{toDateFormat(new Date(item.registeredDate))}</div>
                <div>{itemText}</div>
                <div>{item.coupon ? '₩0' : (imp ? toPriceFormat(imp.amount) : '')}</div>
                <div>{item.coupon ? '쿠폰' : (imp ? payMethodToKorean(imp.pgProvider, imp.payMethod) : undefined)}</div>
            </div>
        </div>;
    }

    onPurchaseHistoryItemClick = (item) => {
        DialogManager.push(PurchaseHistoryDetailDialog, {history: item});
    };

    onLoadMoreClick = (e) => {
        if (this.props.purchaseRequest === Consts.REQUEST_WAITING) {
            return;
        }
        this.props.actions.getPurchaseHistory(this.props.purchasePage + 1);
    };

    render() {
        return (<div>
            <Navigator leftMenuType={LeftMenuTypes.HOME} title="결제 내역"/>
            <div style={styles.container}>
                {this.props.purchaseRequest === Consts.REQUEST_WAITING ? <Loader/> : undefined}
                {
                    this.props.purchaseDataSource ? <div>
                        <div style={styles.purchaseContainer}>
                            {
                                this.props.purchaseDataSource.map((item, key) => {
                                    return (this.renderPurchaseHistoryItem(item, key));
                                })
                            }
                            {
                                this.props.purchaseDataSource && this.props.purchaseDataSource.length === 0 ?
                                    <p style={styles.zeroAlert}>결제 내역이 없습니다.</p> : undefined
                            }
                            {!this.props.purchaseLast ? <button className="flat-button full" onClick={this.onLoadMoreClick}>더 불러오기</button> : undefined}
                        </div>
                    </div> : undefined
                }
            </div>
        </div>);
    }
}

const styles = {
    container: {
        position: 'absolute',
        top: 53,
        left: 0,
        right: 0,
        bottom: 0,
        whiteSpace: 'nowrap',
        overflowY: 'auto',
        padding: '0 5px'
    },
    purchaseContainer: {
        maxWidth: 1000,
        margin: 'auto',
        overflow: 'hidden',
        fontSize: 13
    },
    purchaseItem: {
        background: '#FFFFFF',
        margin: 5,
        padding: 10,
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        cursor: 'pointer'
    },
    zeroAlert: {
        display: 'block',
        fontSize: 18,
        textAlign: 'center',
        marginTop: 10
    }
};

export default connect(PurchaseHistoryPage.StateToProps, PurchaseHistoryPage.DispatchToProps)(PurchaseHistoryPage);
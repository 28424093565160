/**
 * Created by kimchangduk on 2017-08-22.
 */

import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import ConfirmDialogView from "./ConfirmDialogView";
import DialogManager from "./DialogManager";
import UnmarkedQuestions from "../components/CBT/UnmarkedQuestions";

class CBTFinishConfirmDialog extends React.Component {
  static propTypes = {
    onRequestClose: PropTypes.func,
    onRequestNaviation: PropTypes.func,
    answers: PropTypes.array,
    questions: PropTypes.array,
    onRequestFinish: PropTypes.func,
  };

  renderUnmarkedQuestions = (questionIndexes) => {
    return (
      <div>
        <UnmarkedQuestions questionIndexes={questionIndexes} onNavigationClick={this.onNavigationClick} />
        <hr style={styles.hr} />
      </div>
    );
  };

  onNavigationClick = (selectedIndex) => {
    const question = this.props.questions[selectedIndex];
    if (!question) {
      DialogManager.alert("이동할 문항을 선택해 주세요.");
    } else {
      if (this.props.onRequestNaviation) this.props.onRequestNaviation(question.id);
      if (this.props.onRequestClose) this.props.onRequestClose();
    }
  };

  render() {
    const answers = this.props.answers;
    const unmarkedQuestionIndexes = [];
    for (let key in answers) {
      if (answers[key] === null) {
        unmarkedQuestionIndexes.push(parseInt(key));
      }
    }

    return (
      <ConfirmDialogView
        content={
          <div>
            {unmarkedQuestionIndexes && unmarkedQuestionIndexes.length > 0 ? this.renderUnmarkedQuestions(unmarkedQuestionIndexes) : undefined}
            <strong>정말로 시험을 종료하시겠습니까?</strong>
          </div>
        }
        onPrimaryButtonClick={() => {
          if (this.props.onRequestFinish) this.props.onRequestFinish();
        }}
        onRequestClose={this.props.onRequestClose}
        contentStyle={{ textAlign: "center" }}
        footerStyle={{ textAlign: "center" }}
      />
    );
  }
}

const borderColor = "rgba(0,0,0,0.2)";

const styles = {
  hr: {
    border: "none",
    margin: "15px 0",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.2)",
  },
};

export default Radium(CBTFinishConfirmDialog);

/**
 * Created by kimchangduk on 2017-07-23.
 */

export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const ROOT = "/";
export const CBT = "/cbt";
export const MEMBERSHIP = "/membership";
export const BOARD = "/board";
export const BOARD_WRITE = "/board/write";
export const profile = "/profile";
export const PURCHASE_HISTORY = "/purchaseHistory";
export const buildMembership = (urlKey) => {
  return `${MEMBERSHIP}/${urlKey}`;
};
export const buildLesson = (urlKey) => {
  return `${LESSON}/${urlKey}`;
};
export const buildMemorize = (urlKey, lessonId) => {
  return `${MEMORIZE}/${urlKey}/${lessonId}`;
};

//<editor-fold desc="deck관련">
export const LESSON = "/lesson";
export const REMIND = "/remind";
export const PREVIEW = "/preview";
export const MEMORIZE = "/memorize";
export const buildRemind = (level, deckUrlKey) => `/remind/${level}/${deckUrlKey ? deckUrlKey : ""}`;
export const buildPreview = (level, deckUrlKey) => `/preview/${level}/${deckUrlKey ? deckUrlKey : ""}`;
//</editor-fold>

export const buildVoiceMp3 = (deckId, fileName) => `https://s3.ap-northeast-2.amazonaws.com/memorybox/audio/${deckId}/${fileName}`;
export const buildCategory = (id) => `/category/${id}`;
export const buildCBT = (urlKey) => `/cbt/${urlKey}`;
export const buildBoardItem = (id) => `/board/${id}`;
export const buildHashtag = (name) => `/tag/${name}`;
export const buildBoardItemEdit = (id) => `/board/${id}/edit`;
export const buildCBTResult = (cbtId, paperId) => `/cbt/${cbtId}/result/${paperId}`;
export const buildCBTResultSolution = (cbtId, paperId) => `/cbt/${cbtId}/result/${paperId}/solution`;

export const loginRequiredUrls = [REMIND, PURCHASE_HISTORY, PREVIEW];

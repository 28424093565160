/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-08-05.
 */

 import React from "react";
 import { connect } from "react-redux";
 import PropTypes from "prop-types";
 import { Consts } from "../constants";
 import * as Api from "../Api";
 import ConfirmDialogView from "./ConfirmDialogView";
 import DefaultDialogView from "./DefaultDialogView";
 import Loader from "../components/Loader";
 import DialogManager from "./DialogManager";
 import EmailVerificationRequiredDialog from "./EmailVerificationRequiredDialog";
 import { getMessageFromResponse } from "../utils";
 import DescImg from '../assets/images/isbn_desc.png';
 
 class CouponRegisterDialog extends React.Component {
   static propTypes = {
     onRequestClose: PropTypes.func,
     message: PropTypes.string,
     checkCouponUsable: PropTypes.bool,
     isLogin: PropTypes.bool,
   };
 
   static defaultProps = {
     checkCouponUsable: true,
   };
 
   static StateToProps = (state) => {
     return { isLogin: state.user.state.isLogin };
   };
 
   constructor(props) {
     super(props);
 
     this.state = {
       couponNumber: "",
       errorMessage: "",
       paymentStatusRequest: props.checkCouponUsable ? Consts.REQUEST_WAITING : Consts.REQUEST_NONE,
       paymentStatus: null,
     };
 
     if (this.props.checkCouponUsable) {
       Api.getPaymentState()
         .then((response) => {
           this.setState({ paymentStatus: response.data, paymentStatusRequest: Consts.REQUEST_SUCCESS });
         })
         .catch((error) => {
           this.setState({ paymentStatusRequest: Consts.REQUEST_FAILURE });
         });
     }
   }
 
   onInputChange = (e) => {
     this.setState({ couponNumber: e.target.value });
   };
 
   onConfirmClick = (e) => {
     e.closeDialog = false;
 
     const couponNumber = this.state.couponNumber.replace(/[^0-9.]/g, '');
     if(couponNumber.length == 0) {
       this.setState({
         errorMessage: "ISBN번호를 입력해주세요",
       });  
       return;
     }
 
     
     this.setState({
       errorMessage: "",
     });
 
     
     Api.registerCoupon(couponNumber)
       .then((response) => {
         // 쿠폰 등록 성공시 새로고침
         window.location.reload();
       })
       .catch((error) => {
         if (error.response && error.response.data && error.response.data.id === Consts.RESPONSE_ID_EMAIL_VERIFICATION_REQUIRED) {
           DialogManager.push(EmailVerificationRequiredDialog);
         } else {
           this.setState({ errorMessage: getMessageFromResponse(error.response, "구매 도서 인증에 실패했습니다.") });
         }
       });
   };
 
   renderCouponUseDialog = () => {
     return (
       <ConfirmDialogView
         showCloseButton={true}
         onRequestClose={this.props.onRequestClose}
         parentClassName="coupon-view"
         headerStyle={styles.textAlignCenter}
         showHeader={true}
         header="구매 도서 인증"
         buttonType={Consts.ConfirmDialogViewButtonTypes.CONFIRM_OR_CANCEL}
         onPrimaryButtonClick={this.onConfirmClick}
         content={[
           <div key="0" className={`message ${this.props.message ? "" : "hidden"}`} dangerouslySetInnerHTML={{ __html: this.props.message }} />,
           <p key="1"><strong>ISBN 번호를 입력한 후 [확인] 버튼을 클릭하세요.</strong></p>,
           <div key="2" className="control-panel">
             <input type="text" className="bg editbox-full" value={this.state.couponNumber} onChange={this.onInputChange} placeholder="ISBN 번호(-제외)" />
             <div className="control-invalid-message2">{this.state.errorMessage}</div>
             <p style={styles.info}><strong>종이책 </strong>: <br/>도서 뒷표지 아래쪽에 있는 바코드의 아래쪽 숫자</p>
             <img src={DescImg} width="100%"/>
             <p style={styles.info}><strong>전자책(eBook) </strong>:<br/>전자책 앞 또는 뒷부분의 판권면 (발행인, 편집자 등을 표시하는 곳) 중 ISBN에 표기(예: 979-11-6050-407-1 05320으로 된 곳의 앞 13자리 숫자)</p>
           </div>,
         ]}
       />
     );
   };
 
   render() {
     if (this.props.checkCouponUsable) {
       switch (this.state.paymentStatusRequest) {
         case Consts.REQUEST_SUCCESS: {
           const couponStatus = this.state.paymentStatus ? this.state.paymentStatus.find((item) => item.name === "COUPON") : false;
           if (!couponStatus) {
             return <DefaultDialogView content={<p>현재 이용하실 수 없습니다.</p>} onRequestClose={this.props.onRequestClose} />;
           }
 
           if (couponStatus.active) {
             return this.renderCouponUseDialog();
           } else {
             return <DefaultDialogView content={couponStatus.message} onRequestClose={this.props.onRequestClose} />;
           }
         }
 
         case Consts.REQUEST_FAILURE:
           return <DefaultDialogView content={<p>현재 결제를 이용하실 수 없습니다.</p>} onRequestClose={this.props.onRequestClose} />;
 
         case Consts.REQUEST_WAITING:
         default:
           return <Loader />;
       }
     } else {
       return this.renderCouponUseDialog();
     }
   }
 }
 
 const styles = {
   textAlignCenter: { textAlign: "center" },
   info: { marginTop: 15, fontSize: 14, lineHeight:1.55 },
 };
 
 export default connect(CouponRegisterDialog.StateToProps)(CouponRegisterDialog);
 
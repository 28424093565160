/* eslint-disable react/no-danger */
/**
 * Created by kimchangduk on 2017-07-25.
 */

import React from 'react';
import PropTypes from 'prop-types';
import AppHistory from '../history';
import {Urls} from '../constants';
import {toPriceFormat} from "../utils";
import Dotdotdot from 'react-dotdotdot';
import { isMobileDevice } from "../utils";

class DeckItem extends React.Component {
    static propTypes = {
        deck: PropTypes.object
    };

    onClick = () => {
        if (!this.props.deck) return;
        AppHistory.move(Urls.buildLesson(this.props.deck.urlKey));
    };

    renderForMobile() {
        const spaceRemovedName = this.props.deck.name ? this.props.deck.name.replace(/\s+/g, " ") : '';

        return (<div className="deck-item-mobile" onClick={this.onClick}>
            <div className="cover">
                <div className="cover-wrapper"><img src={this.props.deck.coverImage} style={styles.img}/></div>
            </div>
            <div className="info" style={{padding: 5, textAlign: 'left', lineHeight: '1.5em'}}>
				<p className="name">{spaceRemovedName}</p>
                <div style={{position: "absolute", bottom: 10, fontSize: 11, color: '#689f38', fontWeight: 'bold'}}>
                    {this.renderPrice()}
                </div>
            </div>
        </div>);
    }

    renderForPC() {
        return (<div className="deck-item" onClick={this.onClick}>
            <div className="cover">
                <img src={this.props.deck.coverImage} style={styles.img}/>
            </div>
            <div style={{padding: 5, textAlign: 'left', lineHeight: '1.5em'}}>
                <div style={styles.name}>
                    <Dotdotdot clamp={2}>
                        <p>{this.props.deck.name}</p>
                    </Dotdotdot>
                </div>
                <div style={{fontSize: 13, color: '#689f38', fontWeight: 'bold', textAlign: 'right'}}>
                    {this.renderPrice(this.props.deck)}
                </div>
            </div>
        </div>);
    }

    renderPrice = () => {
        if (this.props.deck.has) {
            return <span>구입한 항목</span>;
        }

        if (this.props.deck.salesLabel) {
            return <span dangerouslySetInnerHTML={{__html: this.props.deck.salesLabel}}/>;
        }
        if (this.props.deck.discountPrice !== null && this.props.deck.discountPrice !== undefined) {
            return <span><span className="text-line-through">{toPriceFormat(this.props.deck.price)}</span> <span>{toPriceFormat(this.props.deck.discountPrice)}</span></span>;
        }
        return <span>{toPriceFormat(this.props.deck.price)}</span>;
    };

    render() {
        if (isMobileDevice()) {
            return this.renderForMobile();
        } else {
            return this.renderForPC();
        }
    }
}

const styles = {
    buyTag: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 48,
        height: 48
    },
    img: {width: '100%', height: '100%'},
    name: {
        wordBreak: 'break-all',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: '#212121',
        fontSize: 14
    }
};

export default DeckItem;
/**
 * Created by kimchangduk on 2017-08-19.
 */

import React from "react";
import Radium from "radium";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Question from "./Question";

class CBTQuestionsForMobile extends React.Component {
  static defaultProps = {
    fontScale: 1,
  };

  static propTypes = {
    fontScale: PropTypes.number,
    style: PropTypes.object,
    classSubjects: PropTypes.array,
    classSubjectIndex: PropTypes.object,
    questions: PropTypes.array,
    dummyAnswersMap: PropTypes.array,
    answers: PropTypes.array,
    onAnswerClick: PropTypes.func,
    onDummyAnswerClick: PropTypes.func,
    questionNavigation: PropTypes.object,
    onFinishRequest: PropTypes.func,
  };
  state = {
    currentQuestionId: 0,
  };

  componentDidMount() {
    this.initCurrentQuestionId();
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.questionNavigation !== nextProps.questionNavigation) {
      this.navigateToQuestion(nextProps.questionNavigation);
    } else if (this.props.questions !== nextProps.questions) {
      if (nextProps.questions) {
        this.initCurrentQuestionId(nextProps.questions);
      }
    }
  };

  initCurrentQuestionId = (questions) => {
    if (questions === undefined) {
      questions = this.props.questions;
    }

    if (!questions || questions.length <= 0) {
      return;
    }

    this.setState({ currentQuestionId: questions[0].id });
  };

  buildQuestionRef = (id) => `question-${id}`;

  onPrevButtonClick = () => {
    const questions = this.props.questions;
    const currentIndex = questions.findIndex((q) => q.id === this.state.currentQuestionId);
    const prevQuestion = questions[currentIndex - 1];
    if (prevQuestion) {
      this.setState({ currentQuestionId: prevQuestion.id });
    }
  };

  onNextButtonClick = () => {
    const questions = this.props.questions;
    const currentIndex = questions.findIndex((q) => q.id === this.state.currentQuestionId);
    const nextQuestion = questions[currentIndex + 1];
    if (nextQuestion) {
      this.setState({ currentQuestionId: nextQuestion.id });
    }
  };

  navigateToQuestion = ({ id }) => {
    this.setState({ currentQuestionId: id });
  };

  onAnswerClick = (questionId, answerNumber) => {
    if (this.props.onAnswerClick) {
      this.props.onAnswerClick(questionId, answerNumber);
    }
    this.onNextButtonClick();
  };

  renderSubjectDivider = (classSubjectId) => {
    const classSubjectIndex = this.props.classSubjects.findIndex((a) => a.id === classSubjectId);
    const classSubject = this.props.classSubjects[classSubjectIndex];
    if (classSubject && classSubject.subject) {
      return (
        <div style={styles.subjectDivider}>
          과목{classSubjectIndex + 1}: {classSubject.subject.name}
        </div>
      );
    }
  };

  renderQuestion = () => {
    const questions = this.props.questions;
    const questionId = this.state.currentQuestionId;
    const questionIndex = questions.findIndex((a) => a.id === this.state.currentQuestionId);
    const question = questions[questionIndex];
    if (question) {
      return (
        <div>
          {this.props.classSubjectIndex && this.props.classSubjectIndex[question.classSubjectId] === question.id
            ? this.renderSubjectDivider(question.classSubjectId)
            : undefined}
          <Question
            ref={this.buildQuestionRef(question.id)}
            key={questionId}
            question={question}
            fontScale={this.props.fontScale}
            answer={this.props.answers[questionIndex]}
            dummyAnswers={this.props.dummyAnswersMap[questionIndex]}
            onAnswerClick={this.onAnswerClick}
            onDummyAnswerClick={this.props.onDummyAnswerClick}
            questionIndex={questionIndex}
          />
        </div>
      );
    }
  };

  render() {
    const questions = this.props.questions;
    return (
      <div style={[styles.container, this.props.style]}>
        <div style={styles.questionPages}>{this.renderQuestion()}</div>
        <div style={styles.pagenator}>
          <button
            style={styles.button}
            className="plumb-button plumb-button-default"
            disabled={questions[0].id === this.state.currentQuestionId}
            onClick={this.onPrevButtonClick}
          >
            이전
          </button>
          <button
            style={styles.button}
            className="plumb-button plumb-button-default"
            onClick={this.onNextButtonClick}
            disabled={questions[questions.length - 1].id === this.state.currentQuestionId}
          >
            다음
          </button>
          <button style={[styles.button, styles.finishButton]} className="plumb-button plumb-button-cancel" onClick={this.props.onFinishRequest}>
            종료
          </button>
        </div>
      </div>
    );
  }
}

const tableBorderColor = "rgba(0,0,0,0.5)";

const styles = {
  container: {
    display: "inline-block",
    backgroundColor: "#FFFFFF",
    textAlign: "left",
    fontWeight: "bold",
  },
  example: {
    paddingLeft: 15,
  },
  exampleNumber: {
    cursor: "pointer",
    position: "relative",
  },
  questionPages: {
    height: "calc(100% - 50px)",
    position: "relative",
    overflowY: "auto",
    padding: 5,
  },
  question: {
    marginBottom: 20,
  },
  questionNumber: {
    width: 20,
  },
  pagenator: {
    height: 50,
    paddingTop: 3,
    borderTop: "1px solid",
    borderTopColor: tableBorderColor,
    textAlign: "center",
    position: "relative",
  },
  button: {
    height: 38,
    padding: "9px 12px",
    marginLeft: 3,
    marginRight: 3,
  },
  finishButton: {
    position: "absolute",
    right: 3,
    top: 3,
  },
  subjectDivider: {
    textAlign: "center",
    padding: 5,
    background: "rgba(0,0,0,0.2)",
    border: "1px solid #000000",
    marginBottom: 10,
  },
};

export default Radium(CBTQuestionsForMobile);

import GA4React from "ga-4-react";
import { store } from "./store";

const GA_ENABLED = true;

const measureId = "G-Z9HT75FPQ0";
const ga4react = new GA4React(measureId);

class GA {
  initialized = false;
  initialize = () => {
    if (GA_ENABLED) {
      const state = store.getState();
      ga4react.initialize().then((ga4) => {
        this.initialized = true;
        ga4.pageview(window.location.pathname + window.location.search);
      });
    }
  };

  logPage = () => {
    if (GA_ENABLED && this.initialized) {
      ga4react.pageview(window.location.pathname + window.location.search);
    }
  };

  setUserId = (userId) => {
    if (GA_ENABLED) {
      try {
        ga4react.gtag("config", measureId, {
          user_id: userId,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
}

const singleton = new GA();

export default singleton;

/**
 * Created by kimchangduk on 2017-07-27.
 */

import React from "react";
import update from "immutability-helper";
import { Consts, Strings } from "../constants";
import PropTypes from "prop-types";
import CardQueueProgress from "./CardQueueProgress";
import { connect } from "react-redux";
import CardView from "./CardView/CardView";
import Swipable from "./Swipable";
import Timer from "./Timer";
import { getDeckList } from "../actions/deckList";
import MemboxTabItem from "./MemboxTabItem";
import { ENABLE_AD_CARD } from "../constants/Consts";
import AdFitHorizontal from "./AdFit/AdFitHorizontal";
const AD_CARD_ID = "AD_CARD";

// RemindPage, PreviewPage, MemorizePage에서 보여주는 카드 학습 컴포넌트
class PreviewStudyView extends React.Component {
  static propTypes = {
    lessonName: PropTypes.string, // MemorizePage에서만 사용
    enableTimeOver: PropTypes.bool,
    memboxInfo: PropTypes.object,
    memorizedCardIds: PropTypes.array,
    cardIds: PropTypes.array,
    cardDataSource: PropTypes.array,
    totalCardCount: PropTypes.number,
    onMemorize: PropTypes.func,
    memboxLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    leftMemboxLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightMemboxLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actions: PropTypes.object,
    deckListRequest: PropTypes.string,
    onComplete: PropTypes.func,
  };

  static defaultProps = {
    enableTimeOver: false,
    memboxLevel: "0",
    totalCardCount: 0,
  };

  static StateToProps = (state, ownProps) => {
    return {};
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {},
    };
  };

  constructor(props) {
    super(props);
    let adCardIndex = -1; //localStorage.getItem("adCardIndex");
    if (typeof adCardIndex === "string") {
      adCardIndex = parseInt(adCardIndex);
    }
    if (adCardIndex === null || adCardIndex === undefined || isNaN(adCardIndex)) {
      adCardIndex = 49;
    }
    this.state = {
      removing: false,
      learnLevel: Consts.LearnLevels.CHECKING,
      frontCardFliped: false,
      frontCardShakeTime: false,
      frontCardFlyToNext: false,
      frontCardFlyToPrev: false,
      frontCardFadeOutToLeft: false,
      frontCardFadeOutToRight: false,
      timerStartTime: Date.now(),
      adCardInterval: 50, // 광고카드 주기
      currentAdCardIndex: adCardIndex, // 현재 광고카드 인덱스,
      pauseUpdateRendering: false,
      cardIdQueue: [],
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.pauseUpdateRendering) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.cardIds) {
      return null;
    }
    const cardIds = props.cardIds.filter((a) => props.memorizedCardIds.findIndex((b) => b === a) < 0);

    if (ENABLE_AD_CARD) {
      let { currentAdCardIndex } = state;
      if (currentAdCardIndex < 0) {
        currentAdCardIndex = cardIds.length;
      }
      const cardIdQueue =
        state.adCardInterval > 0 && cardIds.length >= currentAdCardIndex ? update(cardIds, { $splice: [[currentAdCardIndex, 0, AD_CARD_ID]] }) : cardIds;
      return { cardIdQueue, currentAdCardIndex };
    }
    return { cardIdQueue: cardIds };
  }

  onKeyDown = (e) => {
    switch (e.keyCode) {
      // ← 키입력
      case 37:
        this.onCardBack();
        break;
      // → 키입력
      case 39:
        this.onCardNext();
        break;
      // space 키입력
      case 32:
        this.onFlip();
        break;
      default:
        break;
    }
  };

  onCardBack = () => {
    switch (this.state.learnLevel) {
      case Consts.LearnLevels.CHECKING:
        this.shake();
        break;
      case Consts.LearnLevels.CHECKED:
      case Consts.LearnLevels.TIMEOVER: {
        const { cardIdQueue } = this.state;
        if (cardIdQueue && cardIdQueue.length > 0) {
          this.moveToBack(cardIdQueue[0]);
        }
        break;
      }
      default:
        break;
    }
  };

  onCardNext = () => {
    const cardIdsQueue = this.state.cardIdQueue;
    if (this.state.currentAdCardIndex === 0) {
      if (cardIdsQueue && cardIdsQueue.length > 0) {
        this.moveCardNext(cardIdsQueue[0]);
      }
      return;
    }
    switch (this.state.learnLevel) {
      case Consts.LearnLevels.CHECKING:
        this.shake();
        break;
      case Consts.LearnLevels.CHECKED: {
        if (cardIdsQueue && cardIdsQueue.length > 0) {
          this.moveCardNext(cardIdsQueue[0]);
        }
        break;
      }
      case Consts.LearnLevels.TIMEOVER:
        this.shake();
        break;
      default:
        break;
    }
  };

  onFlip = (learnLevel) => {
    if (this.state.removing) return;
    const cardIdQueue = this.state.cardIdQueue;
    if (!cardIdQueue || cardIdQueue.length <= 0) {
      return;
    }
    const card = this.props.cardDataSource.find((a) => a.id === cardIdQueue[0]);
    if (!card) {
      return;
    }

    switch (card.templateId) {
      case 10:
        return;
      default:
        break;
    }

    const updateValue = { frontCardFliped: !this.state.frontCardFliped };

    if (this.state.learnLevel !== Consts.LearnLevels.TIMEOVER && this.state.learnLevel !== Consts.LearnLevels.CHECKED) {
      if (!learnLevel) {
        learnLevel = Consts.LearnLevels.CHECKED;
      }
      updateValue.learnLevel = learnLevel;
    }

    this.setState(updateValue);
  };

  resetCardRemoveState() {
    this.setState({
      removing: false,
      learnLevel: Consts.LearnLevels.CHECKING,
      frontCardFliped: false,
      frontCardShakeTime: false,
      frontCardFlyToNext: false,
      frontCardFlyToPrev: false,
      frontCardFadeOutToLeft: false,
      frontCardFadeOutToRight: false,
      timerStartTime: this.props.cardIds && this.props.cardIds.length > 0 ? Date.now() : undefined,
    });
  }

  moveToBack = (card) => {
    if (this.state.removing) return;

    //#region 왼쪽으로 넘길 기억상자가 존재하지 않으면 날라가고 아니면 빙글빙글 효과를 내며 기억상자속으로 가도록
    const needToMemorize = this.props.leftMemboxLevel !== undefined && this.props.leftMemboxLevel !== null;
    if (needToMemorize) {
      this.setState({ removing: true, frontCardFlyToPrev: true });
    } else {
      this.setState({ removing: true, frontCardFadeOutToLeft: true });
    }
    //#endregion

    window.setTimeout(() => {
      this.setState({ pauseUpdateRendering: true });
      if (card !== AD_CARD_ID && this.props.onMemorize) {
        this.props.onMemorize(card, this.props.leftMemboxLevel);
      }
      // this.moveNextAdCard();
      this.setState({ pauseUpdateRendering: false });
      this.resetCardRemoveState();
    }, cardFlyAnimationTime);

    // TODO: GA호출
    // if (ga) ga('send', 'event', 'study', 'moveCardBack');
  };

  moveCardNext = (card) => {
    if (this.state.removing) return;

    //#region 오른쪽으로 넘길 기억상자가 존재하지 않으면 날라가고 아니면 빙글빙글 효과를 내며 기억상자속으로 가도록
    const needToMemorize = this.props.rightMemboxLevel !== undefined && this.props.rightMemboxLevel !== null;
    if (needToMemorize) {
      this.setState({ removing: true, frontCardFlyToNext: true });
    } else {
      this.setState({ removing: true, frontCardFadeOutToRight: true });
    }
    //#endregion

    window.setTimeout(() => {
      this.setState({ pauseUpdateRendering: true });
      if (card !== AD_CARD_ID && this.props.onMemorize) {
        this.props.onMemorize(card, this.props.rightMemboxLevel);
      }
      this.moveNextAdCard();
      this.setState({ pauseUpdateRendering: false });
      this.resetCardRemoveState();
      if (this.state.cardIdQueue.length === 0) {
        this.props.onComplete();
      }
    }, cardFlyAnimationTime);

    // TODO: GA호출
    // if (ga) ga('send', 'event', 'study', 'moveCardBack');
  };

  onCardTimeout = () => {
    this.onFlip(Consts.LearnLevels.TIMEOVER);
  };

  moveNextAdCard() {
    let currentAdCardIndex = this.state.currentAdCardIndex;
    if (this.state.adCardInterval > 0) {
      if (currentAdCardIndex === 0) {
        currentAdCardIndex = this.state.adCardInterval - 1;
      } else {
        currentAdCardIndex--;
      }
    }
    // localStorage.setItem("adCardIndex", currentAdCardIndex);
    this.setState({ currentAdCardIndex });
  }

  onAdSkipClick = () => {
    this.onCardNext();
  };

  /**
   * 정답 확인을 요구하는 경고로 카드를 흔듬
   */
  shake = () => {
    this.setState({ frontCardShakeTime: Date.now() });
    window.setTimeout(() => {
      this.setState({ frontCardShakeTime: false });
    }, 500);
  };

  renderMembox(level) {
    if (level === undefined || level === null || !this.props.memboxInfo) {
      return undefined;
    }
    const memboxInfo = this.props.memboxInfo[level];
    return <MemboxTabItem dataSource={memboxInfo} index={level} style={{ width: 70, margin: 0, height: 60 }} />;
  }

  render() {
    const cardIdsQueue = this.state.cardIdQueue;
    let cardQueue = undefined;
    if (cardIdsQueue) {
      cardQueue = cardIdsQueue.map((cardId, key) => {
        if (key >= maxLoadingCount || !this.props.cardDataSource || cardId === null || cardId === undefined) {
          return undefined;
        }

        const cardData = this.props.cardDataSource.find((a) => a.id === cardId);
        const cardViewProps = {
          dataSource: cardData,
          disabled: key > 0,
        };
        if (cardId === AD_CARD_ID) {
          cardViewProps.dataSource = { templateId: -1 };
          cardViewProps.onAdSkipClick = this.onAdSkipClick;
        }

        if (key === 0) {
          cardViewProps.lessonName = this.props.lessonName;
          cardViewProps.active = true;
          cardViewProps.fliped = this.state.frontCardFliped;
          cardViewProps.shakeTime = this.state.frontCardShakeTime;
          cardViewProps.fadeOutToLeft = this.state.frontCardFadeOutToLeft;
          cardViewProps.fadeOutToRight = this.state.frontCardFadeOutToRight;
          cardViewProps.flyToNext = this.state.frontCardFlyToNext;
          cardViewProps.flyToPrev = this.state.frontCardFlyToPrev;
          cardViewProps.onFlip = this.onFlip;
        }

        return <CardView key={cardId} {...cardViewProps} />;
      });
    }

    return (
      <Swipable className="preview-study-view" onSwipeLeft={this.onCardBack} onSwipeRight={this.onCardNext}>
        <AdFitHorizontal mobile={document.body.clientWidth < 728} />
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            bottom: 0,
            height: 70,
            left: 0,
            right: 0,
            margin: "0px auto",
            backgroundColor: "rgb(76, 76, 76)",
            borderTop: "1px solid rgb(127, 127, 127)",
          }}
        >
          <div className="memorybox-item disable anchor-bottom-left" style={{ bottom: 6, left: 10, height: 60 }}>
            {this.renderMembox(this.props.leftMemboxLevel)}
          </div>
          <div className="memorybox-item disable anchor-bottom-right" style={{ bottom: 6, right: 10, height: 60 }}>
            {this.renderMembox(this.props.rightMemboxLevel)}
          </div>
          <div className="ready-queue" style={styles.readyQueue}>
            <CardQueueProgress
              value={this.props.totalCardCount ? this.props.totalCardCount - this.props.memorizedCardIds.length : 0}
              total={this.props.totalCardCount ? this.props.totalCardCount : 0}
            />
          </div>
        </div>
        {this.props.enableTimeOver ? (
          <Timer
            className="anchor-top"
            startTime={this.state.timerStartTime}
            onTimeout={this.onCardTimeout}
            run={this.state.learnLevel === Consts.LearnLevels.CHECKING}
          />
        ) : undefined}
        <div className="card-queue">{cardQueue}</div>
      </Swipable>
    );
  }
}

const styles = {
  readyQueue: {
    position: "absolute",
    left: "37.5%",
    width: "25%",
    top: "calc(50% - 7px)",
  },
};

const maxLoadingCount = 5;
const cardFlyAnimationTime = 500;

export default connect(PreviewStudyView.StateToProps, PreviewStudyView.DispatchToProps)(PreviewStudyView);

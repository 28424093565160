/**
 * Created by kimchangduk on 2017-09-13.
 */

import React from 'react';
import DialogManager from '../dialogs/DialogManager';
import PropTypes from 'prop-types';
import DefaultDialogView from "./DefaultDialogView";
import josa from 'josa-js';
import CouponRegisterDialog from "./CouponRegisterDialog";
import {Consts} from '../constants';
import BuyDialog from "./BuyDialog";
import {getPaymentState} from "../Api";
import Loader from "../components/Loader";

class SelectBuyMethodDialog extends React.Component {
    static propTypes = {
        item: PropTypes.object,
        onRequestClose: PropTypes.func
    };

    state = {
        paymentStatusRequest: Consts.REQUEST_WAITING,
        paymentStatus: null
    };

    componentDidMount() {
        getPaymentState().then(response => {
            this.setState({paymentStatus: response.data, paymentStatusRequest: Consts.REQUEST_SUCCESS});
        }).catch(error => {
            this.setState({paymentStatusRequest: Consts.REQUEST_FAILURE});
        });
    }

    onBuyClick = () => {
        DialogManager.pop();
        const impStatus = this.state.paymentStatus ? this.state.paymentStatus.find(item => item.name === 'IMP') : false;
        if (!impStatus) {
            DialogManager.alert('현재 이용하실 수 없습니다.');
        }

        if (impStatus.active) {
            DialogManager.push(BuyDialog, {item: this.props.item});
        } else {
            DialogManager.alert(impStatus.message);
        }
    };

    onCouponRegisterClick = () => {
        DialogManager.pop();
        const couponStatus = this.state.paymentStatus ? this.state.paymentStatus.find(item => item.name === 'COUPON') : false;
        if (!couponStatus) {
            DialogManager.alert('현재 이용하실 수 없습니다.');
        }

        if (couponStatus.active) {
            let message;
            switch (this.props.item.type) {
                case Consts.MEMBERSHIP:
                    message = undefined;
                    break;

                default:
                    message = `<b>${this.props.item.name}</b> 교재를 학습하려면 구매 도서 인증이 필요합니다.`;
            }

            DialogManager.push(CouponRegisterDialog, {message: message, checkCouponUsable: false});
        } else {
            DialogManager.alert(couponStatus.message);
        }
    };

    render() {
        switch (this.state.paymentStatusRequest) {
            case Consts.REQUEST_SUCCESS:
                return (<DefaultDialogView
                    showCloseButton={true}
                    content={<div>
                        <p style={styles.title}>{this.props.item.name}{josa.c(this.props.item.name, '을/를')} 구입 하시겠습니까?</p>
                    </div>}
                    footerClassName="button-length-2"
                    footerContent={
                        <div style={styles.footerContent}>
                            <button onClick={this.onBuyClick}>구입하기</button>
                            <button onClick={this.onCouponRegisterClick}>쿠폰 등록</button>
                            <hr/>
                        </div>
                    }
                    buttonType={Consts.DefaultDialogViewButtonTypes.CUSTOM}
                    showHeader={false}
                    onRequestClose={this.props.onRequestClose}
                />);

            case Consts.REQUEST_FAILURE:
                return (<DefaultDialogView
                    content={<p>현재 결제를 이용하실 수 없습니다.</p>}
                    onRequestClose={this.props.onRequestClose}
                />);

            case Consts.REQUEST_WAITING:
            default:
                return (<Loader/>);
        }
    }
}

const styles = {
    title: {
        textAlign: 'center',
        margin: '10px 0'
    },
    footerContent: {
        height: '100%'
    }
};

export default SelectBuyMethodDialog;
/**
 * Created by kimchangduk on 2017-09-09.
 */

import React from "react";
import Radium from "radium";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCategoryList, getCategory } from "../../actions/category";
import "./CategoryTable.scss";
import AppHistory from "../../history";
import * as Urls from "../../constants/Urls";
import ExitButton from "../../assets/icons/svg_kit/close.svg";

class CategoryTable extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    style: PropTypes.object,
    categoryListState: PropTypes.object,
    categoryListDataSource: PropTypes.array,
    categoryState: PropTypes.object,
    categoryDataSource: PropTypes.object,
    onBlur: PropTypes.func,
  };

  static StateToProps = (state, ownProps) => {
    return {
      categoryListState: state.category.categoryList.state,
      categoryListDataSource: state.category.categoryList.dataSource,
      categoryState: state.category.category.state,
      categoryDataSource: state.category.category.dataSource,
    };
  };

  static DispatchToProps = (dispatch, ownProps) => {
    return {
      actions: {
        getCategoryList: () => {
          dispatch(getCategoryList());
        },
        getCategory: (id) => {
          dispatch(getCategory(id));
        },
      },
    };
  };

  componentDidMount() {
    this.props.actions.getCategoryList();
    const thisElement = ReactDOM.findDOMNode(this);
    thisElement.focus();
  }

  onCategoryClick = (e) => {
    e.stopPropagation();

    // 페이지 이동
    const categoryId = parseInt(e.target.getAttribute("data"));
    if (!isNaN(categoryId)) {
      if (/\/category\/\d+/.test(window.location.pathname)) {
        AppHistory.replace(Urls.buildCategory(categoryId));
      } else {
        AppHistory.move(Urls.buildCategory(categoryId));
      }
    }
    this.onBlur();
  };

  onBlur = () => {
    if (this.props.onBlur) this.props.onBlur();
  };

  travelCategorysWithBullet(categories, depth = 0, array = undefined) {
    if (!array) {
      array = [];
    }

    if (!categories) {
      return array;
    }

    const marginLeft = 20 + depth * 20;

    for (let categoryIndex in categories) {
      categoryIndex = parseInt(categoryIndex);
      const category = categories[categoryIndex];
      const existsSubcategories = category.subCategories && category.subCategories.length > 0;

      array.push(
        <li
          className={depth > 0 ? `branch ${categoryIndex === categories.length - 1 ? "last" : ""}` : ""}
          key={category.id}
          style={[styles.sidemenuItem, { marginLeft }, existsSubcategories ? styles.nonClickable : undefined]}
          data={category.id}
          onClick={existsSubcategories ? undefined : this.onCategoryClick}
        >
          {category.name}
        </li>
      );
      this.travelCategorysWithBullet(category.subCategories, depth + 1, array);
    }
    return array;
  }

  onTouchBackLayer = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    const categoryComponents = this.travelCategorysWithBullet(this.props.categoryListDataSource);
    return (
      <div>
        <div style={styles.backLayer} onClick={this.onTouchBackLayer} className="opacity-0" />
        <div tabIndex={1} className="category-table" style={[styles.container, this.props.style]}>
          <div className="text-align-right">
            <img src={ExitButton} style={styles.exitButton} onClick={this.onTouchBackLayer} />
          </div>
          <ul className="category-tree">{categoryComponents}</ul>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    position: "absolute",
    color: "#000000",
    animation: "opacity0to1 0.125s",
  },
  sidemenuItem: {
    padding: "12px 0",
    cursor: "pointer",
  },
  backLayer: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    animation: "opacity0to1 0.125s",
  },
  exitButton: {
    cursor: "pointer",
    float: "right",
  },
  nonClickable: {
    cursor: "arrow",
  },
};

export default connect(CategoryTable.StateToProps, CategoryTable.DispatchToProps)(Radium(CategoryTable));

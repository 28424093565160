/* eslint-disable no-useless-escape */
/**
 * Created by kimchangduk on 2017-04-13.
 */

export const ENABLE_AD_CARD = false;

export const OS = "os";
export const OS_WEB = "Web";
export const OS_ANDROID = "Android";
export const OS_IOS = "iOS";

export const COOKIE_KEY_FCM_TOKEN = "fcm_token";
export const COOKIE_KEY_HOW_TO_USE_NEVER_SHOW = "HOW_TO_USE_NEVER_SHOW";

export const GA_ID = "UA-57633567-1";

export const REQUEST_NONE = "REQUEST_NONE";
export const REQUEST_WAITING = "REQUEST_WAITING";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_FAILURE = "REQUEST_FAILURE";

export const ROLE_ADMIN = "ROLE_ADMIN";

export const SEX_MALE = "MALE";
export const SEX_FEMALE = "FEMALE";

export const MEMORIZE_STATE_READY = "READY";
export const MEMORIZE_STATE_WAIT = "WAIT";

export const Authorities = {
  ROLE_SUPERUSER: "ROLE_SUPERUSER",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_API_VIEWER: "ROLE_API_VIEWER",
  ROLE_API_TESTER: "ROLE_API_TESTER",
  ROLE_TESTER: "ROLE_TESTER",
};

export const LearnLevels = {
  CHECKING: "CHECKING",
  CHECKED: "CHECKED",
  TIMEOVER: "TIMEOVER",
};

export const DefaultDialogViewButtonTypes = { DEFAULT: "DEFAULT", SUCCESS: "SUCCESS", ERROR: "ERROR", CUSTOM: "CUSTOM" };
export const ConfirmDialogViewButtonTypes = { YES_OR_NO: "YES_OR_NO", CONFIRM_OR_CANCEL: "CONFIRM_OR_CANCEL" };

export const RESPONSE_ID_EMAIL_VERIFICATION_REQUIRED = 4011001;
export const RESPONSE_ID_BUY_REQUIRED = 4031001;

export const PAGENATION_LOCALE = {
  // Options.jsx
  items_per_page: "",
  jump_to: "이동",
  jump_to_confirm: "확인",
  page: "페이지",

  // Pagination.jsx
  prev_page: "이전",
  next_page: "다음",
  prev_5: "이전 5 페이지",
  next_5: "다음 5 페이지",
  prev_3: "이전 3 페이지",
  next_3: "다음 3 페이지",
};

export const MEMBERSHIP = "MEMBERSHIP";
export const DECK = "DECK";
export const CBT_TEST = "CBT_TEST";

export const DEFAULT_SHORT_TERM_MEMORY_LEVEL = 25;

export const OrderMode = {
  ORDERED: "ORDERED",
  RANDOM: "RANDOM",
};

export const BR_REGEX = /(?:<br\/>|<\/br>|<br>)/g;

export const HELP_MAIL = "help@membox.co.kr";

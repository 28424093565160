/**
 * Created by kimchangduk on 2017-08-10.
 */

import React from 'react';
import PropTypes from 'prop-types';

class UnsupportedFcmDialog extends React.Component {
    static propTypes = {
        onRequestClose: PropTypes.func
    };

    onChromeInstallClick = () => {
        window.open("https://www.google.com/chrome/index.html");
    };

    render() {
        return (<div className="popup-view popup ">
                <div className="popup-panel default-panel">
                    <div className="popup-content">
                        학습 알림은 Chrome, Firefox, Opera Mobile 에서만 지원됩니다.
                    </div>
                    <div className="popup-footer button-length-2">
                        <button onClick={this.onChromeInstallClick}>Chrome 설치하기</button>
                        <button onClick={this.props.onRequestClose}>닫기</button>
                        <hr/>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    whiteColor: {
        color: '#FFFFFF'
    }
};

export default UnsupportedFcmDialog;
/**
 * Created by kimchangduk on 2017-10-17.
 */

import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DefaultDialogView from "./DefaultDialogView";
import {payMethodToKorean, toPriceFormat} from "../utils";
import {Consts} from "../constants";

class PurchaseHistoryDetailDialog extends React.Component {
    static propTypes = {
        actions: PropTypes.object,
        history: PropTypes.object,
        onRequestClose: PropTypes.func
    };

    static StateToProps = (state, ownProps) => {
        return {};
    };

    static DispatchToProps = (dispatch, ownProps) => {
        return {
            actions: {}
        };
    };

    render() {
        const history = this.props.history;
        const imp = history.imp;
        return (<DefaultDialogView
            panelClassName="width-400"
            onRequestClose={this.props.onRequestClose}
            content={<div>
                <div style={styles.row}>
                    <div style={styles.column1}>주문번호</div>
                    <div style={styles.column2}>{history.uid}</div>
                </div>

                <div style={styles.row}>
                    <div style={styles.column1}>결제내역</div>
                    <div style={styles.column2}>
                        {
                            history.items.map((item, key) => {
                                return <div key={key}>
                                    {item.name}
                                </div>;
                            })
                        }
                    </div>
                </div>

                <div style={styles.row}>
                    <div style={styles.column1}>금액</div>
                    <div style={styles.column2}>{history.coupon ? '₩0' : (imp ? toPriceFormat(imp.amount) : '')}</div>
                </div>

                <div style={styles.row}>
                    <div style={styles.column1}>결제수단</div>
                    <div style={styles.column2}>{history.coupon ? '쿠폰' : (imp ? payMethodToKorean(imp.pgProvider, imp.payMethod) : '')}</div>
                </div>

                {
                    history.coupon ?
                        <div style={styles.row}>
                            <div style={styles.column1}>쿠폰코드</div>
                            <div style={styles.column2}>{history.coupon.code}</div>
                        </div> : undefined

                }
                <div className="text-align-center margin-top-10">
                    <a style={styles.refund} href={`mailto:${Consts.HELP_MAIL}?subject=${history.uid} - 환불요청`}>환불요청</a>
                </div>
                {
                    imp && imp.receiptUrl ?
                        <button onClick={() => {
                            window.open(imp.receiptUrl);
                        }} className="flat-button primary full" style={styles.receipt}>영수증 인쇄</button> : undefined
                }
            </div>
            }
        />);
    }
}

const styles = {
    column1: {display: 'inline-block', width: '40%', padding: 5, verticalAlign: 'top', wordBreak: 'break-all'},
    column2: {display: 'inline-block', width: '60%', padding: 5, verticalAlign: 'top', wordBreak: 'break-all'},
    receipt: {marginTop: 10},
    row: {
        borderBottom: '1px solid rgba(0,0,0,0.1)'
    },
    refund: {
        color: '#000000',
        fontWeight: 'bold',
        ':hover': {
            textDecoration: 'underline'
        }
    }
};

export default connect(PurchaseHistoryDetailDialog.StateToProps, PurchaseHistoryDetailDialog.DispatchToProps)(PurchaseHistoryDetailDialog);
/**
 * Created by kimchangduk on 2017-12-31.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Navigator, {LeftMenuTypes} from "../components/Navigator";
import {Consts, Urls, Strings} from '../constants';
import * as Api from '../Api';
import Loader from "../components/Loader";
import MembershipItem from "../components/MembershipItem";
import DeckItem from "../components/DeckItem";
import CBTItem from "../components/CBTItem";
import {isMobileDevice} from "../utils";

class HashtagResultPage extends React.Component {
    static propTypes = {
        match: PropTypes.object
    };

    state = {
        responseData: null,
        request: Consts.REQUEST_NONE
    };

    componentDidMount() {
        this.requestHashtag(this.props.match.params.name);
    }

    componentWillReceiveProps(nextProps) {
        const prevHashtag = this.props.match.params.name;
        const nextHashtag = nextProps.match.params.name;
        if (prevHashtag !== nextHashtag) {
            this.requestHashtag(nextHashtag);
        }
    }

    requestHashtag = (name) => {
        this.setState({request: Consts.REQUEST_WAITING});
        Api.hashtag(name).then(response => {
            this.setState({request: Consts.REQUEST_SUCCESS, responseData: response.data});
        }).catch(error => {
            this.setState({request: Consts.REQUEST_FAILURE});
        });
    };

    renderContent() {
        switch (this.state.request) {
            case Consts.REQUEST_WAITING:
                return <Loader/>;
            case Consts.REQUEST_SUCCESS: {
                const data = this.state.responseData ? this.state.responseData.items : null;
                const isMobile = isMobileDevice();
                return <div>
                    <div style={isMobile ? styles.mobile.list : null}>
                        {
                            data ? data.membership.sort((a, b) => {
                                if (a.has && b.has && a.buyDate && b.buyDate) {
                                    return b.buyDate - a.buyDate;
                                }
                                return b.has - a.has;
                            }).map((item, key) => {
                                return (<MembershipItem mobile={isMobile} key={key} data={item}/>);
                            }) : undefined
                        }
                    </div>
                    <div style={isMobile ? styles.mobile.list : null}>
                        {
                            data ? data.deck.sort((a, b) => {
                                if (a.has && b.has && a.buyDate && b.buyDate) {
                                    return b.buyDate - a.buyDate;
                                }
                                return b.has - a.has;
                            }).map((item, key) => {
                                return (<DeckItem mobile={isMobile} key={key} deck={item}/>);
                            }) : undefined
                        }
                    </div>
                    <div style={isMobile ? styles.mobile.list : null}>
                        {
                            data ? data.cbt.sort((a, b) => {
                                if (a.has && b.has && a.buyDate && b.buyDate) {
                                    return b.buyDate - a.buyDate;
                                }
                                return b.has - a.has;
                            }).map((item, key) => {
                                return (<CBTItem mobile={isMobile} key={key} test={item}/>);
                            }) : undefined
                        }
                    </div>
                </div>;
            }
            case Consts.REQUEST_FAILURE:
                return <p>{Strings.NO_RESULTS}</p>;
        }
    }

    render() {
        return (<div>
            <Navigator leftMenuType={LeftMenuTypes.BACK} title={`# ${this.props.match.params.name}`}/>
            <div className="search-result" style={styles.content}>
                {this.renderContent()}
            </div>
        </div>);
    }
}

const styles = {
    content: {
        position: 'absolute'
    },
    mobile: {
        list: {
            margin: '10px 10px',
            backgroundColor: '#FFFFFF',
            borderRadius: 5
        }
    }
};

export default HashtagResultPage;
import React from 'react';
import PropTypes from 'prop-types';
import FoldableDiv from "./FoldableDiv";
import {toPriceFormat} from "../../utils";

class DescriptionDiv extends React.Component {
    static propTypes = {
        defaultOpenState: PropTypes.bool,
        data: PropTypes.string,
        showBuyButton: PropTypes.bool,
        onBuyClick: PropTypes.func,
        price: PropTypes.number
    };

    static defaultProps = {
        showBuyButton: false,
        defaultOpenState: false
    };

    render() {
        return <div style={styles.container}>
            <FoldableDiv content={this.props.data} defaultFold={!this.props.defaultOpenState}/>
            {
                this.props.showBuyButton ? <div style={styles.buyButton} onClick={this.props.onBuyClick}>{this.props.price ? toPriceFormat(this.props.price) + ' ' : ''}구입</div> : undefined
            }
        </div>;
    }
}

const styles = {
    container: {
        margin: 10,
        padding: '10px 10px',
        border: '1px solid #bbbbbb',
        borderRadius: '.25rem',
        color: '#004085',
        backgroundColor: '#ffffff',
    },
    buyButton: {
        display: 'inline-block',
        border: '1px solid black',
        cursor: 'pointer',
        color: 'black',
        fontWeight: 'bold',
        fontSize: 14,
        padding: '3px 5px',
        marginTop: 4
    }
};

export default DescriptionDiv;
/**
 * Created by kimchangduk on 2017-04-21.
 */
import React from 'react';
import AppHistory from '../history';
import {Consts, Urls} from '../constants';
import {connect} from 'react-redux';
import {getUser} from '../actions/user';
import PropTypes from 'prop-types';
import SplashPage from "./SplashPage";
import RightSideMenu from "../components/RightSideMenu";
import DialogManager from "../dialogs/DialogManager";
import CategorySideMenu from "../components/CategorySideMenu";
import Footer from "../components/Footer";
import Notifications from 'react-notify-toast';
import NoticePopupList from "../components/NoticePopupList";

class App extends React.Component {
    static propTypes = {
        rightSideMenuVisible: PropTypes.bool,
        categorySideMenuVisible: PropTypes.bool,
        isLogin: PropTypes.bool,
        loginRequest: PropTypes.string,
        getRequest: PropTypes.string,
        location: PropTypes.object,
        actions: PropTypes.object,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    static StateToProps = (state) => {
        return {
            isLogin: state.user.state.isLogin,
            loginRequest: state.user.state.loginRequest,
            getRequest: state.user.state.getRequest,
            rightSideMenuVisible: state.sideMenu.rightSideMenuVisible,
            categorySideMenuVisible: state.sideMenu.categorySideMenuVisible
        };
    };

    static DispatchToProps = (dispatch) => {
        return {
            actions: {
                getUser: () => {
                    dispatch(getUser());
                }
            }
        };
    };

    componentDidMount() {
        setTimeout(() => {
            this.props.actions.getUser();
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLogin !== this.props.isLogin) {
            // 회원정보 불러온 후 로그인 필요한 경로 접근시 로그인페이지로 리다이렉트
            if (!this.props.isLogin) {
                if (this.checkLoginRequiredPath()) {
                    AppHistory.move(Urls.ROOT);
                }
            }
        }

        // 현재 path가 변경되면 로그인 필요페이지인지 검사
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.isLogin === false && this.checkLoginRequiredPath()) {
                AppHistory.replaceLoginPage();
            }
        }
    }

    checkLoginRequiredPath = () => {
        for (let url of Urls.loginRequiredUrls) {
            if (this.props.location.pathname.startsWith(url)) {
                return true;
            }
        }
        return false;
    };

    render() {
        if (this.props.isLogin === undefined) {
            let splashMessage;
            if (this.props.loginRequest === Consts.REQUEST_WAITING) {
                splashMessage = '로그인 중';
            } else if (this.props.getRequest === Consts.REQUEST_WAITING) {
                splashMessage = '회원 정보 불러오는 중';
            }

            return <SplashPage message={splashMessage}/>;
        }
        return (<div>
            {this.props.children}
            <RightSideMenu visible={this.props.rightSideMenuVisible}/>
            <DialogManager/>
            <Notifications/>
            <NoticePopupList/>
        </div>);
    }
}

export default connect(App.StateToProps, App.DispatchToProps)(App);
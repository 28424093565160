import React from "react";
import PropTypes from "prop-types";

class Footer extends React.Component {
  static propTypes = {};

  render() {
    return (
      <div style={styles.container} className="footer">
        <div>
          (주) 길벗알앤디 · 대표이사: 강윤석{" "}
          <a href="/terms-of-service" rel="noreferrer" target="_blank" className="terms-link">
            이용약관
          </a>
          <a href="/privacy-policy" rel="noreferrer" target="_blank" className="terms-link">
            개인정보취급방침
          </a>
        </div>
        <div>사업자등록번호: 105-88-13453 · 통신판매업신고: 2015-서울마포-01477</div>
        <div>마포구 월드컵북로 5길 65 · TEL: 02-335-0932</div>
      </div>
    );
  }
}

const styles = {
  container: {},
};

export default Footer;

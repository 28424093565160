/**
 * Created by kimchangduk on 2017-7-25.
 */

import { ActionTypes, Consts } from "../constants";
import update from "immutability-helper";

const initialState = {
  state: {
    request: Consts.REQUEST_NONE,
    errorMessage: "",
    urlKey: null,
  },
  dataSource: null,
};

export default function cbtSolutionReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_CBT_SOLUTION_REQUEST:
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_WAITING },
          errorMessage: { $set: "" },
          urlKey: { $set: action.urlKey },
        },
      });

    case ActionTypes.GET_CBT_SOLUTION_SUCCESS: {
      if (state.state.urlKey !== action.urlKey) {
        return state;
      }

      const data = action.data;
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_SUCCESS },
          errorMessage: { $set: "" },
        },
        dataSource: { $set: data },
      });
    }

    case ActionTypes.GET_CBT_SOLUTION_FAILURE:
      if (state.state.urlKey !== action.urlKey) {
        return state;
      }
      return update(state, {
        state: {
          request: { $set: Consts.REQUEST_FAILURE },
          errorMessage: { $set: action.errorMessage },
        },
      });

    default:
      return state;
  }
}
